<template>
	  <!-- <div v-show="!this.inLoader" > -->
  <div class="row">
    <div class="col-12">
      <h3 class="text-dark mb-4">Cookies</h3>
      <p class="d-block mb-4 text-dark">
        {{this.total_cookies > 0 ? (this.discovered_cookies.length + this.declared_cookies.length): 0 }} cookies discovered on <b>{{domain.domains_reports ? domain.domains_reports.length > 0 ? date(domain.domains_reports[0].created_at) : date(domain.created_at) : ""}}</b>
        <br />
      </p>
      <p class="mb-3" v-if="domain.last_scan_at == '' || domain.last_scan_at == null">
              <strong>Your website scanning is in process</strong>
            </p>

            <p class="mb-3" v-else-if="domain.scan_frequency == 'only_once'">
              <strong>Only one time scan was allowed.</strong>
            </p>
            <p style="         background: #eef7f5;" class="" v-else>
              <b> Status: </b> Next scheduled on
              <b>{{
                domain.domains_reports
                ? domain.domains_reports.length > 0
                ? dateWithFrequency(
                domain.domains_reports[0].created_at,
                domain.scan_frequency
                )
                : dateWithFrequency(
                domain.created_at,
                domain.scan_frequency
                )
                : dateWithFrequency(
                      domain.last_scan_at,
                      domain.scan_frequency
                    )
                }}</b>
            </p>
    </div>
    <div class="col-12">
      <ul class="list-unstyled border-bottom p-0 mb-4 d-flex align-items-center tab-list-get-code">
        <li class="mb-4 mr-3">
          <a
            class="btn"
            @click="tab = 'DiscoveredCookies'"
            :class="[tab === 'DiscoveredCookies' ? 'btngreenbg' : 'btntabbg']"
            >Discovered Cookies</a
          >
        </li>
        <li class="mb-4 mr-3">
          <a
            class="btn"
            @click="tab = 'DeclaredCookies'"
            :class="[tab === 'DeclaredCookies' ? 'btngreenbg' : 'btntabbg']"
            >Self Declared Cookies
          </a>
        </li>
        <li class="mb-4 mr-3 ml-auto">
          <a
            class="btn btn-primary"
            @click="downloadCSV"
            ><i  class="fa fa-download mr-2"></i>Download CSV
          </a>
        </li>
      </ul>
    </div>
    <div v-show="this.trackingLoader" class="report-hol" style="margin-top: 30px; margin-left: 550px;">
        <div  class="before scanning-block text-center py-5">
            <PulseLoader  style="display:inline-block" />
        </div>
      </div>
    <div v-show="!this.trackingLoader" class="col-12">
      <component :is="tab" />
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import DiscoveredCookies from "@/components/CustoMiZationComponent/TrackingManagerParts/DiscoveredCookies.vue";
import DeclaredCookies from "@/components/CustoMiZationComponent/TrackingManagerParts/DeclaredCookies.vue";
import moment from "moment";
 import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
 import Papa from 'papaparse';
 import { mapState } from 'vuex';
export default {
  name: "CookieManager",
  components: {
    DiscoveredCookies,
    DeclaredCookies,
    PulseLoader,
  },
  data() {
    return {
      tab: "DiscoveredCookies",
    };
  },
  watch:{
		domain_id: {
			// deep: true,
      // immediate:true,
			handler(){
				this.loadCookies();
			}
		}
	},
  computed:{
    ...mapState('domain',['trackingLoader', 'totalCookies', 'discovered_cookies', 'self_declared_cookies']),
    discovered_cookies: function () {
			return this.$store.getters['domain/getDiscoveredCookies'];
		},
    declared_cookies: function () {
			return this.$store.getters['domain/getSelfDeclaredCookies'];
		},
    domain: function () {
			return this.$store.getters['domain/getCurrentDomain'];
		},
    domain_id: function () {
			return this.$store.getters['domain/getCurrentDomainId'];
		},
    total_cookies: function () {
			return this.$store.getters['domain/getTotalCookies'];
		},
    customize_categories : function () {
      return this.$store.getters['domain/getCategories'];
    }
  },
  mounted() {
		this.loadCookies();
    console.log(this.total_cookies);
	},
  methods: {
//    

convertToCSV(cookies) {
  const mapCategoryIdToName = {
    1: 'Statistics',
    2: 'Marketing',
    3: 'Necessary',
    4: 'Preferences',
    5: 'Unclassified',
  };

  const extractProperties = (obj) => {
  let category;
  if (obj.cb_cat_id > 5) {
    const customCategories = this.customize_categories;
    const customCategory = customCategories.find(category => category.id === obj.cb_cat_id);
    category = customCategory ? customCategory.customize_title : '';
  } else {
    category = mapCategoryIdToName[obj.cb_cat_id] || '';
  }
  return {
    Name: obj.name,
    Category: category,
    URL: obj.first_found_url,
    Provider: obj.provider,
    Description: obj.purpose_desc || '',
  };
};


  const extractedData = cookies.map(obj => extractProperties(obj));
  const headers = Object.keys(extractedData[0]);
  const csv = Papa.unparse({ fields: headers, data: extractedData });
  return csv;
},

downloadCSV() {
  // console.log("custom categories",this.customize_categories);
  const discovered_cookies = this.discovered_cookies;
  const self_declared_cookies = this.self_declared_cookies;

  if (discovered_cookies.length === 0 && self_declared_cookies.length === 0) {
    console.warn('No data available for download.');
    return;
  }

  const allCookies = [...discovered_cookies, ...self_declared_cookies];

  const csv = this.convertToCSV(allCookies);

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'all_cookies.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    console.error('Your browser does not support the download attribute.');
  }
},

    loadCookies(){
			let payload = {
				domain_id: this.domain_id,
			};
			this.$store.dispatch('domain/loadCookies',payload);
		},
    date(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    dateWithFrequency(value, frequency) {
      let date = new Date(value);
      switch (frequency) {
        case "only_once":
          date = "Only one time scan was allowed";
          break;
        case "monthly":
          date.setMonth(date.getMonth() + 1);
          // date = (date.getDate()) + '/' + ((date.getMonth() + 1) + 1) + '/' + date.getFullYear();
          break;
        case "3 months":
          date.setMonth(date.getMonth() + 3);
          // date = (date.getDate()) + '/' + ((date.getMonth() + 1) + 3) + '/' + date.getFullYear();
          break;
        case "6 months":
          date.setMonth(date.getMonth() + 6);
          // date = (date.getDate()) + '/' + ((date.getMonth() + 1) + 6) + '/' + date.getFullYear();
          break;
        case "yearly":
          date.setMonth(date.getMonth() + 12);
          break;
        default:
          break;
      }
      date =
              date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
      return date;
    },
  },
};
</script>

<style scoped>
.btntabbg {
  background: #e0e0e0;
  color: #000;
}
.btngreenbg {
  background: #6cc04a !important;
  color: #fff !important;
}
@media (max-width: 575.99px) {
  .tab-list-get-code {
    flex-direction: column !important;
    margin-bottom: 15px !important;
  }
  .tab-list-get-code li {
    margin-right: 0 !important;
    margin-bottom: 15px !important;
    width: 100% !important;
  }
  .tab-list-get-code li a {
    width: 100% !important;
  }
}
</style>
