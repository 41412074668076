<template>
  <form>
    <div class="row m-0 mb-1">
      <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 p-0 pt-3">
        <h2 class="mb-4">Cookie Policy Generator</h2>
      </div>
    </div>
    <div class="progress-bar-value mb-1">
      Progress {{ progress.toFixed(2) }}%
    </div>
    <div class="progress mb-1" style="height: 20px">
      <div
        role="progressbar"
        aria-valuenow="40"
        aria-valuemin="0"
        aria-valuemax="100"
        class="progress-bar"
        style="
          background-color: rgb(108, 192, 74);
          padding: 4px 0px;
          width: 70%;
        "
        :style="progressStyle"
      >
        <span class="sr-only"
          >{{ progress.toFixed(2) }}% Complete (success)</span
        >
      </div>
    </div>
    <p class="mb-5">{{ current_cookieXray.name }}</p>
    <div>
      <!-- v-for="(question, key) in questions" :key="key" v-if="key == current_question_index" -->

      <div v-for="(question, key) in questions">
        <div v-if="key == current_question_index">
          <!-- :question_index="key" :question="question" -->
          <QuestionS
            :question_index="key"
            :question="question"
            
          >
          </QuestionS>
        </div>
      </div>
      <span
        v-if="show_error && error_message === ''"
        class="text-color-red"
        style="font-size: 14px"
      >
        * Please give answer to continue
      </span>
      <span
        v-if="show_error && error_message !== ''"
        class="text-color-red"
        style="font-size: 14px"
      >
        * {{ error_message }}
      </span>
    </div>
    <hr class="footer-hr" />
    <div class="d-flex">
      <a class="btn btn-white-bg mr-3" :class="{ 'disabled-link': shouldBeDisabled }" @click="handlePreviousClick">
        Previous</a
      >
      <a id="next-question" class="btn btn-blue-bg" :class="{ 'disabled-link': shouldBeDisabled }" @click="handleNextClick"
        >{{ nextButtonText }}
      </a>
    </div>
  </form>
</template>
<script>
import QuestionS from "@/components/CustoMiZationComponent/PolicyReport/QuestionS.vue";
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: "PolicyQuestion",
  components: {
    QuestionS,
  },
  watch: {
    domain_id: {
			// deep: true,
			handler(){
				this.loadCurrentCookieXray()
			}
		},
    current_question_index: function(val) {
      this.$store.commit("policy/findNextQuestion");
      this.$store.commit("policy/findPrevQuestion");
    },
    is_done: function(val) {
    },
    is_last_question: function(val) {
      if (val == false) {
        this.$store.commit("policy/setIsDone", false);
      }
    },
  },
  data() {
    return {
		mountedif: false,
		buttonShow: false,

    show: false,
      isCurrentDone: false,
      // progress:0,
      policies: [],
      policy_docs: [],
      cookie_questions: [],
      cookie_answers: [],
      visible_types: ["html", "text"],
      // visible_types: ['html', 'text', 'checkbox','radiogroup','dropdown'],
      question_visible_types: ["checkbox", "radiogroup", "dropdown", "text"],
    };
  },
  computed: {
    ...mapState('domain', ['user','assign_role']),
    cmp_url(){
      return process.env.VUE_APP_ENV_VARIABLE
		},
    nextButtonText() {
      if (this.is_last_question == true) {
        return "Submit";
      }
      return "Next";
    },
    domain_id: function () {
      return this.$store.getters["domain/getCurrentDomainId"];
    },
    progress: function () {
      return this.$store.getters["policy/getProgress"];
    },
    progressStyle: function () {
      return { width: this.progress + "%" };
    },
    current_cookieXray() {
      return this.$store.getters["policy/getCurrentCookieXray"];
    },
    shouldBeDisabled() {
      return !this.user.isSuperAdmin && this.user.superAdminId !== null && this.assign_role === 'reader';
    },
    questions() {
      return this.$store.getters["policy/getQuestion"];
    },
    current_question_index() {
      return this.$store.getters["policy/getCurrentQuestionIndex"];
    },
    show_error() {
      return this.$store.getters["policy/getShowError"];
    },
    cookie_policy: function () {
      return this.$store.getters["domain/getFeatures"].cookie_policy;
    },
    current_answer: function () {
      return this.$store.getters["policy/getCurrentAnswer"];
    },
	AllAnswers() {
      return this.$store.getters["policy/getAllAnswers"];
    },
	userId: function(){
		return this.$store.state.domain.userId;
	},
	is_last_question() {
      return this.$store.getters["policy/is_last_question"];
    },
	error_message: function() {
      return this.$store.getters["policy/getErrorMessage"];
    },
	is_done() {
      return this.$store.getters["policy/is_done"];
    },
  },
  
  mounted() {
    this.loadCurrentCookieXray();
  },
  methods: {
    handlePreviousClick() {
    if (!this.shouldBeDisabled) {
      this.showPreviousQuestion();
    }
  },
  handleNextClick() {
    if (!this.shouldBeDisabled) {
      this.showNextQuestion();
    }
  },
    loadCurrentCookieXray() {
      this.$store
        .dispatch("policy/loadCurrentCookieXray", {
          id: 1,
          d_id: this.domain_id,
        })
        .then(() => {
          this.mountedif = true;
        });
    },
    showNextQuestion: function () {
      if (
        this.cookie_policy == null ||
        (this.cookie_policy === 0 && this.current_question_index == 4)
      ) {
        this.$store.commit("domain/setShowPricingPopup", true);
        return;
      }
      const self = this;
      if (!this.validateInputValues()) {
        return;
      }
    //   self.$Progress.start();
      self.$store
        .dispatch("policy/isCurrentQuestionDone")
        .then(() => {
          if (self.is_last_question == true && self.show_error == false) {
            self.$store.commit("policy/setIsDone", true);
            let payload = {
              done: self.is_done,
              cookieXray_id: self.current_cookieXray.id,
              domain_id: this.domain_id,
              answers: self.AllAnswers,
              user_id: this.userId,
            };
            self.buttonShow = true;
			// this.$store.dispatch('policy/cookieAllAnswers',payload)
            axios
              .post(`${this.cmp_url}/api/v2/cookieXrayAnswers`, payload,
              {
           
           headers:{
               'Authorization' : 'Bearer ' + localStorage.getItem('user_token')
           }
       })
              .then(function (response) {
                self.$store.commit("policy/setErrorMessage", "");
                self.generateReport();
                self.buttonShow = false;
                // self.$Progress.finish();
              })
              .catch(function (error) {
                self.buttonShow = false;
                if (error.response) {
                  console.log(self.errors);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log("Error", error.message);
                }
              });
          } else {
            this.$store.dispatch("policy/setNextQuestion");
          }
        })
        .catch((error) => {
          this.$store.commit("policy/setShowError", true);
        });
    },
    showPreviousQuestion: function () {
      this.$store.dispatch("policy/setPrevQuestion");
      this.$store.commit("policy/setShowError", false);
      this.$store.commit("policy/setLastQuestion", false);
      this.$store.commit("policy/setIsDone", false);
      this.$store.commit("policy/setErrorMessage", "");
    },
    validateInputValues: function () {
      if (this.current_answer === null) return true;

      let question = this.questions.find(
        (ques, index) => index === this.current_question_index
      );

      if (question && question.hasOwnProperty("inputType")) {
        let error = "";
        switch (question.inputType) {
          case "tel":
            let tel_regex =
              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            if (!this.current_answer.match(tel_regex)) {
              error = "Please enter valid Phone Number.";
            }
            break;
          case "number":
            let number_regex = /^\d+$/;
            if (!this.current_answer.match(number_regex)) {
              error = "Please enter a valid Number";
            }
            break;
          case "email":
            let email_regex =
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!this.current_answer.match(email_regex)) {
              error = "Please enter a valid Email Address";
            }
            break;
          case "url":
            let url_regex =
              /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
            if (!this.current_answer.match(url_regex)) {
              error = "Please enter a valid URL";
            }
            break;
        }
        if (!error == "") {
          this.$store.commit("policy/setShowError", true);
          this.$store.commit("policy/setErrorMessage", error);
          return false;
        }
      }

      return true;
    },
    async generateReport() {
      const self = this;
      var domain_id = this.domain_id;
      let is_answered = false;
      let answer = null;
      var user_id = this.userId;
      var current_id = 1;
      return await axios
        .get(`${this.cmp_url}/api/v2/getCurrentCookieXray/${current_id}/${user_id}`, {
           
           headers:{
               'Authorization' : 'Bearer ' + localStorage.getItem('user_token')
           }
       })
        .then((response) => {
          var answers = response.data.current_cookieXray.answer;
          answers.forEach(function(ans) {
            if (ans.domain_id == domain_id) {
              is_answered = true;
              answer = ans;
            }
          });
          response.data.current_cookieXray.answer = answer;
          self.$store.commit("policy/setCurrentCookieXray", {
            cookieXray: response.data.current_cookieXray,
          });
          self.$store.commit(
            "policy/setCurrentCookieXrayId",
            response.data.current_cookieXray.id
          );

          if (answer != null) {
            if (answer.hasOwnProperty("answers")) {
              self.$store.commit(
                "policy/setCurrentQuestionIndex",
                answer.answers[1].current_question_index
              );
              self.$store.commit(
                "policy/setQuestionCounter",
                answer.answers[1].question_counter
              );
            }
          } else {
            self.$store.commit("policy/setCurrentQuestionIndex", 0);
            self.$store.commit("policy/setQuestionCounter", 1);
          }
          self.$store.commit("policy/setQuestions");
          self.$store.commit("policy/setTotalQuestions");
          self.$store.commit("policy/initializeAnswers");
          self.$store.commit("policy/findNextQuestion");
          self.$store.commit("policy/findPrevQuestion");

          var current_cookie_xray = self.$store.getters['policy/getCurrentCookieXray'];

          self.policy_docs = [];
          self.cookie_questions = [];
          self.cookie_answers = [];

          self.policy_docs.push(
            current_cookie_xray.questions.pages[0].elements.filter((question) =>
              self.visible_types.includes(question.type)
            )
          );
          self.cookie_questions.push(
            current_cookie_xray.questions.pages[0].elements.filter((question) =>
              self.question_visible_types.includes(question.type)
            )
          );
          self.cookie_answers.push(current_cookie_xray.answer.answers[0]);
          self.findPolicies();
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    findPolicies() {
      var final_policy_docs = [];
      const self = this;
      self.cookie_answers[0].reverse();
      for (var i = 0; i < self.policy_docs[0].length; i++) {
        if (
          typeof self.policy_docs[0][i] !== "undefined" &&
          self.policy_docs[0][i].hasOwnProperty("visibleIf")
        ) {
          var policy_docs_condition = self.policy_docs[0][i].visibleIf;
          String.prototype.replaceAll = function(search, replacement) {
              var target = this;
              return target.replace(new RegExp(search, 'g'), replacement);
          };
          var result = policy_docs_condition
            .replaceAll("\n", "")
            .replaceAll(/AND/, "&&")
            .replaceAll(/OR/, "||")
            .replaceAll("=", "==")
            .replaceAll("NOTEQUAL", "!=")
            .replaceAll("notequal", "!=")
            .replaceAll("equal", "==")
            .replaceAll("EQUAL", "==")
            .replaceAll("notempty", "!= 'null'")
            .replaceAll("empty", "== 'null'")
            .replaceAll("notcontains", "!==")
            .replaceAll("contains", "===");
          //break;

          self.cookie_answers[0].forEach(({ question_name, answer }) => {
            if (question_name !== "") {
              result = this.findVariableValue(result, question_name, answer);
            }
          });
          result = result.replaceAll("{", "'").replaceAll("}", "'");
          if (eval(result) == true) {
            final_policy_docs.push(self.policy_docs[0][i]);
          }
        } else {
          // here is without visible.
          final_policy_docs.push(self.policy_docs[0][i]);
        }
      }
      self.cookie_answers[0].reverse();

      self.createPolicy(final_policy_docs);

      return;
      // self.showRecommendations = final_recommendations;
      // let usman = self.showRecommendations[0].html.split('\n');
    },
    findVariableValue(source,search,values){

    var found = 0;
    var vals = [];
    var  i = 0;

    while((found = source.indexOf(search,0)) !== -1){
              var val = {
                  var_start: found,
                  var_end: found + search.length,
                  value_start: source.indexOf("'",found+ search.length)+1,
                  value_end:0,
                  value:''
              };
              //val.value_start = source.indexOf("'",val.var_end)+1,
              val.value_end = source.indexOf("'",val.value_start+1);
              val.value = source.substr(val.value_start,val.value_end - val.value_start);


              if(Array.isArray(values)){
                  if(values.includes(val.value)){
                      source = this.replaceVariableValues(source,search,val,'true')
                  } else {
                      source = this.replaceVariableValues(source,search,val,'false')
                  }
              }else{
                  if(values == val.value){
                      source = this.replaceVariableValues(source,search,val,true);
                  } else {
                      source = this.replaceVariableValues(source,search,val,false);
                  }
              }
              vals.push(val)
          }
          return source;
    },
    replaceVariableValues (source,question,val,what){
        var broken = [
            source.substr(0,val.var_start),
            'true',
            source.substr(val.var_end,val.value_start - val.var_end),
            what,
            source.substr(val.value_end,source.length),
        ];
        return broken.join('');
    },
    
    async createPolicy(final_policy) {
      var self = this;
      var id = self.$store.getters['policy/getCurrentCookieXray'].id;
      var policy_doc;
      await axios
        .get(`${this.cmp_url}/api/v2/cookieXray-policy-doc/${id}`,{
           
           headers:{
               'Authorization' : 'Bearer ' + localStorage.getItem('user_token')
           }
       })
        .then(function(response) {
          policy_doc = response.data.policy_doc.document;

          final_policy.forEach(function(policy) {
            if (policy.hasOwnProperty("title")) {
              var answer = self.cookie_answers[0].find(
                (x) => x.question_name == policy.name
              ).answer;
              policy_doc = policy_doc.replaceAll(policy.name, answer);
            } else {
              policy_doc = policy_doc.replaceAll(policy.name, policy.html);
            }
          });

          var policy_identifiers = [
            "data_protection_for_org_cookie_policy_comp_info_org_name",
            "data_protection_for_org_cookie_policy_website_details_address_of_website",
            "data_protection_for_org_cookie_policy_cookie_categories_account_related_para",
            "data_protection_for_org_cookie_policy_cookie_categories_login_related_para",
            "data_protection_for_org_cookie_policy_cookie_categories_newsletter_related_para",
            "data_protection_for_org_cookie_policy_cookie_categories_sell_products_order_processing_para",
            "data_protection_for_org_cookie_policy_cookie_categories_survey_related_para",
            "data_protection_for_org_cookie_policy_cookie_categories_forms_related_para",
            "data_protection_for_org_cookie_policy_cookie_categories_website_preference_para",
            "data_protection_for_org_cookie_policy_third_party_ga_para",
            "data_protection_for_org_cookie_policy_third_party_gas_para",
            "data_protection_for_org_cookie_policy_third_party_adverts_para",
            "data_protection_for_org_cookie_policy_third_party_social_media_para1",
            "data_protection_for_org_cookie_policy_third_party_social_media_choice_facebook",
            "data_protection_for_org_cookie_policy_third_party_social_media_choice_twitter",
            "data_protection_for_org_cookie_policy_third_party_social_media_choice_googleplus",
            "data_protection_for_org_cookie_policy_third_party_social_media_choice_instagram",
            "data_protection_for_org_cookie_policy_third_party_social_media_choice_linkedin",
            "data_protection_for_org_cookie_policy_third_party_social_media_para2",
          ];

          policy_identifiers.forEach(function(identifier) {
            policy_doc = policy_doc.replaceAll(identifier, "");
          });

          self.uploadPolicy(policy_doc);

          return;
        })
        .catch(function(error) {
          if (error.response) {
            self.errors = error.response.data.errors;
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
    async uploadPolicy(policy_doc) {
      var self = this;
      let payload = {
        policy_doc: policy_doc,
        domain_id: this.domain_id,
        user_id: this.userId
      };

      axios
        .post(`${this.cmp_url}/api/v2/cookieXray-policy-doc`, payload,{
           
           headers:{
               'Authorization' : 'Bearer ' + localStorage.getItem('user_token')
           }
       })
        .then(function(response) {

          // toast.fire({
          //   type: "success",
          //   title: "Policy Generated Successfully",
          // });

          self.loadPolicy();
        })
        .catch(function(error) {
          if (error.response) {
            console.log(self.errors);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
      return;
    },
    async loadPolicy() {
      const self = this;
      var id = this.domain_id;
      var user_id = this.userId;

      await axios
        .get(`${this.cmp_url}/api/v2/cookieXray-policy/${id}/${user_id}`,{
           
           headers:{
               'Authorization' : 'Bearer ' + localStorage.getItem('user_token')
           }
       })
        .then(async (response) => {
          self.$store.commit("policy/setPolicy", response.data.policy);
          self.$store.commit("policy/setShowQuestionnaire", false);
          self.$store.commit("policy/setShowPolicyReport", true);
          // self.$store.commit('setCurrentDomainId', self.$store.getters.getCurrentDomainId);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.disabled-link {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

</style>