export const getAddDomainModel = (state) =>{
    return state.showAddDomainModel;
}

export const getDomains = (state) =>{
    return state.domains;
}

export const getUserToken = (state) =>{
    return state.userToken;
}

export const getUser = (state) =>{
    return state.user;
}

export const currentActiveModal = (state) =>{
    return state.currentActiveModal;
}

export const getCurrentDomainId =  (state) => {
    return state.current_domain_id;
}

export const getCurrentDomain = (state) => {
    return state.current_domain;
}

export const getFeatures = (state) => {
    return state.features;
}

export const getFeatureRejectVal = (state) => {
    return state.features.allow_reject;
}

export const getShowPricingPopup = (state) => {
    return state.show_pricing_popup;
}

export const getShowDialoguePreview = (state) => {
    return state.show_dialogue_preview;
}

export const getDialogueProperties =  (state) => {
    return state.dialogue_properties;
}

export const getManuallyBlockScripts =  (state) => {
    return state.ManuallyBlockScripts;
}

export const getStart =  (state) => {
    return state.scriptStart;
}

export const getScriptCategories =  (state) => {
    return state.scriptCategories;
}

export const getDiscoveredCookies =  (state) => {
    return state.discovered_cookies;
}

export const getSelfDeclaredCookies =  (state) => {
    return state.self_declared_cookies;
}

//dialogue

export const getPreviewBtn = (state) => {
    return state.preview_btn;
}

export const getCurrentBanner = (state) => {
    return state.current_banner;
}


export const getExertoBanner = (state) => {
    return state.exerto_banner;
}

export const getDefaultBanner = (state) => {
    return state.default_banner;
}
export const getUniversalBanner = (state) => {
    return state.universal_banner;
}

export const getActivetab = (state) => {
    return state.activetab;
}

export const getConsentlog_limit_reached = (state) => {
    return state.consentlog_limit_reached;
}

export const getForm = (state) => {
return state.form;
}

export const getGoogleConsentModeButton = (state) => {
    return state.apply_google_consent;
}

export const getFacebookConsentModeButton = (state) => {
    return state.apply_facebook_consent;
}

export const getDefaultLanguage = (state) => {
    return state.language;
}

export const getPreDefinedLanguages = (state) => {
    return state.preDefinedLanguages;
}

export const getDialogueLanguages = (state) => {
    return state.dialogue_languages;
}

export const getCurrentLanguage  = (state) => {
    return state.current_language;
}

export const getButtonIsDisabled  = (state) => {
    return state.button_is_disabled;
}

export const getWcagBanner = (state) => {
    return state.apply_wcag_banner;
}

export const getFeaturePoweredBy = (state) => {
    return state.features.powered_by;
}

//pricePlan

export const getUserType = (state) => {
    return state.user.type
}

export const getProduct = (state) => {
    return state.product;
}

export const getPopupFeatures = (state) => {
    return state.pricing_features;
}

export const getActivatedPlan = (state) => {
    return state.activated_plan;
}

export const getCurrency =  (state) => {
    return state.currency;
  }
  export const getTotalCookies =  (state) => {
    return state.totalCookies;
  }

  //Add New Categories
  export const getCategories = (state) => {
    return state.categories;
  }


  export const getScriptCustomizeCategories = (state) => {
    return state.script_customize_categories;
  }


  export const getMode = (state) => {
    return state.current_banner.modes;
  }


  export const getHide_font = (state) => {
    return state.hide_font;
  }
  export const getAssign_role = (state) => {
    return state.assign_role;
  }
  

