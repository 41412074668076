<template>
			<div class="sction-box">
			<h3 class="text-blue mb-3">Code To Paste In Custom Html</h3>
			<textarea 
				class="scriptcode" style="height:210px" id="banner-script" readonly ><script>(function() {
    var head = document.head || document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '{{script_url}}';
    script.setAttribute('data-key', {{ script && script.key }});
    script.setAttribute('data-name', 'CookieXray');
    head.insertBefore(script, head.firstChild);
})();</script>
			</textarea>
			<div class="notearea">
				<button class="btn btn-blue-bg mt-3" @click="copyScript('banner')">
					Copy Code
				</button>
			</div>
		</div>
	<div>
		<div class="mt-5">
			<h3 class="text-blue mb-4">
				Installation instructions for Google Tag Manager (GTM):
			</h3>

			<details open>
				<summary>Step 1</summary>
				<div>
					<p>Create Account on GTM</p>
					<img
						class="img-fluid"
						src="@/assets/images/google-tag-manager-Images/1.png"
						alt="login"
					/>
				</div>
			</details>
			<div id="accordion" role="tablist">
				<details>
					<summary>Step 2</summary>
					<div>
						<p>
							Click on
							<strong class="d-inline-block text-dark"
								>“Tags”</strong
							>
							to ribbons in right.
						</p>

						<img
							class="img-fluid"
							src="@/assets/images/google-tag-manager-Images/2.png"
							alt="login"
						/>
					</div>
				</details>

				<details>
					<summary>Step 3</summary>
					<div>
						<p>
							Click on
							<strong class="d-inline-block text-dark"
								>“New”</strong
							>
							button.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/google-tag-manager-Images/3.png"
							alt="login"
						/>
					</div>
				</details>

				<details>
					<summary>Step 4</summary>
					<div>
						<ul class="list-unstyled">
							<li>
								Name it
								<strong class="d-inline-block text-dark"
									>“Seers Cookie Consent”</strong
								>
							</li>
							<li>Then click on tag.</li>
						</ul>
						<img
							class="img-fluid"
							src="@/assets/images/google-tag-manager-Images/4.png"
							alt="login"
						/>
					</div>
				</details>

				<details>
					<summary>Step 5</summary>
					<div>
						<p>
							Click on
							<strong class="d-inline-block text-dark"
								>“Custom HTML”</strong
							>
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/google-tag-manager-Images/5.png"
							alt="login"
						/>
					</div>
				</details>

				<details>
					<summary>Step 6</summary>
					<div>
						<p>
							Paste Code provided by Seersco.com. Then Check
							<strong class="d-inline-block text-dark"
								>“Support document write”</strong
							>.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/google-tag-manager-Images/6.png"
							alt="login"
						/>
					</div>
				</details>

				<details>
					<summary>Step 7</summary>
					<div>
						<p>Choose a trigger to fire the GTM tag.</p>
						<img
							class="img-fluid"
							src="@/assets/images/google-tag-manager-Images/7.png"
							alt="login"
						/>
					</div>
				</details>

				<details>
					<summary>Step 8</summary>
					<div>
						<p>
							Click
							<strong class="d-inline-block text-dark"
								>“+”</strong
							>
							(Plus) symbol on top right corner.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/google-tag-manager-Images/8.png"
							alt="login"
						/>
					</div>
				</details>

				<details>
					<summary>Step 9</summary>
					<div>
						<p>
							Choose
							<strong class="d-inline-block text-dark"
								>“Domain ID”</strong
							>
							on top left corner. Then click a trigger type icon.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/google-tag-manager-Images/9.png"
							alt="login"
						/>
					</div>
				</details>

				<details>
					<summary>Step 10</summary>
					<div>
						<p>
							Click on
							<strong class="d-inline-block text-dark"
								>“DOM Ready”</strong
							>
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/google-tag-manager-Images/10.png"
							alt="login"
						/>
					</div>
				</details>

				<details>
					<summary>Step 11</summary>
					<div>
						<p>
							Click on
							<strong class="d-inline-block text-dark"
								>“Save”</strong
							>
							button on right top corner to save trigger
							configuration.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/google-tag-manager-Images/11.png"
							alt="login"
						/>
					</div>
				</details>

				<details>
					<summary>Step 12</summary>
					<div>
						<p>
							Click
							<strong class="d-inline-block text-dark"
								>“Save”</strong
							>
							button to save tag.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/google-tag-manager-Images/12.png"
							alt="login"
						/>
					</div>
				</details>

				<details>
					<summary>Step 13</summary>
					<div>
						<p>
							Click
							<strong class="d-inline-block text-dark"
								>“Submit”</strong
							>
							button to submit GTM tag.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/google-tag-manager-Images/13.png"
							alt="login"
						/>
					</div>
				</details>

				<details class="mb-5">
					<summary>Step 14</summary>
					<div>
						<p>
							Add version Name and description of tag. Then click
							<strong class="d-inline-block text-dark"
								>“Publish”</strong
							>
							button. Your GTM Tag will be published.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/google-tag-manager-Images/14.png"
							alt="login"
						/>
					</div>
				</details>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
	name: "Gtm",
  	computed:{
	script: function() {
			return this.$store.getters['getCode/getScript'];
		},
	cmp_url(){
			return process.env.VUE_APP_BANNER_URL
		},
	script_url: function() {
			return  this.cmp_url + '/banners/' + this.$store.state.domain.userId +'/'+ this.$store.getters['domain/getCurrentDomainId'] + "/cb.js";
		},
},  mounted() {
		// console.log('scriptscript', this.cmp_url)
	},
	methods: {
		copyScript(script){
			if (script === "banner") {
				$("#banner-script").select();
			}

			if (script === "step1") {
				$("#googleStep1").select();
			}

			if (script === "step2") {
				$("#googleStep2").select();
			}

			if (script === "step3") {
				$("#googleStep3").select();
			}

			if (script === "step4") {
				$("#googleStep4").select();
			}

			if (script === "DoNotSell") {
				$("#DoNotSell").select();
			}
			Swal.fire({
				icon: "success",
				title: 'Code Copied',
				text: "Code copied successfully",
				timer: 4000,
				confirmButtonText: "Ok"
			});
			document.execCommand("copy");
		}
	},
};

</script>

<style scoped>
.sction-box {
border: 0;
	margin-bottom: 30px;
	width: 100%;
	display: inline-block;
}
.scriptcode {
	background: #fff !important;
	border-radius: 4px !important;
	-webkit-border-radius: 4px !important;
	padding-inline-start: 10px;	
	padding-block-start: 0%;
	padding: auto;
	padding-top:10px ;
	font-size: 14px;
	color: #707070;
	-webkit-box-shadow: 0px 0px 6px rgb(0 0 0 / 15%) !important;
	-moz-box-shadow: 0px 0px 6px rgb(0 0 0 / 15%) !important;
	box-shadow: 0px 0px 6px rgb(0 0 0 / 15%) !important;
}

textarea:focus {
	outline: none !important;
}
::-webkit-scrollbar, ::-moz-scrollbar, ::-ms-scrollbar, ::-o-scrollbar {
    display: none;
}
p span{
	font-size: 16px !important;
        font-weight: 500;    
}
 p{
		font-size: 15px !important;
        font-weight: 400;
}
</style>