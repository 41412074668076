<template>
	<div>
		<div class="sction-box mb-0">
			<h3 class="text-blue mb-3">How to install Facebook Pixel Consent Mode</h3>
		</div>
		<div class="sction-box">
			<div class="mb-2">		
                <p class="mb-0">You need a Facebook Pixel code to activate Facebook Consent Mode on your website</p>
				<p> 
				<span class="text-blue">Step 1:</span> Find the Facebook pixel code in the header on the website or get it from your Facebook account:
			</p></div>
			<p class="mb-2 text-blue d-block font-weight-bold">The code looks like this:</p>
			<textarea
				class="scriptcode mb-5"
				id="facebookStep1"
				readonly
				style="height: 325px;"
			>
				<!-- Facebook Pixel Code --> 
				<script> 
				!function(f,b,e,v,n,t,s) 
				{if(f.fbq)return;n=f.fbq=function(){n.callMethod? 
				n.callMethod.apply(n,arguments):n.queue.push(arguments)}; 
				if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; 
				n.queue=[];t=b.createElement(e);t.async=!0; 
				t.src=v;s=b.getElementsByTagName(e)[0]; 
				s.parentNode.insertBefore(t,s)}(window, document,'script', 
				'https://connect.facebook.net/en_US/fbevents.js'); 
				fbq('init', 'xxxxxxxxxxxxxxxxxxx'); 
				fbq('track', 'PageView'); 
				<!-- End Facebook Pixel Code --> 
				</script> 
			</textarea
			>
			<button class="btn btn-blue-bg mb-5 float-right" @click="copyScript('step1')">Copy Script</button>

			<p>
				<span class="text-blue">Step 2:</span>
				Insert the facebook pixel code (below in bold) as shown below:
			</p>

				<textarea
				class="scriptcode mb-3"
				id="facebookStep2"
				style="height: 60px"
				readonly>
					fbq('consent', 'revoke');
				</textarea>
		

				<button class="btn btn-blue-bg mb-5 float-right" @click="copyScript('step2')">Copy Script</button>
				<br>
				<br>
				<div class="mb-2 mt-5">
						
					<p><span class="text-blue">Step 3:</span>	Find the Facebook pixel code in the header on the website or get it from your Facebook account:</p>
					<p class="mb-3">So the resulting code should look like this:

				Seers CMP Code looks like this:</p>
				</div>
			


				<textarea readonly="readonly" class="scriptcode mb-3" id="facebookStep3" style="height:370px;">
					<!-- Facebook Pixel Code --> 
					<script> 
					!function(f,b,e,v,n,t,s) 
					{if(f.fbq)return;n=f.fbq=function(){n.callMethod? 
					n.callMethod.apply(n,arguments):n.queue.push(arguments)}; 
					if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; 
					n.queue=[];t=b.createElement(e);t.async=!0; 
					t.src=v;s=b.getElementsByTagName(e)[0]; 
					s.parentNode.insertBefore(t,s)}(window, document,'script', 
					'https://connect.facebook.net/en_US/fbevents.js'); 
					fbq('consent','revoke'); 
					fbq('init', 'xxxxxxxxxxxxxxxxxxx'); 
					fbq('track', 'PageView'); 
					<!-- End Facebook Pixel Code --> 
					</script> 
				</textarea>
				<button class="btn btn-blue-bg mb-5 float-right" @click="copyScript('step3')">Copy Script</button>

			<p class="mt-5">
				Your Facebook Consent mode will be activated.
			</p>
			<p>			
				<span class="text-blue">Note:</span>
				To activate Facebook Consent Mode, you must have Seers’ CMP code installed on your website.
			</p>
            <p class="mb-3 text-blue d-block font-weight-bold">Seers CMP Code looks like this:</p>
				<textarea readonly="readonly" class="scriptcode mb-3" id="facebookStep4" style="height:80px;">
					<script data-key="{{ script && script.key }}" data-name="CookieXray" src="{{script_url}}" type="text/javascript"></script>
				</textarea>
            <div class="w-100">
                    <button class="btn btn-blue-bg mb-5 float-right" @click="copyScript('step4')">Copy Script</button>
            </div>

                <ol class="mb-3 pl-3 mt-5">
					<li class="mb-2">Remember to enable the toggle to <strong>"OK"</strong> activate this feature in the <strong>“settings”</strong> panel of the Seers CMP.  </li>
					<li class="mb-2"> Facebook Consent Mode is an optional feature and should be enabled in settings too. Go to your <strong>Dashboard &gt; Preferences Tab &gt; Enable Facebook Consent Mode.</strong></li>
					<li class="mb-2">Also, you must update the Facebook Pixel code, as described in Step 2.</li>
				</ol>
               <p>To read more about Facebook consent mode <a href="https://seerssupport.zendesk.com/">click here</a></p>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
	name: "Fcm",
	computed:{
		cmp_url(){
			return process.env.VUE_APP_BANNER_URL
		},
		script: function() {
			return this.$store.getters['getCode/getScript'];
		},
		script_url: function() {
			// return process.env.MIX_CB_HOST +'/'+ this.$store.getters.getUser.id +'/'+ this.$store.getters.getCurrentDomainId + "/cb.js";
			return this.cmp_url + '/banners/' + this.$store.state.domain.userId +'/'+ this.$store.getters['domain/getCurrentDomainId'] + "/cb.js";
		},
	},
	methods: {
		copyScript(script){
			if (script === "step1") {
				$("#facebookStep1").select();
			}

			if (script === "step2") {
				$("#facebookStep2").select();
			}

			if (script === "step3") {
				$("#facebookStep3").select();
			}

			if (script === "step4") {
				$("#facebookStep4").select();
			}
			
			Swal.fire({
				icon: "success",
				title: 'Code Copied',
				text: "Code copied successfully",
				timer: 4000,
				confirmButtonText: "Ok"
			});
			document.execCommand("copy");
		}
	},
};
</script>

<style scoped>
.sction-box {
border: 0;
	margin-bottom: 30px;
	width: 100%;
	display: inline-block;
}
.scriptcode {
	border: 0;
	width: 100%;
	background: #fff !important;
	border-radius: 4px !important;
	-webkit-border-radius: 4px !important;
	padding: 20px;
	font-size: 14px;
	color: #707070;
	-webkit-box-shadow: 0px 0px 6px rgb(0 0 0 / 15%) !important;
	-moz-box-shadow: 0px 0px 6px rgb(0 0 0 / 15%) !important;
	box-shadow: 0px 0px 6px rgb(0 0 0 / 15%) !important;
}
textarea:focus {
	outline: none !important;
}
::-webkit-scrollbar {
    display: none;
}
p span{
	font-size: 16px !important;
        font-weight: 500;    
}
 p{
	font-size: 15px !important;
        font-weight: 400;
}
</style>
