<template>
  <div class="row mb-4">
    <div class="col-10 col-sm-10 mx-auto block-style">
      <table class="datatable-responsive w-100">
        <tbody>
          <tr class="row">
            <td class="py-2 px-3 col-12 col-sm-6">
              <div class="form-group row">
                <label class="col-12 control-label mb-2">Name: *</label>
                <div class="col-12">
                  <input
                    type="text"
                    v-model="cookie.name"
                    name="cookie_policy_url"
                    placeholder=""
                    class="form-control"
                  />
                </div>
              </div>
            </td>
            <td class="py-2 px-3 col-12 col-sm-6">
              <div class="form-group row">
    <label class="col-12 mb-2">Category:</label>
    <div class="col-12">
        <select
            name="cookie_consent"
            v-model="cookie.category_id"
            class="form-control bg-white"
        >
            <option value="3">Necessary</option>
            <option value="4">Preferences</option>
            <option value="1">Statistics</option>
            <option value="2">Marketing</option>
            <option v-for="customCategory in custom_category" :value="customCategory.id">{{ customCategory.customize_title }}</option>
            <option value="5">Unclassified</option>
        </select>
    </div>
</div>

            </td>
          </tr>
          <tr class="row align-items-center">
            <td class="py-2 px-3 col-12 col-sm-6">
              <div class="form-group row">
                <label class="col-12 control-label mb-2"
                  >First found URL: *</label
                >
                <div class="col-12">
                  <input
                    type="text"
                    v-model="cookie.first_found_url"
                    name="cookie_policy_url"
                    placeholder=""
                    class="form-control"
                  />
                </div>
              </div>
            </td>
            <td class="py-2 px-3 col-12 col-sm-6">
              <div class="form-group row mb-0">
                <label
                  class="col-12 mb-2 control-label d-flex align-items-center"
                  ><input
                    type="checkbox"
                    v-model="cookie.http_only"
                    name="is_cookie_banner"
                    class="mr-2"
                    style="width: 16px; height: 16px"
                  />
                  <span class="label-text control-label">
                    HTTP only</span
                  ></label
                >
              </div>
            </td>
          </tr>
          <tr class="row align-items-center">
            <td class="py-2 px-3 col-12 col-sm-6">
              <div class="form-group row">
                <label class="col-12 control-label mb-2">Provider: *</label>
                <div class="col-12">
                  <input
                    type="text"
                    v-model="cookie.provider"
                    name="cookie_policy_url"
                    placeholder=""
                    class="form-control"
                  />
                </div>
              </div>
            </td>
            <td class="py-2 px-3 col-12 col-sm-6">
              <div class="row form-group mb-0">
                <label class="col-12 control-label d-flex align-items-center"
                  ><input
                    type="checkbox"
                    v-model="cookie.secure"
                    name="is_cookie_banner"
                    class="mr-2"
                    style="width: 16px; height: 16px"
                  />
                  <span class="label-text control-label"> Secure</span></label
                >
              </div>
            </td>
          </tr>
          <tr class="row">
            <td class="py-2 px-3 col-12 col-sm-6">
              <div class="form-group row">
                <label class="col-12 control-label mb-2">Domain path:</label>
                <div class="col-12">
                  <input
                    type="text"
                    v-model="cookie.domain_path"
                    name="cookie_policy_url"
                    placeholder=""
                    class="form-control"
                  />
                </div>
              </div>
            </td>
            <td class="py-2 px-3 col-12 col-sm-6">
              <div class="form-group row">
                <label class="col-12 control-label mb-2">Expiry:</label>
                <div class="col-12">
                  <input
                    type="text"
                    v-model="cookie.expiry"
                    name="cookie_policy_url"
                    placeholder="0"
                    class="form-control"
                  />
                  <span
                    style="
                      position: absolute;
                      right: 25px;
                      top: 7px;
                      float: left;
                      font-size: 14px;
                    "
                    >days</span
                  >
                </div>
              </div>
            </td>
          </tr>
          <tr class="row">
            <td class="py-2 px-3 col-12 col-sm-6">
              <div class="form-group row">
                <label class="col-12 control-label mb-2">Example value:</label>
                <div class="col-12">
                  <input
                    type="text"
                    v-model="cookie.example_value"
                    name="cookie_policy_url"
                    placeholder=""
                    class="form-control"
                  />
                </div>
              </div>
            </td>
            <td class="py-2 px-3 col-12 col-sm-6">
              <div class="form-group row">
                <label class="col-12 control-label mb-2">Type:</label>
                <div class="col-12">
                  <select
                    name="cookie_consent"
                    v-model="cookie.type"
                    class="form-control bg-white"
                  >
                    <option value="HTTP Cookie">HTTP Cookie</option>
                    <option value="HTML Local Storage">
                      HTML Local Storage
                    </option>
                    <option value="Flash Local Shared Object">
                      Flash Local Shared Object
                    </option>
                    <option value="Silverlight Isolated Storage">
                      Silverlight Isolated Storage
                    </option>
                    <option value="Pixel Tracker">Pixel Tracker</option>
                    <option value="IndexedDB">IndexedDB</option>
                    <option value="Ultra Sound Beacon">
                      Ultra Sound Beacon
                    </option>
                  </select>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="w-100 cookie_ex_tab pb-4 mb-4">
        <p class="mt-3">Cookie purpose description:</p>
        <div class="form-group form-group-md">
          <label
            class="control-label text-left"
            style="
              background: rgb(108, 192, 74);
              padding: 7px 10px;
              margin-bottom: 0px;
              color: #fff !important;
              border-bottom: none;
              border-radius: 4px 4px 0px 0px !important;
            "
            >EN-English
          </label>
          <textarea
            name="cookies_body"
            v-model="cookie.purpose_desc"
            maxlength="2000"
            placeholder=""
            class="form-control"
            style="
              height: 80px;
              border-radius: 0px;
              padding: 10px;
              border: 1px solid rgb(199, 199, 199);
              background: rgb(255, 255, 255) !important;
            "
          ></textarea>
        </div>
        <button v-on:click="createCookie()" :class="shouldBeDisabled ? 'disabled' : ''" v-if="loading == false" class="btn btn-blue-bg float-right text-white">
          <i class="fa fa-plus-circle"></i>&nbsp; Add Cookie
        </button>
        <a class="btn btn-blue-bg float-right text-white"  v-else ><i  class="fa fa-spinner fa-pulse fa-fw"></i> processing...</a>
      </div>
    </div>
  </div>
  <div
    class="row"
    v-for="(cookie, index) in self_declared_cookies"
    :key="index"
  >
    <div class="col-10 col-sm-10 mx-auto block-style">
      <div class="row align-items-center mb-3">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <h4 class="font-weight-bold">
            {{ cookie.name }}
          </h4>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="row m-0 align-items-center">
            <label
              class="col-sm-12 col-12 col-md-6 col-lg-4 col-xl-4 control-label text-left mb-0 font-weight-bold"
              >Category:</label
            >
            <div class="col-sm-12 col-12 col-md-6 col-lg-8 col-xl-8">
              <select
                name="cookie_consent"
                class="form-control bg-white"
                v-on:change="updateCategory(index, $event, false)"
              >
                <option
                  :selected="cookie.cb_cat_id == 3 ? true : false"
                  value="3"
                >
                  Necessary
                </option>
                <option
                  :selected="cookie.cb_cat_id == 4 ? true : false"
                  value="4"
                >
                  Preferences
                </option>
                <option
                  :selected="cookie.cb_cat_id == 1 ? true : false"
                  value="1"
                >
                  Statistics
                </option>
                <option
                  :selected="cookie.cb_cat_id == 2 ? true : false"
                  value="2"
                >
                  Marketing
                </option>
                <option v-for="customCategory in custom_category" :value="customCategory.id" :selected="cookie.cb_cat_id == customCategory.id ? true : false">
            {{ customCategory.customize_title }}
        </option>
                <option
                  :selected="cookie.cb_cat_id == 5 ? true : false"
                  value="5"
                >
                  Unclassified
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="datatable-responsive overflow-auto">
            <table class="datatable-responsive w-100">
              <tbody>
                <tr class="border-bottom">
                  <td
                    class="border-top-0 py-3"
                    style="width: 71%; white-space: nowrap"
                  >
                    First found URL: {{ cookie.first_found_url }}
                  </td>
                  <td class="border-top-0 py-3">
                    <div class="form-group row mb-0">
                      <label
                        class="col-12 mb-2 control-label d-flex align-items-center"
                        ><input
                          type="checkbox"
                          name="is_cookie_banner"
                          class="mr-2"
                          :checked="cookie.httponly"
                          v-on:change="updateHttpOnly(index, $event, false)"
                          style="width: 16px; height: 16px"
                        />
                        <span class="label-text control-label">
                          HTTP only</span
                        ></label
                      >
                    </div>
                  </td>
                </tr>
                <tr class="border-bottom">
                  <td class="py-3" style="white-space: nowrap">
                    Provider: {{ cookie.provider }}
                  </td>
                  <td class="py-3">
                    <div class="row form-group mb-0">
                      <label
                        class="col-12 control-label d-flex align-items-center"
                        ><input
                          type="checkbox"
                          :checked="cookie.secure"
                          v-on:change="
                            updateSecureProperty(index, $event, false)
                          "
                          name="is_cookie_banner"
                          class="mr-2"
                          style="width: 16px; height: 16px"
                        />
                        <span class="label-text control-label">
                          Secure</span
                        ></label
                      >
                    </div>
                  </td>
                </tr>
                <tr class="border-bottom">
                  <td class="py-3" style="white-space: nowrap">
                    Domain path: {{ cookie.domain_path }}
                  </td>
                  <td class="py-3" style="white-space: nowrap">
                    Expiry: {{ cookie.expiry }}
                  </td>
                </tr>
                <tr class="border-bottom">
                  <td class="py-3" style="white-space: nowrap">
                    Country: {{ cookie.country }}
                  </td>
                  <td class="py-3" style="white-space: nowrap">
                    Type: {{ cookie.type }}
                  </td>
                </tr>
                <tr>
                  <td class="p-3"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 cookie_ex_tab">
          <p style ="word-wrap: break-word;">Example value: {{ cookie.example_value }}</p>
          <p>Cookie purpose description:</p>
          <div class="form-group form-group-md">
            <label
              class="control-label text-left"
              style="
                background: rgb(108, 192, 74);
                padding: 7px 10px;
                margin-bottom: 0px;
                color: rgb(255, 255, 255);
                border-bottom: none;
                border-radius: 4px 4px 0px 0px !important;
              "
              >EN-English
            </label>
            <span class="update_cookie_btn"
              >
              <button
                class="control-label float-right"
                v-on:click="updateCookie(cookie)" v-if="updateLoading == false" 
                :class="shouldBeDisabled ? 'disabled' : ''"
                style="
                  background: rgb(59, 110, 248);
                  padding: 7px 10px;
                  margin-bottom: 0px;
                  color: rgb(255, 255, 255) !important;
                  border: none;
                  border-radius: 4px 4px 0px 0px !important;
                  margin-top: 4px;
                "
              >
                <i class="fa fa-save mr-2"></i> Update Cookie
              </button>
              <a class="control-label float-right mr-5" style="
                  background: rgb(59, 110, 248);
                  padding: 7px 10px;
                  margin-bottom: 0px;
                  color: rgb(255, 255, 255) !important;
                  border: none;
                  border-radius: 4px 4px 0px 0px !important;
                  margin-top: 4px;
                " v-else ><i  class="fa fa-spinner fa-pulse fa-fw"></i> processing...</a>
              </span
            >
            <span class="update_cookie_btn"
              >
              <button
                class="control-label float-right mr-1"
                v-on:click="removeCookie(cookie, false)" v-if="removeLoading == false" 
                style="
                  background: rgb(59, 110, 248);
                  padding: 7px 10px;
                  margin-bottom: 0px;
                  color: rgb(255, 255, 255) !important;
                  border: none;
                  border-radius: 4px 4px 0px 0px !important;
                  margin-top: 4px;
                "
              >
                <i class="fa fa-minus-circle mr-2"></i>Delete Cookie
              </button>
              <a class="control-label float-right mr-5" style="
                  background: rgb(59, 110, 248);
                  padding: 7px 10px;
                  margin-bottom: 0px;
                  color: rgb(255, 255, 255) !important;
                  border: none;
                  border-radius: 4px 4px 0px 0px !important;
                  margin-top: 4px;
                " v-else ><i  class="fa fa-spinner fa-pulse fa-fw"></i> processing....</a>
              </span
            >
            <textarea
              v-on:change="updatePurpose(index, $event, false)"
              v-html="cookie.purpose_desc"
              name="cookies_body"
              maxlength="2000"
              placeholder=""
              class="form-control"
              style="
                height: 40px;
                border-radius: 0px;
                padding: 10px;
                border: 1px solid rgb(199, 199, 199);
                background: rgb(255, 255, 255) !important;
              "
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { emitter } from "@/eventbus";
import { mapState } from 'vuex';
export default {
  name: "DeclaredCookies",
  data() {
    return {
      loading: false,
      updateLoading:false,
      removeLoading:false,
      discovered_cookie: [],
      self_declared_cookie: [],
      cookie: {
        name: "",
        category_id: "5",
        first_found_url: "",
        provider: "",
        domain_path: "",
        example_value: "",
        purpose_desc: "",
        http_only: false,
        secure: false,
        expiry: "",
        type: "HTTP Cookie",
      },
      default_cookie: {
        name: "",
        category_id: "5",
        first_found_url: "",
        provider: "",
        domain_path: "",
        example_value: "",
        purpose_desc: "",
        http_only: false,
        secure: false,
        expiry: "",
        type: "HTTP Cookie",
      },
    };
  },
  // watch:{
	// 	domain_id: {
	// 		// deep: true,
  //     // immediate:true,
	// 		handler(){
	// 			this.loadCookies();
	// 		}
	// 	}
	// },
  computed: {
    ...mapState('domain', ['trackingLoader' , 'user' , 'assign_role']
		),
    userId() {
      return this.$store.state.domain.userId;
    },
    uservar() {
      return this.$store.state.domain.uservar;
    },
    custom_category() {
      return this.$store.state.domain.categories;
    },
    domain_id: function () {
      return this.$store.getters["domain/getCurrentDomainId"];
    },
    discovered_cookies: function () {
      return this.$store.getters["domain/getDiscoveredCookies"];
    },
    self_declared_cookies: function () {
      return this.$store.getters["domain/getSelfDeclaredCookies"];
    },
    script: function () {
      return this.$store.getters["getCode/getScript"];
    },
    shouldBeDisabled() {
		return !this.user.isSuperAdmin && this.user.superAdminId !== null && this.assign_role === 'reader';
		},
  },
  methods: {
    createCookie() {
      this.loading = true;
      let key = null;
      if (this.script) {
        key = this.script.key;
      }
      let payload = {
        domain_id: this.domain_id,
        cookie: this.cookie,
        key: key,
      };
      if (
        this.cookie.name !== "" &&
        this.cookie.first_found_url !== "" &&
        this.cookie.provider !== ""
      ) {
        this.$store.dispatch("domain/createCookie", payload)
        .then(() => {
          this.loading = false;
          emitter.emit('saveDialogueProperties');
        })
        .catch(()=> {
          this.loading = false;
        })
      } else {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Fill the missing or required fields",
          timer: 4000,
        });
        this.loading = false;
      }
    },
    updateCookie(cookie) {
      this.updateLoading = true;
      let key = null;
      if (this.script) {
        key = this.script.key;
      }
      let payload = {
        domain_id: this.domain_id,
        cookie: cookie,
        cookie_id: cookie.id,
      };
      this.$store.dispatch("domain/updateCookie", payload)
      .then(() => {
        this.updateLoading = false;
        emitter.emit('saveDialogueProperties');
      })
    },
    updateCategory(index, event, hasDiscoveredCookie) {
      if (!hasDiscoveredCookie) {
        this.self_declared_cookies[index].cb_cat_id = event.target.value;
      }
      return;
    },
    updateHttpOnly(index, event, hasDiscoveredCookie) {
      if (!hasDiscoveredCookie) {
        this.self_declared_cookies[index].httponly = event.target.checked;
      }
      return;
    },
    updateSecureProperty(index, event, hasDiscoveredCookie) {
      if (!hasDiscoveredCookie) {
        this.self_declared_cookies[index].secure = event.target.checked;
      }
      return;
    },
    updatePurpose(index, event, hasDiscoveredCookie) {
      if (!hasDiscoveredCookie) {
        this.self_declared_cookies[index].purpose_desc = event.target.value;
      }
      return;
    },
	removeCookie(cookie) {
        const self = this;
        Swal.fire({
          title: "Are you sure!",
          text: "You won't revert it back",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#41a2db",
          cancelButtonColor: "#666666",
          confirmButtonText: "Yes! Delete",
        }).then(async (result) => {
          this.removeLoading = true;
          if (result.value) {
            let payload = {
              id: cookie.id,
              domain_id: self.domain_id,
            };
            this.$store.dispatch('domain/removeCookie',payload)
            .then(() => {
              this.removeLoading = false;
              emitter.emit('saveDialogueProperties');
            })
          }
          else{
            this.removeLoading = false;
          }
        });
      },
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

</style>
