<template>
	<div class="header-dashboard-logined">
		<a :href="dashboardLink" class="logo-hol">
    <img src="../../assets/images/logoo.svg" alt="logo-icon" />
  </a>
		<div class="header-right">
			<div class="logined-dropdown" @click="HelpFeedback = !HelpFeedback">
				<img
					title="Support"
					src="../../assets/images/help-normal.svg"
				/>
				<div class="logined-dropdown-content" v-if="HelpFeedback">
					<a :href="`${this.front_url}/contact`"  title="Help">Help</a>
					<a href="#" id="feedback-modal" @click="feedback(true)" title="feedback">feedback</a>

				</div>
			</div>
			<div class="logined-dropdown ml-3" @click="ProductsDropDown = !ProductsDropDown">
				<img
					title="Products"
					src="../../assets/images/menu-normal.svg"
				/>
				<div class="logined-dropdown-content icon-dropdown-content" v-if="ProductsDropDown">
					<div class="flex-row-container">
						<a class="flex-row-item" href="javacript::void(0)">
							<svg
								id="Cookie_Consent"
								data-name="Cookie Consent"
								xmlns="http://www.w3.org/2000/svg"
								width="30.143"
								height="30.143"
								viewBox="0 0 30.143 30.143"
							>
								<g
									id="_003---Cookies"
									data-name="003---Cookies"
									transform="translate(0 0)"
								>
									<path
										id="Shape"
										d="M26.5,5h3.014a.5.5,0,0,0,0-1H26.5a.5.5,0,0,0,0,1Z"
										transform="translate(-12.938 -1.991)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-2"
										data-name="Shape"
										d="M43.5,5h5.526a.5.5,0,1,0,0-1H43.5a.5.5,0,0,0,0,1Z"
										transform="translate(-21.398 -1.991)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-3"
										data-name="Shape"
										d="M2.512,23.109h1.03c-.013.166-.026.333-.026.5a6.527,6.527,0,0,0,11.555,4.166,6.527,6.527,0,0,0,11.545-3.8.5.5,0,0,0-.714-.482,1.256,1.256,0,0,1-1.787-1.137,1.177,1.177,0,0,1,.123-.534.5.5,0,0,0-.431-.727,1.252,1.252,0,0,1-.569-2.338.5.5,0,0,0-.007-.877,6.531,6.531,0,0,0-8.16,1.565A6.52,6.52,0,0,0,3.7,22.1H2.512A1.507,1.507,0,0,1,1,20.6V5.024H29.138V20.6A1.507,1.507,0,0,1,27.631,22.1a.5.5,0,1,0,0,1A2.515,2.515,0,0,0,30.143,20.6V2.512A2.515,2.515,0,0,0,27.631,0H2.512A2.515,2.515,0,0,0,0,2.512V20.6a2.515,2.515,0,0,0,2.512,2.512ZM20.1,18.086a5.555,5.555,0,0,1,1.988.368,2.258,2.258,0,0,0,1.055,3.533,2.261,2.261,0,0,0,2.232,2.63,1.562,1.562,0,0,0,.161-.006A5.526,5.526,0,1,1,20.1,18.086Zm-10.048,0a5.5,5.5,0,0,1,4.421,2.228,6.47,6.47,0,0,0,0,6.6,5.523,5.523,0,1,1-4.421-8.826ZM2.512,1H27.631a1.507,1.507,0,0,1,1.507,1.507V4.019H1V2.512A1.507,1.507,0,0,1,2.512,1Z"
										transform="translate(0 0)"
										fill="#c1c1c1"
									/>
									<ellipse
										id="Oval"
										cy="0.5"
										ry="0.5"
										transform="translate(2.372 1.962)"
										fill="#c1c1c1"
									/>
									<circle
										id="Oval-2"
										data-name="Oval"
										cx="0.5"
										cy="0.5"
										r="0.5"
										transform="translate(4.372 1.962)"
										fill="#c1c1c1"
									/>
									<ellipse
										id="Oval-3"
										data-name="Oval"
										cx="1"
										cy="0.5"
										rx="1"
										ry="0.5"
										transform="translate(5.372 1.962)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-4"
										data-name="Shape"
										d="M6,25.01a2.01,2.01,0,0,0,2.01,2.01h7.033a2.01,2.01,0,1,0,0-4.019H8.01A2.01,2.01,0,0,0,6,25.01Zm10.048,0a1,1,0,0,1-1,1H8.01a1,1,0,1,1,0-2.01h7.033A1,1,0,0,1,16.048,25.01Z"
										transform="translate(-2.986 -11.445)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-5"
										data-name="Shape"
										d="M34.01,27.019h7.033a2.01,2.01,0,1,0,0-4.019H34.01a2.01,2.01,0,0,0,0,4.019Zm0-3.014h7.033a1,1,0,1,1,0,2.01H34.01a1,1,0,0,1,0-2.01Z"
										transform="translate(-15.924 -11.445)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-6"
										data-name="Shape"
										d="M12.512,14a.5.5,0,1,0,0-1H10.5a.5.5,0,0,0,0,1Z"
										transform="translate(-4.976 -6.469)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-7"
										data-name="Shape"
										d="M46.512,14a.5.5,0,1,0,0-1H44.5a.5.5,0,1,0,0,1Z"
										transform="translate(-21.895 -6.469)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-8"
										data-name="Shape"
										d="M29.555,14a.5.5,0,1,0,0-1H18.5a.5.5,0,1,0,0,1Z"
										transform="translate(-8.957 -6.469)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-9"
										data-name="Shape"
										d="M30.043,18.5a.5.5,0,0,0-.5-.5H21.5a.5.5,0,1,0,0,1H29.54A.5.5,0,0,0,30.043,18.5Z"
										transform="translate(-10.45 -8.957)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-10"
										data-name="Shape"
										d="M17.507,42.014A1.507,1.507,0,1,0,16,40.507,1.507,1.507,0,0,0,17.507,42.014Zm0-2.01a.5.5,0,1,1-.5.5A.5.5,0,0,1,17.507,40Z"
										transform="translate(-7.962 -19.407)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-11"
										data-name="Shape"
										d="M13.507,49.014A1.507,1.507,0,1,0,12,47.507,1.507,1.507,0,0,0,13.507,49.014Zm0-2.01a.5.5,0,1,1-.5.5A.5.5,0,0,1,13.507,47Z"
										transform="translate(-5.971 -22.891)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-12"
										data-name="Shape"
										d="M37.507,42.014A1.507,1.507,0,1,0,36,40.507,1.507,1.507,0,0,0,37.507,42.014Zm0-2.01a.5.5,0,1,1-.5.5A.5.5,0,0,1,37.507,40Z"
										transform="translate(-17.914 -19.407)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-13"
										data-name="Shape"
										d="M33.507,49.014A1.507,1.507,0,1,0,32,47.507,1.507,1.507,0,0,0,33.507,49.014Zm0-2.01a.5.5,0,1,1-.5.5A.5.5,0,0,1,33.507,47Z"
										transform="translate(-15.924 -22.891)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-14"
										data-name="Shape"
										d="M20,50.507A1.507,1.507,0,1,0,21.507,49,1.507,1.507,0,0,0,20,50.507Zm1.507-.5a.5.5,0,1,1-.5.5A.5.5,0,0,1,21.507,50Z"
										transform="translate(-9.952 -24.383)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-15"
										data-name="Shape"
										d="M40,50.507A1.507,1.507,0,1,0,41.507,49,1.507,1.507,0,0,0,40,50.507Zm1.507-.5a.5.5,0,1,1-.5.5A.5.5,0,0,1,41.507,50Z"
										transform="translate(-19.905 -24.383)"
										fill="#c1c1c1"
									/>
								</g>
							</svg>
							Cookie Consent
						</a>
						<a
							class="flex-row-item"
							:href="`${this.front_url}/business/training-of-gdpr`"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								id="GDPR_eTraining"
								data-name="GDPR eTraining"
								width="33.319"
								height="30.143"
								viewBox="0 0 33.319 30.143"
							>
								<g
									id="Group_4905"
									data-name="Group 4905"
									transform="translate(0 0)"
								>
									<g
										id="Group_4904"
										data-name="Group 4904"
										transform="translate(0 0)"
									>
										<path
											id="Path_6506"
											data-name="Path 6506"
											d="M27.051,24.4h-25A2.053,2.053,0,0,0,0,26.451V47.284a2.053,2.053,0,0,0,2.051,2.051H18.425a.488.488,0,1,0,0-.976H16.6V45.2a2.045,2.045,0,0,0-1.262-1.893L12.775,42.24a.552.552,0,0,1-.341-.511v-.762a3.625,3.625,0,0,0,1.042-2.537V36.347a3.387,3.387,0,0,0-3.613-3.092A3.387,3.387,0,0,0,6.25,36.347V38.43a3.626,3.626,0,0,0,1.042,2.537v.762a.552.552,0,0,1-.341.511L4.387,43.308A2.045,2.045,0,0,0,3.125,45.2v3.158H2.051A1.076,1.076,0,0,1,.976,47.284v-16.7H28.125V38.69a.488.488,0,1,0,.976,0V26.451A2.053,2.053,0,0,0,27.051,24.4ZM4.1,45.2a1.072,1.072,0,0,1,.661-.992l2.564-1.068a1.526,1.526,0,0,0,.941-1.412v-.106a.488.488,0,0,0,.276-.909A2.647,2.647,0,0,1,7.226,38.43V36.347a2.423,2.423,0,0,1,2.637-2.116A2.423,2.423,0,0,1,12.5,36.347V38.43a2.648,2.648,0,0,1-1.319,2.284.488.488,0,0,0,.245.911l.032,0v.106a1.526,1.526,0,0,0,.941,1.412l2.564,1.068a1.072,1.072,0,0,1,.661.992v3.158H4.1ZM28.125,29.608H.976V26.451a1.076,1.076,0,0,1,1.074-1.074h25a1.076,1.076,0,0,1,1.074,1.074Z"
											transform="translate(0 -24.4)"
											fill="#c1c1c1"
										/>
									</g>
								</g>
								<g
									id="Group_4907"
									data-name="Group 4907"
									transform="translate(2.086 2.604)"
								>
									<g
										id="Group_4906"
										data-name="Group 4906"
										transform="translate(0 0)"
									>
										<path
											id="Path_6507"
											data-name="Path 6507"
											d="M32.545,64.418h-.005a.491.491,0,1,0,.005,0Z"
											transform="translate(-32.054 -64.418)"
											fill="#c1c1c1"
										/>
									</g>
								</g>
								<g
									id="Group_4909"
									data-name="Group 4909"
									transform="translate(5.206 2.604)"
								>
									<g
										id="Group_4908"
										data-name="Group 4908"
										transform="translate(0 0)"
									>
										<path
											id="Path_6508"
											data-name="Path 6508"
											d="M80.486,64.418H80.48a.491.491,0,1,0,.005,0Z"
											transform="translate(-79.995 -64.418)"
											fill="#c1c1c1"
										/>
									</g>
								</g>
								<g
									id="Group_4911"
									data-name="Group 4911"
									transform="translate(3.643 2.604)"
								>
									<g
										id="Group_4910"
										data-name="Group 4910"
										transform="translate(0 0)"
									>
										<path
											id="Path_6509"
											data-name="Path 6509"
											d="M56.475,64.418h-.005a.491.491,0,1,0,.005,0Z"
											transform="translate(-55.984 -64.418)"
											fill="#c1c1c1"
										/>
									</g>
								</g>
								<g
									id="Group_4913"
									data-name="Group 4913"
									transform="translate(7.292 2.604)"
								>
									<g
										id="Group_4912"
										data-name="Group 4912"
										transform="translate(0 0)"
									>
										<path
											id="Path_6510"
											data-name="Path 6510"
											d="M131.287,64.418h-18.75a.488.488,0,0,0,0,.976h18.75a.488.488,0,1,0,0-.976Z"
											transform="translate(-112.049 -64.418)"
											fill="#c1c1c1"
										/>
									</g>
								</g>
								<g
									id="Group_4915"
									data-name="Group 4915"
									transform="translate(18.28 15.104)"
								>
									<g
										id="Group_4914"
										data-name="Group 4914"
										transform="translate(0 0)"
									>
										<path
											id="Path_6511"
											data-name="Path 6511"
											d="M295.615,258.974l-7.031-2.446a.487.487,0,0,0-.321,0l-5.056,1.759a.488.488,0,0,0,.321.922l4.9-1.7,6.52,2.268a14.616,14.616,0,0,1-6.52,10.7A14.4,14.4,0,0,1,282.8,263.5a13.668,13.668,0,0,1-.919-4.077.488.488,0,0,0-.976.021,14.345,14.345,0,0,0,.969,4.366,15.368,15.368,0,0,0,6.293,7.655.488.488,0,0,0,.516,0,15.368,15.368,0,0,0,6.293-7.655,14.339,14.339,0,0,0,.969-4.366A.487.487,0,0,0,295.615,258.974Z"
											transform="translate(-280.903 -256.501)"
											fill="#c1c1c1"
										/>
									</g>
								</g>
								<g
									id="Group_4917"
									data-name="Group 4917"
									transform="translate(22.637 20.187)"
								>
									<g
										id="Group_4916"
										data-name="Group 4916"
										transform="translate(0 0)"
									>
										<path
											id="Path_6512"
											data-name="Path 6512"
											d="M354.184,334.744a.488.488,0,0,0-.69,0l-3.018,3.018-1.795-1.795a.488.488,0,1,0-.69.69l2.14,2.14a.488.488,0,0,0,.69,0l3.363-3.363A.488.488,0,0,0,354.184,334.744Z"
											transform="translate(-347.848 -334.601)"
											fill="#c1c1c1"
										/>
									</g>
								</g>
								<g
									id="Group_4919"
									data-name="Group 4919"
									transform="translate(15.625 8.333)"
								>
									<g id="Group_4918" data-name="Group 4918">
										<path
											id="Path_6513"
											data-name="Path 6513"
											d="M249.447,152.456h-8.334a1.01,1.01,0,0,0-1.009,1.009v1.563a1.01,1.01,0,0,0,1.009,1.009h8.333a1.01,1.01,0,0,0,1.009-1.009v-1.563A1.01,1.01,0,0,0,249.447,152.456Zm.033,2.571a.033.033,0,0,1-.033.033h-8.333a.033.033,0,0,1-.033-.033v-1.563a.033.033,0,0,1,.033-.033h8.333a.033.033,0,0,1,.033.033Z"
											transform="translate(-240.105 -152.456)"
											fill="#c1c1c1"
										/>
									</g>
								</g>
								<g
									id="Group_4921"
									data-name="Group 4921"
									transform="translate(16.146 13.021)"
								>
									<g
										id="Group_4920"
										data-name="Group 4920"
										transform="translate(0 0)"
									>
										<path
											id="Path_6514"
											data-name="Path 6514"
											d="M254.846,224.487H248.6a.488.488,0,0,0,0,.976h6.25a.488.488,0,0,0,0-.976Z"
											transform="translate(-248.108 -224.487)"
											fill="#c1c1c1"
										/>
									</g>
								</g>
								<g
									id="Group_4923"
									data-name="Group 4923"
									transform="translate(16.146 15.104)"
								>
									<g
										id="Group_4922"
										data-name="Group 4922"
										transform="translate(0 0)"
									>
										<path
											id="Path_6515"
											data-name="Path 6515"
											d="M252.242,256.5H248.6a.488.488,0,1,0,0,.976h3.646a.488.488,0,1,0,0-.976Z"
											transform="translate(-248.108 -256.502)"
											fill="#c1c1c1"
										/>
									</g>
								</g>
							</svg>
							GDPR eTraining
						</a>
						<a class="flex-row-item" :href="`${this.front_url}/business/policy-generator`">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								id="Policies_Pack"
								data-name="Policies Pack"
								width="30.137"
								height="30.143"
								viewBox="0 0 30.137 30.143"
							>
								<g
									id="_033---Insurance"
									data-name="033---Insurance"
									transform="translate(0 0)"
								>
									<path
										id="Shape"
										d="M29.775,3.341,22.239.042a.5.5,0,0,0-.4,0L14.3,3.341a.5.5,0,0,0-.3.46v7.888a5.533,5.533,0,0,0,2.291,4.475L21.5,19.921a.919.919,0,0,0,1.078,0l5.208-3.756a5.533,5.533,0,0,0,2.291-4.475V3.8A.5.5,0,0,0,29.775,3.341Zm-.7,8.348A4.526,4.526,0,0,1,27.2,15.35l-5.16,3.721-5.16-3.721A4.526,4.526,0,0,1,15,11.689V4.13l7.034-3.079L29.072,4.13Z"
										transform="translate(-6.975 -0.001)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-2"
										data-name="Shape"
										d="M23.828,4.408,18.3,6.828a.5.5,0,0,0-.3.46v6.574a3.517,3.517,0,0,0,1.457,2.846l4.278,3.086a.5.5,0,0,0,.588,0L28.6,16.708a3.517,3.517,0,0,0,1.457-2.846V7.288a.5.5,0,0,0-.3-.46L24.23,4.408A.5.5,0,0,0,23.828,4.408Zm5.225,3.208v6.246a2.512,2.512,0,0,1-1.04,2.031l-3.984,2.874-3.984-2.874A2.512,2.512,0,0,1,19,13.862V7.616l5.024-2.2Z"
										transform="translate(-8.966 -2.173)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-3"
										data-name="Shape"
										d="M4.171,29.739,3.084,27.2a1.6,1.6,0,0,0-2.93,1.294l1.969,4.331A5.336,5.336,0,0,0,3.213,34.4l3.1,3.1a1,1,0,0,0-.272.683V42.2a1,1,0,0,0,1,1h5.526a1,1,0,0,0,1-1V38.18a1,1,0,0,0-1-1V36.24a5.264,5.264,0,0,0-1.1-3.291,3.617,3.617,0,0,0-1.674-1.221,9.938,9.938,0,0,1-2.876-1.846A2.311,2.311,0,0,0,4.171,29.739Zm8.4,12.459H7.042V38.18h5.526Zm-1.885-8.622a4.263,4.263,0,0,1,.881,2.663v.935H7.409L3.924,33.689a4.345,4.345,0,0,1-.887-1.28L1.068,28.078A.6.6,0,1,1,2.16,27.6l1.124,2.621a5.929,5.929,0,0,0,1.267,1.871l2.295,2.3a.5.5,0,1,0,.71-.71l-2.295-2.3a4.955,4.955,0,0,1-.619-.754,1.306,1.306,0,0,1,1.627.027,10.711,10.711,0,0,0,3.205,2.03A2.639,2.639,0,0,1,10.683,33.577Z"
										transform="translate(-0.017 -13.06)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-4"
										data-name="Shape"
										d="M21,53h-.5a.5.5,0,1,0,0,1H21a.5.5,0,1,0,0-1Z"
										transform="translate(-9.961 -26.373)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-5"
										data-name="Shape"
										d="M44.451,32.8l1.969-4.331a1.6,1.6,0,1,0-2.93-1.294l-1.084,2.53a2.317,2.317,0,0,0-2.749.151A9.938,9.938,0,0,1,36.781,31.7a3.617,3.617,0,0,0-1.674,1.221A5.264,5.264,0,0,0,34,36.217v.935a1,1,0,0,0-1,1v4.019a1,1,0,0,0,1,1h5.526a1,1,0,0,0,1-1V38.156a1,1,0,0,0-.272-.683l3.1-3.1A5.335,5.335,0,0,0,44.451,32.8Zm-4.919,9.374H34V38.156h5.526Zm-.367-5.024H35.01v-.935a4.263,4.263,0,0,1,.881-2.663,2.641,2.641,0,0,1,1.206-.9,10.706,10.706,0,0,0,3.206-2.03,1.306,1.306,0,0,1,1.63-.032,4.926,4.926,0,0,1-.622.759l-2.295,2.3a.5.5,0,0,0,.71.71l2.295-2.3a5.928,5.928,0,0,0,1.267-1.871l1.124-2.621a.6.6,0,1,1,1.092.483l-1.969,4.331a4.345,4.345,0,0,1-.887,1.28Z"
										transform="translate(-16.43 -13.037)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-6"
										data-name="Shape"
										d="M38,53h-.5a.5.5,0,1,0,0,1H38a.5.5,0,1,0,0-1Z"
										transform="translate(-18.42 -26.373)"
										fill="#c1c1c1"
									/>
									<path
										id="Shape-7"
										data-name="Shape"
										d="M23.8,16.622a.5.5,0,1,0-.6.8l2.01,1.507a.5.5,0,0,0,.3.1.54.54,0,0,0,.085-.007.5.5,0,0,0,.333-.217l4.019-6.029a.5.5,0,0,0-.836-.558l-3.725,5.587Z"
										transform="translate(-11.454 -5.971)"
										fill="#c1c1c1"
									/>
								</g>
							</svg>
							Policies Pack
						</a>
						<a class="flex-row-item" :href="`${this.front_url}/business/templates/best-practice-template`">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="30.137"
								height="30.143"
								viewBox="0 0 30.137 30.143"
							>
								<g
									id="Templates_Pack"
									data-name="Templates Pack"
									transform="translate(0 0)"
								>
									<g
										id="Group_4946"
										data-name="Group 4946"
										transform="translate(0 13.162)"
									>
										<g
											id="Page-1"
											transform="translate(0 0)"
										>
											<g
												id="_033---Insurance"
												data-name="033---Insurance"
												transform="translate(0 0)"
											>
												<path
													id="Shape"
													d="M4.171,29.739,3.084,27.2a1.6,1.6,0,0,0-2.93,1.294l1.969,4.331A5.336,5.336,0,0,0,3.213,34.4l3.1,3.1a1,1,0,0,0-.272.683V42.2a1,1,0,0,0,1,1h5.526a1,1,0,0,0,1-1V38.18a1,1,0,0,0-1-1V36.24a5.264,5.264,0,0,0-1.1-3.291,3.617,3.617,0,0,0-1.674-1.221,9.938,9.938,0,0,1-2.876-1.846A2.311,2.311,0,0,0,4.171,29.739Zm8.4,12.459H7.042V38.18h5.526Zm-1.885-8.622a4.263,4.263,0,0,1,.881,2.663v.935H7.409L3.924,33.689a4.345,4.345,0,0,1-.887-1.28L1.068,28.078A.6.6,0,1,1,2.16,27.6l1.124,2.621a5.929,5.929,0,0,0,1.267,1.871l2.295,2.3a.5.5,0,1,0,.71-.71l-2.295-2.3a4.955,4.955,0,0,1-.619-.754,1.306,1.306,0,0,1,1.627.027,10.711,10.711,0,0,0,3.205,2.03A2.639,2.639,0,0,1,10.683,33.577Z"
													transform="translate(-0.017 -26.222)"
													fill="#c1c1c1"
												/>
												<path
													id="Shape-2"
													data-name="Shape"
													d="M21,53h-.5a.5.5,0,1,0,0,1H21a.5.5,0,1,0,0-1Z"
													transform="translate(-9.961 -39.535)"
													fill="#c1c1c1"
												/>
												<path
													id="Shape-3"
													data-name="Shape"
													d="M44.451,32.8l1.969-4.331a1.6,1.6,0,1,0-2.93-1.294l-1.084,2.53a2.317,2.317,0,0,0-2.749.151A9.938,9.938,0,0,1,36.781,31.7a3.617,3.617,0,0,0-1.674,1.221A5.264,5.264,0,0,0,34,36.217v.935a1,1,0,0,0-1,1v4.019a1,1,0,0,0,1,1h5.526a1,1,0,0,0,1-1V38.156a1,1,0,0,0-.272-.683l3.1-3.1A5.335,5.335,0,0,0,44.451,32.8Zm-4.919,9.374H34V38.156h5.526Zm-.367-5.024H35.01v-.935a4.263,4.263,0,0,1,.881-2.663,2.641,2.641,0,0,1,1.206-.9,10.706,10.706,0,0,0,3.206-2.03,1.306,1.306,0,0,1,1.63-.032,4.926,4.926,0,0,1-.622.759l-2.295,2.3a.5.5,0,0,0,.71.71l2.295-2.3a5.928,5.928,0,0,0,1.267-1.871l1.124-2.621a.6.6,0,1,1,1.092.483l-1.969,4.331a4.345,4.345,0,0,1-.887,1.28Z"
													transform="translate(-16.43 -26.199)"
													fill="#c1c1c1"
												/>
												<path
													id="Shape-4"
													data-name="Shape"
													d="M38,53h-.5a.5.5,0,1,0,0,1H38a.5.5,0,1,0,0-1Z"
													transform="translate(-18.42 -39.535)"
													fill="#c1c1c1"
												/>
											</g>
										</g>
									</g>
									<g
										id="theme"
										transform="translate(6.459 0)"
									>
										<path
											id="Path_6521"
											data-name="Path 6521"
											d="M75.367,402.283h-10.2a.567.567,0,0,1-.567-.567v-1.7h-.567v1.7a1.134,1.134,0,0,0,1.134,1.134h10.2a1.134,1.134,0,0,0,1.069-.756l-.534-.189A.568.568,0,0,1,75.367,402.283Zm0,0"
											transform="translate(-61.763 -385.846)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6522"
											data-name="Path 6522"
											d="M76.5,5.967V3.133c0-.006,0-.011,0-.018a.282.282,0,0,0-.015-.073c0-.009-.005-.018-.009-.026a.285.285,0,0,0-.057-.083L73.582.1A.282.282,0,0,0,73.5.042L73.473.033A.285.285,0,0,0,73.4.018s-.01,0-.016,0H65.165A1.134,1.134,0,0,0,64.031,1.15V2.283H64.6V1.15a.567.567,0,0,1,.567-.567H73.1v1.7a1.134,1.134,0,0,0,1.134,1.134h1.7V5.967ZM74.233,2.85a.567.567,0,0,1-.567-.567V.984L75.533,2.85Zm0,0"
											transform="translate(-61.763 -0.016)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6523"
											data-name="Path 6523"
											d="M104.039,32.016h.567v.567h-.567Zm0,0"
											transform="translate(-100.353 -30.882)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6524"
											data-name="Path 6524"
											d="M136.039,32.016h.567v.567h-.567Zm0,0"
											transform="translate(-131.22 -30.882)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6525"
											data-name="Path 6525"
											d="M168.039,32.016h.567v.567h-.567Zm0,0"
											transform="translate(-162.086 -30.882)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6526"
											data-name="Path 6526"
											d="M10.2,85.967V80.3a.283.283,0,0,0-.283-.283H1.985c-.006,0-.011,0-.016,0a.289.289,0,0,0-.076.015l-.025.009a.285.285,0,0,0-.083.057l-1.7,1.7a.283.283,0,0,0-.057.083c0,.009-.006.018-.009.026A.277.277,0,0,0,0,81.983c0,.006,0,.012,0,.018v3.967a.283.283,0,0,0,.283.283H9.92a.283.283,0,0,0,.283-.283ZM1.7,80.984v.733H.968Zm7.935,4.7H.568v-1l2.54-2.031,2.655,2.389a.284.284,0,0,0,.374,0l1.8-1.542,1.7,1.458Zm0-1.466-1.516-1.3a.283.283,0,0,0-.368,0l-1.8,1.538L3.308,82.073a.283.283,0,0,0-.367-.011l-2.373,1.9V82.283H1.985A.283.283,0,0,0,2.268,82V80.583H9.636Zm0,0"
											transform="translate(0 -77.182)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6527"
											data-name="Path 6527"
											d="M144.881,113.716a.85.85,0,1,0-.85-.85A.85.85,0,0,0,144.881,113.716Zm0-1.134a.283.283,0,1,1-.283.283A.283.283,0,0,1,144.881,112.583Zm0,0"
											transform="translate(-138.929 -108.048)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6528"
											data-name="Path 6528"
											d="M43.1,178.9l1.417-1.134.354.443-1.417,1.134Zm0,0"
											transform="translate(-41.575 -171.469)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6529"
											data-name="Path 6529"
											d="M202.379,202.926l.567-.567.4.4-.567.567Zm0,0"
											transform="translate(-195.21 -195.191)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6530"
											data-name="Path 6530"
											d="M270.549,312.016h-6.235a.283.283,0,0,0-.283.283v3.968a.283.283,0,0,0,.283.283h6.235a.283.283,0,0,0,.283-.283V312.3A.283.283,0,0,0,270.549,312.016Zm-.283,3.968H264.6v-3.4h5.668Zm0,0"
											transform="translate(-254.678 -300.963)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6531"
											data-name="Path 6531"
											d="M304.684,355.894l.4-.4-.366-.366.366-.366-.4-.4-.567.567a.283.283,0,0,0,0,.4Zm0,0"
											transform="translate(-293.264 -341.807)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6532"
											data-name="Path 6532"
											d="M386.776,355.894l.567-.567a.283.283,0,0,0,0-.4l-.567-.567-.4.4.366.366-.366.366Zm0,0"
											transform="translate(-372.688 -341.807)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6533"
											data-name="Path 6533"
											d="M344.281,344.341l.566-2.267.55.137-.566,2.267Zm0,0"
											transform="translate(-332.085 -329.957)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6534"
											data-name="Path 6534"
											d="M39.683,275.7v-3.4a.283.283,0,0,0-.283-.283H32.314a.283.283,0,0,0-.283.283v3.4a.283.283,0,0,0,.283.283H39.4A.283.283,0,0,0,39.683,275.7Zm-.567-.283H32.6v-2.834h6.518Zm0,0"
											transform="translate(-30.896 -262.38)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6535"
											data-name="Path 6535"
											d="M64.031,304.016H64.6v.567h-.567Zm0,0"
											transform="translate(-61.763 -293.247)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6536"
											data-name="Path 6536"
											d="M96.031,304.016h4.251v.567H96.031Zm0,0"
											transform="translate(-92.629 -293.247)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6537"
											data-name="Path 6537"
											d="M64.031,336.016H64.6v.567h-.567Zm0,0"
											transform="translate(-61.763 -324.113)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6538"
											data-name="Path 6538"
											d="M96.031,336.016h4.251v.567H96.031Zm0,0"
											transform="translate(-92.629 -324.113)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6539"
											data-name="Path 6539"
											d="M309.982,184.016h-5.668a.283.283,0,0,0-.283.283v3.4a.283.283,0,0,0,.283.283h5.668a.283.283,0,0,0,.283-.283v-3.4A.283.283,0,0,0,309.982,184.016Zm-.283,3.4h-5.1v-2.834h5.1Zm0,0"
											transform="translate(-293.261 -177.498)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6540"
											data-name="Path 6540"
											d="M336.031,216.016h1.134v.567h-1.134Zm0,0"
											transform="translate(-324.127 -208.364)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6541"
											data-name="Path 6541"
											d="M384.031,216.016H386.3v.567h-2.267Zm0,0"
											transform="translate(-370.427 -208.364)"
											fill="#c1c1c1"
										/>
										<path
											id="Path_6542"
											data-name="Path 6542"
											d="M336.031,248.016H340v.567h-3.968Zm0,0"
											transform="translate(-324.127 -239.231)"
											fill="#c1c1c1"
										/>
									</g>
								</g>
							</svg>
							Templates Pack
						</a>
						<a
							class="flex-row-item"
							:href="`${this.front_url}/business/gdpr-audit`"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								id="GDPR_Audit"
								data-name="GDPR Audit"
								width="30.144"
								height="30.143"
								viewBox="0 0 30.144 30.143"
							>
								<path
									id="Path_6543"
									data-name="Path 6543"
									d="M72.142,45.907a.557.557,0,0,0-.162-.395l-3.349-3.349A.558.558,0,0,0,68.236,42h-13.4a.558.558,0,0,0-.558.558v3.907H51.489a.558.558,0,0,0-.558.558v3.907H42.558A.558.558,0,0,0,42,51.49v20.1a.558.558,0,0,0,.558.558H65.445A.558.558,0,0,0,66,71.585V67.678h2.233a.558.558,0,0,0,.558-.558V63.212h2.791a.558.558,0,0,0,.558-.558V45.907Zm-3.348-2,1.443,1.443H68.794ZM43.116,71.027V52.048H61.537v2.791a.558.558,0,0,0,.558.558h2.791v15.63ZM62.654,54.281V52.837L64.1,54.281ZM66,66.561V54.839h0a.557.557,0,0,0-.162-.395L62.49,51.095a.557.557,0,0,0-.395-.162H52.048V47.582H64.328v2.791a.558.558,0,0,0,.558.558h2.791v15.63Zm-.558-16.746V48.372l1.443,1.443ZM68.794,62.1V50.373s0-.007,0-.011a.563.563,0,0,0-.01-.1s0-.008,0-.013a.554.554,0,0,0-.025-.081c0-.01-.008-.02-.013-.029a.561.561,0,0,0-.033-.062c-.005-.008-.01-.016-.015-.024a.565.565,0,0,0-.062-.075l-3.349-3.349a.557.557,0,0,0-.395-.162v0H55.4V43.116H67.678v2.791a.558.558,0,0,0,.558.558h2.791V62.1Z"
									transform="translate(-42 -42)"
									fill="#c1c1c1"
								/>
								<ellipse
									id="Ellipse_14"
									data-name="Ellipse 14"
									cx="0.5"
									rx="0.5"
									transform="translate(2.372 11.925)"
									fill="#c1c1c1"
								/>
								<ellipse
									id="Ellipse_15"
									data-name="Ellipse 15"
									cx="1"
									rx="1"
									transform="translate(3.372 11.925)"
									fill="#c1c1c1"
								/>
								<ellipse
									id="Ellipse_16"
									data-name="Ellipse 16"
									cx="0.5"
									rx="0.5"
									transform="translate(5.372 11.925)"
									fill="#c1c1c1"
								/>
								<circle
									id="Ellipse_17"
									data-name="Ellipse 17"
									cx="0.5"
									cy="0.5"
									r="0.5"
									transform="translate(11.372 6.925)"
									fill="#c1c1c1"
								/>
								<ellipse
									id="Ellipse_18"
									data-name="Ellipse 18"
									cy="0.5"
									ry="0.5"
									transform="translate(13.372 6.925)"
									fill="#c1c1c1"
								/>
								<circle
									id="Ellipse_19"
									data-name="Ellipse 19"
									cx="0.5"
									cy="0.5"
									r="0.5"
									transform="translate(14.372 6.925)"
									fill="#c1c1c1"
								/>
								<ellipse
									id="Ellipse_20"
									data-name="Ellipse 20"
									cx="0.5"
									cy="1"
									rx="0.5"
									ry="1"
									transform="translate(14.372 1.925)"
									fill="#c1c1c1"
								/>
								<ellipse
									id="Ellipse_21"
									data-name="Ellipse 21"
									cx="0.5"
									cy="1"
									rx="0.5"
									ry="1"
									transform="translate(16.372 1.925)"
									fill="#c1c1c1"
								/>
								<ellipse
									id="Ellipse_22"
									data-name="Ellipse 22"
									cy="1"
									ry="1"
									transform="translate(18.372 1.925)"
									fill="#c1c1c1"
								/>
								<path
									id="Path_6582"
									data-name="Path 6582"
									d="M4.889-5.21A2.308,2.308,0,0,0,2.5-7.04,2.448,2.448,0,0,0,.03-4.492,2.433,2.433,0,0,0,2.5-1.952,2.523,2.523,0,0,0,4.788-3.53v.007a3.605,3.605,0,0,0,.136-.868H2.219v.725H4.078A1.641,1.641,0,0,1,2.5-2.648,1.757,1.757,0,0,1,.755-4.492,1.743,1.743,0,0,1,2.5-6.315,1.563,1.563,0,0,1,4.114-5.21Zm1.17,2.5V-6.279H7.135A1.706,1.706,0,0,1,8.886-4.492a1.7,1.7,0,0,1-1.751,1.78ZM5.334-7v5.017h1.8A2.39,2.39,0,0,0,9.6-4.492,2.4,2.4,0,0,0,7.135-7Zm4.651-.007v5.024h.732V-3.66h1.105a1.587,1.587,0,0,0,1.629-1.672,1.593,1.593,0,0,0-1.629-1.679Zm.732,2.634v-1.9h1.105a.9.9,0,0,1,.911.955.893.893,0,0,1-.911.947Zm4.055.187,1.83,2.2h.94L15.956-3.875a1.512,1.512,0,0,0,1.3-1.536,1.556,1.556,0,0,0-1.636-1.6h-1.83v5.024h.718V-6.279h1.112a.855.855,0,0,1,.911.868.856.856,0,0,1-.911.876h-.847Z"
									transform="translate(2.862 24.954)"
									fill="#c1c1c1"
								/>
							</svg>
							GDPR Audit
						</a>
					</div>
				</div>
			</div>
			<div class="logined-dropdown ml-3" @click="UserDropDown = !UserDropDown">
				<img src="../../assets/images/nophoto.png" />
				<!-- <img :src="this.user.avatar_link" /> -->
				<div class="logined-dropdown-content" v-if="UserDropDown">
					<div class="menu-profile-inside">
						<img src="../../assets/images/nophoto.png" />
						<a class="user-link" href="javascript::void(0)">{{userEmail}}</a>
					</div>
					<a class="setting-img" :href="`${this.front_url}/business/profile`">Setting</a>
					<a class="logout-img" href="javascript:void(0)" @click="logout()">Logout</a>
				</div>
			</div>
		</div>
		<div v-if="feedbackModal" class="feed-custom-modal" id="custom-modal">
    <div class="feed-modal-content">
        <div class="feed-modal-header">
            <span class="feed-close-modal" @click="feedback(false)">&#10006;</span>
            <h2 class="text-white">Send feedback</h2>
        </div>
        <div class="feed-modal-body">
            <textarea class="feed-textarea" id="feed-textarea" placeholder="Have feedback? We'd love to hear it, but please don't share sensitive information. Have questions? Try help or support"></textarea>
            <div class="feed-star-rating">
                <div>
                    <svg class="rating-1 " xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" data-rating="1" @click="updateRating($event)">
                        <path id="smiley_defualt_01" data-name="smiley defualt 01" d="M2429-1945a55,55,0,0,1,55-55,55,55,0,0,1,55,55,55,55,0,0,1-55,55A55,55,0,0,1,2429-1945Zm35.149-46.994a50.857,50.857,0,0,0-16.213,10.931,50.868,50.868,0,0,0-10.931,16.213A50.69,50.69,0,0,0,2433-1945a50.7,50.7,0,0,0,4.006,19.851,50.875,50.875,0,0,0,10.931,16.212,50.869,50.869,0,0,0,16.213,10.931A50.692,50.692,0,0,0,2484-1894a50.691,50.691,0,0,0,19.85-4.006,50.877,50.877,0,0,0,16.213-10.931,50.853,50.853,0,0,0,10.931-16.212A50.685,50.685,0,0,0,2535-1945a50.678,50.678,0,0,0-4.007-19.85,50.846,50.846,0,0,0-10.931-16.213,50.865,50.865,0,0,0-16.213-10.931A50.688,50.688,0,0,0,2484-1996,50.69,50.69,0,0,0,2464.15-1991.994Zm-.82,73.2a2.5,2.5,0,0,1-1.04-3.379c.333-.628,8.3-15.417,20.773-15.971,7.846-.349,15.383,4.959,22.409,15.781a2.5,2.5,0,0,1-.736,3.458,2.5,2.5,0,0,1-3.458-.735c-5.991-9.229-12.048-13.8-17.993-13.507-9.644.428-16.508,13.187-16.576,13.316a2.5,2.5,0,0,1-2.211,1.331A2.485,2.485,0,0,1,2463.33-1918.792ZM2494-1960a7,7,0,0,1,7-7,7,7,0,0,1,7,7,7,7,0,0,1-7,7A7,7,0,0,1,2494-1960Zm-35,0a7,7,0,0,1,7-7,7,7,0,0,1,7,7,7,7,0,0,1-7,7A7,7,0,0,1,2459-1960Z" transform="translate(-2429 2000)" fill="#292929" />

                    </svg>
                    <p class="text-center hide">Terrible</p>
                </div>
                <div>
                    <svg class="rating-2" xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" data-rating="2" v-on:click="updateRating($event)">
                        <path id="smiley_defualt_02" data-name="smiley defualt  02" d="M2429-1945a55.062,55.062,0,0,1,55-55,55.063,55.063,0,0,1,55,55,55.063,55.063,0,0,1-55,55A55.062,55.062,0,0,1,2429-1945Zm4,0a51.058,51.058,0,0,0,51,51,51.058,51.058,0,0,0,51-51,51.057,51.057,0,0,0-51-51A51.057,51.057,0,0,0,2433-1945Zm68.72,19.482c-18.937-16.71-34.139-.855-34.777-.174a2.5,2.5,0,0,1-3.53.129,2.494,2.494,0,0,1-.139-3.525c.187-.205,19.107-20.165,41.755-.178a2.5,2.5,0,0,1,.219,3.528,2.5,2.5,0,0,1-1.875.846A2.489,2.489,0,0,1,2501.72-1925.518ZM2494-1960a7,7,0,0,1,7-7,7,7,0,0,1,7,7,7,7,0,0,1-7,7A7,7,0,0,1,2494-1960Zm-35,0a7,7,0,0,1,7-7,7,7,0,0,1,7,7,7,7,0,0,1-7,7A7,7,0,0,1,2459-1960Z" transform="translate(-2429 2000)" fill="#292929" />
                    </svg>
                    <p class="hide text-center">Bad</p>
                </div>

                <div>
                    <svg class="rating-3" xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" data-rating="3" @click="updateRating($event)">
                        <path id="smiley_defualt_03" data-name="smiley defualt 03" d="M2429-1945a55.062,55.062,0,0,1,55-55,55.063,55.063,0,0,1,55,55,55.063,55.063,0,0,1-55,55A55.062,55.062,0,0,1,2429-1945Zm4,0a51.058,51.058,0,0,0,51,51,51.059,51.059,0,0,0,51-51,51.058,51.058,0,0,0-51-51A51.057,51.057,0,0,0,2433-1945Zm70.164,18.1a225.911,225.911,0,0,0-37.842,0,2.5,2.5,0,0,1-2.7-2.281,2.5,2.5,0,0,1,2.281-2.7,230.932,230.932,0,0,1,38.683,0,2.5,2.5,0,0,1,2.281,2.7,2.5,2.5,0,0,1-2.488,2.291C2503.307-1926.892,2503.235-1926.895,2503.164-1926.9ZM2494-1960a7,7,0,0,1,7-7,7,7,0,0,1,7,7,7,7,0,0,1-7,7A7,7,0,0,1,2494-1960Zm-35,0a7,7,0,0,1,7-7,7,7,0,0,1,7,7,7,7,0,0,1-7,7A7,7,0,0,1,2459-1960Z" transform="translate(-2429 2000)" fill="#292929" />
                    </svg>
                    <p class="hide text-center">okay</p>
                </div>
                <div>
                    <svg class="rating-4" xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" data-rating="4" @click="updateRating($event)">
                        <path id="smiley_defualt_04" data-name="smiley defualt 04" d="M2429-1945a55.062,55.062,0,0,1,55-55,55.063,55.063,0,0,1,55,55,55.063,55.063,0,0,1-55,55A55.062,55.062,0,0,1,2429-1945Zm4,0a51.059,51.059,0,0,0,51,51,51.059,51.059,0,0,0,51-51,51.058,51.058,0,0,0-51-51A51.058,51.058,0,0,0,2433-1945Zm30.447,12.473a2.5,2.5,0,0,1-.2-3.529,2.5,2.5,0,0,1,3.524-.207c.644.567,16.007,13.76,35.132-.151a2.5,2.5,0,0,1,3.492.551,2.5,2.5,0,0,1-.55,3.492c-7.473,5.436-14.54,7.254-20.645,7.254A34,34,0,0,1,2463.447-1932.527ZM2494-1960a7,7,0,0,1,7-7,7,7,0,0,1,7,7,7,7,0,0,1-7,7A7,7,0,0,1,2494-1960Zm-35,0a7,7,0,0,1,7-7,7,7,0,0,1,7,7,7,7,0,0,1-7,7A7,7,0,0,1,2459-1960Z" transform="translate(-2429 2000)" fill="#292929" />
                    </svg>
                    <p class="hide text-center">Good</p>
                </div>
                <div>
                    <svg class="rating-5" xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" data-rating="5" @click="updateRating($event)">
                        <path id="smiley_defualt_05" data-name="smiley defualt 05" d="M2429-1945a55.062,55.062,0,0,1,55-55,55.062,55.062,0,0,1,55,55,55.062,55.062,0,0,1-55,55A55.062,55.062,0,0,1,2429-1945Zm4,0a51.059,51.059,0,0,0,51,51,51.059,51.059,0,0,0,51-51,51.058,51.058,0,0,0-51-51A51.058,51.058,0,0,0,2433-1945Zm49.816,23.372c-12.12-.762-19.668-14.477-19.984-15.059a2.5,2.5,0,0,1,1.01-3.389,2.5,2.5,0,0,1,3.389,1.008c.093.171,6.587,11.876,15.916,12.45,5.8.36,11.8-3.7,17.79-12.068a2.5,2.5,0,0,1,3.488-.578,2.5,2.5,0,0,1,.578,3.488c-6.736,9.415-13.84,14.181-21.131,14.181C2483.52-1921.595,2483.167-1921.607,2482.816-1921.628ZM2494-1960a7,7,0,0,1,7-7,7,7,0,0,1,7,7,7,7,0,0,1-7,7A7,7,0,0,1,2494-1960Zm-35,0a7,7,0,0,1,7-7,7,7,0,0,1,7,7,7,7,0,0,1-7,7A7,7,0,0,1,2459-1960Z" transform="translate(-2429 2000)" fill="#292929" />
                    </svg>
                    <p class="hide text-center">Great</p>
                </div>

            </div>
            <div class="feed-text-modal">
                <p>Go to the Legal Help page to request content changes for legal reasons. Some account and system
                    information may be sent to Seers. We will use the information that you give us to help address
                    technical issues and to improve our services, subject to our Privacy Policy and Terms of Service.
                </p>
            </div>

            <div class="custom-feed-btn-div">
                <div class="custom-btn-position">
                    <button type="button" class="feed-btn" id="cancel" @click="feedback(false)">CANCEL</button>
                    <button type="button" class="feed-btn feed-btn-send" id="send" @click="send()"> SEND</button>
					<button type="button" class="feed-btn feed-btn-send" id="loader" disabled="true"> <i  class="fa fa-spinner fa-pulse fa-fw"></i></button>
                </div>
            </div>

        </div>
    </div>
</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';
export default {
	name: "HeaderView",
	data() {
		return {
			HelpFeedback:false,
			ProductsDropDown:false,
			UserDropDown:false,
			rating: 1,
			feedbackModal:false,
			user_token: localStorage.getItem("user_id"),
		};
	},
	computed: {
		cmp_url(){
			return process.env.VUE_APP_ENV_VARIABLE
		},
		front_url() {
			return process.env.VUE_APP_FRONT;
		},
		userEmail(){
			return this.$store.state.domain.userEmail;
		},
		
		user(){
			return this.$store.state.domain.user;
		},
		dashboardLink() {
      return `${this.front_url}/business/dashboard`;
    }
	},
	mounted() {
		// console.log('this.user', this.user)
		console.log('hiiii',this.front_url);

	},
	methods: {
		feedback(value){
			if(value === true){
				this.feedbackModal = true;
			}
			else{
				this.feedbackModal = false;
			}
		},
		updateRating(element) {
			this.rating = element.currentTarget.getAttribute("data-rating");
			this.removeSmilyClass();
			element.target.classList.add('smile-active');
		},
		removeSmilyClass() {
			var element = document.getElementsByClassName('feed-star-rating');
			var children = element[0].children;
			for(var i=0; i<children.length; i++){
				var child = children[i];
				child.children[0].classList.remove("smile-active");
			}
		},
		send() {
			let feedback = document.getElementById('feed-textarea').value;
			if (feedback.length == 0) {
				this.feedbackModal = false;
				// document.getElementById('custom-modal').style = 'display: none !important';
				Swal.fire({
                  type: "Error",
                  title: "Warning",
                  text: "Feedback field is required",
                  confirmButtonText: "Ok",
                });
				return false;
			}
			if (this.rating >= 1 && this.rating <= 5) {
            var data = {
                feedback: feedback,
                rating: this.rating
            }
            document.getElementById('send').style = 'display: none !important';
            document.getElementById('loader').style = 'display: block !important';
            axios.post(`${this.cmp_url}/api/v2/feedback`, data,{
            headers: {
                Authorization: 'Bearer ' + this.$store.state.domain.userToken,
            }
			})
			.then(response => {
				if (response.data) {
					this.feedbackModal = false;
					// document.getElementById('custom-modal').style = 'display: none !important';
					document.getElementById('loader').style = 'display: none !important';
					document.getElementById('send').style = 'display: block !important';
					Swal.fire({
						type: "Success",
						title: "success",
						text: response.data.message,
						confirmButtonText: "Ok",
					});
					document.getElementById('feed-textarea').value = "";
					this.rating = 0;
					this.removeSmilyClass();

				} else {
					this.feedbackModal = false;
					// document.getElementById('custom-modal').style = 'display: none !important';
					Swal.fire({
						type: "Error",
						title: "error",
						text: "Please try Again",
						confirmButtonText: "Ok",
					});
				}
			})
			.catch(function(error) {
				
				this.feedbackModal = false;
				// document.getElementById('custom-modal').style = 'display: none !important';
				Swal.fire({
					type: "Error",
					title: "error",
					text: "Please try Again",
					confirmButtonText: "Ok",
				});

			});
        } else {
            // this.hideDiv('custom-modal');
            // document.getElementById('custom-modal').style = 'display: none !important';
			this.feedbackModal = false;
			Swal.fire({
				type: "Error",
				title: "error",
				text: "Please Input the required Rating",
				confirmButtonText: "Ok",
			});
        }
		},
		logout() {
			console.log('logout func',this.front_url);
			this.$store.commit("dialogue/resetState");
			localStorage.removeItem("user_token");
			this.$store.commit("domain/SetuserTokenNull", null);
			window.location.href = `${this.front_url}/business/dashboard`;
		},
	},
};
</script>

<style scoped>
#loader {
        display: none !important;
}
.header-dashboard-logined {
	background-color: #fff;
	padding: 15px 30px 0px;
	display: inline-block;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 999;
	bottom: auto;
	box-shadow: 0 3px 8px #c1c1c1a6;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/* Style the header links */
.header-dashboard-logined .logo-hol img{
width: 100px;
margin-bottom: 15px;
}

/* Change the background color on mouse-over */
.header-dashboard-logined a:hover {
	color: #3b6ef8;
}

/* Style the active/current link*/
.header-dashboard-logined a.active {
	background-color: dodgerblue;
	color: white;
}

.logined_logo img {
	webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	width: 134px;
	max-width: 100%;
	margin: 0 0 0 0px;
}

/* Float the link section to the right */
.header-right {
	float: right;
	display: flex;
	align-items: center;
	list-style-type: none;
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 350px) {
	.header-dashboard-logined {
		padding: 15px 15px 0px !important;
	}
}

.logined-dropdown {
	position: relative;
	display: inline-block;
    padding-bottom: 15px;
}
.logined-dropdown-content {
	position: absolute;
	background-color: #fff;
	min-width: 160px;
	overflow: auto;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	right: 0;
	border-radius: 4px;
	padding: 5px 5px 8px 5px;
    top:100%;
}
.logined-dropdown-content a {
	color: #c1c1c1;
	padding: 8px 8px;
	text-decoration: none;
	display: block;
	font: 400 13px/1 "Poppins", sans-serif;
	cursor: pointer;
}

.menu-profile-inside {
	display: block;
	border-bottom: 1px solid #cecece;
	padding: 10px 20px;
	text-align: center;
	margin-bottom: 10px;
}

.insideimg {
	margin-bottom: 10px;
}

.user-name {
	font-size: 14px;
	margin: 10px 0 0 0;
	color: #3b6ef8;
}

.menu-profile-inside a.user-link {
	color: #292929;
}
.menu-profile-inside {
	font-size: 12px;
}
.menu-profile-inside a.user-link:hover {
	color: #6cc04a;
}

.setting-img::before {
	background: url("../../assets/images/settingsnormal.svg");
	content: "";
	width: 9.702px;
	height: 9.705px;
	margin-right: 6px;
	display: inline-block;
	background-repeat: no-repeat;
}

.setting-img:hover::before {
	background: url("../../assets/images/settings-hover.svg");
}

.logout-img::before {
	background: url("../../assets/images/logout-normal.svg");
	content: "";
	width: 9.75px;
	height: 9.75px;
	margin-right: 6px;
	display: inline-block;
	background-repeat: no-repeat;
}

.logout-img:hover::before {
	background: url("../../assets/images/logout-active-or-hover.svg");
}

.profileimg {
	color: #6cc04a;
	font-size: 25px;
	background-size: cover;
	width: 25px;
	height: 25px;
	object-fit: cover;
	object-position: top center;
	max-width: 100%;
	margin: 2px 0px 0px 15px;
	border-radius: 50%;
}

/*==========================*/
/*profile menu dropdown*/
/*==========================*/

/*==========================*/
/* menu dropdown Icons*/
/*==========================*/

.logined-dropdown-content.icon-dropdown-content {
	min-width: 380px;
	height: 480px;
	overflow-y: auto;
	padding: 8px 15px;
	overflow-x: hidden;
	transition: height 0.3s ease-in-out 0s;
	max-height: calc(-65px + 100vh);
}

.flex-row-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin: 0 -8px 0 -8px;
}

.flex-row-container > .flex-row-item {
	flex: 1 1 99px;
	/*grow | shrink | basis */
	height: 100px;
	margin: 8px;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
	font-size: 11px;
	color: #707070;
	text-align: center;
}

.flex-row-container > .flex-row-item:focus {
	outline: 0px auto -webkit-focus-ring-color;
}

.flex-row-container > .flex-row-item:hover,
.flex-row-container > .flex-row-item:hover svg path {
	background-color: #f9faff;
	color: #3b6ef8;
	fill: #3b6ef8;
}

.flex-row-item {
	border: 1px solid #f5f7ff;
	border-radius: 10px;
}

.flex-row-item svg {
	margin-bottom: 10px;
}

/*==========================*/
/* menu dropdown Icons End*/
/*==========================*/
/*toottip*/
.title-tooltip[data-tooltip] {
	position: relative;
}

.title-tooltip[data-tooltip]::before,
.title-tooltip[data-tooltip]::after {
	opacity: 0;
	visbility: hidden;
	margin-top: -0.5em;
}

.title-tooltip[data-tooltip]::before {
	content: attr(data-tooltip);
	display: block;
	position: absolute;
	bottom: -146%;
	left: 50%;
	margin-left: -4.75em;
	width: 8em;
	text-align: center;
	border-radius: 4px;
	background: black;
	color: white;
	font-size: 0.8em;
	line-height: 1.1;
	padding: 0.75em 0.95em;
	z-index: 999;
}

.title-tooltip[data-tooltip]:hover {
	cursor: help;
}

.title-tooltip[data-tooltip]:hover::before,
.title-tooltip[data-tooltip]:hover::after {
	opacity: 1;
	visiblity: visible;
}

/*tooltip end */

/*==========================*/
/*modal popup feedback*/
/*==========================*/

.feed-custom-modal {
	/* display: none; */
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	background: rgba(0, 0, 0, 0.6);
	z-index: 99999;
	animation-name: show_layer;
	animation-duration: 0.1s;
}

.feed-modal-content {
	border: 0px solid transparent !important;
}

.feed-custom-modal .feed-modal-content {
	position: absolute;
	border: 0px solid transparent !important;
	overflow: hidden;
	background-color: #fff;
	max-width: 100%;
	width: 400px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
	animation-name: show_modal;
	animation-duration: 0.5s;
	height: max-content;
	-moz-transition: height 0.3s ease;
	-webkit-transition: height 0.3s ease;
	-o-transition: height 0.3s ease;
	transition: height 0.3s ease;
}



.feed-custom-modal .feed-modal-header {
	background-color: #3b6ef8;
	padding: 15px 15px;
	align-items: center !important;
	justify-content: center !important;
}

.feed-custom-modal .feed-close-modal {
	display: grid;
    place-items: center;
	color: #292929;
	font-size: 13px;
	cursor: pointer;
	top: 15px;
	position: absolute;
	right: 15px;
	background: #ffffff;
	width: 25px;
	height: 25px;
	border-radius: 50%;
}

.feed-modal-body {
	padding: 15px;
}

.feed-textarea:focus {
	outline: -webkit-focus-ring-color auto 0px;
}

.feed-textarea {
	width: 100%;
	height: 100px;
	/* padding: 12px 20px; */
	box-sizing: border-box;
	border: 0px solid #ccc;
	border-radius: 4px;
	background-color: #ffffff;
	font-size: 14px;
	resize: none;
	font-weight: normal;
	overflow-y: auto;
	border-bottom: 1px solid #f5efef;
	border-radius: 0px;
}

textarea::-webkit-input-placeholder {
	color: #818e94;
}

textarea:-moz-placeholder {
	/* Firefox 18- */
	color: #818e94;
}

textarea::-moz-placeholder {
	/* Firefox 19+ */
	color: #818e94;
}

textarea:-ms-input-placeholder {
	color: #818e94;
}

/*star rating*/

.feed-star-rating {
	border-bottom: 1px solid #f5efef;
	padding-bottom: 7px;
	display: flex;
	justify-content: space-around;
	padding: 10px 0px 15px 0px;
}

.feed-star-rating svg {
	width: 45px;
	height: 45px;
	cursor: pointer;
	transition: all 0.3s;
}

.feed-star-rating svg.rating-1:hover path,
.feed-star-rating svg.rating-1.smile-active path {
	fill: #e63d3d !important;
}

.feed-star-rating svg.rating-2:hover path,
.feed-star-rating svg.rating-2.smile-active path {
	fill: #db7c1d !important;
}

.feed-star-rating svg.rating-3:hover path,
.feed-star-rating svg.rating-3.smile-active path {
	fill: #e2c41e !important;
}

.feed-star-rating svg.rating-4:hover path,
.feed-star-rating svg.rating-4.smile-active path {
	fill: #3b6ef8 !important;
}

.feed-star-rating svg.rating-5:hover path,
.feed-star-rating svg.rating-5.smile-active path {
	fill: #6cc04a !important;
}

.feed-star-rating svg:hover {
	transform: translateY(-10px);
	transition: all 0.3s;
}

.feed-star-rating svg.smile-active {
	transform: translateY(-10px);
	transition: all 0.3s;
}

.feed-star-rating img {
	width: 100%;
}

/*star rating end*/

.feed-text-modal p {
	font-size: 12px;
	margin-top: 10px;
	border-bottom: 1px solid #f5efef;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.custom-feed-btn-div {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.custom-btn-position {
	display: flex;
	justify-content: flex-end;
}

.custom-btn-position .feed-btn {
	background: none;
	border: none;
	padding: 8px 10px;
	cursor: pointer;
	font-weight: normal;
	color: #5d5d5d;
	font-size: 14px;
	min-width: 80px;
	border-radius: 2px;
}

.custom-btn-position .feed-btn:hover {
	background: #eeeeee;
}

.feed-btn-send {
	color: #fff !important;
	background: #3b6ef8 !important;
	margin-left: 5px;
}

.hide {
	display: none;
}

.text-center {
	text-align: center;
}

@media only screen and (max-width: 500px) {
	.icon-dropdown-content {
		position: fixed;
		min-width: 100% !important;
		top: 61px;
	}
}

/* new dropdown */

.select-box {
	padding-top: 12px;
	height: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: -moz-box;
	display: -moz-flex;
	display: flex;
	-webkit-box-align: center;
	-moz-box-align: center;
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-moz-flex-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	display: block;
	width: 100%;
	margin: 0 auto;
	font-family: "Open Sans", "Helvetica Neue", "Segoe UI", "Calibri", "Arial",
		sans-serif;
	font-size: 18px;
	color: #60666d;
}

.select-box:hover .select-box__list {
	opacity: 1;
	-webkit-animation-name: none;
	-moz-animation-name: none;
	-ms-animation-name: none;
	-o-animation-name: none;
	animation-name: none;
	visibility: visible;
}

.select-box__current {
	position: relative;
	cursor: pointer;
	outline: none;
	width: 50px;
}

.select-box:hover .select-box__icon {
	transform: translateY(-50%) rotate(0deg);
}

.select-box__icon {
	margin-top: 6px;
	transform: translateY(-50%) rotate(180deg);
	width: 10px;
	transition: 0.2s ease;
}

.select-box__value .select-box__input {
	display: none;
}

.select-box__value .select-box__input-text {
	display: none;
	width: 100%;
}

.select-box__value .select-box__input-text.selected-lang {
	display: block !important;
}

.select-box__value {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: -moz-box;
	display: -moz-flex;
	display: flex;
	width: 40px;
	padding-bottom: 15px;
}

.select-box__list {
	z-index: 999;
	position: absolute;
	visibility: hidden;
	width: 150px;
	padding: 8px 15px;
	list-style: none;
	opacity: 0;
	right: -10px;
	top: 100%;
	border-radius: 7px;
	-webkit-border-radius: 7px;
	overflow: hidden;
	background: #fff;
	-webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
	-moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
	-ms-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
	-o-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
	-webkit-animation-name: HideList;
	animation-name: HideList;
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-timing-function: step-start;
	animation-timing-function: step-start;
}

.select-box__option {
	display: block;
	padding: 0 10px 0;
	background-color: #fff;
	color: #2e2e2e;
}

.select-box__option:hover,
.select-box__option:focus {
	color: #3b6ef8 !important;
}
.goog-logo-link {
	display: none !important;
}
.goog-te-gadget {
	color: transparent !important;
	height: 35px !important;
	overflow-y: hidden;
	/* margin-bottom: -7px; */
	font-family: "Poppins", sans-serif;
	border: 0 !important;
	/* -webkit-box-shadow: 0 5px 18px rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 5px 18px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 18px rgb(0 0 0 / 10%);
    background-color: #fff; */
	border-radius: 4px;
}
.select-box__list.languagedropdown li a {
	display: flex !important;
	align-items: center !important;
}
:focus-visible {
	outline: -webkit-focus-ring-color auto 0px !important;
}
select:focus {
	border-color: transparent !important;
	outline: 0 !important;
	box-shadow: none !important;
}
.goog-te-gadget .goog-te-combo {
	margin: 8px !important;
}
select.google_translate_element {
	font-family: "Poppins", sans-serif;
	border: 0 !important;
}
.goog-te-gadget .goog-te-combo {
	font-family: "Poppins", sans-serif;
	border: 0 !important;
	width: 100px !important;
}
.goog-te-banner-frame {
	display: none !important;
}

@-webkit-keyframes HideList {
	from {
		transform: scaleY(1);
	}

	to {
		transform: scaleY(0);
	}
}

@keyframes HideList {
	from {
		transform: scaleY(1);
	}

	to {
		transform: scaleY(0);
	}
}
</style>