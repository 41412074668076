export default {
    tab: 'Reports',
    show_questionnaire: false,
    show_policy_report: false,
    current_cookieXray: {},
    current_cookieXray_id: 0,
    current_question_index: 0,
    question_counter: 1,
    questions: [{}],
    visible_types: ["text", "checkbox", "radiogroup", "dropdown"],
    total_questions: 0,
    answers: [
        [],
        {
          current_question_index: 0,
          question_counter: 0,
          next_question_index: 0,
          prev_question_index: 0,
        },
    ],
    progress: 0,
    is_last_question: false,
    show_error: false,
    error_message: "",
    prev_question_index: 0,
    is_done: false,
    next_question_index: 0,
    policy: "Loading Policy...",
    
}