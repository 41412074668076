<template>
	<div class="row" v-for="(cookie, index) in this.discovered_cookies" :key="index">
		<div class="col-10 col-sm-10 mx-auto block-style">
			<div  class="row align-items-center mb-3">
				<div
					
					class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
				>
					<h4  class="font-weight-bold notranslate">
						{{ cookie.name }}
					</h4>
				</div>
				<div
					
					class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
				>
					<div  class="row m-0 align-items-center">
						<label
							
							class="
								col-sm-12 col-12 col-md-6 col-lg-4 col-xl-4
								control-label
								text-left
								mb-3
								font-weight-bold
							"
							>Category:</label
						>
						<div
							
							class="col-sm-12 col-12 col-md-6 col-lg-8 col-xl-8 form-group"
						>
							<select
                                          name="cookie_consent"
                                          class="form-control bg-white"
                                          v-on:change="
                                      updateCategory(index, $event, true)
                                    "
                                         
                                  >
                                    <option
                                            :selected="
                                        cookie.cb_cat_id == 3 ? true : false
                                      "
                                            value="3"
                                     >Necessary</option
                                    >
                                    <option
                                            :selected="
                                        cookie.cb_cat_id == 4 ? true : false
                                      "
                                            value="4"
                                     >Preferences</option
                                    >
                                    <option
                                            :selected="
                                        cookie.cb_cat_id == 1 ? true : false
                                      "
                                            value="1"
                                     >Statistics</option
                                    >
                                    <option
                                            :selected="
                                        cookie.cb_cat_id == 2 ? true : false
                                      "
                                            value="2"
                                     >Marketing</option
                                    >
									<option 
											v-for="customCategory in custom_category" :value="customCategory.id" :selected="cookie.cb_cat_id == customCategory.id ? true : false">
            								{{ customCategory.customize_title }}
       								</option>
                                    <option
                                            :selected="
                                        cookie.cb_cat_id == 5 ? true : false
                                      "
                                            value="5"
                                     >Unclassified</option
                                    >
                                  </select>
						</div>
					</div>
				</div>
			</div>
			<div  class="row">
				<div  class="col-12">
					<div
						
						class="table-responsive"
					>
						<table
							
							class="datatable-responsive w-100"
						>
							<tbody >
								<tr  class="border-bottom">
									<td
										
										class="border-top-0 py-3 border-0 notranslate"
										style="width: 71%; white-space: nowrap"
									>
										First found URL: {{ cookie.first_found_url }}
									</td>
									<td
										
										class="border-top-0 py-3 border-0"
									>
										<label
											
											class="
												mb-0
												control-label
												d-flex
												align-items-center
											"
											style="white-space: nowrap"
											><input
												
												type="checkbox"
												name="is_cookie_banner"
												class="mr-2"
												style="
													width: 16px;
													height: 16px;
												"
												:checked="cookie.httponly"
                                                v-on:change="updateHttpOnly(index,$event,true)"
											/>
											<span
												
												class="label-text control-label"
											>
												HTTP Only</span
											></label
										>
									</td>
								</tr>
								<tr  class="border-bottom">
									<td
										
										class="py-3 border-0"
										style="white-space: nowrap"
									>
										Provider: {{ cookie.provider }}
									</td>
									<td  class="py-3 border-0">
										<label
											
											class="
												mb-0
												control-label
												d-flex
												align-items-center
											"
											style="white-space: nowrap"
											><input
												
												type="checkbox"
												name="is_cookie_banner"
												class="mr-2"
												style="
													width: 16px;
													height: 16px;
												"
												:checked="cookie.secure"
                                                v-on:change="updateSecureProperty(index,$event,true )"
											/>
											<span
												
												class="label-text control-label"
											>
												Secure</span
											></label
										>
									</td>
								</tr>
								<tr  class="border-bottom">
									<td
										
										class="py-3 border-0"
										style="white-space: nowrap"
									>
										Domain path: {{ cookie.domain_path }}
									</td>
									<td
										
										class="py-3 border-0"
										style="white-space: nowrap"
									>
										Expiry: {{ cookie.expiry }}
									</td>
								</tr>
								<tr  class="border-bottom">
									<td
										
										class="py-3 border-0"
										style="white-space: nowrap"
									>
										Country: {{ cookie.country }}
									</td>
									<td
										
										class="py-3 border-0"
										style="white-space: nowrap text-gray-dark"
									>
										Type: {{ cookie.type }}	
									</td>
								</tr>
								<tr >
									<td  class="p-3"></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div  class="row">
				<div  class="col-12 cookie_ex_tab">
					<p style ="word-wrap: break-word;">Example value: {{ cookie.example_value }}</p>
					<p class="mt-3">Cookie purpose description:</p>
					<div  class="form-group form-group-md">
						<label
							
							class="control-label text-left text-capitalize"
							style="
								background: rgb(108, 192, 74);
								padding: 7px 10px;
								margin-bottom: 0px;
								color: #fff !important;
								border-bottom: none;
								border-radius: 4px 4px 0px 0px !important;
							"
							>EN-English
						</label>
						<textarea
							
							name="cookies_body notranslate"
							maxlength="2000"
							placeholder=""
							class="form-control"
							style="
								height: 80px;
								border-radius: 0px;
								padding: 10px;
								border: 1px solid rgb(199, 199, 199);
								background: rgb(255, 255, 255) !important;
							" v-html="cookie.purpose_desc"
							v-on:change="updatePurpose(index, $event, true)"
						></textarea>
						<label
						class="control-label float-right"
						:class="{ 'disabled': shouldBeDisabled }"
						style="
							background: rgb(59, 110, 248);
							padding: 7px 10px;
							margin-bottom: 0px;
							color: #fff !important;
							border-bottom: none;
							margin-top: 15px;
							border-radius: 4px !important;
							cursor: pointer;
						"
						v-on:click="shouldBeDisabled ? null : updateCookie(cookie, true)"
						v-if="loading == false"
						>
						<i class="fa fa-save mr-2"></i>&nbsp;Update Cookie
						</label>

						<a class="btn btn-blue-bg float-right" style="
								background: rgb(59, 110, 248);
								padding: 7px 10px;
								margin-bottom: 0px;
								color:#fff !important;
								border-bottom: none;
                                margin-top:15px;
                                border-radius: 4px !important;
							"  v-else><i  class="fa fa-spinner fa-pulse fa-fw"></i></a>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
import { emitter } from "@/eventbus";
import { mapState } from 'vuex';
export default {
	name: "DiscoveredCookies",
	data() {
		return {
			loading: false,
		}
	},
	computed:{
		...mapState('domain', ['trackingLoader' , 'user' , 'assign_role']
		),
		domain_id: function () {
			return this.$store.getters['domain/getCurrentDomainId'];
		},
		discovered_cookies: function () {
			return this.$store.getters['domain/getDiscoveredCookies'];
		},
		self_declared_cookies: function () {
			return this.$store.getters['domain/getSelfDeclaredCookies'];
		},
		script: function () {
			return this.$store.getters["getCode/getScript"];
		},
		custom_category() {
            	return this.$store.state.domain.categories;
    },
	shouldBeDisabled() {
		return !this.user.isSuperAdmin && this.user.superAdminId !== null && this.assign_role === 'reader';
		},
	},
	methods: {
		updateCookie(cookie, hasDiscoveredCookie) {
			this.loading = true;
			let key = null;
			if (this.script) {
				key = this.script.key;
			}
			let payload = {
				domain_id: this.domain_id,
				cookie: cookie,
				cookie_id: cookie.id,
			};
			this.$store.dispatch("domain/updateCookie", payload)
			.then(() => {
				this.loading = false;
				emitter.emit('saveDialogueProperties');
			})
		},
		updateCategory(index, event, hasDiscoveredCookie) {
		if (hasDiscoveredCookie) {
			this.discovered_cookies[index].cb_cat_id = event.target.value;
		} 
		return;
		},
		updateHttpOnly(index, event, hasDiscoveredCookie) {
		if (hasDiscoveredCookie) {
			this.discovered_cookies[index].httponly = event.target.checked;
		} 
		return;
		},
		updateSecureProperty(index, event, hasDiscoveredCookie) {
		if (hasDiscoveredCookie) {
			this.discovered_cookies[index].secure = event.target.checked;
		} 
		return;
		},
		updatePurpose(index, event, hasDiscoveredCookie) {
		if (hasDiscoveredCookie) {
			this.discovered_cookies[index].purpose_desc = event.target.value;
		} 
		return;
		},
	},
};
</script>

<style scoped>
.label-text{
    font-size: 14px !important;
}
.disabled {
  opacity: 0.5; 
  pointer-events: none; 
  cursor: not-allowed;
}


</style>