<template>
	<div class="row">
		<div class="col-12">
			<h3 class="text-dark mb-4">Vendor manager</h3>
			<p class="d-block mb-4 pb-4 border-bottom text-gray-dark">
				Manage vendors by manually or auto blocking scripts and tags. Enabling this feature will block the cookies and tracking technologies of these vendors until a consent is given by the website user.
			</p>
		</div>
        <div class="col-12">
			<ul
				class="
					list-unstyled
                    border-bottom
					p-0
					mb-4
					d-flex
					align-items-center
					tab-list-get-code
				"
			>
				<li class="mb-4 mr-3">
					<a class="btn"
						@click="tab = 'ManuallyBlock'"
						:class="[tab === 'ManuallyBlock' ? 'btngreenbg' : 'btntabbg']"
						>Manually block vendors</a
					>
				</li>
				<li class="mb-4 mr-3">
					<a class="btn "
						@click="tab = 'AutoBlock'"
						:class="[
							tab === 'AutoBlock' ? 'btngreenbg' : 'btntabbg',
						]"
						>Auto block vendors
					</a>
				</li>
			</ul>
		</div>
        <div class="col-12">
			<component :is="tab" />
		</div>

	</div>
</template>

<script>
import ManuallyBlock from "@/components/CustoMiZationComponent/TrackingManagerParts/ManuallyBlock.vue";
import AutoBlock from "@/components/CustoMiZationComponent/TrackingManagerParts/AutoBlock.vue";
export default {
	name: "VendorManager",
	components: {
		ManuallyBlock,
        AutoBlock,
	},
	data() {
		return {
			tab: "ManuallyBlock",
		};
	},
};
</script>

<style scoped>
.btntabbg{
background: #E0E0E0;
color: #000;
}
.btngreenbg{
background: #6CC04A !important;
color:  #fff !important;
}
@media (max-width: 575.99px){
    .tab-list-get-code{
        flex-direction: column !important;
		margin-bottom: 15px !important;
    }
.tab-list-get-code li{
margin-right: 0 !important;
 margin-bottom: 15px !important;
 width: 100% !important;
}
.tab-list-get-code li a{
    width: 100% !important;
}
}

</style>