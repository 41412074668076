<template>
    <div v-if="current_banner.name === 'default' && current_banner.layout != 'preferenceLayout'" class="col-12 p-0">
        <p class="mb-3">Display Style</p>
        <ul class="list-unstyled mb-2 p-0 banner-btn-holder">
            <li v-if="current_banner.layout == 'defaultLayout'"
            class="mr-3 mb-2"
            @click="active_Tab('seers-cmp-banner-bar')"
            :class="[
      current_banner.position === 'seers-cmp-banner-bar' || 
      current_banner.position === 'seers-cmp-top-bar' || 
      current_banner.position === 'seers-cmp-top-hanging-bar' || 
      current_banner.position === 'seers-cmp-bottom-hanging-bar' 
      ? 'banneractive' : ''
    ]"
            >
            <button class="btn btn-banner">
                <img
                v-if="activeTab === 'BannerStyle'"
                src="@/assets/images/Banner-styles/BannerActive.svg"
                class="img-fluid mr-3"
                />
                <img
                v-else
                src="@/assets/images/Banner-styles/BannerNotActive.svg"
                class="img-fluid mr-3"
                />
                Banner
            </button>
            </li>
            <li
            class="mr-3 mb-2"
            @click="active_Tab('seers-cmp-middle-bar')"
            :class="[current_banner.position === 'seers-cmp-middle-bar' ? 'banneractive' : '']"
            >
            <button class="btn btn-banner">
                <img
                v-if="activeTab === 'ModalStyle'"
                src="@/assets/images/Banner-styles/ModalActive.svg"
                class="img-fluid mr-3"
                />
                <img
                v-else
                src="@/assets/images/Banner-styles/ModalNotActive.svg"
                class="img-fluid mr-3"
                />
                Modal
            </button>
            </li>
            <li
            class="mr-3 mb-2"
            @click="active_Tab('seers-cmp-top-left-bar')"
            :class="[current_banner.position === 'seers-cmp-top-left-bar' || current_banner.position === 'seers-cmp-left-bar' || current_banner.position === 'seers-cmp-top-right-bar' || current_banner.position === 'seers-cmp-right-bar' ? 'banneractive' : '']"
            >
            <button class="btn btn-banner">
                <img
                v-if="activeTab === 'TooltipStyle'"
                src="@/assets/images/Banner-styles/tooltipActive.svg"
                class="img-fluid mr-3"
                />
                <img
                v-else
                src="@/assets/images/Banner-styles/tooltipNotActive.svg"
                class="img-fluid mr-3"
                />
                Tooltip
            </button>
            </li>
        </ul>
    </div>
    <div v-if="current_banner.name === 'default' && current_banner.layout === 'preferenceLayout'" class="col-12 p-0">
        <p class="mb-3">Display Style</p>
        <ul class="list-unstyled mb-2 p-0 banner-btn-holder">
            <li 
            class="mr-3 mb-2"
            :class="[
      current_banner.layout === 'preferenceLayout'
      ? 'banneractive' : ''
    ]"
            >
            <button class="btn btn-banner">
                <img
                src="@/assets/images/Banner-styles/BannerActive.svg"
                class="img-fluid mr-3"
                />
                Banner
            </button>
            </li>
        </ul>
    </div>
    <div v-if="current_banner.name === 'universal'" class="col-12 p-0">
        <p class="mb-3">Display Style</p>
        <ul class="list-unstyled mb-2 p-0 banner-btn-holder">
            <li 
            class="mr-3 mb-2"
            :class="[
      current_banner.name === 'universal'
      ? 'banneractive' : ''
    ]"
            >
            <button class="btn btn-banner">
                <img
                src="@/assets/images/Banner-styles/ModalActive.svg"
                class="img-fluid mr-3"
                />
                Modal
            </button>
            </li>
        </ul>
    </div>
    <div class="col-12 p-0" v-if="current_banner.layout == 'defaultLayout' && 
    (current_banner.position === 'seers-cmp-banner-bar' || 
     current_banner.position === 'seers-cmp-top-bar' || 
     current_banner.position === 'seers-cmp-top-hanging-bar' || 
     current_banner.position === 'seers-cmp-bottom-hanging-bar')">

    <p class="mb-3">Position</p>
    <ul class="list-unstyled mb-2 p-0 banner-btn-holder">
        <li
        class="mr-3 mb-2"
        @click="changePosition('seers-cmp-top-bar')"
        :class="[
            current_banner.position === 'seers-cmp-top-bar'
            ? 'banneractive'
            : '',
        ]"
        >
        <button class="btn btn-banner">
            <img
            v-if="current_banner.position === 'seers-cmp-top-bar'"
            src="@/assets/images/Banner-styles/BannerTopActiveBlue.svg"
            class="img-fluid mr-3"
            />
            <img
            v-else
            src="@/assets/images/Banner-styles/BannerTopNotActive.svg"
            class="img-fluid mr-3"
            />
            Top
        </button>
        </li>
        <li
        class="mr-3 mb-2"
        @click="changePosition('seers-cmp-banner-bar')"
        :class="[
            current_banner.position === 'seers-cmp-banner-bar'
            ? 'banneractive'
            : '',
        ]"
        >
        <button class="btn btn-banner">
            <img
            v-if="current_banner.position === 'seers-cmp-banner-bar'"
            src="@/assets/images/Banner-styles/BannerBottomActiveBlue.svg"
            class="img-fluid mr-3"
            />
            <img
            v-else
            src="@/assets/images/Banner-styles/BannerBottomNotActive.svg"
            class="img-fluid mr-3"
            />
            Bottom
        </button>
        </li>
        <li
    class="mr-3 mb-2"
    @click="changePosition('seers-cmp-top-hanging-bar')"
    :class="[
        current_banner.position === 'seers-cmp-top-hanging-bar'
        ? 'banneractive'
        : ''
    ]"
>

    <button class="btn btn-banner">
        <img
        v-if="current_banner.position === 'seers-cmp-top-hanging-bar'"
        src="@/assets/images/Banner-styles/BannerTopActiveBlue.svg"
        class="img-fluid mr-3"
        />
        <img
        v-else
        src="@/assets/images/Banner-styles/BannerTopNotActive.svg"
        class="img-fluid mr-3"
        />
        Top Hanging
    </button>
</li>
<li
    class="mr-3 mb-2"
    @click="changePosition('seers-cmp-bottom-hanging-bar')"
    :class="[
        current_banner.position === 'seers-cmp-bottom-hanging-bar'
        ? 'banneractive'
        : ''
    ]"
>
    <button class="btn btn-banner">
        <img
        v-if="current_banner.position === 'seers-cmp-bottom-hanging-bar'"
        src="@/assets/images/Banner-styles/BannerBottomActiveBlue.svg"
        class="img-fluid mr-3"
        />
        <img
        v-else
        src="@/assets/images/Banner-styles/BannerBottomNotActive.svg"
        class="img-fluid mr-3"
        />
        Bottom Hanging
    </button>
</li>
    </ul>
    </div>
    <div class="col-12 p-0" v-if="current_banner.layout == 'preferenceLayout' || current_banner.name == 'universal'">

    <p class="mb-3">Position</p>
    <ul class="list-unstyled mb-2 p-0 banner-btn-holder">
        <li v-if="current_banner.layout == 'preferenceLayout'"
        class="mr-3 mb-2"
        @click="changePosition('seers-cmp-banner-preference-bar')"
        :class="[
            current_banner.position === 'seers-cmp-banner-preference-bar'
            ? 'banneractive'
            : '',
        ]"
        >
        <button class="btn btn-banner">
            <img
            v-if="current_banner.position === 'seers-cmp-banner-preference-bar'"
            src="@/assets/images/Banner-styles/BannerBottomActiveBlue.svg"
            class="img-fluid mr-3"
            />
            <img
            v-else
            src="@/assets/images/Banner-styles/BannerBottomNotActive.svg"
            class="img-fluid mr-3"
            />
            Bottom
        </button>
        </li>
        <li v-if="current_banner.layout == 'preferenceLayout'"
    class="mr-3 mb-2"
    @click="changePosition('seers-cmp-preference-bottom-hanging-bar')"
    :class="[
        current_banner.position === 'seers-cmp-preference-bottom-hanging-bar'
        ? 'banneractive'
        : ''
    ]"
>
    <button class="btn btn-banner">
        <img
        v-if="current_banner.position === 'seers-cmp-preference-bottom-hanging-bar'"
        src="@/assets/images/Banner-styles/BannerBottomActiveBlue.svg"
        class="img-fluid mr-3"
        />
        <img
        v-else
        src="@/assets/images/Banner-styles/BannerBottomNotActive.svg"
        class="img-fluid mr-3"
        />
        Bottom Hanging
    </button>
</li>
        <li v-if="current_banner.name == 'universal'"
        class="mr-3 mb-2"
        :class="[
            current_banner.name === 'universal'
            ? 'banneractive'
            : '',
        ]"
        >
        <button class="btn btn-banner">
            <img
            src="@/assets/images/Banner-styles/ModalActive.svg"
            class="img-fluid mr-3"
            />
            Modal
        </button>
        </li>
    </ul>
    </div>
    <div class="col-12 p-0" v-if="current_banner.position === 'seers-cmp-middle-bar'">
    <p class="mb-3">Position</p>
    <ul class="list-unstyled mb-2 p-0 banner-btn-holder">
        <li
        class="mr-3 mb-2"
        @click="changePosition('seers-cmp-middle-bar')"
        :class="[
            current_banner.position === 'seers-cmp-middle-bar'
            ? 'banneractive'
            : '',
        ]"
        >
        <button class="btn btn-banner">
            <img
            v-if="current_banner.position === 'seers-cmp-middle-bar'"
            src="@/assets/images/Banner-styles/ModalActive.svg"
            class="img-fluid mr-3"
            />
            <img
            v-else
            src="@/assets/images/Banner-styles/ModalNotActive.svg"
            class="img-fluid mr-3"
            />
            Modal
        </button>
        </li>
    </ul>
    </div>

    <div class="col-12 p-0" v-if="current_banner.name === 'default' && (current_banner.position === 'seers-cmp-top-left-bar' || current_banner.position === 'seers-cmp-left-bar' || current_banner.position === 'seers-cmp-top-right-bar' || current_banner.position === 'seers-cmp-right-bar')">
    <p class="mb-3">Position</p>
    <ul class="list-unstyled mb-2 p-0 banner-btn-holder">
        <li
        class="mr-3 mb-2"
        @click="changePosition('seers-cmp-top-left-bar')"
        :class="[
            current_banner.position === 'seers-cmp-top-left-bar'
            ? 'banneractive'
            : '',
        ]"
        >
        <button class="btn btn-banner">
            <img
            v-if="current_banner.position === 'seers-cmp-top-left-bar'"
            src="@/assets/images/Banner-styles/TooltipTopLeftActive.svg"
            class="img-fluid mr-3"
            />
            <img
            v-else
            src="@/assets/images/Banner-styles/TooltipTopLeftNotActive.svg"
            class="img-fluid mr-3"
            />
            Top Left
        </button>
        </li>
        <li
        class="mr-3 mb-2"
        @click="changePosition('seers-cmp-left-bar')"
        :class="[
            current_banner.position === 'seers-cmp-left-bar'
            ? 'banneractive'
            : '',
        ]"
        >
        <button class="btn btn-banner">
            <img
            v-if="current_banner.position === 'seers-cmp-left-bar'"
            src="@/assets/images/Banner-styles/TooltipBottomLeftActive.svg"
            class="img-fluid mr-3"
            />
            <img
            v-else
            src="@/assets/images/Banner-styles/TooltipBottomLeftNotActive.svg"
            class="img-fluid mr-3"
            />
            Bottom Left
        </button>
        </li>
        <li
        class="mr-3 mb-2"
        @click="changePosition('seers-cmp-top-right-bar')"
        :class="[
            current_banner.position === 'seers-cmp-top-right-bar'
            ? 'banneractive'
            : '',
        ]"
        >
        <button class="btn btn-banner">
            <img
            v-if="current_banner.position === 'seers-cmp-top-right-bar'"
            src="@/assets/images/Banner-styles/TooltipTopRightActive.svg"
            class="img-fluid mr-3"
            />
            <img
            v-else
            src="@/assets/images/Banner-styles/TooltipTopRightNotActive.svg"
            class="img-fluid mr-3"
            />
            Top right
        </button>
        </li>
        <li
        class="mr-3 mb-2"
        @click="changePosition('seers-cmp-right-bar')"
        :class="[
            current_banner.position === 'seers-cmp-right-bar'
            ? 'banneractive'
            : '',
        ]"
        >
        <button class="btn btn-banner">
            <img
            v-if="current_banner.position === 'seers-cmp-right-bar'"
            src="@/assets/images/Banner-styles/TooltipBottomRightActive.svg"
            class="img-fluid mr-3"
            />
            <img
            v-else
            src="@/assets/images/Banner-styles/TooltipBottomRightNotActive.svg"
            class="img-fluid mr-3"
            />
            Bottom Right
        </button>
        </li>
    </ul>
    </div>
    <div class="col-12 p-0">
    <p class="mb-3">Layouts</p>
    <ul class="list-unstyled mb-2 p-0 banner-btn-holder">
        <li
        class="mr-3 mb-2"
        :class="[
            current_banner.name === 'default' && 
            current_banner.layout === 'defaultLayout'
            ? 'banneractive'
            : '',
        ]"
        @click="changeLayout('default','defaultLayout')"
        >
        <button class="btn-banner bg-transparent border-0">
            <img
            src="@/assets/images/Banner-styles/default.svg" width="120"
            />
        </button>
        </li>
        <li
        class="mr-3 mb-2"
        :class="[
            current_banner.name === 'default' && 
            current_banner.layout === 'googleLayout'
            ? 'banneractive'
            : '',
        ]"
        @click="changeLayout('default','googleLayout')"
        >
        <button class="btn-banner bg-transparent border-0">
            <img   
            src="@/assets/images/Banner-styles/google_banner.svg" width="120"
            />
        </button>
        </li>
        <li
        class="mr-3 mb-2"
        :class="[
            current_banner.name === 'universal'
            ? 'banneractive'
            : '',
        ]"
        @click="changeBanner('universal')"
        >
        <button class="btn-banner bg-transparent border-0">
            <img   
            src="@/assets/images/Banner-styles/google_banner.svg" width="120"
            />
        </button>
        </li>
        <li
        class="mr-3 mb-2"
        :class="[
            current_banner.name === 'default' && 
            current_banner.layout === 'preferenceLayout'
            ? 'banneractive'
            : '',
        ]"
        @click="changeLayout('default','preferenceLayout')"
        >
        <button class="btn-banner bg-transparent border-0">
            <img
            src="@/assets/images/Banner-styles/default.svg" width="120"
            />
        </button>
        </li>
    </ul>
    </div>
</template>

<script>
export default {
    name: 'defaultBannerLayout',
    data() {
        return {
            activeTab: "",
        }
    },
    computed:{
        current_banner: function () {
          return this.$store.getters["domain/getCurrentBanner"];
        },
        banner_type: function () {
          return this.$store.state.domain.banner_type;
        },
        form: function () {
          return this.$store.state.dialogue.form;
        },
        banners: function () {
          return this.$store.state.domain.banners;
        },
        universal_banners: function() {
      return Object.values(this.banners).filter((banner) =>banner.name.includes("universal"));
    },
    },
    mounted() {
        this.banner_style();
    },
    methods: {
        changeLayout(bannerName,layout){
            let banner_found = false;
            this.form.banners.forEach((banner) => {
                if(banner.name === bannerName){
                    if(bannerName == 'default'){
                        if(layout == 'googleLayout'){
                            banner.is_active = true;
                            banner.layout = layout;
                            banner.position = 'seers-cmp-middle-bar';
                            banner.mainPositions = this.banners[banner.name].mainPositions;
                            banner.customizeColors = this.banners[banner.name].customizeColors;
                            this.activeTab = 'ModalStyle';
                            this.$store.commit("domain/setCurrentBanner", banner);
                            banner_found = true;
                        }
                        else if(layout == 'defaultLayout'){
                            banner.is_active = true;
                            banner.layout = layout;
                            banner.position = 'seers-cmp-banner-bar';
                            banner.mainPositions = this.banners[banner.name].mainPositions;
                            banner.customizeColors = this.banners[banner.name].customizeColors;
                            this.activeTab = 'BannerStyle';
                            this.$store.commit("domain/setCurrentBanner", banner);
                            banner_found = true;
                        }
                        else if(layout == 'preferenceLayout'){
                            banner.is_active = true;
                            banner.layout = layout;
                            banner.position = 'seers-cmp-banner-preference-bar';
                            banner.mainPositions = this.banners[banner.name].mainPositions;
                            banner.customizeColors = this.banners[banner.name].customizeColors;
                            this.activeTab = 'BannerStyle';
                            this.$store.commit("domain/setCurrentBanner", banner);
                            banner_found = true;
                        }
                    }
                }
                else{
                    banner.is_active = false;
                }
            })
            if (!banner_found) {
                if(layout == 'googleLayout'){
                    let banner = this.banners[bannerName];
                    banner.layout = layout;
                    banner.position = 'seers-cmp-middle-bar';
                    banner.mainPositions = this.banners[banner.name].mainPositions;
                    banner.customizeColors = this.banners[banner.name].customizeColors;
                    banner.is_active = true;
                    this.activeTab = 'ModalStyle';
                    this.$store.commit("domain/setCurrentBanner", banner);
                    banner_found = false;
                }
                else if(layout == 'defaultLayout'){
                    let banner = this.banners[bannerName];
                    banner.layout = layout;
                    banner.position = 'seers-cmp-banner-bar';
                    banner.mainPositions = this.banners[banner.name].mainPositions;
                    banner.customizeColors = this.banners[banner.name].customizeColors;
                    banner.is_active = true;
                    this.activeTab = 'BannerStyle';
                    this.$store.commit("domain/setCurrentBanner", banner);
                }
            }
            this.updateConsentSpecific();
            // if (!banner_found) {
            //     if(layout == 'googleLayout'){
            //         let banner = this.banners['default'];
            //         banner.is_active = true;
            //         banner.layout = 'googleLayout';
            //         banner.position = 'seers-cmp-middle-bar';
            //         this.form.banners.push(banner);
            //         this.$store.commit("domain/setCurrentBanner", banner);
            //     }
            //     else if(layout == 'defaultLayout'){
            //         let banner = this.banners['default'];
            //         banner.is_active = true;
            //         banner.layout = 'defaultLayout';
            //         banner.position = 'seers-cmp-banner-bar';
            //         this.form.banners.push(banner);
            //         this.$store.commit("domain/setCurrentBanner", banner);
            //     }
            // }
            // console.log('this.form',this.form)
        },
        updateConsentSpecific(event) {
          this.$store.commit("domain/setButtonIsDisabled", true);
        },
        displayAlert(){
          this.$store.commit("domain/setShowPricingPopup", true);
        },
        active_Tab(active){
            // console.log('this.current_banner.position',this.current_banner.position)
            // alert(active)
            if(active === 'seers-cmp-banner-bar' || active === 'seers-cmp-top-bar' || active === 'seers-cmp-top-hanging-bar' || active === 'seers-cmp-bottom-hanging-bar'){
                this.activeTab = 'BannerStyle';
            }
            if(active === 'seers-cmp-middle-bar'){
                this.activeTab = 'ModalStyle';
            }
            if(active === 'seers-cmp-top-left-bar' || active === 'seers-cmp-left-bar' || active === 'seers-cmp-top-right-bar' || active === 'seers-cmp-right-bar'){
                this.activeTab = 'TooltipStyle';
            }
            // this.activeTab = 'BannerStyle';
            this.current_banner.position = active;
            this.updateConsentSpecific();
        },
        banner_style: function(){
        if(this.current_banner.position === 'seers-cmp-top-bar' || this.current_banner.position === 'seers-cmp-banner-bar' || this.current_banner.position === 'seers-cmp-bottom-hanging-bar' || this.current_banner.position === 'seers-cmp-top-hanging-bar'){
            return this.activeTab = 'BannerStyle';
        }
        else if(this.current_banner.position === 'seers-cmp-middle-bar'){
            return this.activeTab = 'ModalStyle';
        }
        else if(this.current_banner.position === 'seers-cmp-top-left-bar' || this.current_banner.position === 'seers-cmp-left-bar' || this.current_banner.position === 'seers-cmp-top-right-bar' || this.current_banner.position === 'seers-cmp-right-bar'){
            return this.activeTab = 'TooltipStyle';
        }
        },
        changeBanner: async function (option) {
            let needToUpgrade = false;
            if (option === "universal") {

          this.bannerChange('universal');
          this.$store.commit('domain/banner_type','universal')
          this.$store.commit("domain/setButtonIsDisabled", true);
    }
        },
        bannerChange(model){
        let banner_found = false;
        this.form.banners.forEach((banner) => {
        if (banner.name === model) {
          banner.is_active = true;
          banner.mainPositions = this.banners[banner.name].mainPositions;
          banner.customizeColors = this.banners[banner.name].customizeColors;
          this.$store.commit("domain/setCurrentBanner", banner);
          banner_found = true;
        } else{
            banner.is_active = false;  
        }
        });
        if (!banner_found) {
        this.form.banners.push(this.banners[model]);
        this.$store.commit("domain/setCurrentBanner", this.banners[model]);
        }
        },
        changePosition(banner_position) {
            switch (banner_position) {
                
                    // default:
                    // this.bannerPositionClasses = "seers-cmp-banner-bar";
                    // this.current_banner.position = banner_position;
                    // break;
                    case "seers-cmp-banner-bar":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    // this.bannerPositionClasses = "seers-cmp-banner-bar";
                    break;
                    case "seers-cmp-top-bar":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    // this.bannerPositionClasses = "seers-cmp-top-bar";
                    break;
                    case "seers-cmp-bottom-hanging-bar":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    break;
                    case "seers-cmp-top-hanging-bar":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    break;
                    case "seers-cmp-middle-bar":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    // this.bannerPositionClasses = "seers-cmp-middle-bar";
                    break;
                    case "seers-cmp-top-left-bar":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    // this.bannerPositionClasses = "seers-cmp-top-left-bar";
                    break;
                    case "seers-cmp-top-right-bar":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    // this.bannerPositionClasses = "seers-cmp-top-right-bar";
                    break;
                    case "seers-cmp-left-bar":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    // this.bannerPositionClasses = "seers-cmp-left-bar";
                    break;
                    case "seers-cmp-right-bar":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    // this.bannerPositionClasses = "seers-cmp-right-bar";
                    break;
                    case "seers-cmp-banner-preference-bar":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    // this.bannerPositionClasses = "seers-cmp-right-bar";
                    break;
                    case "seers-cmp-preference-bottom-hanging-bar":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    // this.bannerPositionClasses = "seers-cmp-right-bar";
                    break;
            }
        },

    },

}
</script>

<style scoped>
    .banner-btn-holder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.banner-btn-holder li .btn.btn-banner img {
  width: 18px !important;
  height: 18px !important;
}
.banner-btn-holder li .btn.btn-banner {
  color: #000000;
  background: #ffffff;
  border: 1px solid #c6c7c9;
}
.banner-btn-holder li.banneractive .btn-banner {
  color: #3b6ef8 !important;
  background: #ecf1ff !important;
  border: 1px solid #3b6ef8 !important;
}
</style>