<template>
  <div>
      <theLoader></theLoader>
  </div>
</template>
<script>
import theLoader from "@/components/headerComponent/TheLoader.vue";
import { useRoute } from 'vue-router';
export default {
    name: 'LoginComponent',
    components: {
    theLoader,
  },
    async created() {
        await this.getCurrentUser();
        
    },
    methods: {
        getCurrentUser: async  function() {
          console.log('here in get user')
          this.$store.commit('reset')
          let param = this.$route.query.access_token;
            console.log('param--> '+param);
            await this.$store.dispatch('domain/getCurrentUser',param)
            .then(()=> {
                this.$router.push({
                  name: 'add-domain'
              })
            })
        },
    },

}
</script>

