export default {
    methods: {
        replaceVariableValues (source,question,val,what){
            //var new_source = source.replace(question,'true').Replace(val.value,what);
            //console.log('source:', source);
            var broken = [
                source.substr(0,val.var_start),
                'true',
                //source.substr(val.var_end,source.length),
                source.substr(val.var_end,val.value_start - val.var_end),
                what,
                source.substr(val.value_end,source.length),
            ];
            //console.log('broken:',broken)
            return broken.join('');
        },
        
        findVariableValue (source,search,values){
        
            var found = 0;
            var vals = [];
            // var  i = 0;
        
            while((found = source.indexOf(search,0)) !== -1){
                //console.log('Inside Find Variable in while loop Values source:',source);
                //console.log('Inside Find Variable in while loop Values search:',search);
                //console.log('Inside Find Variable in while loop Values values:',values);
                //console.log('Inside Find Variable in while loop Values found:',found);
                var val = {
                    var_start: found,
                    var_end: found + search.length,
                    value_start: source.indexOf("'",found+ search.length)+1,
                    value_end:0,
                    value:''
                };
                //val.value_start = source.indexOf("'",val.var_end)+1,
                val.value_end = source.indexOf("'",val.value_start+1);
                val.value = source.substr(val.value_start,val.value_end - val.value_start);
        
                //console.log('Val in iteration :', val)
        
        
                if(Array.isArray(values)){
                    //console.log('In array iteration:', values.includes(val.value));
                    //console.log('In array iteration:', val.value);
                    if(values.includes(val.value)){
                        //console.log('In array iteration if:', val.value);
                        source = this.replaceVariableValues(source,search,val,'true')
                    } else {
                        //console.log('In array iteration else:', val);
                        source = this.replaceVariableValues(source,search,val,'false')
                    }
                }else{
                    //console.log('In else iteration:', values == (val.value));
                    //console.log('In else iteration:', values);
                    if(values == val.value){
                        //console.log('In else if iteration:', val.value);
                        source = this.replaceVariableValues(source,search,val,true);
                        //console.log('In else source:', source);
                    } else {
                        source = this.replaceVariableValues(source,search,val,false);
                        //console.log('In else source:', source);
                    }
                }
                vals.push(val)
            }
            //console.log('vals:',vals);
            //console.log('source:',source);
            return source;
        },
        abc(){
            console.log('okokkokokokokkok')
        }
    },
}