<template>
    <div
        id="SeersCMPBannerMainBar"
        class="seers-cmp-banner-bar"
        :class="this.banner.position" :style="{fontFamily: this.banner.font_style + ' !important',background: this.banner.banner_bg_color + ' !important' }"
        >
        <div class="seers-cmp-cookie-policy-text">
        <!-- Add logo title banner -->
            <div class="seers-popup-header" v-if="this.banner.layout == 'googleLayout'">
              <div class="seers-client-logo-cc mb-3" v-if="dialogue.logo_status !== 'none'">
                  <img :src="this.dialogue.logo_link">
              </div>
              <h3 class="cookie_cc_heading" style="font-size: 12px !important; color : #000000 !important; margin-bottom: 10px !important; font-weight: bold !important;" :style="{color: this.banner.title_text_color + '!important',fontFamily: this.banner.font_style + '!important',}">{{this.language.title}}</h3>
            </div>
         <!--end Add logo title banner -->
         <h3 v-if="this.banner.position== 'seers-cmp-banner-bar' || this.banner.position== 'seers-cmp-top-bar'  || this.banner.position== 'seers-cmp-top-hanging-bar'  || this.banner.position== 'seers-cmp-bottom-hanging-bar' || this.banner.position== 'seers-cmp-banner-preference-bar' || this.banner.position== 'seers-cmp-preference-bottom-hanging-bar'" class="cookie_cc_heading" style="font-size: 12px !important; color : #000000 !important; margin-bottom: 10px !important; font-weight: bold !important;" :style="{color: this.banner.title_text_color + '!important',fontFamily: this.banner.font_style + '!important',}">{{this.language.title}}</h3>
        <p lang="en" class="seers-cmp-policy-desc" style="font-size:11px !important; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3;  -webkit-box-orient: vertical;" :style="{color: this.banner.body_text_color + ' !important',fontFamily: this.banner.font_style + ' !important',}" v-html="sanitizedBody" ></p>
        
           
        <div  v-if="banner.position === 'seers-cmp-banner-preference-bar' || this.banner.position== 'seers-cmp-preference-bottom-hanging-bar'" class="d-flex " style="width: 100%; margin-bottom: 5px; margin-top: 20px;">
                <div style="padding-right: 20px;" :style="{ color: this.banner.body_text_color, fontSize: '11px', textAlign: 'center' }">
                    <div style="display: inline-block; text-align: center;">
                    <div style="margin-bottom: 5px;" >Necessary</div>
                    <div class="seers_main_switch seers_switch mt-0" style="display: inline-flex; align-items: center;">
                    <input class="cmn-toggle cmp-toggle-round"  type="checkbox">
                    <label  :style="{'--visual-toggle': this.banner.agree_btn_color}" style="margin-left: 5px;"></label>
                    </div>
                </div>
            </div>
            <div style="padding-right: 20px;"  :style="{ color: this.banner.body_text_color, fontSize: '11px', textAlign: 'center' }">
                    <div style="display: inline-block; text-align: center;">
                    <div style="margin-bottom: 5px;">Preferences</div>
                    <div class="seers_main_switch seers_switch mt-0" style="display: inline-flex; align-items: center;">
                    <input class="cmn-toggle cmn-toggle-round" type="checkbox">
                    <label style="margin-left: 5px;"></label>
                    </div>
                </div>
            </div>
            <div style="padding-right: 20px;"  :style="{ color: this.banner.body_text_color, fontSize: '11px', textAlign: 'center' }">
                    <div style="display: inline-block; text-align: center;">
                    <div style="margin-bottom: 5px;">Statistics</div>
                    <div class="seers_main_switch seers_switch mt-0" style="display: inline-flex; align-items: center;">
                    <input class="cmn-toggle cmn-toggle-round" type="checkbox">
                    <label style="margin-left: 5px;"></label>
                    </div>
                </div>
            </div>
            <div style="padding-right: 20px;"  :style="{ color: this.banner.body_text_color, fontSize: '11px',  textAlign: 'center' }">
                    <div style="display: inline-block; text-align: center;">
                    <div style="margin-bottom: 5px;">Marketing</div>
                    <div class="seers_main_switch seers_switch mt-0" style="display: inline-flex; align-items: center;">
                    <input class="cmn-toggle cmn-toggle-round" type="checkbox">
                    <label style="margin-left: 5px;"></label>
                    </div>
                </div>
            </div>
            <a
            href="javascript:void(0);"
            lang="en"
            tabindex="0" style="font-size: 9px; margin-top: 20px;"

            id="SeersCMPBannerPreferenceButton"
            class="seers-cmp-preference-btn seers-cmp-default-style-btn"
            :style="{color: this.banner.agree_btn_color}"
        >
            Show details
        </a>
            </div>
        </div>
        
        <div id="btn_size" class="seers-cmp-cookie-policy-btn-hol mt-3" :style="{
            display: (this.banner.position === 'seers-cmp-top-hanging-bar' || this.banner.position === 'seers-cmp-bottom-hanging-bar' || this.banner.position === 'seers-cmp-preference-bottom-hanging-bar' ) && this.dialogue.allow_reject === true ? 'block !important' : 'flex !important'
            }">
        <a v-if="banner.layout !== 'preferenceLayout'"
            href="javascript:void(0);"
            lang="en"
            tabindex="0" :class="this.button_type" style="font-size:8px !important; font-weight:bold !important; min-width:80px !important; padding:4px 7px !important" :style="{border: '2px solid ' + this.banner.preferences_text_color + '!important', background:this.banner.preferences_btn_color + '!important', color: this.banner.preferences_text_color + ' !important',fontFamily: this.banner.font_style + ' !important',width: (this.banner.position === 'seers-cmp-top-hanging-bar' || this.banner.position === 'seers-cmp-bottom-hanging-bar') && this.dialogue.allow_reject === true ? '85px' : ''
            }"

            id="SeersCMPBannerPreferenceButton"
            class="seers-cmp-preference-btn seers-cmp-default-style-btn"
        >
            {{ this.language.btn_preference_title }}
        </a>
        <a v-if="banner.layout === 'preferenceLayout'"
            href="javascript:void(0);"
            lang="en"
            tabindex="0" :class="this.button_type" style="font-size:8px !important; font-weight:bold !important; min-width:80px !important; padding:4px 7px !important" :style="{border: '2px solid ' + this.banner.preferences_text_color + '!important', background:this.banner.preferences_btn_color + '!important', color: this.banner.preferences_text_color + ' !important',fontFamily: this.banner.font_style + ' !important',width: (this.banner.position === 'seers-cmp-top-hanging-bar' || this.banner.position === 'seers-cmp-bottom-hanging-bar') && this.dialogue.allow_reject === true ? '85px' : ''
            }"

            id="SeersCMPBannerPreferenceButton"
            class="seers-cmp-preference-btn seers-cmp-default-style-btn"
        >
            {{ this.language.btn_save_my_choices }}
        </a>
        <a
            href="javascript:void(0);" v-if="this.dialogue.allow_reject"
            id="SeersCMPBannerDisAgreeButtonForWidth"
            lang="en"
            tabindex="0" :class="this.button_type" style="font-size:8px !important; font-weight:bold !important; min-width:80px !important; padding:4px 7px !important; " :style="{border: '2px solid' + this.banner.disagree_btn_color + '!important', background: this.banner.disagree_btn_color + '!important', color: this.banner.disagree_text_color + ' !important',fontFamily: this.banner.font_style + ' !important',width: (this.banner.position === 'seers-cmp-top-hanging-bar' || this.banner.position === 'seers-cmp-bottom-hanging-bar') && this.dialogue.allow_reject === true ? '85px' : '',
              }"
            class="seers-cmp-btn seers-cmp-disagree-button seers-cmp-default-style-btn"
        >
            {{ this.language.btn_disagree_title }}</a
        >
        
        <a
            href="javascript:void(0);"
            id="SeersCMPBannerAgreeButtonForWidth"
            lang="en"
            tabindex="0" :class="this.button_type" style="font-size:8px !important; font-weight:bold !important; min-width:80px !important; padding:4px 7px !important" :style="{border: '2px solid ' + this.banner.agree_btn_color + ' !important',  background: this.banner.agree_btn_color + ' !important',color: this.banner.agree_text_color + ' !important',fontFamily: this.banner.font_style + ' !important',width: (this.banner.position === 'seers-cmp-top-hanging-bar' || this.banner.position === 'seers-cmp-bottom-hanging-bar') && this.dialogue.allow_reject === true ? '85px' : '',
              }"
            class="seers-cmp-btn seers-cmp-agree-button seers-cmp-default-style-btn"
        >
            {{ this.language.btn_agree_title }}
        </a>
        </div>  
        </div>
</template>

<script>
import { width } from '@amcharts/amcharts4/.internal/core/utils/Utils';
import DOMPurify from 'dompurify';
export default {
    name: "visualDefaultBanner",

    computed:{
        sanitizedBody() {
          return DOMPurify.sanitize(this.language.body);
        },
        banner: function() {
            return this.$store.getters['domain/getCurrentBanner'];
        },
        banner_type: function () {
            return this.$store.state.domain.banner_type;
        },
        language: function() {
            return this.$store.getters['domain/getCurrentLanguage'];
        },
        dialogue: function() {
            return this.$store.getters['domain/getDialogueProperties'];
        },
        button_type() {
            if (this.banner.button_type.includes("default"))
                return "seers-cmp-default-style-btn";
            else if (this.banner.button_type.includes("flat"))
                return "seers-cmp-flat-style-btn";
            else if (this.banner.button_type.includes("rounded"))
                return "seers-cmp-rounded-style-btn";
            else return "seers-cmp-stroke-style-btn";
        },
    }

}
</script>

<style scoped>
.seers-popup-header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 5px 0 5px 0;
} 
.seers-cmp-top-hanging-bar , .seers-cmp-bottom-hanging-bar{
  border-radius: 10px !important;
}
.seers-cmp-cookie-data-hol .seers-cmp-preference-bottom-hanging-bar {
    position: absolute;
    bottom: 0;
    margin: auto;
    top: auto;
    left: 0;
    right: 0;
    width: 70%;
    max-width: 100%;
    padding: 35px 25px;
    margin-bottom: 20px !important;
    border-radius: 20px !important;
    /* z-index: 9999999999999; */
    box-sizing: border-box;
    background: #fff;
    display: -moz-flex;
    display: flex;
    -moz-flex-align: center;
    align-items: center;
    -webkit-box-align: justify;
    -moz-box-align: justify;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    box-shadow: 0px -4px 19px rgba(0, 0, 0, 0.07);
    -webkit-box-shadow: 0px -4px 19px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0px -4px 19px rgba(0, 0, 0, 0.07);
    animation: seers-cmp-slide-in-bottom 1s both;
}
.seers-cmp-cookie-data-hol .seers-cmp-banner-preference-bar {
    position: absolute;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    padding: 20px 40px 20px;
    /* z-index: 9999999999999; */
    box-sizing: border-box;
    background: #fff;
    display: -moz-flex;
    display: flex;
    -moz-flex-align: center;
    align-items: center;
    -webkit-box-align: justify;
    -moz-box-align: justify;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    box-shadow: 0px -4px 19px rgba(0, 0, 0, 0.07);
    -webkit-box-shadow: 0px -4px 19px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0px -4px 19px rgba(0, 0, 0, 0.07);
    animation: seers-cmp-slide-in-bottom 1s both;
}

.seers_main_switch {
  float: right;
  margin-top: 10px;
}
.cmn-toggle-round + label {
  padding: 2px !important;
  width: 34px !important;
  height: 14px !important;
  background-color: #e3e6e8 !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
  border-radius: 30px !important;
}

.cmn-toggle-round + label::before,
.cmn-toggle-round + label::after {
  display: block !important;
  position: absolute !important;
  top: 1px !important;
  left: 1px !important;
  bottom: 1px !important;
  content: "" !important;
}

.cmn-toggle-round + label::before {
  right: 1px !important;
  color: #3B6Ef8 !important;
  font-size: 8px;
  background-color: #e3e6e8 !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
  border-radius: 30px !important;
  -webkit-transition: background 0.4s !important;
  -moz-transition: background 0.4s !important;
  -o-transition: background 0.4s !important;
  transition: background 0.4s !important;
}

.cmn-toggle-round + label::after {
  width: 16px !important;
  background-color: #ffffff !important;
  border-radius: 100% !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
  height: 16px;
  top: -1px !important;
  background-color: #ffffff !important;
  -webkit-border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -ms-border-radius: 100% !important;
  -o-border-radius: 100% !important;
  border-radius: 100% !important;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
  -webkit-transition: margin 0.4s !important;
  -moz-transition: margin 0.4s !important;
  -o-transition: margin 0.4s !important;
  transition: margin 0.4s !important;
}

.cmn-toggle-round:checked + label::before {
  background: #1e8e3e !important;
}
.cmn-toggle-round:checked + label::after {
  margin-left: 18px !important;
}

.cmn-toggle {
  position: absolute !important;
  margin-left: -9999px !important;
  visibility: hidden !important;
}
.cmn-toggle + label {
  display: block !important;
  position: relative !important;
  cursor: pointer !important;
  outline: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}


.cmp-toggle-round + label {
  padding: 2px !important;
  width: 30px !important;
  height: 14px !important;
  background-color: #e3e6e8;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
  border-radius: 30px !important;
}
.cmp-toggle-round + label::before,
.cmp-toggle-round + label::after {
  display: block !important;
  position: absolute !important;
  top: 1px !important;
  left: 19px !important;
  bottom: 1px !important;
  content: "" !important;
}
.cmp-toggle-round + label::before {
  right: 1px !important;
  color: #3B6Ef8 !important;
  font-size: 8px;
  /* background-color: #3b6ef8 !important; */
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
  border-radius: 30px !important;
  -webkit-transition: background 0.4s !important;
  -moz-transition: background 0.4s !important;
  -o-transition: background 0.4s !important;
  transition: background 0.4s !important;
}
.cmp-toggle-round + label::after {
  width: 16px !important;
    background-color: var(--visual-toggle) !important;
    border-radius: 100% !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
    height: 16px;
    top: -1px !important;
  -webkit-border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -ms-border-radius: 100% !important;
  -o-border-radius: 100% !important;
  border-radius: 100% !important;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
  -webkit-transition: margin 0.4s !important;
  -moz-transition: margin 0.4s !important;
  -o-transition: margin 0.4s !important;
  transition: margin 0.4s !important;
}

.disabled_switch input.cmn-toggle-round:checked + label::before {
  background-color: var(--switch-color) !important;
}


.seers-cmp-cookie-data-hol .seers-cmp-preference-bottom-hanging-bar .seers-cmp-cookie-policy-btn-hol {
    display: -moz-flex;
    display: flex;
    -moz-flex-align: center;
    align-items: center;
    margin: 0 -7.5px;
    flex: auto;
    transform: rotate(180deg);
}

.seers-cmp-cookie-data-hol .seers-cmp-preference-bottom-hanging-bar .seers-cmp-cookie-policy-btn-hol .seers-cmp-btn {
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    transform: rotate(180deg);
}

.seers-cmp-cookie-data-hol .seers-cmp-preference-bottom-hanging-bar .seers-cmp-cookie-policy-btn-hol .seers-cmp-preference-btn {
    border-radius: 4px;
    -webkit-border-radius: 4px;
     transform: rotate(180deg);
}
@media screen and (max-width: 991px) {
    .seers-cmp-cookie-data-hol .seers-cmp-banner-bar, .seers-cmp-cookie-data-hol .seers-cmp-banner-bar.seers-cmp-top-bar {
        -moz-flex-align: flex-start !important;
        align-items: flex-start !important;
        display: block;
    }
}
    @import '@/assets/css/BannerCss/DefaultBannerBox.css';
</style>