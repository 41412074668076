export const getProgress = (state) => {
    return state.progress;
}

export const getShowPolicyReport = (state) => {
    return state.show_policy_report;
}

export const getShowQuestionnaire = (state) => {
    return state.show_questionnaire;
}

export const getCurrentCookieXray =  (state) => {
    return state.current_cookieXray;
}

export const getCurrentQuestionIndex = (state) => {
    return state.current_question_index;
}


export const getQuestionCounter = (state) => {
    return state.question_counter;
}

export const getQuestion = (state) => {
    return state.questions;
}

export const getCurrentQuestion = (state) => {
    return state.questions[state.current_question_index];
}

export const getTotalQuestions = function(state) {
    return state.total_questions;
}

export const getShowError = (state) => {
    return state.show_error;
}

export const getCurrentAnswer = (state) => {
    if (
      state.answers.hasOwnProperty(0) &&
      state.answers[0].hasOwnProperty(state.current_question_index) &&
      state.answers[0][state.current_question_index].hasOwnProperty("answer")
    ){
      return state.answers[0][state.current_question_index].answer;
    }
    return false;
}

export const getErrorMessage = (state) => {
    return state.error_message;
}

export const getPrevQuestionIndex = (state) => {
    return state.prev_question_index;
}

export const is_last_question = (state) => {
    return state.is_last_question;
}

export const is_done = (state) => {
    return state.is_done;
}

export const getAllAnswers = (state) => {
    return state.answers;
}

export const getNextQuestionIndex = (state) => {
    return state.next_question_index;
}

export const getPolicy = (state) => {
    return state.policy;
  }