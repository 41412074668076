<template>
	<div>
		<div>
			<h3 class="text-blue mb-3">Cookie Consent Banner Script</h3>
			<p class="mb-2">Copy this script into your website's HTML header</p>
			<textarea
				class="scriptcode mb-0"
				placeholder="Describe yourself here..."
				id="GodaddyStep1"
				readonly><script data-key="{{ script && script.key }}" data-name="CookieXray" src="{{script_url}}" type="text/javascript"></script></textarea
			>
			<p class="font-italic mb-0">
				Note: Paste this before any other script in the HTML head tag
			</p>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step1')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<h3 class="text-blue mb-3">
				Cookie Consent Banner Integrations / Plugins Script
			</h3>
			<span class="d-block mb-3"
				>Input this ID in web builders plugins (WordPress, Joomla,
				Presta shop etc.)</span
			>
			<p class="mb-2">Domain Group ID:</p>
			<textarea
				class="scriptcode mb-3"
				placeholder="Describe yourself here..."
				id="GodaddyStep2"
				readonly>{{script && script.key}}</textarea>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step2')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<h3 class="text-blue mb-3">Cookie Policy Script</h3>
			<p class="mb-2">
				Copy code into your cookie policy URL to show default Cookie
				Policy.
			</p>
			<textarea
				class="scriptcode mb-3"
				placeholder="Describe yourself here..."
				id="GodaddyStep3"
				readonly><div id="seers-cookie-consent"></div></textarea>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step3')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<hr />
			<h3 class="text-blue my-5">
				Installation instructions for GoDaddy:
			</h3>

			<details open>
				<summary>Step 1</summary>
				<div>
					<p>Go to the homepage of your GoDaddy website & Login with credentials.</p>
					<img
						class="img-fluid"
						src="@/assets/images/godaddy-images/1.png"
					/>
				</div>
			</details>
			<div id="accordion" role="tablist">
				<details>
					<summary>Step 2</summary>
					<div>
						<p class="mb-1">You will land on your home page:</p>
			<p>1. Click <strong class="d-inline-block text-dark">"Pages &amp; Sections"</strong> button in ribbon appearing on Right side of the website. </p>

						<img
							class="img-fluid"
							src="@/assets/images/godaddy-images/3.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 3</summary>
					<div>
					<p class="mb-1">Next window will appear. Scroll down until you reach the <strong class="d-inline-block text-dark">" HTML"</strong> option:</p>
					<p class="mb-1">1. Click <strong class="d-inline-block text-dark">"HTML"</strong> button.</p>
					<p> 2. Click on <strong class="d-inline-block text-dark">"Add"</strong> button.</p>
						<img
							class="img-fluid"
							src="@/assets/images/godaddy-images/4.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 4</summary>
					<div>
						<p class="mb-1">Next page will open:</p>
		<ul class="pl-3"><li class="mb-1">1. Paste script you got from <a href="/business/seers-cookie-consent">Seers</a> in <strong>Custom Code</strong> field.</li> <li class="mb-1">2. You will see a preview of the banner, adjust according to requirement.</li> <li class="mb-1">3. Click the <strong class="d-inline-block text-dark">Publish</strong> button. </li></ul>
						<img
							class="img-fluid"
							src="@/assets/images/godaddy-images/5.png"
						/>
					</div>
				</details>

				<details class="mb-5">
					<summary>Step 5</summary>
					<div>
						<p>
Go to your website. You will see your banner.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/godaddy-images/6.png"
						/>
					</div>
				</details>
				
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
	name: "Godaddy",
	computed:{
		cmp_url(){
			return process.env.VUE_APP_BANNER_URL
		},
		script: function() {
			return this.$store.getters['getCode/getScript'];
		},
		script_url: function() {
			// return process.env.MIX_CB_HOST +'/'+ this.$store.getters.getUser.id +'/'+ this.$store.getters.getCurrentDomainId + "/cb.js";
			return this.cmp_url + '/banners/' + this.$store.state.domain.userId +'/'+ this.$store.getters['domain/getCurrentDomainId'] + "/cb.js";
		},
	},
	methods: {
		copyScript(script){
			if (script === "step1") {
				$("#GodaddyStep1").select();
			}

			if (script === "step2") {
				$("#GodaddyStep2").select();
			}

			if (script === "step3") {
				$("#GodaddyStep3").select();
			}
			
			Swal.fire({
				icon: "success",
				title: 'Code Copied',
				text: "Code copied successfully",
				timer: 4000,
				confirmButtonText: "Ok"
			});
			document.execCommand("copy");
		}
	},
};
</script>

<style scoped>
textarea:focus {
	outline: none !important;
}
::-webkit-scrollbar {
	display: none;
}
p span {
	font-size: 16px !important;
	font-weight: 500;
}
p {
	font-size: 15px !important;
	font-weight: 400;
}
.scriptcode {
	padding-inline-start: 10px;	
	padding-block-start: 0%;
	padding: auto;
}
#GodaddyStep3 {
	height: 50px;
	padding-left: 15%;
	padding-top: 1.3%;
}
#GodaddyStep2 {
	height: 50px;
	padding-left: 15%;
	padding-top: 1.3%;
}
</style>