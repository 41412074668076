<template>
    <div class="col-12 p-0">
        <p class="mb-3">Display Style</p>
        <ul class="list-unstyled mb-2 p-0 banner-btn-holder">
            
            <li
            class="mr-3 mb-2"
            @click="active_Tab('seers_cc_center')"
            :class="[current_banner.position === 'seers_cc_center' ? 'banneractive' : '']"
            >
            <button class="btn btn-banner">
                <img
                v-if="activeTab === 'ModalStyle'"
                src="@/assets/images/Banner-styles/ModalActive.svg"
                class="img-fluid mr-3"
                />
                <img
                v-else
                src="@/assets/images/Banner-styles/ModalNotActive.svg"
                class="img-fluid mr-3"
                />
                Modal
            </button>
            </li>
            <li
            class="mr-3 mb-2"
            @click="active_Tab('seers_cc_top_left')"
            :class="[current_banner.position === 'seers_cc_top_left' || current_banner.position === 'seers_cc_bottom' || current_banner.position === 'seers_cc_top_right' || current_banner.position === 'seers_cc_bottom_right' ? 'banneractive' : '']"
            >
            <button class="btn btn-banner">
                <img
                v-if="activeTab === 'TooltipStyle'"
                src="@/assets/images/Banner-styles/tooltipActive.svg"
                class="img-fluid mr-3"
                />
                <img
                v-else
                src="@/assets/images/Banner-styles/tooltipNotActive.svg"
                class="img-fluid mr-3"
                />
                Tooltip
            </button>
            </li>
        </ul>
    </div>
    <div class="col-12 p-0" v-if="current_banner.position === 'seers_cc_center'">
    <p class="mb-3">Position</p>
    <ul class="list-unstyled mb-2 p-0 banner-btn-holder">
        <li
        class="mr-3 mb-2"
        @click="changePosition('seers_cc_center')"
        :class="[
            current_banner.position === 'seers_cc_center'
            ? 'banneractive'
            : '',
        ]"
        >
        <button class="btn btn-banner">
            <img
            v-if="current_banner.position === 'seers_cc_center'"
            src="@/assets/images/Banner-styles/ModalActive.svg"
            class="img-fluid mr-3"
            />
            <img
            v-else
            src="@/assets/images/Banner-styles/ModalNotActive.svg"
            class="img-fluid mr-3"
            />
            Modal
        </button>
        </li>
    </ul>
    </div>

    <div class="col-12 p-0" v-if="current_banner.position === 'seers_cc_top_left' || current_banner.position === 'seers_cc_bottom' || current_banner.position === 'seers_cc_top_right' || current_banner.position === 'seers_cc_bottom_right'">
    <p class="mb-3">Position</p>
    <ul class="list-unstyled mb-2 p-0 banner-btn-holder">
        <li
        class="mr-3 mb-2"
        @click="changePosition('seers_cc_top_left')"
        :class="[
            current_banner.position === 'seers_cc_top_left'
            ? 'banneractive'
            : '',
        ]"
        >
        <button class="btn btn-banner">
            <img
            v-if="current_banner.position === 'seers_cc_top_left'"
            src="@/assets/images/Banner-styles/TooltipTopLeftActive.svg"
            class="img-fluid mr-3"
            />
            <img
            v-else
            src="@/assets/images/Banner-styles/TooltipTopLeftNotActive.svg"
            class="img-fluid mr-3"
            />
            Top Left
        </button>
        </li>
        <li
        class="mr-3 mb-2"
        @click="changePosition('seers_cc_bottom')"
        :class="[
            current_banner.position === 'seers_cc_bottom'
            ? 'banneractive'
            : '',
        ]"
        >
        <button class="btn btn-banner">
            <img
            v-if="current_banner.position === 'seers_cc_bottom'"
            src="@/assets/images/Banner-styles/TooltipBottomLeftActive.svg"
            class="img-fluid mr-3"
            />
            <img
            v-else
            src="@/assets/images/Banner-styles/TooltipBottomLeftNotActive.svg"
            class="img-fluid mr-3"
            />
            Bottom Left
        </button>
        </li>
        <li
        class="mr-3 mb-2"
        @click="changePosition('seers_cc_top_right')"
        :class="[
            current_banner.position === 'seers_cc_top_right'
            ? 'banneractive'
            : '',
        ]"
        >
        <button class="btn btn-banner">
            <img
            v-if="current_banner.position === 'seers_cc_top_right'"
            src="@/assets/images/Banner-styles/TooltipTopRightActive.svg"
            class="img-fluid mr-3"
            />
            <img
            v-else
            src="@/assets/images/Banner-styles/TooltipTopRightNotActive.svg"
            class="img-fluid mr-3"
            />
            Top right
        </button>
        </li>
        <li
        class="mr-3 mb-2"
        @click="changePosition('seers_cc_bottom_right')"
        :class="[
            current_banner.position === 'seers_cc_bottom_right'
            ? 'banneractive'
            : '',
        ]"
        >
        <button class="btn btn-banner">
            <img
            v-if="current_banner.position === 'seers_cc_bottom_right'"
            src="@/assets/images/Banner-styles/TooltipBottomRightActive.svg"
            class="img-fluid mr-3"
            />
            <img
            v-else
            src="@/assets/images/Banner-styles/TooltipBottomRightNotActive.svg"
            class="img-fluid mr-3"
            />
            Bottom Right
        </button>
        </li>
    </ul>
    </div>
     <div class="col-12 p-0" v-if="form.extro_banner == true">
        <p class="mb-3">Layouts</p>
        <ul class="list-unstyled mb-2 p-0 banner-btn-holder">
            <li
            class="mr-3 mb-2"
            :class="[
                current_banner.layout === 'ccpaLayout'
                ? 'banneractive'
                : '',
            ]" @click="changeLayout('ccpaLayout')"
            >
            <button class="btn-banner bg-transparent border-0">
                <img
                src="@/assets/images/Banner-styles/side_one.svg" width="120"
                />
            </button>
            </li>
            <li
            class="mr-3 mb-2"
            :class="[
                current_banner.layout === 'exerto'
                ? 'banneractive'
                : '',
            ]" @click="changeLayout('exertoLayout')"
            >
            <button class="btn-banner bg-transparent border-0">
                <img
                src="@/assets/images/Banner-styles/side_one.svg" width="120"
                />
            </button>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'defaultBannerLayout',
    data() {
        return {
            activeTab: "ModalStyle",
        }
    },
    computed:{
        current_banner: function () {
          return this.$store.getters["domain/getCurrentBanner"];
        },
        banner_type: function () {
          return this.$store.state.domain.banner_type;
        },
        form: function () {
          return this.$store.state.dialogue.form;
        },
        banners: function () {
          return this.$store.state.domain.banners;
        },
    },
    mounted() {
        this.banner_style();
    },
    methods: {
        changeLayout(layout){
            alert(layout);
            // return;
            // let banner_found = false;
            // console.log('this.form111',this.form)
            this.form.banners.forEach((banner) => {
                if(banner.name == 'side_one_ccpa'){
                    if(layout == 'ccpaLayout'){
                        console.log('3')
                        banner.is_active = true;
                        banner.layout = layout;
                        banner.position = 'seers_cc_center';
                        banner.mainPositions = this.banners[banner.name].mainPositions;
                        banner.customizeColors = this.banners[banner.name].customizeColors;
                        this.activeTab = 'ModalStyle';
                        this.$store.commit("domain/setCurrentBanner", banner);
                    }
                }
                else if(layout == 'exertoLayout'){
                    console.log('4')
                    banner.is_active = true;
                    banner.layout = layout;
                    banner.position = 'seers_cc_center';
                    banner.mainPositions = this.banners[banner.name].mainPositions;
                    banner.customizeColors = this.banners[banner.name].customizeColors;
                    this.activeTab = 'ModalStyle';
                    this.$store.commit("domain/setCurrentBanner", banner);
                }
                else{
                    banner.is_active = false;
                }
                console.log('this.form.banners', this.form)
            })
            this.updateConsentSpecific();
            // if (!banner_found) {
            //     if(layout == 'googleLayout'){
            //         let banner = this.banners['default'];
            //         banner.is_active = true;
            //         banner.layout = 'googleLayout';
            //         banner.position = 'seers-cmp-middle-bar';
            //         this.form.banners.push(banner);
            //         this.$store.commit("domain/setCurrentBanner", banner);
            //     }
            //     else if(layout == 'defaultLayout'){
            //         let banner = this.banners['default'];
            //         banner.is_active = true;
            //         banner.layout = 'defaultLayout';
            //         banner.position = 'seers-cmp-banner-bar';
            //         this.form.banners.push(banner);
            //         this.$store.commit("domain/setCurrentBanner", banner);
            //     }
            // }
            // console.log('this.form',this.form)
        },
        updateConsentSpecific(event) {
          this.$store.commit("domain/setButtonIsDisabled", true);
        },
        displayAlert(){
          this.$store.commit("domain/setShowPricingPopup", true);
        },
        active_Tab(active){
            if(active === 'seers_cc_center'){
                this.activeTab = 'ModalStyle';
            }
            if(active === 'seers_cc_top_left' || active === 'seers_cc_bottom' || active === 'seers_cc_top_right' || active === 'seers_cc_bottom_right'){
                this.activeTab = 'TooltipStyle';
            }
            // this.activeTab = 'BannerStyle';
            this.current_banner.position = active;
            this.updateConsentSpecific();
        },
        banner_style: function(){
        if(this.current_banner.position === 'seers_cc_center'){
            return this.activeTab = 'ModalStyle';
        }
        else if(this.current_banner.position === 'seers_cc_top_left' || this.current_banner.position === 'seers_cc_bottom' || this.current_banner.position === 'seers_cc_top_right' || this.current_banner.position === 'seers_cc_bottom_right'){
            return this.activeTab = 'TooltipStyle';
        }
        },
        changePosition(banner_position) {
            switch (banner_position) {
                
                    // default:
                    // this.bannerPositionClasses = "seers-cmp-banner-bar";
                    // this.current_banner.position = banner_position;
                    // break;
                    case "seers_cc_center":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    // this.bannerPositionClasses = "seers-cmp-middle-bar";
                    break;
                    case "seers_cc_top_left":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    // this.bannerPositionClasses = "seers_cc_top_left";
                    break;
                    case "seers_cc_top_right":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    // this.bannerPositionClasses = "seers_cc_top_right";
                    break;
                    case "seers_cc_bottom":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    // this.bannerPositionClasses = "seers-cmp-left-bar";
                    break;
                    case "seers_cc_bottom_right":
                    this.current_banner.position = banner_position;
                    this.updateConsentSpecific();
                    // this.bannerPositionClasses = "seers_cc_bottom_right";
                    break;
            }
        },

    },

}
</script>

<style scoped>
     .banner-btn-holder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.banner-btn-holder li .btn.btn-banner img {
  width: 18px !important;
  height: 18px !important;
}
.banner-btn-holder li .btn.btn-banner {
  color: #000000;
  background: #ffffff;
  border: 1px solid #c6c7c9;
}
.banner-btn-holder li.banneractive .btn.btn-banner {
  color: #3b6ef8 !important;
  background: #ecf1ff !important;
  border: 1px solid #3b6ef8 !important;
}
.banner-btn-holder li.banneractive .btn-banner img{
     border: 1px solid #3b6ef8 !important;
}
</style>