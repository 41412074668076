<template>
	
	<div  class="row">
        	<div class="col-12">
			<ul
				class="
					list-unstyled
                    border-bottom
					p-0
mb-4
					d-flex
					align-items-center
					tab-list-get-code
				"
			>
				<li class="mb-4 mr-3">
					<a class="btn"
						@click="tab = 'VendorManager'"
						:class="[tab === 'VendorManager' ? 'btngreenbg' : 'btntabbg']"
						>Vendor Manager</a
					>
				</li>
				<li class="mb-4 mr-3">
					<a class="btn "
						@click="tab = 'CookieManager'"
						:class="[
							tab === 'CookieManager' ? 'btngreenbg' : 'btntabbg',
						]"
						>Cookie Manager
					</a>
				</li>
			</ul>
		</div>
		<div class="col-12">
			<component  :is="tab" />
		</div>

	</div>
</template>

<script>
import VendorManager from "@/components/CustoMiZationComponent/TrackingManagerParts/VendorManager.vue";
import CookieManager from "@/components/CustoMiZationComponent/TrackingManagerParts/CookieManager.vue";
import { mapState } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { tabComponent } from "@/store/modules/policy/mutations";
export default {
	name: "TrackingManager",
	components: {
		VendorManager,
        CookieManager,
		PulseLoader,
	},
	data() {
		return {
			tab: "VendorManager",
			mounted:false
		};
	},
	computed: {
		...mapState('domain',['inLoader']),
		domain_id: function () {
			return this.$store.getters['domain/getCurrentDomainId'];
		},
    },
	mounted() {
		// this.filterScripts();
	},
	// watch:{
	// 	domain_id: {
	// 		// deep: true,
	// 		handler(){
	// 			this.filterScripts()
	// 		}
	// 	}
	// },
	methods: {
		filterScripts: function (page = 1) {
			//this.$store.commit('domain/inLoader', true)
			let payload = {
				domain_id:this.domain_id,
				page:page
			};
			this.$store.dispatch('domain/filterScripts',payload)
			.then(()=>{
				//this.$store.commit('domain/inLoader', false)
				this.mounted = true
			})
        }
	},
};
</script>

<style scoped>
.btntabbg{
background: #E0E0E0;
color: #000;
}
.btngreenbg{
background: #3b6ef8 !important;
color:  #fff !important;
}
@media (max-width: 575.99px){
    .tab-list-get-code{
        flex-direction: column !important;
		 margin-bottom: 15px !important;
    }
.tab-list-get-code li{
margin-right: 0 !important;
 margin-bottom: 15px !important;
 width: 100% !important;
}
.tab-list-get-code li a{
    width: 100% !important;
}
}

</style>