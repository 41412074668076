<template>
	<div class="row">
        	<div class="col-12">
			<ul
				class="
					list-unstyled
                    border-bottom
					pb-0
mb-4
					d-flex
					align-items-center
					tab-list-get-code tab-list-get-codetwo
				"
			>
							<li class="mb-4 mr-3">
					<a class="btn "
						@click="tab = 'Plugins'"
						:class="[
							tab === 'Plugins' ? 'btngreenbg' : 'btntabbg',
						]"
						>Plugins 
					</a>
				</li>
				<li class="mb-4 mr-3">
					<a class="btn"
						@click="tab = 'Manual'"
						:class="[tab === 'Manual' ? 'btngreenbg' : 'btntabbg']"
						>Manual </a
					>
				</li>

			</ul>
		</div>
        	<div class="col-12">
			<component :is="tab" />
		</div>
	</div>
</template>

<script>
import Manual from "@/components/Integrations/PluginsIntegration/Manual.vue";
import Plugins from "@/components/Integrations/PluginsIntegration/Plugins.vue";
export default {
	name: "PluginsInstalation",
	components: {
		Manual,
        Plugins,
	},
	data() {
		return {
			tab: "Plugins",
		};
	},
};
</script>

<style scoped>
.btntabbg{
background: #E0E0E0;
color: #000;
}
.btngreenbg{
background: #6CC04A !important;
color:  #fff !important;
}
@media (max-width: 575.99px){
	.tab-list-get-code.tab-list-get-codetwo{
		 flex-direction: row !important;
		 margin-bottom: 15px !important;
	}
    .tab-list-get-code{
        flex-direction: column !important;
		margin-bottom: 15px !important;
    }
	
.tab-list-get-code li{
margin-right: 0 !important;
margin-bottom: 15px !important;
 width: 100% !important;
}
.tab-list-get-code li a{
    width: 100% !important;
}
}

</style>