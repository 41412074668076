<template>
	<div class="row">
		<div class="col-12">
			<ul
				class="
					list-unstyled
					border-bottom
					pb-4
					mb-4
					d-flex
					flex-wrap
					align-items-center
					tab-list-get-code
				"
			>
				<li>
					<a
						class="btn"
						@click="tab = 'Dcart'"
						:class="[tab === 'Dcart' ? 'btnbluebg' : '']"
						><img
							src="@/assets/images/3dcart.png"
							style="width: 60px"
						/>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'BigCommerce'"
						:class="[tab === 'BigCommerce' ? 'btnbluebg' : '']"
						><img
							src="@/assets/images/BigCommerce-logo-Cm.png"
							style="width: 75px"
						/>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'Blogger'"
						:class="[tab === 'Blogger' ? 'btnbluebg' : '']"
						><img
							src="@/assets/images/blogger-CMS.png"
							style="width: 50px"
						/>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'Ecwid'"
						:class="[tab === 'Ecwid' ? 'btnbluebg' : '']"
						><img
							src="@/assets/images/Ecwid.png"
							style="width: 80px"
						/>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'Godaddy'"
						:class="[tab === 'Godaddy' ? 'btnbluebg' : '']"
						><img
							src="@/assets/images/GoDaddy_logo_CMS.png"
							style="width: 80px"
						/>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'LightSpeed'"
						:class="[tab === 'LightSpeed' ? 'btnbluebg' : '']"
						><img
							src="@/assets/images/lightspeed-logo-CMS.png"
							style="width: 80px"
						/>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'SquareSpace'"
						:class="[tab === 'SquareSpace' ? 'btnbluebg' : '']"
						><img
							src="@/assets/images/Squarespace-CMS.png"
							style="width: 80px"
						/>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'torny'"
						:class="[tab === 'torny' ? 'btnbluebg' : '']"
						><img
							src="@/assets/images/storenvy-logo-CMS.png"
							style="width: 75px"
						/>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'Weebly'"
						:class="[tab === 'Weebly' ? 'btnbluebg' : '']"
						><img
							src="@/assets/images/weebly-Logo-CMS.png"
							style="width: 70px"
						/>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'Wix'"
						:class="[tab === 'Wix' ? 'btnbluebg' : '']"
						><img
							src="@/assets/images/wix-logo-CMS.png"
							style="width: 70px"
						/>
					</a>
				</li>
			</ul>
		</div>
		<div class="col-12">
			<component :is="tab" />
		</div>
	</div>
</template>

<script>
import Dcart from "@/components/Integrations/PluginsIntegration/ManualChild/Dcart.vue";
import BigCommerce from "@/components/Integrations/PluginsIntegration/ManualChild/BigCommerce.vue";
import Blogger from "@/components/Integrations/PluginsIntegration/ManualChild/Blogger.vue";
import Ecwid from "@/components/Integrations/PluginsIntegration/ManualChild/Ecwid.vue";
import Godaddy from "@/components/Integrations/PluginsIntegration/ManualChild/Godaddy.vue";
import LightSpeed from "@/components/Integrations/PluginsIntegration/ManualChild/LightSpeed.vue";
import SquareSpace from "@/components/Integrations/PluginsIntegration/ManualChild/SquareSpace.vue";
import torny from "@/components/Integrations/PluginsIntegration/ManualChild/torny.vue";
import Weebly from "@/components/Integrations/PluginsIntegration/ManualChild/Weebly.vue";
import Wix from "@/components/Integrations/PluginsIntegration/ManualChild/Wix.vue";
export default {
	name: "Manual",
	components: {
		Dcart,
		BigCommerce,
		Blogger,
		Ecwid,
		Godaddy,
		LightSpeed,
		SquareSpace,
		torny,
		Weebly,
		Wix,
	},
	data() {
		return {
			tab: "Dcart",
		};
	},
};
</script>

<style scoped>
.tab-list-get-code {
	grid-gap: 20px;
}
.tab-list-get-code li a {
	width: 96px;
	height: 96px;
	border-radius: 4px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	transition: all 0.3s ease-in-out;
	border: 1px solid rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
	-moz-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
	-ms-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
	-o-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
}
.tab-list-get-code li a:hover {
	border: 1.5px solid #3b6ef8;
	transform: scale(1.1);
}
.tab-list-get-code li a.btnbluebg {
	border: 2px solid #3b6ef8;
	transform: scale(1.1);
}
.tab-list-get-code li a .pre-tooltip-text {
	white-space: break-spaces;
	visibility: hidden;
	width: 120px;
	background-color: #292929;
	color: #fff;
	text-align: left;
	border-radius: 4px;
	padding: 5px;
	position: absolute;
	bottom: 115%;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 10px;
	line-height: 15px !important;
}
.tab-list-get-code li a img {
	width: 50px;
}
.tab-list-get-code li a .pre-tooltip-text:after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	border-width: 7px;
	border-style: solid;
	border-color: #292929 transparent transparent transparent;
}
.tab-list-get-code li a:hover .pre-tooltip-text {
	visibility: visible;
	opacity: 1;
}
@media (max-width: 766.99px) {
	.tab-list-get-code {
		justify-content: space-evenly;
	}
}
</style>