
import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router/index.js'
import '@/assets/css/bootstrap.min.css'
import '@/assets/css/main-style.css'
import '@/assets/js/jquery-3.6.0.min.js'
import '@/assets/js/popper.min.js'
import '@/assets/js/bootstrap.bundle.min.js'
import '@/assets/js/custom.js'
import store from '@/store'
import {eventbus} from '@/eventbus';
import regxFile from "../src/mixins/regex"
import 'vue-toast-notification/dist/theme-sugar.css';
import VueProgressBar from "@aacassandra/vue3-progressbar";
window.$ = window.jQuery = require("jquery");
const options = {
    color: "#6cc04a",
    failedColor: "#874b4b",
    thickness: "3px",
    transition: {
      speed: "0.2s",
      opacity: "0.7s",
      termination: 300,
    },
    // autoRevert: true,
    // location: "left",
    // inverse: false,
  };
// createApp(App).use(router).use(store).mount('#app')
const app = createApp(App).use(eventbus).use(store).use(router).use(VueProgressBar, options).mixin(regxFile)
router.isReady().then(() => {
    app.mount('#app')
})
// .mount('#app')
