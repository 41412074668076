<template>
    <div>
        <div  v-bind:style="{fontFamily :current_banner.font_style  + ' !important', background: current_banner.banner_bg_color}" class="cookie_box seers-cx-show" v-bind:class="current_banner.position">
            <div class="cookie_text_box mb-0">
                <div class="d-flex align-items-center mb-1">
                <div class="seers_logo" v-if="dialogueProperties.logo_status !== 'none'" v-bind:style="{background :current_banner.logo_bg_color}"><img style="width:35px !important;" :src="this.dialogueProperties.logo_link"></div>
                <h2 class="cookie_title" v-bind:style="{color :current_banner.title_text_color}" style="font-size:11px !important;"> {{this.language.cpra_title ? this.language.cpra_title : this.language.title}}</h2>
                </div>
                <p id="seers-cookie-body-1" class="cookie_text mb-1" style="font-size:11px !important; text-overflow: ellipsis;white-space: nowrap;display: block;overflow: hidden; margin-bottom:7px !important;" v-bind:style="{color :current_banner.body_text_color + ' !important'}" v-html="this.language.cpra_body ? this.language.cpra_body : this.language_body_1"></p>
              <div class="d-flex align-items-center justify-content-between mb-1">
                <span class="seers_sub_heading mt-0" v-bind:style="{color :current_banner.body_text_color + ' !important'}">Targeting / Advertising</span>
                <div class="seers_main_switch seers_switch mt-0">
                    <input  class="cmn-toggle cmn-toggle-round" type="checkbox">
                    <label ></label>
                </div>
              </div>
            </div>
            <div class="button_box mt-0">
                <div class="deital_button">
                    <a  v-if="dialogueProperties.cookie_policy_url !== null && dialogueProperties.cookie_policy_url !== ''"  v-bind:style="{color :current_banner.body_text_color + ' !important'}" style="font-size:11px !important;">{{language.policy}}</a> <span v-if="dialogueProperties.cookie_policy_url !== null && dialogueProperties.cookie_policy_url !== ''" v-bind:style="{color :current_banner.body_text_color + ' !important'}" style="font-size:11px !important;">|</span> <a  v-bind:style="{color :current_banner.body_text_color + ' !important'}" style="font-size:11px !important;" id="seers-cx-show-details" disabled>{{language.cpra_btn_preference_title ? language.cpra_btn_preference_title : language.btn_preference_title}}</a>
                </div>
                <div class="button_postion">
                    <button  type="button" class="cookie_btn cookie_btn_open cookie_seers_open_btn " v-bind:class="[this.agreeBtnSpecific, current_banner.button_type]" v-bind:style="{background :current_banner.agree_btn_color + ' !important',color :current_banner.agree_text_color + ' !important'}" style="font-size:11px !important;" disabled>
                        {{language.cpra_btn_agree_title ? language.cpra_btn_agree_title : language.btn_agree_title}}
                    </button>
                </div>
            </div>
                  <div class="cookie_text_box mb-0  mt-1 pt-1 border-top" v-if="form.email_section">
                <p style="font-size:11px !important; text-overflow: ellipsis;white-space: nowrap;display: block;overflow: hidden; margin-bottom:7px !important;" id="seers-cookie-body-1" class="cookie_text" v-bind:style="{color :current_banner.body_text_color + ' !important'}" >{{this.language.cpra_email_section_title ? this.language.cpra_email_section_title : this.language.email_section_title}}</p>
                    <div class="d-flex align-items-center">
                        <label class="email_heading mb-2 mr-2">Email:</label>
                        <input id="email_user" type="email" disabled class="form-control mb-2" style="height:25px !important;">
                    </div>
                    <div class="d-flex align-items-center justify-content-end"> 
                            <button  disabled type="button" class="cookie_btn cookie_btn_open cookie_seers_open_btn seers-cx-show-details" v-bind:class="[this.agreeBtnSpecific, current_banner.button_type]" v-bind:style="{background :current_banner.agree_btn_color + ' !important',color :current_banner.agree_text_color + ' !important'}">
                        Submit
                    </button>
                    </div>
            </div>
        </div>
        <!-- </div> -->
        <!-- Dialogue Preview End -->

        <!--Tag setting-->
        <div class="seers_cc_tag_seeting seers_cc_bottom " v-if="dialogueProperties.has_badge" style="background-image: url(/images/design-new/seersco-thumb-scan.svg);
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 15px;">
            
        </div>
        <!--Tag Setting End-->
    </div>
</template>
<script>
import DOMPurify from 'dompurify';
export default {
    name: "visualCcpaBanner",
    data(){
            return {
                
                readMoreBtnSpecific: '',
                agreeBtnSpecific: '',
                disAgreeBtnSpecific: '',
                arrow_icon: '&#8897;',
                toggle_one: false,
                toggle_two: false,
                anchor_tag_one : '',
                cookie_body_el_one : '',
                anchor_tag_two : '',
                cookie_body_el_two : '',
                container_height: 0,
                footer_height: 0,
            }

        },
    computed:{
        sanitizedBody() {
          return DOMPurify.sanitize(this.language.body);
        },
        form: function () {
          return this.$store.state.dialogue.form;
        },
        current_banner: function(){
            return this.$store.getters["domain/getCurrentBanner"];
        },
        banner: function() {
            return this.$store.getters['domain/getCurrentBanner'];
        },
        banner_type: function () {
            return this.$store.state.domain.banner_type;
        },
        language: function() {
            return this.$store.getters['domain/getCurrentLanguage'];
        },
        dialogueProperties: function() {
            return this.$store.getters['domain/getDialogueProperties'];
        },
        button_type() {
            if (this.banner.button_type.includes("default"))
                return "seers-cmp-default-style-btn";
            else if (this.banner.button_type.includes("flat"))
                return "seers-cmp-flat-style-btn";
            else if (this.banner.button_type.includes("rounded"))
                return "seers-cmp-rounded-style-btn";
            else return "seers-cmp-stroke-style-btn";
        },
        language_body_1: {
                get: function() {
                    let self = this;
                    let lang = this.sanitizedBody;

                    if(!self.toggle_one && self.cookie_body_el_one && lang.split(' ').length > 20){
                        lang = self.truncate(lang.trim(), 20, null);
                        if(!self.cookie_body_el_one.contains(self.anchor_tag_one)){
                            self.cookie_body_el_one.appendChild(self.anchor_tag_one);
                        }
                    }
                    if(lang.split(' ').length < 20 && self.cookie_body_el_one && self.anchor_tag_one && self.cookie_body_el_one.contains(self.anchor_tag_one)){
                        self.cookie_body_el_one.removeChild(self.anchor_tag_one);
                    }

                    return lang;
                },
                set: function(lang) {
                    this.language_body = lang;
                }
            },
    }

}
</script>

<style scoped>
.cookie_box{
    position: absolute !important;
    z-index: 99 !important;
    padding: 8px !important;
}
    @import '@/assets/css/BannerCss/CcpaBannerBox.css';
</style>