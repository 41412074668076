<template>
	<div class="domain-modal">
		<div class="modal-hol">
			<form>
				<div class="modal-header border-0 pb-0">
					<h2>Report Details</h2>
					<button type="button" class="close" @click="handleHideReport">+</button>
				</div>
				<div class="modal-body pb-0">
					<div class="form-group row mb-3">
						<input type="hidden" class="form-control" id="domain_id" v-model="domain.id" />
						<input type="hidden" class="form-control" id="receiver_id" v-model="receiver_id" />
						<label class="
							white-space
								col-12 col-sm-12 col-md-3
								control-label
								mb-2
								d-flex
								justify-content-start
								align-items-center
							">{{ DomainName }}</label>
						<div class="col-12 col-sm-12 col-md-9">
							<input type="text" id="domain_name" placeholder="www.example.com" class="form-control"
								v-model="domain.name" disabled />
						</div>
					</div>
					<div class="form-group row mb-3">
						<label for="scan_email" class="
							white-space
								col-12 col-sm-12 col-md-3
								control-label
								mb-2
								d-flex
								justify-content-start
								align-items-center
							">{{ Email }}</label>
						<div class="col-12 col-sm-12 col-md-9">
							<input type="email" id="scan_email" placeholder="email@example.com" class="form-control"
								v-model="receiver_email" disabled />
						</div>
					</div>
				</div>
				<div class="modal-footer border-0 justify-content-start pt-0">
					<div class="form-group row">
						<ul class="list-unstyled d-flex align-items-center p-0 m-0 flex-wrap">
							<li class="mr-3">
								<a href="javascript:void(0)" class="btn btn-blue-bg p-2" @click="domainReport(domain)">
									<i class="fa fa-eye text-white" aria-hidden="true" style="font-size:15px;"></i>

								</a>
							</li>
							<li>
								<a v-if="scan_report_varient == 1" href="javascript:void(0)" class="btn btn-blue-bg p-2"
									@click="handleRole(domain.id, domain.cb_reports_receivers && domain.cb_reports_receivers.receiver_email, domain.web_url)">
									<i class="fa fa-paper-plane text-white" aria-hidden="true"
										style="font-size:15px;"></i>

								</a>
							</li>
						</ul>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters, mapState } from 'vuex';
export default {
	name: "ReportDomain",
	props: ['domain'],
	data() {
		return {
			Email: "Email:",
			DomainName: "Domain name:",
			receiver_id: this.domain.user_id,
			receiver_email: this.domain.cb_reports_receivers && this.domain.cb_reports_receivers.receiver_email,
		};
	},
	computed: {
		...mapState('domain', ['user', 'assign_role']),
		...mapGetters({
			show_dialogue_preview: 'domain/getShowDialoguePreview',
		}),
		scan_report_varient: function () {
			return this.$store.getters['domain/getFeatures'].scan_report_varient;
		},
		cmp_url() {
			return process.env.VUE_APP_ENV_VARIABLE
		},
	},
	mounted() {
	},
	methods: {
		async handleRole(id, email, web_url) {
			if (!this.user.isSuperAdmin && this.user.superAdminId !== null && this.assign_role === 'reader') {
				await this.handleHideReport();

				const result = await Swal.fire({
					title: "Unauthenticated",
					html: "Please Contact Your Admin",
					icon: "info",
					confirmButtonColor: "#3b6ef8",
					confirmButtonText: "Confirm",
				});
			} else {
				this.sendEmail(id, email, web_url)			}
		},

		handleHideReport: function () {
			this.$emit("onHideReport");
		},
		Popupalert() {
			this.$store.commit("domain/setShowPricingPopup", true);
			return;
		},
		showPopup() {
			if (this.scan_report_varient == 0) {
				this.Popupalert();
			}
		},
		domainReport(domain) {
			let popup = false;
			let title = "";
			if (this.domain.last_scan_at == null) {
				popup = true;
				title = "Domain scan status is still pending";
			} else if (this.domain.report_generated == 0) {
				popup = true;
				title = "Cookie report is in progress, Please try later!";
			} else {
				window.open(`${process.env.VUE_APP_FRONT}/reports/${domain.web_url}`, "_blank");
			}
			if (popup) {
				this.handleHideReport();
				Swal.fire({
					icon: "info",
					title: title,
					timer: 4000,
					showConfirmButton: false,
				});
			}
		},
		sendEmail(id, email, web_url) {
			if (this.domain.last_scan_at != null) {
				let payload = {
					email: email,
					dom_id: id,
					user_id: this.domain.user_id,
					web_url: web_url,
				};
				this.$store.dispatch('domain/sendEmail', payload)
					.then(() => {
						this.handleHideReport();
					})
			} else {
				this.handleHideReport();
				Swal.fire({
					icon: 'info',
					title: "Domain scan status is still pending",
					timer: 3000,
					showCancelButton: false,
					confirmButtonColor: "#3b6ef8",
					cancelButtonColor: "#6cc04a",
					confirmButtonText: 'ok',
					cancelButtonText: "Cancel",
				})
			}
		},
	},
};
</script>
