<template>
  <div></div>
</template>
<script>
import Swal from "sweetalert2";
export default {
    name: 'TheToast',
    props:['message'],
    mounted() {
      if(this.message.status == 200){
        Swal.fire({
          icon: this.message.icon,
          customClass: {
            icon: "no-before-icon",
          },
          title: this.message.message,
          showCancelButton: this.message.showCancelButton,
          confirmButtonColor: "#3b6ef8",
          cancelButtonColor: "#6cc04a",
          confirmButtonText: this.message.confirmButtonText,
          cancelButtonText: "Cancel",
        })
      }
        this.$store.commit('domain/swalAlert',false)
    },

}
</script>

<style>
.no-before-icon::before {
    display: none !important;
}
.swal2-success-line-tip, .swal2-success-line-long {
  font-size: 16px !important;
}
.swal2-x-mark-line-right, .swal2-x-mark-line-left {
  font-size: 16px !important;
} 
</style>