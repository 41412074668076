import { createStore } from 'vuex'

 import createPersistedState from "vuex-persistedstate";

import state from './state';
import * as getters from './getters';
import * as actions from './actions';
import domain from "./modules/domain"
import getCode from './modules/getCode';
import dialogue from './modules/dialogue';
import policy from './modules/policy';
import abortAxiosRequest from './modules/abortAxiosRequest';
let initialState = {
    domain: domain.state,
    getCode: getCode.state,
    dialogue: dialogue.state,
    policy: policy.state
}
export default createStore({
    modules:{
        domain,
        getCode,
        dialogue,
        policy,
        abortAxiosRequest
    },
    plugins: [createPersistedState()],
    state,
    getters,
    mutations:{
        reset (state) {
            Object.keys(state).forEach(key => {
                Object.assign(state[key], initialState[key])
            })
        }
    },
    actions,
});