
export const SetuserTokenNull = (state, value) =>{
    state.userToken = value;
}

export const set_add_domain = (state, addDomain) =>{
    state.show_add_domain = addDomain;
}

export const setShowAddModel = (state, value) =>{
    state.showAddDomainModel = value;
}
export const userToken = (state, userToken) =>{
    state.userToken = userToken;
}
export const setUser = (state, setUser) =>{
    state.user = setUser;
}
export const setUserId = (state, userId) =>{
    state.userId = userId;
}

export const setUserEmail = (state, userEmail) =>{
    state.userEmail = userEmail;
}

export const theLoader = (state, value) => {
    state.theLoader = value;
}
export const inLoader = (state, value) => {
    state.inLoader = value;
}
export const trackingLoader = (state, value) => {
    state.trackingLoader = value;
}
export const reportLoader = (state, value) => {
    state.reportLoader = value;
}


export const setDomains = (state, domains) =>{
    state.domains = domains;
}

export const setNewDomain = (state, domain) =>{
    state.domains.push(domain);
}

export const currentActiveModal = (state, modal) =>{
    state.currentActiveModal = modal;
}

export const setCurrentDomainId = (state, domainID) => {
    state.current_domain_id = domainID;
}

export const setCurrentDomain =  (state, domain) => {
    state.current_domain = domain;
}

export const setFeatures = (state, features) => {
    state.features = features;
}

export const setCurrency = (state, currency) => {
    state.currency = currency;
}

export const setProduct = (state, product) => {
    state.product = product;
}

export const setPopupFeatures = (state, pricing_features) => {
    state.pricing_features = pricing_features;
}

export const setActivatedPlan = (state, plan) => {
    state.activated_plan = plan;
}

export const toastAlert = (state, toastAlert) => {
    state.toastAlert = toastAlert;
}

export const swalAlert = (state, swalAlert) => {
    state.swalAlert = swalAlert;
}

export const removeDomain = (state, removeDomain) => {
    state.domains = state.domains.filter(item => {
        return item.id !== removeDomain;
    });
}

export const setShowPricingPopup = (state, is_active) => {
    state.show_pricing_popup = is_active;
}

export const updateDomain = (state, payload) => {
    let updateDomain = state.domains.find(item => {
        return item.id === payload.domain_id;
    });
    if(updateDomain){
        updateDomain.scan_frequency = payload.scan_frequency;
        updateDomain.cb_reports_receivers.receiver_email = payload.scan_email;
        return
    }
}
export const setScanNowResponse = (state, data) => {
    state.scan_now_response = data;
}

export const setShowDialoguePreview = (state, show) => {
    state.show_dialogue_preview = show;
}

export const setDialogueProperties = (state, properties) => {
    state.dialogue_properties = properties;
}

export const setDefaultLanguage = (state, language) => {
    state.language = language;
}

export const setCurrentLanguage = (state, lang ) => {
    state.current_language = lang;
}

export const setDialogueLanguages = (state, languages) => {
    state.dialogue_languages = languages;
}

export const setShowSearchBar = (state, show) => {
    state.show_searchBar = show;
}

export const setCurrentBanner = (state, banner) => {
    state.current_banner = banner;
}


export const setExertoBanner = (state, banner) => {
    state.exerto_banner = banner;
}

export const setDefaultBanner = (state, banner) => {
    state.default_banner = banner;
}
export const setUniversalBanner = (state, banner) => {
    state.universal_banner = banner;
}

export const setButtonTypeOfCurrentBanner = (state, btn_type) => {
    state.current_banner.button_type = btn_type;
}

export const activetab = (state, activetab) => {
    state.activetab = activetab;
}

export const setIsOneStepBanner = (state, status) => {
    state.is_one_step_banner = status;
}

export const setManuallyBlockScripts = (state, script) => {
    state.ManuallyBlockScripts = script;
}

export const setStart = (state, start) => {
    if(start == null){
        state.scriptStart = 1;
    }
    else{
        state.scriptStart = start;
    }
    
}

export const setScriptCategories = (state, scriptCategories) => {
    state.scriptCategories = scriptCategories;
}

export const updateToggleScript = (state, payload) => {
    let ManuallyBlockScripts = state.ManuallyBlockScripts.data.find(item => {
        return item.id === payload.id;
    });
    if(ManuallyBlockScripts){
        ManuallyBlockScripts.enabled = payload.enabled;
        return
    }
}

export const updateChangeCategory = (state, payload) => {
    let scriptCategories = state.ManuallyBlockScripts.data.find(item => {
        return item.id === payload.id;
    });
    if(scriptCategories){
        scriptCategories.script_category_id = payload.category_id;
        return
    }
}

export const showAddManuallyScriptModal = (state, showAddManuallyScriptModal) => {
    state.show_add_manually_script = showAddManuallyScriptModal;
}

export const showEditManuallyScriptModal = (state, showEditManuallyScriptModal) => {
    state.show_edit_manually_script = showEditManuallyScriptModal;
}

export const setNewScript = (state, script) =>{
    
    if(state.ManuallyBlockScripts.data.length !== state.ManuallyBlockScripts.per_page){
        state.ManuallyBlockScripts.data.push(script);
    }
}

export const removeScript = (state, removeScript) => {
     state.ManuallyBlockScripts.data = state.ManuallyBlockScripts.data.filter(item => {
        return item.id !== removeScript;
    });
}

export const updateScript = (state, payload) => {
    state.ManuallyBlockScripts.data = state.ManuallyBlockScripts.data.map(item => {
        if(item.id === payload.id){
            return Object.assign({}, item, payload)
        }
        return item;
    });
}

export const setAutoBlockScript = (state, setAutoBlockScript) =>{
    state.Auto_block_script = setAutoBlockScript;
}

export const setDiscoveredCookies = (state, discovered_cookies) =>{
    state.discovered_cookies = discovered_cookies;
}

export const setSelfDeclaredCookies = (state, self_declared_cookies) =>{
    state.self_declared_cookies = self_declared_cookies;
}

export const setNewSelfDeclaredCookies = (state, cookies) =>{
    state.self_declared_cookies.push(cookies);  
}

export const removeSelfDeclaredCookie = (state, removeCookie) => {
    state.self_declared_cookies = state.self_declared_cookies.filter(item => {
       return item.id !== removeCookie;
   });
}

export const setUpdateSelfDeclaredCookie = (state, updateCookie) => {
    state.self_declared_cookies = state.self_declared_cookies.map(item => {
        if(item.id === updateCookie.id){
            return Object.assign({}, item, updateCookie)
        }
        return item;
    });
}

//dialogue

export const banner_type = (state, banner_type) => {
    state.banner_type = banner_type;
}

export const consentlog_limit_reached = (state, consentlog_limit_reached) => {
    state.consentlog_limit_reached = consentlog_limit_reached;
}

// export const setGoogleConsentModeButton = (state, value) => {
//     state.apply_google_consent = value;
// }

// export const setFacebookConsentModeButton = (state, value) => {
//     state.apply_facebook_consent = value;
// }

export const setPreDefinedLanguages = (state, value) => {
    state.preDefinedLanguages = value;
}

export const setButtonIsDisabled = (state, value) => {
    state.button_is_disabled = value;
}

export const setAllToggleBtn = (state, value) => {
    state.all_toggle_btn = value;
}

export const setWcagBanner = (state, value) => {
    state.apply_wcag_banner = value;
}

export const setPreviewBtn = (state, value) => {
    state.preview_btn = value;
}
export const setTotalCookies = (state, value) => {
    state.totalCookies = value;
}

//pricePlan

//Add New Category
export const addCategory = (state,value) => {
    state.categories.push(value);
}

export const toggleCategoryVisibility = (state, index) => {
    const category = state.categories[index];
    
    if (category) {
      category.visible = !category.visible;
    } else {
      console.error('Invalid index or category not found:', index);
    }
  };
  

// export const updateCategoryChecked = (state, { value, checked }) => {
//     state.categories[value].checked = checked;
// }

export const setExistingCategoryIds = (state, titles) => {
    state.existingCategoryTitles = titles;
}

export const clearCategories = (state) => {
    state.categories = [];
}
export const deleteCategory = (state, index) => {
    state.categories.splice(index, 1);
  };

export const setScriptCustomizeCategories = (state,value) => {
    state.script_customize_categories=value;
}

export const setMode = (state,value) => {
    state.current_banner.modes=value;
}
export const setHide_font = (state,value) => {
    state.hide_font=value;
}
export const setAssign_role = (state,value) => {
    state.assign_role=value;
}
