import Api from "@/apis/Api";
import axios from "axios";
export const checkForSurvey = async({rootState, state,commit }, payload) => {
    try {
        // if (rootState.abortAxiosRequest.cancelCheckForSurveyToken) {
        //     rootState.abortAxiosRequest.cancelCheckForSurveyToken.cancel('Request canceled');
        //     commit("abortAxiosRequest/setDestroyCancelCheckForSurveyToken", {}, { root: true })
        //   }
        //   const cancelToken = axios.CancelToken.source();
        //   commit("abortAxiosRequest/setCancelCheckForSurveyToken", cancelToken, { root: true })
       const response = await Api.get(`checkForCookieXraySurvey/${payload.domain_id}/${payload.user_id}`,
       {
           
           headers:{
               'Authorization' : 'Bearer ' + rootState.domain.userToken
           },
        //    cancelToken: cancelToken.token,
       });
       const {status} = response;
       if(status===200){
           if(response.data.take_survey){
                commit('setShowQuestionnaire', true);
                commit('setShowPolicyReport', false);
            }else{
                commit('setShowPolicyReport', true);
                commit('setShowQuestionnaire', false);
            }
            // commit("abortAxiosRequest/setDestroyCancelCheckForSurveyToken", {}, { root: true })
       }
    } 
    catch (error) {
        console.log(error)
        // commit("theLoader", true)
        // window.location.href = 'https://upgradedseers.here/business/dashboard';
    }
}

export const loadCurrentCookieXray = async({rootState, state, commit }, payload) => {
    var domain_id = payload.d_id;   
    try {
        // if (rootState.abortAxiosRequest.cancelLoadCurrentCookieXrayToken) {
        //     rootState.abortAxiosRequest.cancelLoadCurrentCookieXrayToken.cancel('Request canceled');
        //     commit("abortAxiosRequest/setDestroyCancelLoadCurrentCookieXrayToken", {}, { root: true })
        //   }
        //   const cancelToken = axios.CancelToken.source();
        //   commit("abortAxiosRequest/setCancelLoadCurrentCookieXrayToken", cancelToken, { root: true })

       const response = await Api.get(`getCurrentCookieXray/${payload.id}`,
       {
           
           headers:{
               'Authorization' : 'Bearer ' + rootState.domain.userToken
           },
        //    cancelToken: cancelToken.token,
       });
       const {status} = response;
       if(status===200){
            let is_answered = false;
            let answer = null;
            var answers = response.data.current_cookieXray.answer;
            answers.forEach(function(ans){
                if(ans.domain_id == domain_id){
                    is_answered = true;
                    answer = ans;
                }
                // commit("abortAxiosRequest/setDestroyCancelLoadCurrentCookieXrayToken", {}, { root: true })
            })
            commit('setCurrentCookieXray', {cookieXray: response.data.current_cookieXray});
            commit('setCurrentCookieXrayId', response.data.current_cookieXray.id);
            response.data.current_cookieXray.answer = answer;
            if (answer != null) {
                if (Object.prototype.hasOwnProperty.call(answer, "answers")) {
                    commit('setCurrentQuestionIndex', answer.answers[1].current_question_index);
                    // commit('setPrevQuestionIndex', answer.answers[1].prev_question_index);
                    commit('setQuestionCounter', answer.answers[1].question_counter);
                }
            } else {
                commit('setCurrentQuestionIndex', 0);
                commit('setQuestionCounter', 1);
            }
            commit('setQuestions');
            commit('setTotalQuestions');
            commit('initializeAnswers');
            commit('findNextQuestion');
            commit('findPrevQuestion');
       }
    } 
    catch (error) {
        console.log(error)
        // commit("theLoader", true)
        // window.location.href = 'https://upgradedseers.here/business/dashboard';
    }
}

export const saveCurrentAnswer = async({rootState,state,  commit}, answer) => {
    
    commit('setCurrentAnswer', answer);
    if (typeof state.answers[0][state.current_question_index + 1] !== 'undefined') {
        state.answers[1].current_question_index = state.current_question_index;
        state.answers[1].question_counter = state.question_counter;
        for (let i = state.current_question_index + 1; i < state.answers[0].length; i++) {
            state.answers[0][i].question_name = null;
            if ((Array.isArray(state.answers[0][i].answer)) && (state.answers[0][i].answer.length >= 0)) {
                state.answers[0][i].answer = [];
            } else {
                state.answers[0][i].answer = null;
            }
        }
    }
    commit('setShowError', false);
    commit('findNextQuestion');
    commit('findPrevQuestion');
    let payload = {
        done: state.is_done,
        cookieXray_id: state.current_cookieXray_id,
        domain_id: answer.domain_id,
        answers: state.answers,
        user_id: answer.user_id
    };
    // return
    try {
        const response = await Api.post(`cookieXrayAnswers`,payload,
        {
            
            headers:{
                'Authorization' : 'Bearer ' + rootState.domain.userToken
            }
        });
        const {status} = response;
        if(status===200){
            //
        }
     } 
     catch (error) {
         console.log(error)
         // commit("theLoader", true)
         // window.location.href = 'https://upgradedseers.here/business/dashboard';
     }
}

export const setPrevQuestion = ({state, getters, commit}) => {
    let prev = getters.getPrevQuestionIndex;
    if (prev != getters.getCurrentQuestionIndex) {
        commit('setQuestionIndex', prev);
        commit('decrementQuestionCounter');
    }
}

export const isCurrentQuestionDone = ({state, commit}) => {
    return new Promise((resolve, reject) => {
        if (state.answers[0][state.current_question_index].answer.length > 0) {
        commit('setShowError', false);
            resolve();
        }
        reject();
    })
}

export const setNextQuestion = ({ getters, commit}) => {
    let next = getters.getNextQuestionIndex;
    if (next != getters.getCurrentQuestionIndex) {
        commit('setQuestionIndex', next);
        commit('incrementQuestionCounter');
    }

}

export const cookieAllAnswers = async({rootState,state,  commit}, payload) => {
    try {
        const response = await Api.post(`cookieXrayAnswers`,payload,
        {
            
            headers:{
                'Authorization' : 'Bearer ' + rootState.domain.userToken
            }
        });
        const {status} = response;
        if(status===200){
            commit("policy/setErrorMessage", "");
        }
        return response;
     } 
     catch (error) {
         console.log(error)
         throw error
         // commit("theLoader", true)
         // window.location.href = 'https://upgradedseers.here/business/dashboard';
     }
}

export const loadPolicy = async({rootState, commit }, payload) => {
    
    try {
       const response = await Api.get(`cookieXray-policy/${payload.id}/${payload.user_id}`,
       {
           
           headers:{
               'Authorization' : 'Bearer ' + rootState.domain.userToken
           }
       });
       const {status} = response;
    //    console.log('21',response)
       if(status===200){
           if(response.data){
                commit('setPolicy', response.data.policy);
            }
            
       }
    } 
    catch (error) {
        console.log(error)
        // commit("theLoader", true)
        // window.location.href = 'https://upgradedseers.here/business/dashboard';
    }
}

export const resetPolicy = async({rootState, commit }, payload) => {
    try {
       const response = await Api.post('cookieXray-reset-policy',payload,
       {
           
           headers:{
               'Authorization' : 'Bearer ' + rootState.domain.userToken
           }
       });
       const {status} = response;
       if(status===200){
           if(response.data.take_survey){

                // commit('setShowQuestionnaire', true);
                // commit('setShowPolicyReport', false);
            }     
       }
       return response;
    } 
    catch (error) {
        console.log(error)
        // commit("theLoader", true)
        // window.location.href = 'https://upgradedseers.here/business/dashboard';
    }
}

export const downloadCookiePolicy = async({rootState }, payload) => {
    try {
       const response = await Api.get(`cookieXray-download-policy/${payload.id}`,
       {
           
           headers:{
               'Authorization' : 'Bearer ' + rootState.domain.userToken
           }
       });
       const {status} = response;
       if(status===200){
           if(response){
            // window.location.href = `https://upgradedcmp.here/api/v2/cookieXray-download-policy/${payload.id}`;
            }
            
       }
    } 
    catch (error) {
        console.log(error)
    }
}