// import state from './state';
// import * as getters from './getters';
// import * as mutations from './mutations';
// import * as actions from './actions';
const getDefaultState = () => {
  return {
      form:{
          web_url: "",
          cookie_policy_url: "",
          policy_design: "raw",
          do_prior_consent: false,
          cookie_consent: "generalised",
          is_cookie_banner: true,
          is_cookie_policy: false,
          is_cookie_declaration_table: false,
          is_show_badge: false,
          enable_cookie_stats: false,
          btn_disagree_title: "Disable All",
          btn_agree_title: "Allow All",
          btn_read_more_title: "...",
          consent_mode: "explicit",
          show_once: true,
          auto_accept_on_scroll: false,
          consent_type: "generalised",
          preferences_checked: false,
          statistics_checked: false,
          targeting_checked: false,
          agreement_expire: 1,
          title: "We use cookies",
          email_section_title: "To request that your personal information is not shared for these purposes outside of the cookie context, enter your email address below. Additionally, you will not need to opt out of targeted advertising again if you visit from a different device or browser and are logged in.",
          body: "We use cookies to personalize content and ads, to provide social media features and to analyze our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you have provided to them or that they have collected from your use of their services. You consent to our cookies if you continue to use our website.",
          cookies_body:
          "Cookies are to ensure website user gets best experience. Necessary cookies can be stored in the users devices. We need your permission for non essential cookies. Learn more about how we process personal data in our Privacy Policy?",
          preference_title: "Preferences",
          preference_body:
          "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.",
          statistics_title: "Statistics",
          statistics_body:
          "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.",
          marketing_title: "Marketing",
          marketing_body:
          "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third-party advertisers.",
          unclassified_title: "Unclassified",
          unclassified_body:
          "Unclassified cookies are cookies that we are in the process of classifying, together with the providers of individual cookies.",
          template_name: "seers-cx-top",
          title_color: "#1a1a1a",
          body_text_color: "#1a1a1a",
          agree_btn_color: "#009900",
          disagree_btn_color: "#1a1a1a",
          preferences_btn_color: "#272727",
          agree_text_color: "#ffffff",
          disagree_text_color: "#ffffff",
          preferences_text_color: "#1a1a1a",
          logo_bg_color: "#fbfbfb",
          banner_bg_color: "#fbfbfb",
          logo: "seersco-logo.png",
          logo_status: "none",
          is_auto_detection: false,
          languages: [],
          banners: [],
          powered_by:true,
          default_language: "GB",
          allow_reject: true,
          save_consent_logs: true,
          extro_banner:false,
          region_detection:false,
          categories:[],
          region_selection:'1',
          deny_reload:true,
        },
  }
}
// initia
const state = getDefaultState()
const actions = {
}
const mutations = {
  setRegionSelection (state,region_selection) {
    state.form.region_selection = region_selection;
},
  resetState (state) {
    // console.log('ststs',state)
    Object.assign(state, getDefaultState())
  },
  setForm(state,formdate){
    Object.assign(state.form, formdate)
  },
  setFormBanner(state,formdate){
    console.log('formdate',formdate)
    state.form.banners.push(formdate)
    // Object.assign(state.form.banners, formdate)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}