<template>
	<div class="row">
		<div class="row">
			<div class="col-12">
				<h3 class="text-dark mb-4">Auto block vendors</h3>
				<p class="d-block mb-4 pb-4 border-bottom text-gray-dark">
					The vendors on this list cannot be changed. Alternatively,
					choose "Manually block vendors" to add or delete vendors
				</p>
			</div>
			<div class="col-12">
				<div v-show="this.trackingLoader" class="report-hol" style="margin-top: 50px; margin-left: 150px;">
					<div class="before scanning-block text-center py-5">
						<PulseLoader style="display:inline-block" />
					</div>
				</div>
				<div class="table-responsive p-2" v-show="!this.trackingLoader">
					<table style="border-collapse: separate; border-spacing: 0 10px;" id="datatable-responsive" cellpadding="0" cellspacing="0" width="100%" class="table cookie-m-table cookie_consent_log_table">
						<thead>
							<tr>
								<th title="Serial Number" class="border-top-0 border-bottom-0">
									No.
								</th>
								<th title="Name of Script" class="border-top-0 border-bottom-0">
									Vendor
								</th>
								<th title="t('Name of Domain.')" class="border-top-0 border-bottom-0">
									Domain
								</th>
							</tr>
						</thead>
						<tbody class="notranslate">
							<tr class="bg-tr" v-for="(script, index) in auto_block_script" :key="index">
								<td class="border-0">{{ start + index }}</td>
								<td class="textlimit border-0">
									{{ script.title }}
								</td>
								<td class="border-0">{{ script.domain_name }}</td>
							</tr>
							<tr class="spacing5">
								<td class="border-0 bg-transparent" colspan="3"></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { mapState } from 'vuex';
export default {
	components: {
		PulseLoader,
	},
	name: "AutoBlock",
	computed: {
		...mapState('domain', ['trackingLoader']
		),
		start: function () {
			return this.$store.getters['domain/getStart'];
		},
		auto_block_script: function () {
			return this.$store.state.domain.Auto_block_script;
		},
	},
	mounted() {
		this.loadAutoBlockScript();
	},
	methods: {
		loadAutoBlockScript() {
			this.$store.commit('domain/trackingLoader', true)
			this.$store.dispatch('domain/loadAutoBlockScript')
			.then(() => {
				this.$store.commit('domain/trackingLoader', false)
			})
		}
	},
};
</script>

<style scoped>
.table td {
	padding: 7px 10px !important;
	font-size: 14px;
	white-space: nowrap;
}

.cookie-m-table>thead>tr>th {
	color: #3b6ef8;
	padding: 7px 10px !important;
	font-size: 15px;
	white-space: nowrap;
}

.bg-tr {
	-ms-box-shadow: 0 1px 7px #bfbfbfba;
	-o-box-shadow: 0 1px 7px #bfbfbfba;
	box-shadow: 0 1px 7px #bfbfbfba;
	border-radius: 4px;
	outline: 1px solid#fff !important;
}
</style>