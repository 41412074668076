<template>	
<PolicyQuestion v-if="show_questionnaire"></PolicyQuestion>
<PolicyReport v-if="show_policy_report" :current_id="'1'"></PolicyReport>
<div v-show="inLoader" class="report-hol" style="margin-top: 50px;">
        <div  class="before scanning-block text-center py-5">
            <PulseLoader :loading="true"  style="display:inline-block" />
        </div>
      </div>
	  <div v-show="!inLoader">
</div>
</template>

<script>
import PolicyQuestion from "@/components/CustoMiZationComponent/PolicyReport/PolicyQuestion.vue"
import PolicyReport from "@/components/CustoMiZationComponent/PolicyReport/report/PolicyReport.vue"
import { mapState } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
	name: "PolicyC",
	components:{
		PolicyQuestion,
		PulseLoader,
		PolicyReport
	},

	computed:{
		
		...mapState('domain',['inLoader']
		),
		
		userId: function(){
			return this.$store.state.domain.userId;
		},
		domain_id: function () {
			return this.$store.getters['domain/getCurrentDomainId'];
		},
		show_questionnaire: function () {
			return this.$store.getters['policy/getShowQuestionnaire'];
		},
		show_policy_report: function () {
			return this.$store.getters['policy/getShowPolicyReport'];
        }
	},
	watch:{
		domain_id: {
			// deep: true,
			handler(){
				this.checkForSurvey()
			}
		},
	},
	mounted() {
		this.checkForSurvey();
	},
	methods: {
		checkForSurvey() {
		this.$store.commit('domain/inLoader', true)
			let payload = {
				domain_id: this.domain_id,
				user_id:this.userId
			};
			this.$store.dispatch('policy/checkForSurvey',payload)
			.then(()=>{
			 	 this.$store.commit('domain/inLoader', false)
				})
				
		}
	},
};
</script>

<style scoped>

</style>