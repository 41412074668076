<template>
	<div>
		<div class="sction-box">
			<h3 class="text-blue mb-3">Cookie Consent Banner Script</h3>
			<p class="mb-2">Copy this script into your website's HTML header</p>
			
			<textarea 
				class="scriptcode" id="banner-script" readonly ><script data-key="{{ script && script.key }}" data-name="CookieXray" src="{{script_url}}" type="text/javascript"></script></textarea>
			<div class="notearea">
				<div class="note">
					Note: Paste this before any other script in the HTML head
					tag
				</div>
				<button class="btn btn-blue-bg mt-3" @click="copyScript('banner')">
					Copy Script
				</button>
				<div class="note mt-4 mb-2">
				 Do Not Sell banner users must integrate this code into the website’s footer.
				</div>
				<textarea class="scriptcode" id="DoNotSell" readonly ><a href="javascript:" id="SeersDoNotSell">Do Not Sell or Share My Personal Information</a></textarea>
				<button class="btn btn-blue-bg mt-3" @click="copyScript('DoNotSell')">
					Copy Code
				</button>
			</div>
		</div>
		<div class="sction-box" v-if="show_google_consent_button">
			<!-- v-if="show_google_consent_button" -->
			<h3 class="mb-3 text-blue">Google Consent Mode Code</h3>
			<div class="mb-2">		
				<p> 
				<span class="text-blue">Step 1:</span> Find the Google Analytics code in the header on the website or
				get it from your Google Analytics account.
			</p></div>

			<textarea
				class="scriptcode"
				id="googleStep1"
				readonly
				style="height: 325px;"
			>
				<script async src="https://www.googletagmanager.com/gtag/js?id=UA-xxxxxxxx-x"></script>
				<script> 
				window.dataLayer = window.dataLayer || [];

				function gtag() {

				dataLayer.push(arguments);}

				gtag('js', new Date());

				gtag('config', 'UA-xxxxxxxxx-x');

				</script>
			</textarea
			>
			<button class="btn btn-blue-bg mb-3 float-right" @click="copyScript('step1')">Copy Script</button>
			<div class="notearea mb-4">
				<div class="note">Note: The code looks like this.</div>
			</div>
			<p>
				<span class="text-blue">Step 2:</span>
				Insert Google Consent Mode code (below in bold) in the Google
				Analytics Code.
			</p>

				<textarea
				class="scriptcode mb-3"
				id="googleStep2"
				style="height: 450px;"
				readonly>
					<script type='text/javascript'> 
					window.dataLayer = window.dataLayer || [];
					function gtag() {
					dataLayer.push(arguments);}
					gtag('consent', 'default', {
					ad_storage: 'denied',
					analytics_storage: 'denied',
					functionality_storage: "denied", 
        				personalization_storage: "denied", 
        				security_storage: "denied",
					ad_user_data: 'denied',
					ad_personalization: 'denied',
					wait_for_update: 500});
					gtag('set', 'ads_data_redaction' , true);
					</script>

                    <!-- Global site tag (gtag.js) - Google Analytics -->
					<script async src="https://www.googletagmanager.com/gtag/js?id=UA-xxxxxxxxx-x"></script>
					<script> 
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());
							gtag('config', 'UA-xxxxxxxxx-x');
					</script>
				</textarea>
		

				<button class="btn btn-blue-bg mb-3 float-right" @click="copyScript('step2')">Copy Script</button>
				<br>
				<br>
				<div class="mb-2">
						
					<p><span class="text-blue">Step 3:</span>	Now, insert the Seers' CMP code after the Google Consent Code
								and before the Google Analytics code.</p>
				</div>
			


				<textarea readonly="readonly" class="scriptcode" id="googleStep3"><script data-key="{{ script && script.key }}" data-name="CookieXray" src="{{script_url}}" type="text/javascript"></script></textarea>
				<button class="btn btn-blue-bg mb-3 float-right" @click="copyScript('step3')">Copy Script</button>
			<br />
			<br />
			<p>
				<span class="text-blue">Step 4:</span> After
				adding Seers' CMP code, your actual code should look like this.
			</p>

				<textarea readonly="readonly" id="googleStep4" class="scriptcode" style="height:550px;">
					<script type='text/javascript'> 
					window.dataLayer = window.dataLayer || [];
					function gtag() {
					dataLayer.push(arguments);}
					gtag('consent', 'default', {
					ad_storage: 'denied',
					analytics_storage: 'denied',
					functionality_storage: "denied", 
        				personalization_storage: "denied", 
        				security_storage: "denied",
					ad_user_data: 'denied',
					ad_personalization: 'denied',
					wait_for_update: 500});
					gtag('set', 'ads_data_redaction' , true);
					</script>
					
					<script data-key='xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
					data-name='CookieXray' src='https://seersco.com/script/cb.js'
					type='text/javascript'></script>

					<!-- Global site tag (gtag.js) - Google Analytics -->  

					<script async src="https://www.googletagmanager.com/gtag/js?id=UA-xxxxxxxxx-x"></script>
					<script> 
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());
							gtag('config', 'UA-xxxxxxxxx-x');
					</script>
				</textarea>
				<button class="btn btn-blue-bg mb-3 float-right" @click="copyScript('step4')">Copy Script</button>

		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
	name: "Gmc",
	computed:{
		cmp_url(){
			return process.env.VUE_APP_BANNER_URL
		},
		show_google_consent_button: function() {
			return this.$store.getters['getCode/getGoogleConsentModeButton'] == 1;
		},
		script: function() {
			return this.$store.getters['getCode/getScript'];
		},
		script_url: function() {
			return  this.cmp_url + '/banners/' + this.$store.state.domain.userId +'/'+ this.$store.getters['domain/getCurrentDomainId'] + "/cb.js";
		},
	},
	mounted() {
		// console.log('scriptscript', this.cmp_url)
	},
	methods: {
		copyScript(script){
			if (script === "banner") {
				$("#banner-script").select();
			}

			if (script === "step1") {
				$("#googleStep1").select();
			}

			if (script === "step2") {
				$("#googleStep2").select();
			}

			if (script === "step3") {
				$("#googleStep3").select();
			}

			if (script === "step4") {
				$("#googleStep4").select();
			}

			if (script === "DoNotSell") {
				$("#DoNotSell").select();
			}
			Swal.fire({
				icon: "success",
				title: 'Code Copied',
				text: "Code copied successfully",
				timer: 4000,
				confirmButtonText: "Ok"
			});
			document.execCommand("copy");
		}
	},
};
</script>

<style scoped>
.sction-box {
border: 0;
	margin-bottom: 30px;
	width: 100%;
	display: inline-block;
}
.scriptcode {
	
	
	background: #fff !important;
	border-radius: 4px !important;
	-webkit-border-radius: 4px !important;
	padding-inline-start: 10px;	
	padding-block-start: 0%;
	padding: auto;
	font-size: 14px;
	color: #707070;
	-webkit-box-shadow: 0px 0px 6px rgb(0 0 0 / 15%) !important;
	-moz-box-shadow: 0px 0px 6px rgb(0 0 0 / 15%) !important;
	box-shadow: 0px 0px 6px rgb(0 0 0 / 15%) !important;
}
textarea:focus {
	outline: none !important;
}
::-webkit-scrollbar {
    display: none;
}
p span{
	font-size: 16px !important;
        font-weight: 500;    
}
 p{
		font-size: 15px !important;
        font-weight: 400;
}
</style>
