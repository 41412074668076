<template>
	<div class="domain-modal">
		<div class="modal-hol">
			<form>
				<div class="modal-header border-0 pb-0">
					<h2>Edit Basics</h2>
					<button type="button" class="close" @click="handleHideEdit">+</button>
				</div>
				<div class="modal-body">
					<!-- <input
					type="hidden"
					class="form-control"
					name="domain_id"
					v-model="domain_id"
					/>
					<input
					type="hidden"
					class="form-control"
					name="receiver_id"
					v-model="receiver_id"
					/> -->
					<div class="form-group row mb-4">
						<label class="
							white-space
							col-12 col-sm-12 col-md-3
								control-label
								mb-2
								d-flex
								justify-content-start
								align-items-center
							">{{ DomainName }}</label>
						<div class="col-12 col-sm-12 col-md-9">
							<input type="text" id="domainName" v-model="domain_name" disabled
								placeholder="www.example.com" class="form-control" />
						</div>
					</div>
					<div class="form-group row mb-4">
						<label for="scan_frequency" class="
							white-space
						col-12 col-sm-12 col-md-3
								control-label
								mb-2
								d-flex
								justify-content-start
								align-items-center
							">{{ ScanFrequency }}</label>
						<div class="col-12 col-sm-12 col-md-9">
							<select id="scan_frequency" v-model="scan_frequency" v-on:change="checkPlan($event)"
								class="form-control">
								<option v-for="option in options" v-bind:value="option.value" :key="option.id">
									{{ option.text }}
								</option>
							</select>
						</div>
					</div>
					<div class="form-group row mb-4">
						<label for="scan_email" class="
							white-space
			col-12 col-sm-12 col-md-3
								control-label
								mb-2
								d-flex
								justify-content-start
								align-items-center
							">{{ Email }}</label>
						<div class="col-12 col-sm-12 col-md-9">
							<input type="email" id="scanEmail" v-model="scan_email" @keydown="clearError('scan_email')"
								placeholder="email@example.com" class="form-control" />
							<span class="text-danger error-tooltip" v-if="hasError('scan_email')"
								v-text="getError('scan_email')"></span>
						</div>
					</div>
				</div>

				<div class="modal-footer border-0 justify-content-start pt-0">
					<div class="form-group row m-0">
						<ul class="list-unstyled d-flex align-items-center p-0 m-0">
							<li class="mr-3 mb-2">
								<a class="btn btn-blue-bg" v-if="loading === false" @click="handleRole">{{ SaveChanges
									}}</a>
								<a class="btn btn-blue-bg" v-else><i class="fa fa-spinner fa-pulse fa-fw"></i>
									Processing...</a>
							</li>
							<li class="mb-2">
								<a type="button" class="btn btn-white-bg" @click="handleHideEdit">{{ Cancel }}</a>
							</li>
						</ul>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters, mapState } from 'vuex';
export default {
	name: "EditDomain",
	props: ['domain'],
	data() {
		return {
			domain_id: this.domain.id,
			receiver_id: this.domain.cb_reports_receivers && this.domain.cb_reports_receivers.id,
			domain_name: this.domain.name,
			scan_email: this.domain.cb_reports_receivers && this.domain.cb_reports_receivers.receiver_email,
			scan_frequency: this.domain.scan_frequency,
			options: [
				// {text: 'weekly', value: 'weekly'},
				{ text: "monthly", value: "monthly" },
				{ text: "3 months", value: "3 months" },
				{ text: "6 months", value: "6 months" },
				{ text: "yearly", value: "yearly" },
				{ text: "only once", value: "only_once" },
			],
			loading: false,
			DomainName: "Domain name:",
			ScanFrequency: "Scan frequency:",
			Email: "Email:",
			Cancel: "Cancel",
			SaveChanges: "Save Changes",
			errors: {}
		};
	},
	computed: {
		...mapState('domain', ['userId', 'userEmail', 'user', 'assign_role']),
		...mapGetters({
			domain_scan_frequency: 'domain/getFeatures', //domain_scan_frequency,plan_name,domain_limit
		}),
		domain_scan_frequency: function () {
			return parseInt(this.$store.getters['domain/getFeatures'].scan_frequency);
		},
		allowed_scan_frequencies: function () {
			switch (this.domain_scan_frequency) {
				case 0:
					return ["only_once"];
					break; // Free
				case 1:
					return ["day", "weekly", "monthly", "3 months", "6 months", "yearly"];
					break;
				case 2:
					return ["weekly", "monthly", "3 months", "6 months", "yearly"];
					break;
				case 3:
					return ["monthly", "3 months", "6 months", "yearly"];
					break; // Pro, Premium
				case 4:
					return ["3 months", "6 months", "yearly"];
					break; // standard
				case 5:
					return ["6 months", "yearly"];
					break;
				case 6:
					return ["yearly"];
					break;
				default:
					return ["only_once"];
			}
		}
	},
	mounted() {
		// console.log('domain_scan_frequency',this.domain_scan_frequency)
	},

	methods: {
		async handleRole() {
			if (!this.user.isSuperAdmin && this.user.superAdminId !== null && this.assign_role === 'reader') {
				await this.handleHideEdit();
				const result = await Swal.fire({
					title: "Unauthenticated",
					html: "Please Contact Your Admin",
					icon: "info",
					confirmButtonColor: "#3b6ef8",
					confirmButtonText: "Confirm",
				});
			} else {
				this.editDomain();
			}
		},

		handleHideEdit: function () {
			this.$emit("onHideEdit");
		},
		checkPlan(event) {
			let frequency = event.target.value;
			if (!this.allowed_scan_frequencies.includes(frequency)) {
				this.$store.commit("domain/setShowPricingPopup", true);
				this.scan_frequency = this.domain.scan_frequency;
			} else {
				this.scan_frequency = frequency;
			}
		},
		editDomain() {
			let payload = {
				domain_id: this.domain_id,
				receiver_id: this.receiver_id,
				//domain_name : self.domain_name,
				scan_email: this.scan_email,
				scan_frequency: this.scan_frequency,
				user_id: this.userId,
				email: this.userEmail
			};
			this.loading = true;
			this.$store.dispatch('domain/editDomain', payload)
				.then(() => {
					this.loading = false;
					this.handleHideEdit();
				})
				.catch((error) => {
					this.loading = false;
					let message = '';
					let response = ((typeof error.response !== 'undefined') ? error.response : '');
					if (response && response.data && error.response.data.url !== undefined) {
						this.popupAlert("Want to unlock more domains? , Upgrade your membership plan to add more domains.");
					}
					else if (response.data && error.response.data.message) {
						this.$store.commit('domain/setShowAddModel', false)
						message = error.response.data.message;
						let confirmtxt = "OK";
						Swal.fire({
							icon: "error",
							title: message,
							timer: 4000,
							showConfirmButton: false,
							confirmButtonText: confirmtxt
						});
					}
					if (error.response) {
						if (error.response.data.errors !== undefined)
							this.errors = error.response.data.errors;
					}
				});
		},
		hasError: function (field) {
			if (this.errors[field]) return true;
			return false;
		},
		hasAnyError() {
			return Object.keys(this.errors).length > 0;
		},
		getError(field) {
			if (this.errors[field]) {
				return this.errors[field][0];
			}
		},
		clearError(field) {
			delete this.errors[field];
		},
	},
};
</script>

<style scoped></style>