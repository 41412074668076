  // initial state
  const state = {
    cancelLoadDialoguePropertiesToken: null,
    cancelFilterScriptsToken: null,
    cancelGetCurrentUserToken: null,
    cancelGetAuthUserToken:null,
    CancelPricingPopupDataToken:null,
    cancelGetAllDomainsToken:null,
    cancelDeleteDomainToken:null,
    cancelScanNowToken:null,
    cancelSendEmailToken:null,
    cancelEditDomainToken:null,
    cancelCookieDialogueToken:null,
    cancelCreateCookieToken:null,
    cancelLoadCookiesToken:null,
    cancelGetReportToken:null, 
    cancelCheckForSurveyToken:null,
    cancelLoadCurrentCookieXrayToken:null,
    // cancelConsentLogToken:null,
    cancelLoadScriptToken:null,
  }
  const actions = {
  }
  const mutations = {
    setCancelLoadDialoguePropertiesToken (state,cancelToken) {
      state.cancelLoadDialoguePropertiesToken = cancelToken;
    },
    setDestroyCancelLoadDialoguePropertiesToken (state) {
      state.cancelLoadDialoguePropertiesToken = null;
    },
    setCancelFilterScriptsToken (state,cancelToken) {
      state.cancelFilterScriptsToken = cancelToken;
    },
    setDestroyCancelFilterScriptsToken (state) {
      state.cancelFilterScriptsToken = null;
    },
    setCancelGetCurrentUserToken (state,cancelToken) {
      state.cancelGetCurrentUserToken = cancelToken;
    },
    setDestroyCancelGetCurrentUserToken (state) {
      state.cancelGetCurrentUserToken = null;
    },
    setCancelGetAuthUserToken (state,cancelToken) {
      state.cancelGetAuthUserToken = cancelToken;
    },
    setDestroyCancelGetAuthUserToken (state) {
      state.cancelGetAuthUserToken = null;
    },
    setCancelPricingPopupDataToken (state,cancelToken) {
      state.cancelPricingPopupDataToken= cancelToken;
    },
    setDestroyCancelPricingPopupDataToken (state) {
      state.cancelPricingPopupDataToken = null;
    },
    setCancelGetAllDomainsToken (state,cancelToken) {
      state.cancelGetAllDomainsToken = cancelToken;
    },
    setDestroyCancelGetAllDomainsToken (state) {
      state.cancelGetAllDomainsToken = null;
    },
    setCancelDeleteDomainToken (state,cancelToken) {
      state.cancelDeleteDomainToken= cancelToken;
    },
    setDestroyCancelDeleteDomainToken (state) {
      state.cancelDeleteDomainToken = null;
    },
    setCancelScanNowToken (state,cancelToken) {
      state.cancelScanNowToken = cancelToken;
    },
    setDestroyCancelScanNowToken (state) {
      state.cancelScanNowToken = null;
    },
    setCancelSendEmailToken (state,cancelToken) {
      state.cancelSendEmailToken = cancelToken;
    },
    setDestroyCancelSendEmailToken (state) {
      state.cancelSendEmailToken = null;
    },
    setCancelEditDomainToken (state,cancelToken) {
      state.cancelEditDomainToken = cancelToken;
    },
    setDestroyCancelEditDomainToken (state) {
      state.cancelEditDomainToken = null;
    },
    setCancelCookieDialogueToken (state,cancelToken) {
      state.cancelCookieDialogueToken = cancelToken;
    },
    setDestroyCancelCookieDialogueToken (state) {
      state.cancelCookieDialogueToken = null;
    },
    setCancelCreateCookieToken (state,cancelToken) {
      state.cancelCreateCookieToken = cancelToken;
    },
    setDestroyCancelCreateCookieToken (state) {
      state.cancelCreateCookieToken = null;
    },
    setCancelLoadCookiesToken (state,cancelToken) {
      state.cancelLoadCookiesToken = cancelToken;
    },
    setDestroyCancelLoadCookiesToken (state) {
      state.cancelLoadCookiesToken = null;
    },
    setCancelGetReportToken(state,cancelToken) {
      state.cancelGetReportToken = cancelToken;
    },
    setDestroyCancelGetReportToken(state) {
      state.cancelGetReportToken = null;
    },
    setCancelCheckForSurveyToken(state,cancelToken) {
      state.cancelCheckForSurveyToken = cancelToken;
    },
    setDestroyCancelCheckForSurveyToken(state) {
      state.cancelCheckForSurveyToken = null;
    },
    setCancelLoadCurrentCookieXrayToken(state,cancelToken) {
      state.cancelLoadCurrentCookieXrayToken = cancelToken;
    },
    setDestroyCancelLoadCurrentCookieXrayToken(state) {
      state.cancelLoadCurrentCookieXrayToken = null;
    },
    // setCancelConsentLogToken(state,cancelToken) {   
    //   state.cancelConsentLogToken = cancelToken;
    // },
    // setDestroyCancelConsentLogToken(state) {
    //   state.cancelConsentLogToken = null;
    // },
    setCancelLoadScriptToken(state,cancelToken) {   
      state.cancelLoadScriptToken = cancelToken;
    },
    setDestroyCancelLoadScriptToken(state) {
      state.cancelLoadScriptToken = null;
    },
    
  }
  
export default {
    namespaced: true,
    state,
    mutations,
    actions
}