<template>
	<div class="row">
		<div class="col-12">
			<ul
				class="
					list-unstyled
					border-bottom
					pb-4
					mb-4
					d-flex 
          flex-wrap
					align-items-center
					tab-list-get-code
				"
			>
				<li>
					<a
						class="btn"
						@click="tab = 'Gmc'"
						:class="[tab === 'Gmc' ? 'btnbluebg' : '']"
						><img src="@/assets/images/coding.png" />
						<span class="pre-tooltip-text"
							>Get Manual Code for your website
						</span>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'Gtm'"
						:class="[tab === 'Gtm' ? 'btnbluebg' : '']"
						><img src="@/assets/images/gtm.png" />
						<span class="pre-tooltip-text">
							Get Manual Code for GTM
						</span>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'Gcm'"
						:class="[tab === 'Gcm' ? 'btnbluebg' : '']"
						v-if="show_google_consent_button"
						><img src="@/assets/images/google-icon.png" />
						<span class="pre-tooltip-text">
							Get Manual Code for Google Consent Mode
						</span>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'Fcm'"
						:class="[tab === 'Fcm' ? 'btnbluebg' : '']"
						v-if="show_facebook_consent_button"
						><img src="@/assets/images/facebook.png" />
						<span class="pre-tooltip-text">
							Get Manual Code for Facebook Consent Mode
						</span>
					</a>
				</li>
			</ul>
		</div>
		<div class="col-12">
			<component :is="tab" />
		</div>
	</div>
</template>

<script>
import Gmc from "@/components/Integrations/ManualIntegration/Gmc.vue";
import Gtm from "@/components/Integrations/ManualIntegration/Gtm.vue";
import Gcm from "@/components/Integrations/ManualIntegration/Gcm.vue";
import Fcm from "@/components/Integrations/ManualIntegration/Fcm.vue";
export default {
	name: "ManualInstalation",
	components: {
		Gmc,
		Gtm,
		Gcm,
		Fcm,
	},
	data() {
		return {
			tab: "Gmc",
		}
	},
	computed:{
		show_google_consent_button: function() {
			return this.$store.getters['getCode/getGoogleConsentModeButton'];
		},
		show_facebook_consent_button: function() {
			return this.$store.getters['getCode/getFacebookConsentModeButton'] == 1;
		},
	},
	mounted() {
		//
	},
};
</script>

<style scoped>
.tab-list-get-code{
  grid-gap: 20px;
}
.tab-list-get-code li a{
  width: 96px;
    height: 96px;
    border-radius: 4px!important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    transition: all .3s ease-in-out;
    border: 1px solid rgba(0,0,0,.1);
-webkit-box-shadow:  0px 0px 6px rgba(0, 0, 0, 0.15);
-moz-shadow:  0px 0px 6px rgba(0, 0, 0, 0.15);
-ms-shadow:  0px 0px 6px rgba(0, 0, 0, 0.15);
-o-shadow:  0px 0px 6px rgba(0, 0, 0, 0.15);
box-shadow:  0px 0px 6px rgba(0, 0, 0, 0.15);
}
.tab-list-get-code li a:hover{
    border: 1.5px solid #3b6ef8;
        transform: scale(1.1);
}
.tab-list-get-code li a.btnbluebg{
    border: 2px solid #3b6ef8;
        transform: scale(1.1);
}
.tab-list-get-code li a .pre-tooltip-text {
    white-space: break-spaces;
    visibility: hidden;
    width: 120px;
    background-color: #292929;
    color: #fff;
    text-align: left;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    bottom: 115%;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 10px;
    line-height: 15px !important;
}
.tab-list-get-code li a img{
  width: 50px;
}
.tab-list-get-code li a .pre-tooltip-text:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
transform: translateX(-50%);
    border-width: 7px;
    border-style: solid;
    border-color: #292929 transparent transparent transparent;
}
.tab-list-get-code li a:hover .pre-tooltip-text {
    visibility: visible;
    opacity: 1;
}
@media (max-width: 766.99px){
  .tab-list-get-code{
justify-content: space-evenly;
  }
}
</style>