<template>
    <div class="form-group form-group-md">
        <select class="form-control js-select" style="width: auto" v-model="selected">
            <option v-for="choice,key in question.choices" :key="key" 
                    :style="getStyles"
                    :name="question.name"
                    :value="choice.value">{{ choice.text }}</option>
        </select>
    </div>
</template>

<script>
    export default {
        props:['question','question_index'],
        data(){
            return {
            }
        },
        watch:{
        },
        computed: {
            userId: function(){
            return this.$store.state.domain.userId;
        },
        domain_id: function () {
        return this.$store.getters["domain/getCurrentDomainId"];
        },
            selected: {
                set: function (value){
                    this.$store.dispatch('policy/saveCurrentAnswer',{user_id:this.userId,domain_id:this.domain_id,question_name:this.question.name,answer:value});
                },
                get: function(value){
                    return this.$store.getters['policy/getCurrentAnswer'];
                }
            }

        },
        mounted() {
        },
        methods:{
            getStyles: function (){
                return '';
            }
        }
    }
</script>
<style scoped>
</style>