<template>
	
	  
	<div class="row">
		<div class="col-12">
			<h3 class="text-dark mb-4">Scripts</h3>
			<p class="d-block mb-4 text-gray-dark">
				Choose the integration according to your website
			</p>
		</div>
            <div class="col-12">
				<ul
					class="
						list-unstyled
						border-bottom
						p-0
						mb-4
						d-flex
						align-items-center
						tab-list-get-code
					"
				>
					<li class="mb-4 mr-3">
						<a class="btn"
							@click="tab = 'ManualInstalation'"
							:class="[tab === 'ManualInstalation' ? 'btngreenbg' : 'btntabbg']"
							>Manual Instalation</a
						>
					</li>
					<li class="mb-4 mr-3">
						<a class="btn "
							@click="tab = 'PluginsInstalation'"
							:class="[
								tab === 'PluginsInstalation' ? 'btngreenbg' : 'btntabbg',
							]"
							>Plugins Instalation
						</a>
					</li>
				</ul>
			</div>
			<div v-show="this.inLoader" class="report-hol" style="margin-top: 50px ; margin-left: 500px ;" >
        <div  class="before scanning-block text-center py-5">
            <PulseLoader  style="display:inline-block" />
        </div>
      </div>
            <div v-show="!this.inLoader" class="col-12">
				<component  :is="tab" />
			</div>
		<div class="col-12">
			<ul
				class="
					list-unstyled
					d-flex
					align-items-center
					p-0
					domain-hol
					mb-0
				"
			>
				<li class="mr-3 mb-3">
					<router-link to="/" class="btn btn-blue-bg">Previous</router-link>
				</li>
				<!-- <li class="mr-3 mb-3">
					<a class="btn btn-white-bg">Copy Script</a>
				</li> -->
				<li class="mr-3 mb-3">
					<router-link to="/customize" class="btn btn-blue-bg">Customisation</router-link>
				</li>
			</ul>
		</div>
	</div>

</template>

<script>
import ManualInstalation from "@/components/Integrations/ManualIntegration/ManualInstalation.vue";
import PluginsInstalation from "@/components/Integrations/PluginsIntegration/PluginsInstalation.vue";
import { mapState, mapGetters } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
	name: "GetCode",
	components: {
		ManualInstalation,
        PluginsInstalation,
		PulseLoader,
	},
	data() {
		return {
			tab: "ManualInstalation",
			mounted: false
		};
	},
	watch:{
		domain_id: {
			// deep: true,
			handler(){
				this.loadScript()
			}
		},
	},
	computed:{
		...mapGetters({
			domain_id: 'domain/getCurrentDomainId',
		}),
        ...mapState({
			
			
			inLoader: state => state.domain.inLoader,
			user_id: state => state.domain.userId,
			form: state => state.domain.form
		}),
	
	},

	mounted(){
		this.loadScript();
		// if(this.domain_id){
		// 	// this.$router.replace(this.$route.query.from);
		// 	if(this.domain_id == 0){
		// 	// this.$router.replace(this.$route.query.from);
		// 	// let backRoute = this.$router.options.history.state.back;
		// 	// this.$router.push(backRoute)
		// 	// console.log(this.$router.options.history.state.back)
		// }
		// }
	},
	methods: {
		loadScript() {
			this.$store.commit('domain/inLoader', true)
			const self = this;
			let payload = {
			domain_id: self.domain_id,
			user_id: self.user_id
			};
			// console.log('pp',payload)
			this.$store.dispatch('getCode/loadScript',payload)
			.then(()=>{
				this.$store.commit('domain/inLoader', false)
			})
		}
	},
};
</script>

<style scoped>
.btntabbg{
background: #E0E0E0;
color: #000;
}
.btngreenbg{
background: #6CC04A !important;
color:  #fff !important;
}
@media (max-width: 575.99px){
    .tab-list-get-code{
        flex-direction: column !important;
		margin-bottom: 15px !important;
    }
.tab-list-get-code li{
margin-right: 0 !important;
 margin-bottom: 15px !important;
 width: 100% !important;
}
.tab-list-get-code li a{
    width: 100% !important;
}
}

</style>