<template>
	<div v-show="this.inLoader" class="report-hol" style="margin-top: 50px;">
		<div class="before scanning-block text-center py-5">
			<PulseLoader style="display:inline-block" />
		</div>
	</div>
	<div v-show="!this.inLoader" class="row">
		<div class="col-12">
			<ul class="
						list-unstyled
						p-0
						pb-2
						mb-2
						d-flex
						align-items-center
					">
				<li class="mr-3">
					<a @click="tab = 'ScanReport'" class="btn" :class="[tab === 'ScanReport' ? 'btnbluebg' : 'btntabbg']">{{
						ScanReport }}</a>
				</li>
				<li>
					<a @click="tab = 'ConsentLog'" class="btn" :class="[
						tab === 'ConsentLog' ? 'btnbluebg' : 'btntabbg',
					]">{{ ConsentLog }}
					</a>
				</li>
			</ul>
		</div>
		<div class="col-12">
			<component :is="tab" />
		</div>
	</div>
</template>

<script>
import ConsentLog from "@/components/CustoMiZationComponent/ReportsComponenet/ConsentLog.vue";
import ScanReport from "@/components/CustoMiZationComponent/ReportsComponenet/ScanReport.vue";
import { mapState } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
	name: "Reports",
	components: {
		ConsentLog,
		ScanReport,
		PulseLoader,
	},
	data() {
		return {
			tab: "ScanReport",
			ScanReport: "Scan Report",
			ConsentLog: "Consent Log",
		};
	},
	computed: {
		...mapState('domain', ['inLoader']
		),
	},
};
</script>

<style scoped>
.btntabbg {
	background: #c2ccd7;
}

.btnbluebg {
	background: #6CC04A !important;
	color: #fff !important;
}

</style>