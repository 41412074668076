<template>
  <div id="app">
    <!-- <PageNotFound /> -->
    <component :is="$route.meta.layout || 'div'">
      <router-view  />
    </component>
  </div>
</template>
<script>
// import PageNotFound from "@/views/Page404.vue"

export default {
  name: "App",
  // components:{
  //   PageNotFound
  // }
};
</script>
 
<style>
</style>