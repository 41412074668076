<template> 
	<div class="domain-modal" v-if="show_add_domain">
		<div class="modal-hol">
		<form>
				<div class="modal-header border-0 pb-0">
					<h2>Add Domain</h2>
					<button type="button" @click="this.$store.commit('domain/setShowAddModel', false)" class="close">+</button>
				</div>
				<div class="modal-body">
					<div class="form-group row mb-4">
						<label
							class="
							white-space
								col-12 col-sm-12 col-md-3
								control-label
								mb-2
								d-flex
								justify-content-start
								align-items-center
							"
							>Domain Name:
						</label>
						<div class="col-12 col-sm-12 col-md-9">
							<input
								type="text"
								id="domain_name"
								:class="hasError('domain_name') ? 'border-danger' : ''"
								v-model="domain_name"
								placeholder="www.example.com"
								class="form-control"
								@keydown="clearError('domain_name')"
							/>
							<span
								class="text-danger-custom"
								v-if="hasError('domain_name')"
								v-text="getError('domain_name')"
							></span>
						</div>
					</div>
					<div class="form-group row mb-4">
						<label
							for="scan_frequency"
							class="
							white-space
								col-12 col-sm-12 col-md-3
								control-label
								mb-2
								d-flex
								justify-content-start
								align-items-center
							"
						>
							Scan frequency:
						</label>
						<div class="col-12 col-sm-12 col-md-9">
							<select id="scan_frequency" class="form-control" v-model="scan_frequency" v-on:change="checkPlan($event)">
								<option
								v-for="option in options"
								v-bind:value="option.value"
								:key="option.id"
							>
								{{ option.text }}
							</option>
							</select>
						</div>
					</div>
					<div class="form-group row mb-2">
						<label
							for="scan_email"
							class="
							white-space
								col-12 col-sm-12 col-md-3
								control-label
								mb-2
								d-flex
								justify-content-start
								align-items-center
							"
						>
							Email:
						</label>
						<div class="col-12 col-sm-12 col-md-9">
							<input
								type="email"
								id="scan_email"
								:class="hasError('scan_email') ? 'border-danger' : ''"
								v-model="scan_email"
								placeholder="email@example.com"
								class="form-control"
								@keydown="clearError('scan_email')"
								
							/>
							<span
							class="text-danger-custom"
								v-if="hasError('scan_email')"
								v-text="getError('scan_email')"
							></span>
						</div>
					</div>
				</div>
				<div class="modal-footer border-0 justify-content-start pt-0">
					<div class="form-group row">
						<ul class="list-unstyled d-flex align-items-center p-0 m-0">
							<li>
								<a class="btn btn-blue-bg" v-if="loading == false" @click="addDomain()">Save</a>
								<a class="btn btn-blue-bg" v-else ><i  class="fa fa-spinner fa-pulse fa-fw"></i></a>
								
							</li>
						</ul>
					</div>
				</div>
			</form>
		</div>
	</div>

</template>

<script>
import Swal from "sweetalert2";
import { mapGetters, mapState } from 'vuex';
export default {
	name: "DomainAdd",
	props:['total_domains'],
	data(){
		return {
			loading: false,
			domain_name: "",
            scan_email: null,
			scan_frequency: "only_once",
			options: [
				{ text: "monthly", value: "monthly" },
				{ text: "3 months", value: "3 months" },
				{ text: "6 months", value: "6 months" },
				{ text: "yearly", value: "yearly" },
				{ text: "only once", value: "only_once" },
			],
			
			
			errors: {},
		}
	},
	computed:{
		...mapState({
			form: state => state.dialogue.form,default: state => state.domain.default,userId: state => state.domain.userId, userEmail: state => state.domain.userEmail,
		}),	
		...mapGetters({ 
			show_add_domain: 'domain/getAddDomainModel',
			user: 'domain/getUser', //plan,
			getFeatures: 'domain/getFeatures', //domain_scan_frequency,plan_name,domain_limit
		}),
		domain_scan_frequency: function() {
			return parseInt(this.$store.getters['domain/getFeatures'].scan_frequency);
		},
		allowed_scan_frequencies: function() {
			switch (this.domain_scan_frequency) {
				case 0:
				return ["only_once"];
				break; // Free
				case 1:
				return ["day", "weekly", "monthly", "3 months", "6 months", "yearly"];
				break;
				case 2:
				return ["weekly", "monthly", "3 months", "6 months", "yearly"];
				break;
				case 3:
				return ["monthly", "3 months", "6 months", "yearly"];
				break; // Pro, Premium
				case 4:
				return ["3 months", "6 months", "yearly"];
				break; // standard
				case 5:
				return ["6 months", "yearly"];
				break;
				case 6:
				return ["yearly"];
				break;
				default:
				return ["only_once"];
			}
		},
		scan_email: {
			set: function (userEmail) {
				 return this.scan_email = userEmail;
			},
			get: function () {
				if(this.scan_email != '' && this.scan_email != null){
					return this.scan_email;
				}
				else if(this.scan_email == ''){
					return this.scan_email;
				}
				else{
					return this.scan_email = this.userEmail;
				}
				// return this.scanning_email != '' ? this.scanning_email :  this.userEmail;
				//return  this.userEmail;
			},
			
    	},

		
	},
	mounted() {

		//console.log('scan_email',this.userEmail)
		// console.log('first12',this.default)
	},
	methods: {
		
		submit(){
			return this.$store.commit('domain/setShowAddModel',false)
		},
		checkPlan(event) {
			let allowed = true,
				frequency = event.target.value;
			if (!this.allowed_scan_frequencies.includes(frequency) && frequency !== "only_once") {
				this.$store.commit("domain/setShowPricingPopup", true);
				allowed = false;
			}

			this.scan_frequency = allowed ? frequency : "only_once";
		},
		 addDomain() {
			this.loading = true;
			if (this.getFeatures.domain_limit - this.total_domains > 0) {
				let payload = {
				domain_name: this.domain_name,
				scan_email: this.scan_email,
				scan_frequency: this.scan_frequency,
				user_id: this.userId,
				priority: this.getFeatures.plan_name === "free" ? 0 : 1,
				};
				this.$store.dispatch('domain/addDomain',payload)
				.then( async (response) => {
					if(response.status === 200){
						console.log('response',response)
					// this.$store.commit('domain/setEmptyForm')
					this.$store.commit('dialogue/resetState')
					this.form.domain_id = response.data.domain.id;
					this.form.user_id = response.data.domain.user_id;
					// this.form.language = this.language;
					// this.form.default_language = this.language.country_code;
					this.form.banners.push(this.default);
					await this.$store.dispatch('domain/coookieDialogue',this.form)
					this.$store.commit('domain/setNewDomain',response.data.domain)
					await this.$store.dispatch("domain/getAllDomains", this.userId);
					let responseMessage = {
						icon: 'success',
						showCancelButton: false,
						confirmButtonText: 'Ok',
						status: response.status,
						message: response.data.message
					}
					this.$store.commit('domain/swalAlert',responseMessage)
					}
					
					this.loading = false;
					
					this.$store.commit('domain/setShowAddModel', false)
				})
				.catch((error) => {
					this.loading = false;
					let message = '';
					let response = ((typeof error.response !== 'undefined') ? error.response : '' );
					if (response && response.data && error.response.data.url !== undefined) {
					this.popupAlert("Want to unlock more domains? , Upgrade your membership plan to add more domains.");
					}
					else if (response.data && error.response.data.message) {
						this.$store.commit('domain/setShowAddModel', false)
						message = error.response.data.message;
						let confirmtxt = "OK";
						Swal.fire({
							icon: "error",
							title: message,
							timer: 4000,
							showConfirmButton: false,
							confirmButtonText: confirmtxt
						});
					}
					if (error.response) {
						if (error.response.data.errors !== undefined)
							this.errors = error.response.data.errors;
					}
				});
			}
			else {
				this.loading = false;
				this.$store.commit("domain/setShowPricingPopup", true);
				return;
			}
		},
		popupAlert: function(desc) {
			console.log(desc)
			this.$store.commit("domain/setShowPricingPopup", true);
			return;
		},
		hasError(field) {
			if (this.errors[field]) return true;
			return false;
		},
		hasAnyError() {
			return Object.keys(this.errors).length > 0;
		},
		getError(field) {
			if (this.errors[field]) {
				return this.errors[field][0];
			}
		},
		clearError(field) {
			delete this.errors[field];
		},
	},
};
</script>

<style scoped>
.text-danger-custom {
  color: #e1464e;
  font-size: 13px;
  text-align: left;
  margin-top: 7px;
}
.border-danger {
  border-color: #e1464e;
}
</style>