<!-- <template>
    <div class="domain-modal">
      <div class="modal-hol">
        <form @submit.prevent="handleSubmit">
          <div class="modal-header border-0 pb-0 m-auto">
            <h2>Edit Your Consent Frequency</h2>
            <button type="button" class="close" @click="handleHideEdit">+</button>
          </div>
          <div class="modal-body">
            <div class="form-group row mb-4">
              <label for="domain" class="col-12 col-sm-12 col-md-3 control-label mb-2 d-flex justify-content-start align-items-center">Domain Name:</label>
              <div class="col-12 col-sm-12 col-md-9">
                <input type="text" id="domain" v-model="localDomain" class="form-control" placeholder="domain" readonly>
              </div>
            </div>
            <div class="form-group row mb-4">
              <label for="frequency" class="col-12 col-sm-12 col-md-3 control-label mb-2 d-flex justify-content-start align-items-center">Frequency:</label>
              <div class="col-12 col-sm-12 col-md-9">
                <select id="frequency" v-model="frequency" v-on:change="checkPlan($event)" class="form-control">
                  <option v-for="option in options" v-bind:value="option.value" :key="option.id">
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row mb-4">
              <label for="email" class="col-12 col-sm-12 col-md-3 control-label mb-2 d-flex justify-content-start align-items-center">Enter Email:</label>
              <div class="col-12 col-sm-12 col-md-9">
                <input type="text" id="email" v-model="localEmail" class="form-control" placeholder="Enter Field Three">
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-start pt-0">
            <button type="submit" class="btn btn-blue-bg">Submit</button>
            <button type="button" class="btn btn-white-bg" @click="handleHideEdit">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </template> -->
<template>
  <div class="domain-modal">
    <div class="modal-hol">
      <form @submit.prevent="handleRole">
        <div class="modal-header">
          <h2>Edit Your Consent Frequency</h2>
          <button type="button" class="close" @click="handleHideEdit">+</button>
        </div>
        <div class="modal-body">
          <!-- Utilize Bootstrap grid system for responsive alignment -->
          <div class="form-group row">
            <label for="domain" class="col-sm-3 col-form-label">Domain Name:</label>
            <div class="col-sm-9">
              <input type="text" id="domain" v-model="localDomain" class="form-control" placeholder="domain" readonly>
            </div>
          </div>
          <div class="form-group row">
            <label for="frequency" class="col-sm-3 col-form-label">Frequency:</label>
            <div class="col-sm-9">
              <select id="frequency" v-model="frequency" v-on:change="checkPlan($event)" class="form-control">
                <option v-for="option in options" v-bind:value="option.value" :key="option.id">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="email" class="col-sm-3 col-form-label">Enter Email:</label>
            <div class="col-sm-9">
              <input type="text" id="email" v-model="localEmail" class="form-control" placeholder="Enter Field Three">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary">Submit</button>
          <button type="button" class="btn btn-secondary" @click="handleHideEdit">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { swalAlert } from '@/store/modules/domain/mutations';
import Swal from "sweetalert2";
import { mapState } from 'vuex';
import axios from 'axios';

export default {
  name: "Emailcsv",
  data() {
    return {
      localEmail: '',
      localDomain: '',
      frequency: '',
      options: [
        { text: "Weekly", value: "7" },
        { text: "Monthly", value: "30" },
        { text: "Quarterly", value: "90" },
      ],
    };
  },
  computed: {
    cmp_url() {
      return process.env.VUE_APP_ENV_VARIABLE
    },
    front_url() {
      return process.env.VUE_APP_FRONT
    },
    ...mapState('domain', ['current_domain', 'userEmail' , 'user' , 'assign_role']),
  },
  created() {

    this.getConsentFrequencyTableData();
  },

  methods: {
    async handleRole() {
      if (!this.user.isSuperAdmin && this.user.superAdminId !== null && this.assign_role === 'reader') {
        await this.handleHideEdit();
        const result = await Swal.fire({
          title: "Unauthenticated",
          html: "Please Contact Your Admin",
          icon: "info",
          confirmButtonColor: "#3b6ef8",
          confirmButtonText: "Confirm",
        });
      } else {
        this.handleSubmit();
      }
    },

    getConsentFrequencyTableData() {
      let dom_id = this.current_domain.id;
      axios.get(`${this.cmp_url}/api/auth/getEmailConsentLogs/${dom_id}`)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.email) {
              if (this.current_domain && this.current_domain.name) {
                this.localDomain = this.current_domain.name;
              }
              this.localEmail = response.data.email;
              this.frequency = response.data.frequency;
            } else {
              if (this.current_domain && this.current_domain.name) {
                this.localDomain = this.current_domain.name;
              }
              this.localEmail = this.userEmail;
              this.frequency = '7';
            }
          }
        })
        .catch((error) => {
          console.error("There was an error:", error);
        });
    },
    handleHideEdit() {
      this.$emit("onHideEdit");
    },
    handleSubmit() {
      console.log(`Submitted: Domain: ${this.localDomain}, Frequency: ${this.frequency}, Email: ${this.localEmail}, ID: ${this.current_domain.id}`);
      this.handleHideEdit();

      let params = {
        domain: this.localDomain,
        frequency: this.frequency,
        email: this.localEmail,
        dom_id: this.current_domain.id,
      };

      axios.post(`${this.cmp_url}/api/auth/emailConsentLogs`, params)
        .then((response) => {
          if (response.status === 200) {
            console.log('Data received:', params);
            console.log(response);
          }
        })
        .catch((error) => {
          console.error("There was an error:", error);
        });

      if (this.current_domain.allow_email_consents == 0) {
        // window.location.href = `${this.seers_url}/contact`
        Swal.fire({
          title: "Contact Us",
          html:
            "Contact us to enquire more about Email Consent Logs",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3b6ef8",
          cancelButtonColor: "#6cc04a",
          confirmButtonText: "Contact Us",
          cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.value) {
              window.open(`${this.front_url}/contact`, '_blank');
            } 
          });
       } 
        }    
    },
};
// export const emailConsentLogs = async (payload) => {
//     try {
      
//         const response = await Api.get(`emailConsectLogs/${payload}`,{
//             headers: {
//                 Authorization: 'Bearer ' + state.userToken,
//             },
//           })
//         if(response){
//             return response;
//         }
//     } catch (error) {
//         console.log(error)
//     }
// }
</script>


<!-- <style scoped>
  .domain-modal {
    position: fixed;
    top: 26%;
    left: 30%;
    width: 37%;
    height: 48%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  
  .modal-hol {
    background: white;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    width: 100%; 
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .close {
    cursor: pointer;
    font-size: 24px;
    border: none;
    background: none;
  }
  
  .form-control {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
  .btn-blue-bg {
    color: white;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-white-bg {
    color: #333;
    background-color: white;
    border-color: #ccc;
  }
  
  .btn, .form-control {
    margin-top: 10px; /* Spacing for buttons and form inputs */
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-start; /* Align buttons to the left */
  }
  </style> -->
  <style scoped>
  .domain-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 95%;
    width: 40%;
    /* width: auto; */
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
  }

  .modal-hol {
    padding: 1em;
  }

  .modal-header,
  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .close {
    cursor: pointer;
    font-size: 1.5em;
  }

  .form-control {
    width: 100%;
  }

  .btn-primary,
  .btn-secondary {
    margin-top: 0.5em;
  }

  @media (max-width: 768px) {
    .modal-hol {
      padding: 0.5em;
    }

    .modal-header h2,
    .modal-footer .btn {
      font-size: 0.9em;
    }

    .domain-modal {
      width: 95%;
    }
  }


</style>

   
