import Api from "@/apis/Api";
import axios from "axios";

export const loadScript = async ({  commit,rootState,state },payload) => {   
    try {
        // if (rootState.abortAxiosRequest.cancelLoadScriptToken) {
        //     rootState.abortAxiosRequest.cancelLoadScriptToken.cancel('Request canceled');
        //     commit('abortAxiosRequest/setDestroyCancelLoadScriptToken',{},{root: true});
        //   }
        //   const cancelToken = axios.CancelToken.source();
        //   commit("abortAxiosRequest/setCancelLoadScriptToken", cancelToken, { root: true })
          const  response = await Api.post('cookieXray-get-script',payload,{
            headers: {
                Authorization: 'Bearer ' + rootState.domain.userToken,
            },
            // cancelToken: cancelToken.token,
          })
        if(response.status === 200){
            // console.log('forAll',response.data.script)
            // commit('abortAxiosRequest/setDestroyCancelLoadScriptToken',{},{root: true});
            commit("setScript", response.data.script);
            commit("domain/setCurrentDomain", response.data.domain, { root: true });
        }
        return response;
    } catch (error) {
        console.log(error)
        throw error
    }
}