<template>
  <div class="row">
    <div v-if="loader">
      <TheLoader></TheLoader>
    </div>
    <div v-if="this.user.isSuperAdmin" class="col-12">
      <a href="javascript:void(0)" class="btn btn-green-bg d-inline-block cursor-pointer"
        :class="{ btnGreyabg: AddDomain }" @click="open_model">{{ AdDomain }}</a>

    </div>
    <!-- <p>okokok: {{this.getDomains}}</p> -->
    <div v-if="show_add_domain">
      <div class="overlay" style="opacity:0.5;"></div>
      <DomainAdd :total_domains="getDomains.length" />
    </div>
    <!-- <div v-if="currentActiveModal != ''">
			<div class="overlay"></div>
			<div class="domain-modal">
				<Modal :type="currentActiveModal" />
			</div>
		</div> -->
    <div v-show="this.inLoader" class="report-hol" style="margin-top: 50px; margin-left: 500px;">
      <div class="before scanning-block text-center py-5">
        <PulseLoader style="display:inline-block" />
      </div>
    </div>
    <div v-show="!this.inLoader" class="col-12 my-4" v-if="getDomains">
      <div class="row m-0">
        <div class="col-12 block-style py-2 px-3 mb-2 d-flex justify-content-between align-items-center"
          style="border-radius: 4px !important" v-for="(domain, index) in getDomains" :key="index">
          <ul class="list-unstyled p-0 m-0 mx-0 ">
            <li class="d-flex align-items-center">
              <div class="form-group mr-2 mb-0 d-flex justify-content-start align-items-center">
                <input type="checkbox" :id="'cmp-check' + domain.id" class="p-0 m-0"
                  :checked="domain.id == domain_id ? true : false" @change="domainCheckbox($event, domain.id)" style="
                    width: 16px;
                    height: 16px !important;
                    vertical-align: baseline;
                    margin-top: 6px !important;
                  " />
                <label :for="'cmp-check' + domain.id" class="mt-1 p-0">
                  <p class="font-weight-bold mb-0 ml-2"> {{ domain.name }}</p>
                </label>
              </div>

            </li>
          </ul>
          <ul class="list-unstyled m-0 mx-0 mt-0 p-0 d-flex justify-content-between align-items-center">
             <li v-if="domain.scan_verified===1 &&
            domain.dialogue.auto_blocking === 1 &&
            domain.dialogue.save_consent_logs === true &&
            domain.dialogue.cookie_policy_url !== null &&
            domain.dialogue.region_detection === 1 &&
            domain.dialogue.allow_reject === true &&
            domain.dialogue.is_cookie_banner === 1">
              <div class="tooltip-container pl-0 mb-0" style="display: inline-block; position: relative;">
                <img src="../assets/images/icon-verified.png" width="24" />
                <span class="tooltip-text">
                  Complaint
                </span>
              </div>
            </li>
            <li v-if="domain.scan_verified !==1 ||
            domain.dialogue.auto_blocking !== 1 ||
            domain.dialogue.save_consent_logs === false ||
            domain.dialogue.cookie_policy_url === null ||
            domain.dialogue.region_detection !== 1 ||
            domain.dialogue.allow_reject === false ||
            domain.dialogue.is_cookie_banner !== 1">
              <div class="tooltip-container pl-0 mb-0" style="display: inline-block; position: relative;">
              <img src="../assets/images/icon-unverified.png" width="24" />
              <span class="tooltip-text">
                Not Complaint
                </span>
              </div>
            </li> 
            <!-- <li class="ml-3 cursor-pointer"> -->

            <li class="ml-3 cursor-pointer">
              <a href="javascript:void(0)" @click="currentActiveModal(domain.id, 'ScanDomain')">
                <img src="../assets/images/domain-scan.svg" width="22" />
              </a>
            </li>
            <li class="ml-3 cursor-pointer">
              <a href="javascript:void(0)" @click="currentActiveModal(domain.id, 'ReportDomain')">
                <img src="../assets/images/domain-report.svg" width="19" />
              </a>
            </li>

            <li class="ml-3 cursor-pointer">
              <a href="javascript:void(0)" @click="currentActiveModal(domain.id, 'EditDomain')">
                <img src="../assets/images/domain-edit.svg" width="22" />
              </a>
            </li>
            <li class="ml-3 cursor-pointer">
              <a href="javascript:void(0)" @click="handleDeleteDomain(domain.id)">
              <img src="../assets/images/domain-delete.svg" width="22" />
            </a>
          </li>
          </ul>
          <div v-if="tabs.EditDomain.show">
            <div class="overlay"></div>
            <EditDomain v-if="tabs.EditDomain.show && current_domain_id === domain.id" @onHideEdit="hideEditDomain"
              :domain="domain" />
          </div>
          <div v-if="tabs.ReportDomain.show">
            <div class="overlay"></div>
            <ReportDomain v-if="tabs.ReportDomain.show && current_domain_id === domain.id"
              @onHideReport="hideReportDomain" :domain="domain" />
          </div>
          <div v-if="tabs.ScanDomain.show">
            <div class="overlay"></div>
            <ScanDomain v-if="tabs.ScanDomain.show && current_domain_id === domain.id" @onHideScan="hideScanDomain"
              :domain="domain" />
          </div>
        </div>
      </div>


      <div class="col-12 d-flex justify-content-start align-align-items-center">
        <router-link to="/get-code" v-if="Domain" class="btn btn-blue-bg d-inline-block"
          @click="loadComponent()">next</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import DomainAdd from "@/components/DomainAdd.vue";
import EditDomain from "@/components/EditDomain.vue";
import ReportDomain from "@/components/ReportDomain.vue";
import ScanDomain from "@/components/ScanDomain.vue";
import TheLoader from "@/components/headerComponent/TheLoader.vue";
import Swal from "sweetalert2";
import { mapGetters, mapState } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
  name: "AddDomain",
  components: {
    // Modal,
    DomainAdd,
    EditDomain,
    ReportDomain,
    ScanDomain,
    TheLoader,
    PulseLoader,
  },
  data() {
    return {
      loader: false,
      isModalActive: false,
      Domain: true,
      DeletDomain: false,
      AddDomain: false,
      AdDomain: "Add Domain",
      Email: "Email:",
      threeMonths: "3 months",
      sixMonths: "6 months",
      monthly: "monthly",
      yearly: "yearly",
      onlyOnce: "only once",
      ScanFrequency: "Scan frequency:",
      DomainName: "Domain name:",
      save: "save",
      current_domain_id: "",
      // selected : false,
      // currentActiveModal : '',
      tabs: {
        ScanDomain: { show: false },
        ReportDomain: { show: false },
        EditDomain: { show: false },
      },

    };
  },
  async mounted() {
    // console.log('user_id',this.user_id)
    await this.getAllDomains();
  },
  computed: {
    ...mapState({
      inLoader: state => state.domain.inLoader,
      user_id: state => state.domain.userId,
      form: state => state.dialogue.form,
      default: state => state.domain.default,
      user: state => state.domain.user,
      assignRole: state => state.domain.assign_role,
    }),
    ...mapGetters({
      show_add_domain: 'domain/getAddDomainModel',
      domain_id: 'domain/getCurrentDomainId',
      current_domain: 'domain/getCurrentDomain',
      getDomains: 'domain/getDomains',
    }),
    getTotalDomains() {
      return this.$store.getters['domain/getDomains'].length;
    },

  },
  methods: {
    async handleDeleteDomain(domainId) {
    if (!this.user.isSuperAdmin && this.user.superAdminId !== null && this.assignRole == 'reader') {
      const result = await Swal.fire({
        title: "Unauthenticated",
        html: "Please Contact To Your Admin",
        icon: "info",
        confirmButtonColor: "#3b6ef8",
        confirmButtonText: "Confirm",

      });
    } else {
      this.deleteDomain(domainId);
    }
  },
    ShowDomain() {
      this.Domain = true;
      this.AddDomain = false;
    },
    open_model() {
      this.$store.commit("domain/setShowAddModel", true);
    },
    loadComponent(comp) {
      let fire_popup = false;
      let title = "";
      let text = "";
      let confirm_button_text = "";


      if (Object.keys(this.getDomains).length === 0) {
        title = "Add domain first!";
        text = "You have added no domain.";
        confirm_button_text = "Add Domain";
        fire_popup = true;
      }
      else if (Object.keys(this.current_domain).length === 0) {
        const imagePath = require("@/assets/images/Add_Domain_Bar.png");
        Swal.fire({
          title: "Wait!",
          text: "Please select the domain.",
          // icon: "warning",
          imageUrl: imagePath,
          // imageUrl: "../../assets/images/google_banner-swl.png",
          confirmButtonColor: "#41a2db",
          cancelButtonColor: "#666666",
          confirmButtonText: "Ok",
          cancelButtonText: "OK",
        });
      }

      if (fire_popup) {
        Swal.fire({
          title: title,
          text: text,
          icon: "warning",
          confirmButtonColor: "#41a2db",
          cancelButtonColor: "#666666",
          confirmButtonText: confirm_button_text,
          cancelButtonText: "OK",
        });
      }
      return;
    },
    async getAllDomains() {
      await this.$store.dispatch("domain/getAllDomains", this.user_id);
    },
    currentActiveModal(domainId, currentModal) {
      this.current_domain_id = domainId;
      // this.$store.commit("domain/setCurrentDomainId", domainId);
      if (currentModal === "EditDomain") {
        this.tabs["ReportDomain"].show = false;
        this.tabs["ScanDomain"].show = false;
        if (this.current_domain_id === domainId) {
          this.tabs[currentModal].show = !this.tabs[currentModal].show;
        }
      } else if (currentModal === "ScanDomain") {
        this.tabs["ReportDomain"].show = false;
        this.tabs["EditDomain"].show = false;
        if (this.current_domain_id === domainId) {
          this.tabs[currentModal].show = !this.tabs[currentModal].show;
        }
      } else if (currentModal === "ReportDomain") {
        this.tabs["ScanDomain"].show = false;
        this.tabs["EditDomain"].show = false;
        if (this.current_domain_id === domainId) {
          this.tabs[currentModal].show = !this.tabs[currentModal].show;
        }
      }

    },
    hideEditDomain: function () {
      this.tabs.EditDomain.show = false;
    },
    hideScanDomain: function () {
      this.tabs.ScanDomain.show = false;
    },
    hideReportDomain: function () {
      this.tabs.ReportDomain.show = false;
    },
    domainCheckbox(e, domain_id) {
      if (e.target.checked) {
        this.$store.commit("domain/setCurrentDomainId", domain_id);
        this.$store.commit("domain/setMode", '');

        this.getDomains.forEach((domain) => {
          if (domain.id == domain_id) {
            if (domain.dialogue == null) {
              this.$store.commit('dialogue/resetState')
              this.form.domain_id = domain.id;
              this.form.user_id = domain.user_id;
              this.form.banners.push(this.default);
              this.$store.dispatch('domain/coookieDialogue', this.form)
              this.$store.dispatch("domain/getAllDomains", this.user_id);
            }

            this.$store.commit("domain/setCurrentDomain", domain);
            // console.log('domaindomain',domain)
            this.$store.commit("getCode/setGoogleConsentModeButton", domain && domain.dialogue && domain.dialogue.apply_google_consent);
            this.$store.commit("getCode/setFacebookConsentModeButton", domain && domain.dialogue && domain.dialogue.apply_facebook_consent);
            if (domain.script != null) {
              this.$store.commit("getCode/setScript", domain.script);
            }
            if (domain.dialogue) {
              this.$store.commit("domain/setDialogueProperties", domain.dialogue);
              // this.$store.commit("setHaveScriptkey", true);
            } else {
              // this.$store.commit("setHaveScriptkey", false);
            }
          }
        });
      }
      else {
        this.$store.commit("domain/setCurrentDomainId", false);
        this.$store.commit("domain/setCurrentDomain", false);
      }
    },
    deleteDomain(domainId) {
      let domain = this.getDomains.find((domain) => domain.id == domainId);
      if (!domain.enabled) {
        Swal.fire({
          title: "Warning",
          text: "The consent limit is reached. You cannot delete your domain URL",
          icon: "warning",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3b6ef8",
          cancelButtonColor: "#6cc04a",
          confirmButtonText: "Upgrade Now",
          cancelButtonText: "Cancel",
        }).then((result) => {
          if (result.value) {
            // this.$store.commit("setShowPricingPopup", true);
          }
        });
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure? You cannot undo this change!",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3b6ef8",
          cancelButtonColor: "#6cc04a",
          confirmButtonText: "Yes, Delete It!",
          cancelButtonText: "Cancel",
        }).then((result) => {
          this.$store.commit("domain/setCurrentDomainId", false);
          this.$store.commit("domain/setCurrentDomain", false);
          if (result.value) {
            let payload = {
              domainId: domainId,
              user_id: this.user_id
            };
            this.$store.dispatch('domain/deleteDomain', payload)

          }
        });
      }
      // this.$store.dispatch('deleteDomain',domainId)
    },
   

    
  },
};
</script>

<style scoped>
.overlay {
  opacity: 0.8;
}

.btnGreyabg {
  background: #b4b4b4 !important;
}

.domain-modal {
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  width: 564px;
  height: max-content;
  top: 50%;
  left: 50%;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 15%);
  border-radius: 8px;
  z-index: 99999;
  overflow: hidden;
  outline: 0;
  transform: translate(-50%, -50%);
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 10px;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
} 




@media (max-width: 575.99px) {
  .block-style {
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .block-style ul:nth-child(1) {
    margin-bottom: 15px !important;
  }

  .domain-modal {
    /* height: 40vh; */
    width: 90vw;
    overflow-y: scroll;
    padding: 0 !important;
  }

  domain-modal .form-group {
    flex-direction: column !important;
  }

  .domain-modal .form-group .limit-class {
    width: 100% !important;
  }

}
</style>
