<template>
	<div>
		<div class="sction-box mb-0">
			<h3 class="text-blue mb-3">How to install Google Consent Mode</h3>
		</div>
		<div class="sction-box">
			<div class="mb-2">
				<p class="mb-0">You need a Google Analytics code to activate Google Consent Mode on your website</p>
				<p>
					<span class="text-blue">Step 1:</span> Find the Google Analytics code in the header on the website
					or
					get it from your Google Analytics account.
				</p>
			</div>
			<p class="mb-2 text-blue d-block font-weight-bold">The code looks like this:</p>
			<textarea class="scriptcode mb-5" id="googleStep1" readonly style="height: 325px;">
				<script async src="https://www.googletagmanager.com/gtag/js?id=UA-xxxxxxxx-x"></script>
				<script> 
				window.dataLayer = window.dataLayer || [];

				function gtag() {

				dataLayer.push(arguments);}

				gtag('js', new Date());

				gtag('config', 'UA-xxxxxxxxx-x');

				</script>
			</textarea>
			<button class="btn btn-blue-bg mb-3 float-right" @click="copyScript('step1')">Copy Script</button>
			<p>
				<span class="text-blue">Step 2:</span>
				Insert Google Consent Mode code (below in bold) in the Google Analytics Code.
			</p>

			<textarea class="scriptcode mb-3" id="googleStep2" style="height: 450px;" readonly>
					<script type='text/javascript'> 
					window.dataLayer = window.dataLayer || [];
					function gtag() {
					dataLayer.push(arguments);}
					gtag('consent', 'default', {
					ad_storage: 'denied',
					analytics_storage: 'denied',
					functionality_storage: "denied", 
        				personalization_storage: "denied", 
        				security_storage: "denied", 
					ad_user_data: 'denied',
					ad_personalization: 'denied',
					wait_for_update: 500});
					gtag('set', 'ads_data_redaction' , true);
					</script>

					<!-- Global site tag (gtag.js) - Google Analytics -->
					<script async src="https://www.googletagmanager.com/gtag/js?id=UA-xxxxxxxxx-x"></script>
					<script> 
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());
							gtag('config', 'UA-xxxxxxxxx-x');
					</script>
				</textarea>


			<button class="btn btn-blue-bg mb-5 float-right" @click="copyScript('step2')">Copy Script</button>
			<br>
			<br>
			<div class="mb-2 mt-5">

				<p><span class="text-blue">Step 3:</span> Now, insert the Seers' CMP code after the Google Consent Code
					and before the Google Analytics code.</p>
				<p><span class="text-blue">Note:</span> To activate Google Consent Mode, you must have Seers’ CMP code
					installed on your website.

					Seers CMP Code looks like this:</p>
			</div>



			<textarea readonly="readonly" class="scriptcode mb-3"
				id="googleStep3"><script data-key="{{ script && script.key }}" data-name="CookieXray" src="{{ script_url }}" type="text/javascript"></script></textarea>

			<button class="btn btn-blue-bg mb-5 float-right" @click="copyScript('step3')">Copy Script</button>
			<p class="mt-5">
				<span class="text-blue">Step 4:</span> After adding Seers' CMP code, your actual code should look like
				this.
			</p>

			<textarea readonly="readonly" class="scriptcode mb-5" id="googleStep4" style="height:550px;">
					<script type='text/javascript'> 
					window.dataLayer = window.dataLayer || [];
					function gtag() {
					dataLayer.push(arguments);}
					gtag('consent', 'default', {
					ad_storage: 'denied',
					analytics_storage: 'denied',
					functionality_storage: "denied", 
        				personalization_storage: "denied", 
        				security_storage: "denied",
					ad_user_data: 'denied',
					ad_personalization: 'denied',
					wait_for_update: 500});
					gtag('set', 'ads_data_redaction' , true);
					</script>
 
					<script data-key='xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
					data-name='CookieXray' src='https://seersco.com/script/cb.js'
					type='text/javascript'></script>

					<!-- Global site tag (gtag.js) - Google Analytics -->  

					<script async src="https://www.googletagmanager.com/gtag/js?id=UA-xxxxxxxxx-x"></script>
					<script> 
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());
							gtag('config', 'UA-xxxxxxxxx-x');
					</script>
				</textarea>
			<button class="btn btn-blue-bg mb-3 float-right" @click="copyScript('step4')">Copy Script</button>
			<ol class="mb-3 pl-3">
				<li class="mb-2">
					Google Consent Mode is an optional feature and should be enabled in settings too. Go to your
					<strong>Dashboard &gt; Preferences tab &gt; Enable Google Consent Mode.</strong>
				</li>
				<li class="mb-2">
					Remember to enable the toggle button to <strong>“OK”</strong> to activate Google Consent Mode in the
					<strong>“settings”</strong> panel of the Seers CMP.
				</li>
				<li class="mb-2">
					Also, you must update the Google Analytics code, as described in Step 2.
				</li>
			</ol>
			<p>To read more about Google consent mode <a href="https://seerssupport.zendesk.com/">click here</a></p>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
	name: "Gcm",
	computed: {
		cmp_url() {
			return process.env.VUE_APP_BANNER_URL
		},
		show_google_consent_button: function () {
			return this.$store.getters['getCode/getGoogleConsentModeButton'] == 1;
		},
		script: function () {
			return this.$store.getters['getCode/getScript'];
		},
		script_url: function () {
			// return process.env.MIX_CB_HOST +'/'+ this.$store.getters.getUser.id +'/'+ this.$store.getters.getCurrentDomainId + "/cb.js";
			return this.cmp_url + '/banners/' + this.$store.state.domain.userId + '/' + this.$store.getters['domain/getCurrentDomainId'] + "/cb.js";
		},
	},
	methods: {
		copyScript(script) {
			if (script === "step1") {
				$("#googleStep1").select();
			}

			if (script === "step2") {
				$("#googleStep2").select();
			}

			if (script === "step3") {
				$("#googleStep3").select();
			}

			if (script === "step4") {
				$("#googleStep4").select();
			}
			Swal.fire({
				icon: "success",
				title: 'Code Copied',
				text: "Code copied successfully",
				timer: 4000,
				confirmButtonText: "Ok"
			});
			document.execCommand("copy");
		}
	},

};
</script>

<style scoped>
.sction-box {
	border: 0;
	margin-bottom: 30px;
	width: 100%;
	display: inline-block;
}

.scriptcode {
	border: 0;
	width: 100%;
	background: #fff !important;
	border-radius: 4px !important;
	-webkit-border-radius: 4px !important;
	padding: 20px;
	font-size: 14px;
	color: #707070;
	-webkit-box-shadow: 0px 0px 6px rgb(0 0 0 / 15%) !important;
	-moz-box-shadow: 0px 0px 6px rgb(0 0 0 / 15%) !important;
	box-shadow: 0px 0px 6px rgb(0 0 0 / 15%) !important;
}

textarea:focus {
	outline: none !important;
}

::-webkit-scrollbar {
	display: none;
}

p span {
	font-size: 16px !important;
	font-weight: 500;
}

p {
	font-size: 15px !important;
	font-weight: 400;
}
</style>
