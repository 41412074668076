<template>
	<div class="row">
		<div class="col-12">
			<ul
				class="
					list-unstyled
					border-bottom
					pb-4
					mb-4
					d-flex 
          flex-wrap
					align-items-center
					tab-list-get-code
				"
			>
				<li>
					<a
						class="btn"
						@click="tab = 'Drupal'"
						:class="[tab === 'Drupal' ? 'btnbluebg' : '']"
						><img src="@/assets/images/Drupal-CMS.png" style="width:45px;"/>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'Joomla'"
						:class="[tab === 'Joomla' ? 'btnbluebg' : '']"
						><img src="@/assets/images/joomla-cms-logo.png" style="width:65px;"/>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'Magento'"
						:class="[tab === 'Magento' ? 'btnbluebg' : '']"
						><img src="@/assets/images/Magento-logo-CMS.png" style="width:65px;"/>
					</a>
				</li>
				<li>
					<a
						class="btn"
						@click="tab = 'Prestashop'"
						:class="[tab === 'Prestashop' ? 'btnbluebg' : '']"
						><img src="@/assets/images/Prestashop-CMS.png" style="width:65px;"/>
					</a>
				</li>
                			<li>
					<a
						class="btn"
						@click="tab = 'Shopify'"
						:class="[tab === 'Shopify' ? 'btnbluebg' : '']"
						><img src="@/assets/images/shopify-logo-CMS1.png" style="width:65px;"/>
					</a>
				</li>
                			<li>
					<a
						class="btn"
						@click="tab = 'Wordpress'"
						:class="[tab === 'Wordpress' ? 'btnbluebg' : '']"
						><img src="@/assets/images/WordPress-Logo-CMS.png" style="width:65px;"/>
					</a>
				</li>
			</ul>
		</div>
		<div class="col-12">
			<component :is="tab" />
		</div>
	</div>
</template>

<script>
import Drupal from "@/components/Integrations/PluginsIntegration/PluginChild/Drupal.vue";
import Joomla from "@/components/Integrations/PluginsIntegration/PluginChild/Joomla.vue";
import Magento from "@/components/Integrations/PluginsIntegration/PluginChild/Magento.vue";
import Prestashop from "@/components/Integrations/PluginsIntegration/PluginChild/Prestashop.vue";
import Shopify from "@/components/Integrations/PluginsIntegration/PluginChild/Shopify.vue";
import Wordpress from "@/components/Integrations/PluginsIntegration/PluginChild/Wordpress.vue";
export default {
	name: "Plugins",
	components: {
		Drupal,
		Joomla,
		Magento,
		Prestashop,
        Shopify,
		Wordpress,
	},
	data() {
		return {
			tab: "Drupal",
		}
	},
};
</script>

<style scoped>
.tab-list-get-code{
  grid-gap: 20px;
}
.tab-list-get-code li a{
  width: 96px;
    height: 96px;
    border-radius: 4px!important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    transition: all .3s ease-in-out;
    border: 1px solid rgba(0,0,0,.1);
-webkit-box-shadow:  0px 0px 6px rgba(0, 0, 0, 0.15);
-moz-shadow:  0px 0px 6px rgba(0, 0, 0, 0.15);
-ms-shadow:  0px 0px 6px rgba(0, 0, 0, 0.15);
-o-shadow:  0px 0px 6px rgba(0, 0, 0, 0.15);
box-shadow:  0px 0px 6px rgba(0, 0, 0, 0.15);
}
.tab-list-get-code li a:hover{
    border: 1.5px solid #3b6ef8;
        transform: scale(1.1);
}
.tab-list-get-code li a.btnbluebg{
    border: 2px solid #3b6ef8;
        transform: scale(1.1);
}
.tab-list-get-code li a .pre-tooltip-text {
    white-space: break-spaces;
    visibility: hidden;
    width: 120px;
    background-color: #292929;
    color: #fff;
    text-align: left;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    bottom: 115%;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 10px;
    line-height: 15px !important;
}
.tab-list-get-code li a img{
  width: 50px;
}
.tab-list-get-code li a .pre-tooltip-text:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
transform: translateX(-50%);
    border-width: 7px;
    border-style: solid;
    border-color: #292929 transparent transparent transparent;
}
.tab-list-get-code li a:hover .pre-tooltip-text {
    visibility: visible;
    opacity: 1;
}
@media (max-width: 766.99px){
  .tab-list-get-code{
justify-content: space-evenly;
  }
}
</style>