<template>
  <!-- <div  v-if="question.type == 'checkbox' || question.type == 'radiogroup' || question.type == 'dropdown' || question.type == 'text'">
    </div> -->
  <div v-if="question.type == 'checkbox' || question.type == 'radiogroup' || question.type == 'dropdown' || question.type == 'text'">
    <div class="row">
      <div class="col-md-12">
        <h6 class="questionare-title pull-left">
          {{ question_counter }} .<span
            v-html="question.title"
            style="font-size: 16px; color: black"
          ></span>
        </h6>
      </div>
    </div>
    <div v-if="question.type == 'text'">
      <TextGroup
        :question="question"
        :question_index="question_index"
      ></TextGroup>
    </div>
    <div v-if="question.type == 'checkbox'">
      <check-group
        :question="question"
        :question_index="question_index"
      ></check-group>
    </div>
    <div v-if="question.type == 'radiogroup'">
      <radio-group
        :question="question"
        :question_index="question_index"
      ></radio-group>
    </div>
    <div v-if="question.type == 'dropdown'">
      <dropdown-group
        :question="question"
        :question_index="question_index"
      ></dropdown-group>
    </div>
  </div>
</template>

<script>
import TextGroup from "./TextGroup.vue";
import CheckGroup from "./CheckGroup.vue";
import RadioGroup from "./RadioGroup.vue";
import DropdownGroup from "./DropdownGroup.vue";
export default {
  name: "QuestionS",
  props: ["question_index", "question"],
  components: {
    CheckGroup,
    RadioGroup,
    DropdownGroup,
    TextGroup,
  },
  computed: {
    question_counter: function () {
      return this.$store.getters["policy/getQuestionCounter"];
    },
  },
};
</script>
