<template>
	<div>
		<div>
			<h3 class="text-blue mb-3">Cookie Consent Banner Script</h3>
			<p class="mb-2">Copy this script into your website's HTML header</p>
			<textarea
				class="scriptcode mb-0"
				placeholder="Describe yourself here..."
				id="WordpressStep1"
				readonly><script data-key="{{ script && script.key }}" data-name="CookieXray" src="{{script_url}}" type="text/javascript"></script></textarea
			>
			<p class="font-italic mb-0">
				Note: Paste this before any other script in the HTML head tag
			</p>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step1')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<h3 class="text-blue mb-3">
				Cookie Consent Banner Integrations / Plugins Script
			</h3>
			<span class="d-block mb-3"
				>Input this ID in web builders plugins (WordPress, Joomla,
				Presta shop etc.)</span
			>
			<p class="mb-2">Domain Group ID:</p>
			<textarea
				class="scriptcode mb-3"
				placeholder="Describe yourself here..."
				id="WordpressStep2"
				readonly>{{script && script.key}}</textarea>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step2')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<h3 class="text-blue mb-3">Cookie Policy Script</h3>
			<p class="mb-2">
				Copy code into your cookie policy URL to show default Cookie
				Policy.
			</p>
			<textarea
				class="scriptcode mb-3"
				placeholder="Describe yourself here..."
				id="WordpressStep3"
				readonly><div id="seers-cookie-consent"></div></textarea>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step3')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<hr />
			<h3 class="text-blue my-5">
				Installation instructions for wordpress:
			</h3>

			<details open>
				<summary>Step 1</summary>
				<div>
					<p>Login to your word press site.</p>
					<img
						class="img-fluid"
						src="@/assets/images/wordpress-images/1.png"
					/>
				</div>
			</details>
			<div id="accordion" role="tablist">
				<details>
					<summary>Step 2</summary>
					<div>
						<p class="mb-1">In Left ribbon:</p>
						<ul class="pl-3">
							<li class="mb-1">
								Click
								<strong class="d-inline-block text-dark"
									>"Plugin"</strong
								>
								option.
							</li>
							<li class="mb-1">
								Then from sub menu on
								<strong class="d-inline-block text-dark"
									>"Add New"</strong
								>
								option.
							</li>
						</ul>

						<img
							class="img-fluid"
							src="@/assets/images/wordpress-images/2.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 3</summary>
					<div>
						<p>It will take you to Plugins homepage:</p>
						<img
							class="img-fluid"
							src="@/assets/images/wordpress-images/3.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 4</summary>
					<div>
						<ul class="pl-3">
							<li class="mb-1">
								Type in
								<strong class="d-inline-block text-dark"
									>"Seers Cookie"</strong
								>
								in search box.
							</li>
							<li class="mb-1">
								It will return the Seers|Cookie Consent Banner
								and Privacy Policy in search result.
							</li>
							<li class="mb-1">
								Click
								<strong class="d-inline-block text-dark"
									>"Install Now"</strong
								>
								button.
							</li>
						</ul>
						<img
							class="img-fluid"
							src="@/assets/images/wordpress-images/4.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 5</summary>
					<div>
						<p>
							It will show Plugin installing as Highlighted in
							box.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/wordpress-images/5.png"
						/>
					</div>
				</details>
				<details>
					<summary>Step 6</summary>
					<div>
						<p>
							As it completes the installation "Activate" button
							will appear. Click on it to activate.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/wordpress-images/6.png"
						/>
					</div>
				</details>
				<details>
					<summary>Step 7</summary>
					<div>
						<p class="mb-1">In Left ribbon:</p>
						<ul class="pl-3">
							<li class="mb-1">
								Click
								<strong class="d-inline-block text-dark"
									>"settings"</strong
								>
								option.
							</li>
							<li class="mb-1">
								Then from sub menu Click on
								<strong class="d-inline-block text-dark"
									>"Cookie Consent"</strong
								>
							</li>
						</ul>
						<img
							class="img-fluid"
							src="@/assets/images/wordpress-images/7.png"
						/>
					</div>
				</details>
				<details>
					<summary>Step 8</summary>
					<div>
						<p>
							It will take you to settings of Seers cookie
							consent:
						</p>
						<ul class="pl-3">
							<li class="mb-1">Get Cookie ID provided by</li>
							<li class="mb-1">
								Then Click
								<strong class="d-inline-block text-dark"
									>"Submit" button.</strong
								>
							</li>
						</ul>
						<img
							class="img-fluid"
							src="@/assets/images/wordpress-images/8.png"
						/>
					</div>
				</details>
				<details class="mb-5">
					<summary>Step 9</summary>
					<div>
						<p>Go to your website homepage. Banner will appear.</p>
						<img
							class="img-fluid"
							src="@/assets/images/wordpress-images/9.png"
						/>
					</div>
				</details>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
	name: "Wordpress",
	computed:{
		cmp_url(){
			return process.env.VUE_APP_BANNER_URL
		},
		script: function() {
			return this.$store.getters['getCode/getScript'];
		},
		script_url: function() {
			// return process.env.MIX_CB_HOST +'/'+ this.$store.getters.getUser.id +'/'+ this.$store.getters.getCurrentDomainId + "/cb.js";
			return this.cmp_url + '/banners/' + this.$store.state.domain.userId +'/'+ this.$store.getters['domain/getCurrentDomainId'] + "/cb.js";
		},
	},
	methods: {
		copyScript(script){
			if (script === "step1") {
				$("#WordpressStep1").select();
			}

			if (script === "step2") {
				$("#WordpressStep2").select();
			}

			if (script === "step3") {
				$("#WordpressStep3").select();
			}
			
			Swal.fire({
				icon: "success",
				title: 'Code Copied',
				text: "Code copied successfully",
				timer: 4000,
				confirmButtonText: "Ok"
			});
			document.execCommand("copy");
		}
	},
};
</script>

<style scoped>
textarea:focus {
	outline: none !important;
}
::-webkit-scrollbar {
	display: none;
}
p span {
	font-size: 16px !important;
	font-weight: 500;
}
p {
	font-size: 15px !important;
	font-weight: 400;
}
.scriptcode {
	padding-inline-start: 10px;	
	padding-block-start: 0%;
	padding: auto;
}
#WordpressStep2 {
	height: 70px;
	padding-left: 15%;
	padding-top: 2%;
}
#WordpressStep3 {
	height: 70px;
	padding-left: 15%;
	padding-top: 2%;
}
</style>