<template>
	<div>
		<div>
			<h3 class="text-blue mb-3">Cookie Consent Banner Script</h3>
			<p class="mb-2">Copy this script into your website's HTML header</p>
			<textarea
				class="scriptcode mb-0"
				placeholder="Describe yourself here..."
				id="ShopifyStep1"
				readonly><script data-key="{{ script && script.key }}" data-name="CookieXray" src="{{script_url}}" type="text/javascript"></script></textarea
			>
			<p class="font-italic mb-0">
				Note: Paste this before any other script in the HTML head tag
			</p>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step1')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<h3 class="text-blue mb-3">
				Cookie Consent Banner Integrations / Plugins Script
			</h3>
			<span class="d-block mb-3"
				>Input this ID in web builders plugins (WordPress, Joomla,
				Presta shop etc.)</span
			>
			<p class="mb-2">Domain Group ID:</p>
			<textarea
				class="scriptcode mb-3"
				placeholder="Describe yourself here..."
				id="ShopifyStep2"
				readonly>{{script && script.key}}</textarea>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step2')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<h3 class="text-blue mb-3">Cookie Policy Script</h3>
			<p class="mb-2">
				Copy code into your cookie policy URL to show default Cookie
				Policy.
			</p>
			<textarea
				class="scriptcode mb-3"
				placeholder="Describe yourself here..."
				id="ShopifyStep3"
				readonl><div id="seers-cookie-consent"></div></textarea>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step3')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<hr />
			<h3 class="text-blue my-5">
				Installation instructions for Shopify:
			</h3>

			<details open>
				<summary>Step 1</summary>
				<div>
					<p>
						Log in to your Shopify account and go to your Shopify
						<b class="d-inline-block text-dark">"admin"</b>. From
						here, you can set up your store, configure your
						settings, and manage your business. You can also install
						Seers Cookie Consent Banner!
					</p>
					<img
						class="img-fluid"
						src="@/assets/images/shopify-images/1.png"
					/>
				</div>
			</details>
			<div id="accordion" role="tablist">
				<details>
					<summary>Step 2</summary>
					<div>
						<p>
							On the admin page, select
							<b class="d-inline-block text-dark"
								>"Online Store"</b
							>, under the heading <b>SALES CHANNELS</b> on the
							left hand menu.
						</p>
					</div>
				</details>

				<details>
					<summary>Step 3</summary>
					<div>
						<p>
							Now, select
							<b class="d-inline-block text-dark">"Themes"</b>.
						</p>
					</div>
				</details>

				<details>
					<summary>Step 4</summary>
					<div>
						<p>
							Here, you will just click (1)
							<b class="d-inline-block text-dark">"Actions"</b>
							and then select (2)
							<b class="d-inline-block text-dark">"Edit Code"</b>
							The code editor will show a directory of theme files
							on the left, and a space to view and edit the files
							on the right.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/shopify-images/EditCode.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 5</summary>
					<div>
						<p>
							Now, you will (1) select <b>theme.liquid</b> under
							<b>Layout.</b> (2) From here, locate the first &lt;
							script &gt; tag and place Seers Cookie Consent
							Script provided to you by the Seers on top of every
							script. (3) Click on Save Button to save the
							changes. Message Assert Saved shows.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/shopify-images/Save.png"
						/>
					</div>
				</details>
				<details>
					<summary>Step 6</summary>
					<div>
						<p>
							Now reload or preview your site Seers Cookie Consent
							popup will appear.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/shopify-images/ShowingBanner.png"
						/>
					</div>
				</details>
				<details>
					<summary>Step 7</summary>
					<div>
						<p>
							Next window will appear. You will see message
							<strong class="d-inline-block text-dark"
								>"Script created"</strong
							>.
						</p>
					</div>
				</details>
				<details class="mb-5">
					<summary>Step 8</summary>
					<div>
						<p>Go to your website, Banner will appear</p>
						<img
							class="img-fluid"
							src="@/assets/images/shopify-images/9.png"
						/>
					</div>
				</details>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
	name: "Shopify",
	computed:{
		cmp_url(){
			return process.env.VUE_APP_BANNER_URL
		},
		script: function() {
			return this.$store.getters['getCode/getScript'];
		},
		script_url: function() {
			// return process.env.MIX_CB_HOST +'/'+ this.$store.getters.getUser.id +'/'+ this.$store.getters.getCurrentDomainId + "/cb.js";
			return this.cmp_url + '/banners/' + this.$store.state.domain.userId +'/'+ this.$store.getters['domain/getCurrentDomainId'] + "/cb.js";
		},
	},
	methods: {
		copyScript(script){
			if (script === "step1") {
				$("#ShopifyStep1").select();
			}

			if (script === "step2") {
				$("#ShopifyStep2").select();
			}

			if (script === "step3") {
				$("#ShopifyStep3").select();
			}
			
			Swal.fire({
				icon: "success",
				title: 'Code Copied',
				text: "Code copied successfully",
				timer: 4000,
				confirmButtonText: "Ok"
			});
			document.execCommand("copy");
		}
	},
};
</script>

<style scoped>
textarea:focus {
	outline: none !important;
}
::-webkit-scrollbar {
	display: none;
}
p span {
	font-size: 16px !important;
	font-weight: 500;
}
p {
	font-size: 15px !important;
	font-weight: 400;
}
.scriptcode {
	padding-inline-start: 10px;	
	padding-block-start: 0%;
	padding: auto;
}
#ShopifyStep2 {
	height: 70px;
	padding-left: 15%;
	padding-top: 2%;
}
#ShopifyStep3 {
	height: 70px;
	padding-left: 15%;
	padding-top: 2%;
}
</style>