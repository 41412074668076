<template>
    <div class="row">
        <div class="form-group input-cookie-consent col-md-6 mb-0">
            <label style="width:100%;">
                <input autofocus :class="show_error ? 'form-control invalid' : 'form-control'" @keydown.enter.prevent="showNextQuestion"  :type="input_type" :name="question.name" v-model="selected" id="text-target" v-cloak>
                <!-- <input
                    autofocus="autofocus"
                    name="data_protection_for_org_cookie_policy_comp_info_org_name"
                    id="text-target"
                    type="text"
                    class="form-control"
                /> -->
                
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: "TextGroup",
    props:['question','question_index'],
    data(){
        return {
            url_regex: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
            email_regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            number_regex: /^\d+$/,
            tel_regex: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        }
    },
    computed:{
        show_error(){
            return this.$store.getters["policy/getShowError"];
        },
        input_type: function () {
            return this.question.inputType || this.question.type;
        },
        userId: function(){
            return this.$store.state.domain.userId;
        },
        domain_id: function () {
        return this.$store.getters["domain/getCurrentDomainId"];
        },
        selected: {
            set: function (value){
                this.$store.commit('policy/setCurrentAnswer', value);
                this.$store.dispatch('policy/saveCurrentAnswer',{user_id:this.userId,domain_id:this.domain_id,question_name:this.question.name,answer:value});
            },
            get: function(value){
                return this.$store.getters["policy/getCurrentAnswer"];
            }
        }
    },
    mounted() {
        let input = document.getElementById('text-target');
        if (input) {
            input.focus();
        }
    },
    methods:{
        showNextQuestion() {
            if (this.selected !== null) {
                if (this.question.hasOwnProperty('inputType')) {
                    let error = '';
                    switch(this.question.inputType) {
                        case 'tel':
                            if(! this.selected.match(this.tel_regex) ) {
                                error = 'Please enter valid Phone Number.';
                            }
                            break;
                        case 'number':
                            if(! this.selected.match(this.number_regex) ) {
                                error = 'Please enter a valid Number';
                            }
                            break;
                        case 'email':
                            if(! this.selected.match(this.email_regex) ) {
                                error = 'Please enter a valid Email Address';
                            }
                            break;
                        case 'url':
                            if(! this.selected.match(this.url_regex) ) {
                                error = 'Please enter a valid URL';
                            }
                            break;
                    }
                    if (! error == '') {
                        this.$store.commit('policy/setShowError', true);
                        this.$store.commit('policy/setErrorMessage', error);
                        return;
                    }
                }
            }
            document.getElementById('next-question').click();
            return;

        }
    }
}
</script>
<style scoped>
    .invalid {
        border-color: red
    }
</style>