<template>
	<div>
		<div>
			<h3 class="text-blue mb-3">Cookie Consent Banner Script</h3>
			<p class="mb-2">Copy this script into your website's HTML header</p>
			<textarea
				class="scriptcode mb-0"
				id="drupalStep1"
				placeholder="Describe yourself here..."
				readonly><script data-key="{{ script && script.key }}" data-name="CookieXray" src="{{script_url}}" type="text/javascript"></script></textarea>
			<p class="font-italic mb-0">
				Note: Paste this before any other script in the HTML head tag
			</p>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step1')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<h3 class="text-blue mb-3">
				Cookie Consent Banner Integrations / Plugins Script
			</h3>
			<span class="d-block mb-3"
				>Input this ID in web builders plugins (WordPress, Joomla,
				Presta shop etc.)</span
			>
			<p class="mb-2">Domain Group ID:</p>
			<textarea
				class="scriptcode mb-3"
				id="drupalStep2"
				placeholder="Describe yourself here..."
				readonly>{{script && script.key}}</textarea>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step2')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<h3 class="text-blue mb-3">Cookie Policy Script</h3>
			<p class="mb-2">
				Copy code into your cookie policy URL to show default Cookie
				Policy.
			</p>
			<textarea
				class="scriptcode mb-3"
				placeholder="Describe yourself here..."
				id="drupalStep3"
				readonly><div id="seers-cookie-consent"></div></textarea>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step3')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<hr />
			<h3 class="text-blue my-5">
				Installation instructions for Drupal:
			</h3>

			<details open>
				<summary>Step 1</summary>
				<div>
					<p>
						Go to your Drupal root folder, and extract the Drupal
						module in <strong>"module"</strong> folder. The
						extracted folder’s name will be
						<strong>"seers_cookie_consent_privacy_policy"</strong>
					</p>
					<img
						class="img-fluid"
						src="@/assets/images/drupal-images/module_0.png"
					/>
				</div>
			</details>
			<div id="accordion" role="tablist">
				<details>
					<summary>Step 2</summary>
					<div>
						<p>
							Login to your Drupal Admin card to configure the
							module.
						</p>

						<img
							class="img-fluid"
							src="@/assets/images/drupal-images/module_1.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 3</summary>
					<div>
						<ul class="pl-2 list-unstyled">
							<li>
								In admin card, go to
								<strong class="d-inline-block text-dark"
									>"Manage &gt; Extend , and search for Seers
									Cookie Consent"</strong
								>
								in search bar. Select and install the plugin.
							</li>
						</ul>
						<img
							class="img-fluid"
							src="@/assets/images/drupal-images/module_2.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 4</summary>
					<div>
						<p>
							Go to
							<strong class="d-inline-block text-dark"
								>"Manage &gt; Configuration"</strong
							>
							and click on
							<strong class="d-inline-block text-dark"
								>"Seers Cookie Consent Form"</strong
							>.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/drupal-images/module_3.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 5</summary>
					<div>
						<p>Enter and save the Domain Group ID.</p>
						<img
							class="img-fluid"
							src="@/assets/images/drupal-images/module_4.png"
						/>
					</div>
				</details>
				<details class="mb-5">
					<summary>Step 6</summary>
					<div>
						<p>
							Save
							<strong class="d-inline-block text-dark"
								>“Configuration”</strong
							>
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/drupal-images/module_5.png"
						/>
					</div>
				</details>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
	name: "Drupal",
	computed:{
		cmp_url(){
			return process.env.VUE_APP_BANNER_URL
		},
		script: function() {
			return this.$store.getters['getCode/getScript'];
		},
		script_url: function() {
			// return process.env.MIX_CB_HOST +'/'+ this.$store.getters.getUser.id +'/'+ this.$store.getters.getCurrentDomainId + "/cb.js";
			return this.cmp_url + '/banners/' + this.$store.state.domain.userId +'/'+ this.$store.getters['domain/getCurrentDomainId'] + "/cb.js";
		},
	},
	methods: {
		copyScript(script){
			if (script === "step1") {
				$("#drupalStep1").select();
			}

			if (script === "step2") {
				$("#drupalStep2").select();
			}

			if (script === "step3") {
				$("#drupalStep3").select();
			}
			
			Swal.fire({
				icon: "success",
				title: 'Code Copied',
				text: "Code copied successfully",
				timer: 4000,
				confirmButtonText: "Ok"
			});
			document.execCommand("copy");
		}
	},
};
</script>

<style scoped>


textarea:focus {
	outline: none !important;
}
::-webkit-scrollbar {
	display: none;
}
p span {
	font-size: 16px !important;
	font-weight: 500;
}
p {
	font-size: 15px !important;
	font-weight: 400;
}
.scriptcode {
	padding-inline-start: 10px;	
	padding-block-start: 0%;
}
#drupalStep2 {
	height: 70px;
	padding-left: 15%;
	padding-top: 2%;
}
#drupalStep3 {
	height: 70px;
	padding-left: 15%;
	padding-top: 2%;
}
</style>