<template>
	<div class="domain-modal" >
		<div class="modal-hol">
			<form>
				<div class="modal-header border-0">
					<h2>Edit Script</h2>
					<button type="button" @click="handleHideScript"  class="close">+</button>
				</div>
				<div class="modal-body">
					<div class="form-group row mb-4">
						<label
							class="
							white-space
								col-12 col-sm-12 col-md-3
								control-label
								mb-2
								d-flex
								justify-content-start
								align-items-center
							"
							>Title:
						</label>
						<div class="col-12 col-sm-12 col-md-9">
							<input
								type="text"
								name="title" id="title" v-model="title"
								class="form-control"
							/>
						</div>
					</div>
					<div class="form-group row mb-4">
						<label
							class="
							white-space
								col-12 col-sm-12 col-md-3
								control-label
								mb-2
								d-flex
								justify-content-start
								align-items-center
							"
							>Category:
						</label>
						<div class="col-12 col-sm-12 col-md-9">
							<select name="category" class="form-control bg-white" id="category" v-model="script_category_id">
								<!-- <option  disabled> --- Select Category ---</option> -->
									<option v-for="(category,index) in script_categories" :key="index" :value="category.id" :selected="category.id === script.script_category_id">{{ category.name }}</option>
							</select>
						</div>
					</div>
					<div class="form-group row mb-4">
						<label
							class="
							white-space
								col-12 col-sm-12 col-md-3
								control-label
								mb-2
								d-flex
								justify-content-start
								align-items-center
							"
							>Src:
						</label>
						<div class="col-12 col-sm-12 col-md-9">
							<input
								type="text"
								name="src" id="src" v-model="src"
								class="form-control"
							/>
						</div>
					</div>
					<div class="form-group row mb-4">
						<label
							class="
							white-space
								col-12 col-sm-12 col-md-3
								control-label
								mb-2
								d-flex
								justify-content-start
								align-items-center
							"
							>Type:
						</label>
						<div class="col-12 col-sm-12 col-md-9">
							<select name="type" class="form-control bg-white" id="type" v-model="type">
								<option selected value="text/javascript">text/javascript</option>
							</select>
						</div>
					</div>
				</div>
				<div class="modal-footer border-0 justify-content-start">
					<div class="form-group row">
						<ul class="list-unstyled d-flex align-items-center p-0 m-0">
							<li>
								<a class="btn btn-blue-bg" v-if="loading == false" @click="UpdateScript()">Update</a>
								<a class="btn btn-blue-bg" v-else ><i  class="fa fa-spinner fa-pulse fa-fw"></i></a>
							</li>
						</ul>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import Swal from 'sweetalert2';
	import { emitter } from "@/eventbus";
export default {
    name:'addManuallyScript',
	props:['script'],
	data() {
		return {
			loading: false,
			current_script_id : this.script.id,
			script_category_id:this.script.script_category_id,
			errors: [],
			title: this.script.title,
			src: this.script.src,
			type: this.script.type,
			enabled: this.script.enabled,
		}
	},
	mounted() {
		// console.log('scriptscript',this.script)
	},
	computed:{
		domain_id: function () {
			return this.$store.getters['domain/getCurrentDomainId'];
		},
		script_categories: function () {
			return this.$store.getters['domain/getScriptCategories'];
		},
	},
	methods: {
		handleHideScript: function() {
			this.$emit("onHideScript");
		},
		UpdateScript() {
    this.loading = true;
    if (!this.validated()){
        this.loading = false;
        return;
    }
    let params = {
        id: this.current_script_id,
        title: this.title,
        src: this.src,
        type: this.type,
        domain_id: this.domain_id,
        script_category_id: this.script_category_id
    };
    this.$store.dispatch('domain/UpdateScript', params)
	.then((response) => {
            if (response && response.status === 200) {
                this.loading = false;
                this.$store.commit('domain/showEditManuallyScriptModal', false);
                emitter.emit('saveDialogueProperties');
            }
        })
        .catch((error) => {
			console.log('error',error);
			if (error.response && error.response.status === 409 && error.response.data && error.response.data.message) {
                this.loading = false;
                this.$store.commit('domain/showEditManuallyScriptModal', false);
                let message = error.response.data.message;
                let confirmtxt = "OK";
                Swal.fire({
                    icon: "error",
                    title: message,
                    timer: 4000,
                    showConfirmButton: false,
                    confirmButtonText: confirmtxt
                });
            } else {
                this.loading = false;
                console.error("An unexpected error occurred:", error);
            }
        });
},

		validated: function () {
			if (this.errors.length > 0) {
				this.removeErrors();
			}
			if (this.title.length <= 0) {
				this.errors.push({title: 'Title field is required.'});
			}

			if (this.src.length <= 0) {
				this.errors.push({src: 'Src field is required.'});
			}

			if (this.type.length <= 0) {
				this.errors.push({type: 'Type field is required.'});
			}

			if (this.errors.length > 0) {
				this.errors.forEach(error => {
					let newItem = Object.keys(error)[0];
					let domElem = document.getElementById(newItem);
					domElem.classList.add('border', 'border-danger');
					domElem.insertAdjacentHTML('afterend', `<span class="text-danger-error" style="color: #e1464e !important; font-size: 13px !important; text-align: left !important; margin-top: 7px !important;">${Object.values(error)[0]}</span>`);
				});
			}
			return !this.errors.length > 0;
        },
		removeErrors: function () {
			// remove all error messages
			const errorMessagesCustom = document.querySelectorAll('.text-danger-error');
			errorMessagesCustom.forEach((element) => element.remove());

			// remove all form controls with highlighted error text box
			const formControls = document.querySelectorAll('.form-control');
			formControls.forEach((element) => element.classList.remove('border', 'border-danger'));
			this.errors = [];
        },
	},
}
</script>