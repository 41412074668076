<template>
	 
	  <div  >
<vue-progress-bar></vue-progress-bar>
	<div class="row">
		<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9">
			<ul
				class="
					list-unstyled
					p-0
					pb-2
					mb-2
					d-flex
					align-items-center
					tab-list-hol-inner
				"
			>
				<li>
					<a
						@click="tabComponent('Reports')"
						:class="[tab === 'Reports' ? 'btnbluebg' : 'btntabbg']"
						>{{ Reports }}</a
					>
				</li>
				<li>
					<a
						@click="tabComponent('Preferences')"
						:class="[
							tab === 'Preferences' ? 'btnbluebg' : 'btntabbg',
						]"
						>{{ Preferences }}
					</a>
				</li>
				<li>
					<a
						@click="tabComponent('Visuals')"
						:class="[tab === 'Visuals' ? 'btnbluebg' : 'btntabbg']"
						>{{ Visuals }}
					</a>
				</li>
				<li>
					<a
						@click="tabComponent('BannerContent')"
						:class="[
							tab === 'BannerContent' ? 'btnbluebg' : 'btntabbg',
						]"
					>
						{{ BannerContent }}</a
					>
				</li>
				<li>
    				<a
      					@click="handleTrackingManager"
      					:class="[tab === 'TrackingManager' ? 'btnbluebg' : 'btntabbg']"
    				>
      					{{ TrackingManager }}
    				</a>
  				</li>

				<li>
					<a
						@click="handlePolicy"
						:class="[tab === 'Policy' ? 'btnbluebg' : 'btntabbg']"
					>
						{{ Policy }}</a
					>
				</li>
				<li>
					<a
						@click="handleByRegion"
						:class="[tab === 'ByRegion' ? 'btnbluebg' : 'btntabbg']"
					>
						{{ ByRegion }}</a
					>
				</li>
			</ul>
		</div>
		<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 d-flex align-items-center justify-content-end">
			<div class="block-style p-2 border none-shadow mb-2">
				<span class="text-dark mb-2 d-block font-weight-bold"
					>How to do?</span
				>
				<ul
					class="
						guide-ul
						list-unstyled
						p-0
						m-0
						d-flex
						align-items-center
						justify-content-between
					"
				>
					<li class="mr-3" @click="openSetup('11055401610908')">
						<a href="#" class="text-blue"
							><svg
								class="mr-1"
								width="14"
								height="14"
								viewBox="0 0 14 14"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.75 2.91675V11.0834C1.75 12.3673 2.7965 12.8334 3.5 12.8334H12.25V11.6667H3.507C3.2375 11.6597 2.91667 11.5536 2.91667 11.0834C2.91667 10.6132 3.2375 10.5071 3.507 10.5001H12.25V2.33341C12.25 1.69 11.7267 1.16675 11.0833 1.16675H3.5C2.7965 1.16675 1.75 1.63283 1.75 2.91675Z"
									fill="#3B6EF8"
								/>
							</svg>
							Setup Guide</a
						>
					</li>
					<li @click="openVideo('https://youtu.be/WbcGhSviCEg')">
						<a href="#" class="text-blue"
							><svg
								class="mr-1"
								width="14"
								height="14"
								viewBox="0 0 14 14"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.3 10.5001V3.5001C13.3 2.7301 12.67 2.1001 11.9 2.1001H2.80002C2.03002 2.1001 1.40002 2.7301 1.40002 3.5001V10.5001C1.40002 11.2701 2.03002 11.9001 2.80002 11.9001H11.9C12.67 11.9001 13.3 11.2701 13.3 10.5001ZM5.60002 9.8001V4.2001L9.80002 7.0001L5.60002 9.8001Z"
									fill="#3B6EF8"
								/>
							</svg>
							Setup Video</a
						>
					</li>
				</ul>
			</div>
		</div>
		<div v-show="this.inLoader" class="report-hol" style="margin-top: 50px; margin-left: 500px;">
        <div  class="before scanning-block text-center py-5">
            <PulseLoader  style="display:inline-block" />
        </div>
      </div>
		<div v-show="!this.inLoader" class="col-12">
			<component v-if="showTab" :is="tab" />
		</div>
	</div>
	</div>
</template>

<script>
import {emitter} from '@/eventbus';
import { useToast } from "vue-toast-notification";
import Reports from "@/components/CustoMiZationComponent/Reports.vue";
import Preferences from "@/components/CustoMiZationComponent/Preferences.vue";
import Visuals from "@/components/CustoMiZationComponent/Visuals.vue";
import BannerContent from "@/components/CustoMiZationComponent/BannerContent.vue";
import TrackingManager from "@/components/CustoMiZationComponent/TrackingManager.vue";
import Policy from "@/components/CustoMiZationComponent/Policy.vue";
import ByRegion from "@/components/CustoMiZationComponent/ByRegion.vue";
import PricePlan from "@/components/CustoMiZationComponent/PricePlan.vue"
import { mapState } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
	name: "CustoMiZation",
	components: {
		Reports,
		Preferences,
		Visuals,
		BannerContent,
		TrackingManager,
		Policy,
		PricePlan,
		PulseLoader,
		ByRegion
	},
	data() {
		return {
			showTab:false,
			// tab: "Reports",
			Reports: "Reports",
			Preferences: "Preferences",
			Visuals: "Visuals",
			BannerContent: "Banner Content",
			TrackingManager: "Tracking Manager",
			Policy: "Policy",
			ByRegion:'By Region',
		};
	},
	computed:{
		...mapState('domain',['inLoader']),
		show_dialogue_preview: function () {
			return this.$store.getters["domain/getShowDialoguePreview"];
		},
		tab: function() {
			return this.$store.state.policy.tab;
		},
		form: function() {
			return this.$store.state.dialogue.form;
		},
		categories: function() {
			return this.$store.state.domain.categories;
		},
		default: function() {
			return this.$store.state.domain.default;
		},
		userId: function(){
			return this.$store.state.domain.userId;
		},
		domain_id: function () {
			return this.$store.getters['domain/getCurrentDomainId'];
		},
		getFeatures: function () {
			return this.$store.getters['domain/getFeatures'];
		},
		defaultLanguage: function() {
			return this.$store.getters['domain/getDefaultLanguage'];
		},
		current_language: function() {
			return this.$store.getters['domain/getCurrentLanguage'];
		},
		dialogue_languages: function()  {
			return this.$store.getters['domain/getDialogueLanguages'];
        },
		categories: function()  {
			return this.$store.getters['domain/getCategories'];
        },
		
		
	},
	watch:{
		domain_id: {
			// deep: true,
			handler(){
				this.loadDialogueProperties()
			}
		},
	},
	mounted() {
		this.loadDialogueProperties();
		emitter.on('saveDialogueProperties',  () =>  {
		this.saveDialogueProperties()
		});
		this.$store.commit('policy/tabComponent','Reports')
	},
	
	methods: {
	handleTrackingManager() {
      if (this.getFeatures.plan_name === 'free') {
        this.$store.commit('domain/setShowPricingPopup', true);
      } else {
        this.tabComponent('TrackingManager');
      }
    },
	handlePolicy() {
      if (this.getFeatures.plan_name === 'free') {
        this.$store.commit('domain/setShowPricingPopup', true);
      } else {
        this.tabComponent('Policy');
      }
    },
	handleByRegion() {
      if (this.getFeatures.plan_name !== 'premium') {
        this.$store.commit('domain/setShowPricingPopup', true);
      } else {
        this.tabComponent('ByRegion');
      }
    },
    // tabComponent(tabName) {
    //   this.$store.commit('setTab', tabName);
    // },
	tabComponent(comp){
			this.$store.commit('policy/tabComponent',comp)
		},
		openURL: function(URL) {
			// let link = window.location.protocol + '//' + window.location.hostname + '/' + URL;
			let link = "https://seerssupport.zendesk.com/hc/en-us/articles/" + URL;
			window.open(link, "_target");
		},
		openSetup: function(URL) {
			// let link = window.location.protocol + '//' + window.location.hostname + '/' + URL;
			let link = "https://seerssupport.zendesk.com/hc/en-us/sections/" + URL;
			window.open(link, "_target");
		},
		openVideo: function(URL) {
			// let link = window.location.protocol + '//' + window.location.hostname + '/' + URL;
			let link = URL;
			window.open(link, "_target");
		},
		bannerShow(){
			if(this.show_dialogue_preview == true){
				this.$store.commit('domain/setShowDialoguePreview', false)
				this.$store.commit("domain/setPreviewBtn", 'Preview');
			}
		},
		saveDialogueProperties(){
			// this.$store.commit('domain/setEmptyForm')
			this.$store.commit('domain/setAllToggleBtn',false)
			this.$store.commit('domain/setButtonIsDisabled',false)
			this.$Progress.start();
			this.form.domain_id = this.domain_id;
			this.form.user_id = this.userId;
			this.form.categories = this.categories;
			this.dialogue_languages.forEach((lang) => {
				if (lang.name === this.current_language.name) {
					Object.assign(lang, this.current_language);
				}
				return;
			});
			this.form.languages = this.dialogue_languages;
			this.$store.dispatch('domain/coookieDialogue',this.form)
			.then(response => {
				// this.$store.commit('domain/setButtonIsDisabled',false)
				this.$store.commit('domain/setAllToggleBtn',true)
				this.$Progress.finish();
				const $toast = useToast();
				let instance = $toast.success('successfully saved');
				setTimeout(() => {
					instance.dismiss();
				}, 3000);
				let payload = {
					domain_id : response.data.cookie_xray_dialogue.cb_users_domain_id,
					user_id : response.data.cookie_xray_dialogue.user_id
				};
				// console.log('this.formthis.form',this.form)
				this.$store.dispatch('domain/loadDialogueProperties',payload)
			})
		},
		loadDialogueProperties(){
			// console.log('okok')
			this.$store.commit('domain/setAllToggleBtn',true)
			this.$store.commit('domain/setButtonIsDisabled',false)
			let payload = {
				domain_id : this.domain_id,
				user_id : this.userId
			};
			this.bannerShow();
			this.$store.dispatch('domain/loadDialogueProperties',payload)
			.then(()=>{
				this.showTab = true
				// console.log('okokk')
			})
		},

	},
	beforeUnmount() {
		// removing eventBus listener
		// console.log('ok',emitter.e.saveDialogueProperties)
		if(emitter.e.saveDialogueProperties){
			emitter.off('saveDialogueProperties')
		}
	},
};
</script>

<style scoped>
.btntabbg {
	background: #c2ccd7;
}
.btnbluebg {
	background: #3b6ef8;
}
</style>