<template>
    
<div class="custom-modal">
			<div class="modal-content">
				<div class="modal-header">
					<span class="close-modal" @click="pricingpoppup">✖</span>
					<h1>
						<strong class="border-green text-green">
							Upgrade</strong
						>
						Your <strong class="text-green">Price</strong> Plan
					</h1>
				</div>
				<div class="modal-body">
					<div class="row d-flex justify-content-between align-items-center flex-wrap">
						<div
							class="col
								mb-3 d-flex
							"
						>
							<div
								class="domains-list-hol d-flex align-items-start"
							>
								<p
									class="plan-title font-weight-bold mr-3 mb-0"
								>
									Domains:
								</p>
								<div
									class="
										domain-range
										d-flex
										align-items-center
									"
								>
									<div
										class="
											domain-range-list
											d-flex
											flex-column
											position-relative
										"
									>
										<ul
											class="
												list-unstyled
												d-flex
												align-items-center
												mb-0
											"
										>
											<li v-for="item in items" :class="{'selected_rang_vl': rng_vl === item.message}" :key="item.message">{{ item.message }}</li>
										</ul>
										<input class="e-range" type="range" min="1" max="10" steps="1" v-model="rng_vl" >
								
									</div>
								</div>
										<a
											href="https://seersco.com/contact"
											class="color-grey more-btn-link ml-3"
											>More</a
										>
							</div>
						</div>
						<div
							class="col
								mb-3
							"
						>
							<div
								class="
									international-pricing
									d-flex
									align-items-center
									justify-content-xl-end
									justify-content-lg-end
									justify-content-md-start
									justify-content-sm-start
									justify-content-start
								"
							>
								<button class="international-price mr-2" :class="{'active-currency': currency === 'USD'}" v-on:click="currency = 'USD'">
									&#36;
								</button>
								<button
									class="
										international-price
										mr-2
									"
									:class="{'active-currency': currency === 'GBP'}" v-on:click="currency = 'GBP'"
								>
									&#163;
								</button>
								<button class="international-price mr-2" :class="{'active-currency': currency === 'EUR'}" v-on:click="currency = 'EUR'">
									&#128;
								</button>
								<button class="international-price" :class="{'active-currency': currency === 'BRL'}" v-on:click="currency = 'BRL'">&#82;&#36;</button>
							</div>
						</div>
					</div>
					<div class="row mb-4">
						<div
							class=" get-off-hol
								col-12 d-flex
								flex-wrap
								align-items-center justify-content-between
								mb-3
							"
						>
							<ul class="list-unstyled mb-3 mr-3 get-off-btn-hol">
								<li class="d-flex align-items-center">
									<button class="get-off-btn mr-2">
										Get 15% off</button
									>every extra domain (up to 5 domains)
								</li>
							</ul>
		<ul class="list-unstyled mb-3 mr-3 get-off-btn-hol">
								<li class="d-flex align-items-center">
									<button class="get-off-btn mr-2">
										Get 20% off</button
									>every extra domain (above 6 domains)
								</li>
							</ul>
										<div class="monthly-yearly-hol">
								<input id="price-check" type="checkbox"  v-model="tenureToggler" />
								<label for="price-check"
									><span>Monthly</span
									><span>Yearly</span></label
								>
								<span class="yearly-tooltip"
									>Save 2 months</span
								>
							</div>
						</div>
					</div>
					<div class="row m-0">
						<div
							class="col-12"
							style="
								border-bottom: 1px solid rgb(193, 193, 193) !important;
							"
						></div>
					</div>
					<div class="pricing-cards">
						<div class="pricing-card products">
							<div class="content-hol">
								<h4>Product</h4>
								<span class="price-title">DETAILS</span>
								<ul class="mb-pricing">
									<div v-for="(feature, index) in features" :key="index" >
										<div v-if="index <= range.premium">
											<li :key="feature.id">{{ feature.display_name }}</li>
										</div>
									</div>
							
								</ul>
								<h5 class="vat text-blue text-left">
									*Price exclude VAT
								</h5>
							</div>
						</div>
						<div class="pricing-cards-hol">
								<div v-for="plan in product.plans" :key="plan.id">
									<div class="same-width" :key="plan.id">
										<div class="pricing-card detail" :class="{'basic': plan.name === 'free', 'silver': plan.name === 'standard', 'gold': plan.name === 'pro', 'platinum': plan.name === 'premium'}">
											<a v-if="plan.is_featured === 1" href="#" class="best-value"><span>BEST VALUE</span></a>
											<div class="content-hol">
												<h4>{{ plan.display_name }}</h4>
												<span class="price-title notranslate">{{ plan.price != 0.00 ? currency_sign + ' ' +
												Number(presentPrice(plan.price)).toLocaleString('en',
                                    { 
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2 
                                    }
                                )   
													+ '/m*' : 'FREE' }}</span>
												<p class="bulled-annually" id="billed_annually" :style="{'padding-top': (tenure ==='yearly' && plan.name !== 'free' )? 'none': '17px'}">{{ plan.name !== 'free' ? 'Billed Annually' : ' ' }}</p>
												<ul class="mb-pricing">
													<div v-for="(feature, index) in plan.features" >
														<div v-if="index <= range[plan.name]">
															<li :key="feature.id">
															<div v-if="feature.name != 'domain_limit' && getFeatureValue(feature) === 'Yes'">
																<i class="fa fa-check" v-if="plan.is_featured === 1" style="font-size:12px;color:yellow"></i>
																<!-- <img :src="plan.is_featured === 1 ? asset + 'images/design-new/yellow-tick.svg' : asset + 'images/design-new/green-tick.svg'" :alt="'Flexible Integrations'" style="float: right;"> -->
																<i v-else class="fa fa-check" style="font-size:12px;color:green"></i>
															</div>
															<div v-else-if="feature.name != 'domain_limit' && getFeatureValue(feature) === 'No'">
																<i  class="fa fa-close" style="font-size:12px;color:red"></i>
																<!-- <img :src="asset + 'images/design-new/red-cross.svg'" :alt="'Flexible Integrations'" style="float: right;"> -->
															</div>
															<div v-else-if="feature.name == 'domain_limit' && plan.name != 'free'">{{rng_vl}}</div>
															<div v-else-if="feature.name == 'domain_limit' && plan.name == 'free'">1</div>
															<div v-else>
																<span style="font-size:11px !important;">{{ getFeatureValue(feature) }}</span>
															</div>
														</li>
														</div>
													</div>
												</ul>
												<a v-if="!show_details" class="mx-auto mb-3 d-block custom-chevron-circle" @click="toggleFeatures" style="left: 50%;transform: translateX(-50%);position: absolute;bottom: 10px;"><i class="fa fa-chevron-circle-down"></i></a>
												<a v-else class="mx-auto mb-3 d-block custom-chevron-circle" @click="toggleFeatures" style="left: 50%;transform: translateX(-50%);position: absolute;bottom: 10px;"><i class="fa fa-chevron-circle-up"></i></a>
											</div>
											<a v-if="plan.name === activated_plan" class="btn disabled" :class="{'basic': plan.name === 'free', 'silver': plan.name === 'standard', 'gold': plan.name === 'pro', 'platinum': plan.name === 'premium'}"><span>Activated</span></a>
											<a v-else class="btn" :class="{'basic': plan.name === 'free', 'silver': plan.name === 'standard', 'gold': plan.name === 'pro', 'platinum': plan.name === 'premium'}" @click="setProduct(plan)" data-toggle="modal" data-target="#exampleModalCenter">
												<span>CHOOSE</span>
											</a>										
										</div>
								</div>
								</div>                        
							</div>
					</div>
				</div>
			</div>
			<!---->
		</div>
</template>

<script>
import Swal from "sweetalert2";
import axios from 'axios';
import { mapState } from 'vuex';
export default {
    name: 'PricingPopup',
	data() {
		return {
			plan: {},
			items: [
				{ message: '1' },
				{ message: '2' },
				{ message: '3' },
				{ message: '4' },
				{ message: '5' },
				{ message: '6' },
				{ message: '7' },
				{ message: '8' },
				{ message: '9' },
				{ message: '10' }
			],
			checked:'yearly',
			spinner:false,
			selected_product:{},
			rng_vl : '1',
			tenure:'yearly',
			tenureToggler: true,
			show_payment_popup: false,
			show_details: false,
			show_popup:true,
			range: {
				free: 9,
				standard: 9,
				pro: 9,
				premium: 9
			},
		}
	},
	watch: {
        tenureToggler: function(value) {
            this.tenure = value ? "yearly" : "monthly";
        },
       
    },
	computed:{
		...mapState('domain', ['userToken']),
		product: function () {
			return this.$store.getters['domain/getProduct'];
		},
		features: function () {
			return this.$store.getters['domain/getPopupFeatures'];
		},
		activated_plan: function () {
			return this.$store.getters['domain/getActivatedPlan'];
		},
		userType: function() {
			return this.$store.getters['domain/getUser'];
		},
		currency: {
			set: function (value) {
				this.$store.commit('domain/setCurrency', value);
			},
			get: function () {
				return this.$store.getters['domain/getCurrency'];
			}
		},
		currency_sign: function () {
			return this.currency === 'GBP' ? '£' : (this.currency === 'USD' ? '$' : (this.currency === 'BRL' ? 'R$' : '€'));
		},
		cmp_url(){
			return process.env.VUE_APP_ENV_VARIABLE
		},
	},
	mounted() {
		// console.log('firstuserType', this.userType)
		this.checkUser();
	},
	methods: {
		pricingpoppup(){
			this.$store.commit("domain/setShowPricingPopup", false);
			this.$store.commit("domain/setHide_font", false);

		},
		checkUser(){
			if(this.userType === "appsumo"){
				Swal.fire({
					title: 'Limit Reached',
					text: "Your limit on your Appsumo Tier is exceeded",
					type: "error",
					showCancelButton: false,
					confirmButtonColor: "#3b6ef8",
				}).then(() => {
					this.$store.commit("domain/setShowPricingPopup", false);
				});
				this.show_popup = false;
				// this.fireToast("error", "please upgrade your tier from appsumo");
			}
		},
		setProduct: function (plan) {

			this.plan = plan;
			this.product.price = this.presentPrice(plan.price);
			this.product.currency = this.currency;
			let pro_payload = {
                name: this.product.name,
                product_name: this.product.name,
                display_name: this.product.display_name,
                plan_name: plan.name,
                plan_id: plan.id,
                price: this.tenure === 'yearly' ? this.product.price * 12 : this.product.price,
                tenure: this.tenure,
                currency: this.currency,
                isFree: plan.name == 'free',
                domain_limit: this.rng_vl,
                price_id_yearly: plan.price_id_yearly,
                price_id_monthly: plan.price_id_monthly,
            }
			if (plan.name !== 'free') {
				axios.post(`${this.cmp_url}/api/v2/stripe-checkout`, pro_payload,{
            headers: {
                Authorization: 'Bearer ' + this.userToken,
            }
          }).then(response => {
                    this.spinner = false;
                    window.location.href = response.data;
                }).catch(error => {
                    this.spinner = false;
                    console.log('error--------->', error);
                });
			} else {
				this.upgradeAccount();
			}			
		},
		presentPrice: function (price) {
			let noOfDomains = parseInt(this.rng_vl);
                price = parseFloat(price).toFixed(2) * noOfDomains;
            
                if(noOfDomains == 1){
                    price = price - 0.01;
                }else if(noOfDomains > 5){
                    price = Math.round(price *(1-0.20)) - 0.01;
                }else{
                    price =  Math.round(price *(1-0.15)) - 0.01
                }
                 if (this.tenure === 'yearly') {
                    // console.log('price yearly', price);
                    price = Math.round((price * 12 * 0.833)/12) - 0.01;
                }

			if (this.currency === 'USD') {
				price = parseFloat(price * 1.30).toFixed(2);
				if ((price - parseInt(price)) > 0.5) {
					price = parseFloat(Math.ceil(price) - 0.01).toFixed(2);
				} else {
					price = parseFloat(Math.floor(price) - 0.01).toFixed(2);
				}
			}

			if (this.currency === 'EUR') {
				price = parseFloat(price * 1.11).toFixed(2);
				if ((price - parseInt(price)) > 0.5) {
					price = parseFloat(Math.ceil(price) - 0.01).toFixed(2);
				} else {
					price = parseFloat(Math.floor(price) - 0.01).toFixed(2);
				}
			}

			if (this.currency === 'BRL') {
               price = parseFloat(price * 7.13).toFixed(2);
               if ((price - parseInt(price)) > 0.5) {
                  price = parseFloat(Math.ceil(price) - 0.01).toFixed(2);
               } else {
                  price = parseFloat(Math.floor(price) - 0.01).toFixed(2);
               }
            }

			return price;
		},
		upgradeAccount: async function () {
			const self = this;
			let payload = {
				product_name:self.selected_product.name,
				plan_name:self.selected_product.plan_name,
				price:self.selected_product.price,
				tenure:self.selected_product.tenure,
				currency:self.selected_product.currency,
            };
			this.spinner = true;
			await axios.post(`${this.cmp_url}/api/v2/make-product-subscription`, payload,{
            headers: {
                Authorization: 'Bearer ' + this.userToken,
            }
          }).then(response => {
				this.spinner = false;
				if (response.data.url) {
					//this.getFeatures();
					//this.$emit('closePaymentPopup');
					//document.getElementById('close_button').click();
					location.reload();
				} 
			})
			.catch(error => {
				this.spinner = false;
				if (error.response.data.hasOwnProperty('message')) {
					Swal.fire({
						title: "Error",
						text: 'Your credit card has been declined. Please try again or contact us.',
						timer: 40000,
						buttons: false
					})
				} else if (error.response.status == 500) {
					Swal.fire({
						title: "Error",
						text: error.response.statusText,
						timer: 40000,
						buttons: false
					})
				}
				if (error.response.data.hasOwnProperty('Already_user')) {
					Swal.fire({
						title: "Error",
						text: error.response.data.Already_user,
						timer: 40000,
						buttons: false
					})
				}
				if (error.response.data.hasOwnProperty('page')) {
					// this.redirect(error.response.data.page);
				}
				if (error.response) {
					this.errors = error.response.data.errors;
					// console.log('this.errors', this.errors);
				} else if (error.request) {
					// console.log(error.request);
				} else {
					// console.log('Error message', error.message);
				}
			});
		},
		getFeatureValue: function (feature) {
			let value = '';
            if(feature.name == 'scan_report_varient')
               value = feature.value > 0 ? 'Full Version' : 'Short Version';
            else if(feature.name == 'logo_branding')
               value =  feature.value == 0 ? 'Seers Logo' :  feature.value == 1 ? 'No Logo' : 'Own Logo';
            else if(feature.name == 'scan_frequency')
               value =  feature.value == 3 ? 'Monthly' : 'Quarterly';
            else if(feature.name == 'scan_pages_limit')
               value =  feature.value <= 500 ? feature.value : 'Unlimited';
            else if(feature.name == 'cookie_declaration_table')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'territory_preferences')
               value =  feature.value == 1 ? 'UK' : (feature.value == 12  ? 'UK & US' : (feature.value == 123 ? 'UK, US & EU' : 'No'));
            else if(feature.name == 'live_support')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'banner_customization')
               value =  feature.value == 0 ? 'No': 'Yes';
            else if(feature.name == 'subdomain')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'integrations')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'cookie_bank')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'multilingual_banner')
               value =  feature.value == 0 ? 'No' : '28 ' + 'Languages';
            else if(feature.name == 'cookie_policy')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'prior_consent')
               value =  feature.value == 1 ? 'Yes' : 'No';
            else if(feature.name == 'consent_log')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'consent_log_limit')
               value =  feature.value == 1 ?  '1 ' + 'Million' : feature.value + ' ' + 'logs';
            else if(feature.name == 'ccpa_banner')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'script_manager')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'google_consent_mode')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'iab')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'allow_reject')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'save_consent_logs')
               value =  feature.value == 0 ? 'No' : 'Yes';
			else if (feature.name == 'uptime')
               value = feature.value == 0 ? 'No' : '24/7';
            else if (feature.name == 'child_privacy')
               value = feature.value == 0 ? 'No' : 'Yes';
            else if (feature.name == 'vendor_management')
               value = feature.value == 0 ? 'No' : 'Yes';
            else if (feature.name == 'analytics')
               value = feature.value == 0 ? 'No' : 'Yes';
            else if (feature.name == 'auto_blocking')
               value = feature.value == 0 ? 'No' : 'Yes';
            else if (feature.name == 'wcag')
               value = feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'powered_by')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'Complaint-Badge')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'GPC Complaint')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'Iab Complaint')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else if(feature.name == 'Do Not Sell Banner')
               value =  feature.value == 0 ? 'No' : 'Yes';
            else
               value = feature.value;
            return value;
		},
		toggleFeatures: function () {
			this.show_details = !this.show_details;
			if (this.show_details) {
				this.range.premium = this.features.length;
				this.range.free = this.range.standard = this.range.pro = this.range.premium;
			} else {
				this.range.free = 9;
				this.range.standard = 9;
				this.range.pro = 9;
				this.range.premium = 9;
			}
		},
	},
}
</script>

<style scoped>
.add-language-tag {
	cursor: pointer;
}
.add-language-btn {
	cursor: pointer;
}

/* modal style */


	.custom-modal .modal-content {
		z-index: 9999999;
		position: fixed;
		border-radius: 10px;
		border: 0px solid transparent;
		overflow: hidden;
		background-color: #fff;
		max-width: 100%;
		width: 90%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		height: 90vh;
		-webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, .1);
		-moz-box-shadow: 0 2px 15px rgba(0, 0, 0, .1);
		box-shadow: 0 2px 15px rgba(0, 0, 0, .1);

	}

	.custom-modal .modal-header {
		background-color: #F5F6F7;
		padding: 25px 15px;
	}

	.custom-modal .modal-header h1 {
		text-align: center;
		font: 600 44px/1.2 'Poppins', sans-serif;
		margin: auto;
	}

	.custom-modal .modal-header h1 span.border {
		position: relative;
		display: inline-block;
		z-index: 1;
	}

.custom-modal .modal-header h1 span.border:before {
    position: absolute;
    content: '';
    bottom: -10px;
    width: 100%;
    height: 4px;
    background: #6CC04A;
    left: 0;
    z-index: -1;
}

	/*.custom-modal .modal-header h1 span.border:after {
		position: absolute;
		content: '';
		bottom: 0;
		width: 68%;
		height: 4px;
		background: #6CC04A;
		right: 0;
		z-index: -1;
	}
	*/
	.custom-modal .modal-header h1 span.text-green {
		position: relative;
		color: #6CC04A;
		display: inline-block;
	}

	.custom-modal .modal-body {
		padding: 60px 200px;
		overflow-y: scroll;
	}

.custom-modal .modal-body .payement-plan-hol{
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: -moz-box;
display: -moz-flex;
display: flex;
-webkit-box-align: justify;
-moz-box-align: justify;
-webkit-box-pack: justify;
-moz-box-pack: justify;
-ms-flex-pack: justify;
-webkit-justify-content: space-between;
-moz-justify-content: space-between;
-ms-justify-content: space-between;
justify-content: space-between;
-webkit-box-align: center;
-moz-box-align: center;
-ms-flex-align: center;
-webkit-align-items: center;
-moz-flex-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
 }
 .custom-modal .modal-body .payement-plan-hol .international-pricing{
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: -moz-box;
display: -moz-flex;
display: flex;
-webkit-box-align: center;
-moz-box-align: center;
-ms-flex-align: center;
-webkit-align-items: center;
-moz-flex-align: center;
-ms-flex-align: center;
align-items: center;
 }
 .custom-modal .modal-body .payement-plan-hol .international-pricing button.international-price{
  width: 40px;
    border: 1.4px solid #c1c1c1;
    background: #fff;
    height: 40px;
    border-radius: 4px;
    font-size: 35px;
    color: #c1c1c1;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px 0 0;
}
 .custom-modal .modal-body .payement-plan-hol .international-pricing button.international-price:last-child{
    margin: 0;
 }
 .custom-modal .modal-body .payement-plan-hol .international-pricing button.international-price:focus{
  outline:0 !important;
}
 .custom-modal .modal-body .payement-plan-hol .international-pricing button.international-price.active, .custom-modal .modal-body .payement-plan-hol .international-pricing button.international-price:hover{
  color:#fff;
      background:#3b6ef8;
        border: 1.4px solid transparent !important;
}

.custom-modal .modal-body .payement-plan-hol .payement-plan {
    border-radius: 7px;
    border: 1px solid #C1C1C1 !important;
    padding: 8px 24px;
 display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: -moz-box;
display: -moz-flex;
display: flex;
-webkit-box-align: justify;
-moz-box-align: justify;
-webkit-box-pack: justify;
-moz-box-pack: justify;
-ms-flex-pack: justify;
-webkit-justify-content: space-between;
-moz-justify-content: space-between;
-ms-justify-content: space-between;
justify-content: space-between;
-webkit-box-align: center;
-moz-box-align: center;
-ms-flex-align: center;
-webkit-align-items: center;
-moz-flex-align: center;
-ms-flex-align: center;
align-items: center;
flex: auto;
margin: 0 25px 0 0;
}

	.custom-modal .modal-body .payement-plan .plan-title {
		margin: 0;
		font-size: 15px;
		font-weight: 500;
		line-height: initial;
	}

	.custom-modal .modal-body .price-switcher {
		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		/* Safari 6.1+ */
		display: flex;
		justify-content: space-between;
		-ms-align-items: center;
		align-items: center;
	}

	.custom-modal .modal-body .price-switcher .switcher-btn {
		position: relative;
		color: #C1C1C1;
		font-size: 13px;
		font-weight: 500;
		line-height: 21px;
		margin: 0 0 0 50px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		/* Safari 6.1+ */
		display: flex;
		justify-content: space-between;
		-ms-align-items: center;
		align-items: center;
	}

	.custom-modal .modal-body .price-switcher .switcher-btn input[type=radio] {
		/*			position: absolute;
		margin-top: 5px !important;
		margin-left: 0 !important;
		left: 0;*/
		display: none;
	}

	.custom-modal .modal-body .price-switcher .switcher-btn input[type="radio"]:checked + label {
		color: #3B6EF8;
		font-size: 11px;
	}

	.custom-modal .modal-body .price-switcher .switcher-btn label {
		font-size: 11px;
		margin: 0;
	}

	.custom-modal .modal-body .price-switcher .switcher-btn input[type="radio"]:checked + label:before {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	.custom-modal .modal-body .price-switcher .switcher-btn label:before {
		position: absolute;
content: '';
width: 10px;
height: 10px;
border-radius: 100%;
background: #3B6EF8;
left: -30px;
top: 5px;
		opacity: 0;
	}

	.custom-modal .modal-body .price-switcher .switcher-btn input[type="radio"]:checked + label:after {
		/* background: #fff; */
		border: 2px solid #3B6EF8;
	}

	.custom-modal .modal-body .price-switcher .switcher-btn label:after {
	position: absolute;
left: -35px;
content: '';
width: 20px;
height: 20px;
border-radius: 100%;
border: 2px solid #efeef1;
	}

	.custom-modal .modal-body .pricing-cards {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		/* Safari 6.1+ */
		display: flex;
		justify-content: space-between;
		/*			padding: 60px 20px 20px;*/
		flex-flow: row;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card {
		-webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, .1);
		-moz-box-shadow: 0 2px 15px rgba(0, 0, 0, .1);
		box-shadow: 0 2px 15px rgba(0, 0, 0, .1);
		position: relative;
		padding: 8px 12px;
		margin: 0 6px;
		border-radius: 8px;
		border: 2px solid transparent;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card:not(.products) {
		width: 100%;
		height: 100%;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.products {
		box-shadow: none;
		padding: 60px 0px 15px;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card .content-hol h4 {
		font-size: 16px;
		margin: 0 0 25px 0;
		position: relative;
		text-transform: uppercase !important;
		font-weight: normal;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.basic .content-hol span.price-title {
		color: #6CC04A !important;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.basic.detail .content-hol h4:after {
		position: absolute;
		content: '';
		width: 80px;
		height: 2px;
		left: 0;
		bottom: -9px;
		background: #6CC04A;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.basic:hover {
		border: 2px solid #6CC04A;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.basic:hover a.btn.basic {
		background: #6CC04A !important;
		color: #fff !important;
		border: 1px solid #6CC04A !important;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.basic:hover a.btn.basic span {
		color: #fff !important;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.basic a.btn.basic {
		display: inline-block;
		border: 1px solid #6CC04A !important;
		border-radius: 7px;
		padding: 3px 25px 4px;
		color: #6CC04A;
		background: #FFFFFF;
		bottom: -15px;
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.silver .content-hol span.price-title {
		color: #8F8F8F !important;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.silver:hover {
		border: 2px solid #8F8F8F;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.silver.detail .content-hol h4:after {
		position: absolute;
		content: '';
		width: 80px;
		height: 2px;
		left: 0;
		bottom: -9px;
		background: #8F8F8F;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.silver:hover a.btn.silver {
		background: #8F8F8F !important;
		color: #fff !important;
		border: 1px solid #8F8F8F !important;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.silver:hover a.btn.silver span {
		color: #fff !important;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.silver a.btn.silver {
		display: inline-block;
		border: 1px solid #C1C1C1 !important;
		border-radius: 7px;
		padding: 3px 25px 4px;
		color: #8F8F8F;
		background: #FFFFFF;
		bottom: -15px;
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.gold .content-hol span.price-title {
		color: #D4C52A !important;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.gold.detail .content-hol h4:after {
		position: absolute;
		content: '';
		width: 80px;
		height: 2px;
		left: 0;
		bottom: -9px;
		background: #D4C52A;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.gold.detail {
		border: 2px solid #D4C52A !important;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.gold a.btn.gold {
		display: inline-block;
		border: 1px solid #D4C52A !important;
		border-radius: 7px;
		padding: 5px 25px 4px !important;
		color: #fff;
		background: #D4C52A;
		bottom: -15px;
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.platinum .content-hol span.price-title {
		color: #3B6EF8 !important;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.products .content-hol h4 {
		position: relative;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.products .content-hol h4:after {
		position: absolute;
		content: '';
		width: 80px;
		height: 2px;
		left: 0;
		bottom: -9px !important;
		background: #3B6EF8;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.platinum.detail .content-hol h4:after {
		position: absolute;
		content: '';
		width: 80px;
		height: 2px;
		left: 0;
		bottom: -9px !important;
		background: #3B6EF8;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.platinum:hover {
		border: 2px solid #3B6EF8;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.platinum:hover a.btn.platinum {
		background: #3B6EF8 !important;
		color: #fff !important;
		border: 1px solid #3B6EF8 !important;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.platinum:hover a.btn.platinum span {
		color: #fff !important;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.platinum a.btn.platinum {
		display: inline-block;
		border: 1px solid #3B6EF8 !important;
		border-radius: 7px;
		padding: 4px 25px 4px !important;
		color: #3B6EF8;
		background: #FFFFFF;
		bottom: -15px;
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.basic a.btn.basic span {
		font-size: 11px;
		color: #6CC04A;
		font-weight: 500;
		display: inline-block;
		-webkit-transition: all 0.3s linear alternate;
		-moz-transition: all 0.3s linear alternate;
		-o-transition: all 0.3s linear alternate;
		transition: all 0.3s linear alternate;
		animation-delay: 3s;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.silver a.btn.silver span {
		color: #8F8F8F;
		font-weight: 500;
		font-size: 11px;
		display: inline-block;
		-webkit-transition: all 0.3s linear alternate;
		-moz-transition: all 0.3s linear alternate;
		-o-transition: all 0.3s linear alternate;
		transition: all 0.3s linear alternate;
		animation-delay: 3s;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.gold a.best-value {
		position: absolute;
		text-transform: uppercase;
		top: -15px;
		left: 50%;
		transform: translateX(-50%);
		border: 2px solid #D4C52A !important;
		border-radius: 7px;
		padding: 5px 25px;
		background: #ffff;
		line-height: initial;
		text-decoration: none;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: center;
		-ms-align-items: center;
		align-items: center;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.gold a.best-value span {
		white-space: nowrap;
		color: #5D5D5D;
		font-weight: normal;
		font-size: 11px;
		-webkit-animation-name: blinker;
		-webkit-animation-duration: 1s;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-timing-function: ease-in-out;
		-webkit-animation-direction: alternate;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.gold a.btn.gold span {
		color: #fff;
		font-weight: 500;
		font-size: 11px;
		display: inline-block;
		-webkit-transition: all 0.3s linear alternate;
		-moz-transition: all 0.3s linear alternate;
		-o-transition: all 0.3s linear alternate;
		transition: all 0.3s linear alternate;
		-webkit-animation: bounce 0.3s infinite alternate;
		animation: bounce 0.3s infinite alternate;
		animation-iteration-count: 30;
		-webkit-animation-iteration-count: 30;
		-moz-animation-iteration-count: 30;
		-o-animation-iteration-count: 30;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.platinum a.btn.platinum span {
		color: #3B6EF8;
		font-weight: 500;
		font-size: 11px;
		display: inline-block;
		-webkit-transition: all 0.3s linear alternate;
		-moz-transition: all 0.3s linear alternate;
		-o-transition: all 0.3s linear alternate;
		transition: all 0.3s linear alternate;
		animation-delay: 3s;
	}



	.custom-modal .modal-body .pricing-cards .pricing-card.basic.detail ul,
	.custom-modal .modal-body .pricing-cards .pricing-card.silver.detail ul,
	.custom-modal .modal-body .pricing-cards .pricing-card.gold.detail ul,
	.custom-modal .modal-body .pricing-cards .pricing-card.platinum.detail ul {
		padding-left: 80px;
		list-style-type: none;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.basic.detail ul li,
	.custom-modal .modal-body .pricing-cards .pricing-card.silver.detail ul li,
	.custom-modal .modal-body .pricing-cards .pricing-card.gold.detail ul li,
	.custom-modal .modal-body .pricing-cards .pricing-card.platinum.detail ul li {
		font-size: 11px !important;
		white-space: nowrap;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 0 0 5px 0;
		line-height: initial;
		height: 20px;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.products ul {
		padding: 0;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.products h5 {
		font-size: 12px;
		font-weight: normal;
		color: #3B6EF8;
		margin: 0;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.products ul li {
		font-size: 14px !important;
		white-space: nowrap;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: #3B6EF8;
		margin: 0 0 5px 0;
		line-height: initial;
		height: 20px;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card .content-hol span.price-title {
		font-size: 24px;
		font-weight: 500;
		margin: 0 0 30px 0;
		display: flex;
		justify-content: flex-start;
		-ms-align-items: flex-start;
		align-items: center;
	}

	.custom-modal .modal-body .pricing-cards .pricing-card.products .content-hol span.price-title {
		color: #3B6EF8;
	}

	.custom-modal .modal-body .pricing-cards .pricing-cards-hol {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		/* Safari 6.1+ */
		display: flex;
		flex-flow: row;
		overflow: hidden;
		overflow-x: scroll;
		padding: 60px 15px 15px;
		justify-content: space-between;
		/* flex:1 1 auto; */
	}
	.custom-modal .modal-body .pricing-cards .pricing-cards-hol .same-width{
		flex:1 1 auto;
		width: 220px;
		margin: 0 5px;
	}
	/*
	.modal-footer {
		padding: 0 15px;
	}
*/

	.custom-modal .close-modal {
		color: #fff;
    font-size: 13px;
    cursor: pointer;
    top: 20px;
    position: absolute;
    right: 20px;
    background: #3B6EF8;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px !important;
    border-radius: 100%;
	}

	@media only screen and (max-width: 991px) {
	.custom-modal .modal-body .payement-plan-hol{
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -ms-box-orient: vertical;
      box-orient: vertical;
 -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
          -webkit-box-align: start;
  -moz-box-align: start;
  -webkit-box-pack: start;
  -moz-box-pack: start;
            -ms-flex-pack: start !important;
    -webkit-justify-content:flex-start !important;
           -moz-justify-content:flex-start !important;
             -ms-justify-content:flex-start !important;
            justify-content:flex-start !important;
     }
     .custom-modal .modal-body .payement-plan-hol .payement-plan {
width: 100% !important;
    margin: 0 0 15px 0 !important;
}
		.custom-modal .modal-content {
			width: 90%;
		}
	}

	@media only screen and (max-width: 767px) {
		.custom-modal .modal-header h1 span.border:before {
			height: 2px;
		}
		.custom-modal .modal-header h1 span.border:after {
			height: 2px;
		}
		.custom-modal .modal-body {
			padding: 20px;
		}
		.custom-modal .modal-header h1 {
			font: 600 30px/1.2 'Poppins', sans-serif;
		}
	}

	@media only screen and (max-width: 575px) {
		.get-off-hol{
			justify-content: center !important;
		}
		.custom-modal .modal-content {
			width: 95% !important;
		}
		.custom-modal .modal-body .pricing-cards {
			margin: 0 -15px;
		}
		.custom-modal .modal-header h1 {
			font: 600 22px/1.2 'Poppins', sans-serif;
		}
		.custom-modal .modal-body .payement-plan .plan-title {
			margin: 0 0 12px 0;
		}
		.custom-modal .modal-body .price-switcher {
			margin: 0 0 0 -18px;
		}
		.custom-modal .modal-body .payement-plan {
			flex-direction: column;
			align-items: flex-start!important;
			padding: 10px 15px !important;
		}
		.custom-modal .modal-body .price-switcher .switcher-btn{
			margin: 0 0 0 40px !important;
		}
		.custom-modal .modal-body .price-switcher .switcher-btn label:before {
			left: -20px !important;
		}
		.custom-modal .modal-body .price-switcher .switcher-btn label:after {
				left: -25px !important;
		}
		.custom-modal .modal-body .pricing-cards .pricing-card .content-hol h4 {
			font-size: 14px !important;
			margin: 0 0 20px 0 !important;
		}
		.custom-modal .modal-body .pricing-cards .pricing-card .content-hol span.price-title {
			font-size: 18px !important;
			margin: 0 0 20px 0 !important;
		}
		/*.custom-modal .modal-body .pricing-card.silver.detail ul,
		.custom-modal .modal-body .pricing-cards .pricing-card.gold.detail ul,
		.custom-modal .modal-body .pricing-cards .pricing-card.platinum.detail ul {
			padding-left: 45px !important;
		}*/
		.custom-modal .modal-body .pricing-cards .pricing-card.products .content-hol span.price-title {
		color: #3B6EF8;
		font-size: 18px !important;
	}
	}


	.custom-chevron-circle i{
		color: #c1c1c1;
		font-size: 25px;
		margin-bottom: 15px;
	}

	.mb-pricing{
		margin-bottom: 60px !important;
	}

.active-currency {
	color: #fff !important;
	background: #3b6ef8 !important;
	border:0 !important;
}

.bulled-annually{
	text-align: left !important;
    margin-top: -26px;
	font-size:12px;
	margin-bottom: 0px;
}
.international-pricing button.international-price {
    width: 54px;
    border: 1.4px solid #c1c1c1;
    background: #fff;
    height: 54px;
    border-radius: 4px;
    font-size: 40px;
    color: #c1c1c1;
    font-weight: bold;
    vertical-align: middle;
    line-height: 45px;
    text-align: center;
}

.international-pricing button.international-price:focus {
    outline: 0 !important;
}

.international-pricing button.international-price.active,
.international-pricing button.international-price:hover {
    color: #fff;
    background: #3b6ef8;
    border: 0 !important;
}

.active-currency {
    color: #fff !important;
    background: #3b6ef8 !important;
    border: 0 !important;
}

.tab-disabled {
    pointer-events: none;
}

.domain-range-list ul li {
    color: #C1C1C1;
    font-size: 12px;
    margin: 0 10px 5px;
    cursor: pointer;
}

.color-grey {
    color: #C1C1C1;
}

.get-off-btn-hol button {
    white-space: nowrap;
    width: 85px;
    height: 24px;
    border-radius: 5px;
    background: #fd5c5c;
    font-family: Poppins;
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    color: #fff;
    border: 0 !important;

}

.get-off-btn-hol button:focus {
    outline: none !important;
}

.get-off-btn-hol li {
    font-family: Poppins;
    font-weight: 500;
    font-size: 11px;
    text-align: left;
    color: #292929;
    white-space: nowrap;
}

@media (max-width: 767px) {
    .domains-list-hol {
flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    }
        .domains-list-hol h5 {
        margin-bottom: 10px !important;
    }
    }

@media (max-width: 576px) {
    .get-off-btn-hol {
        display: flex;
        flex-direction: column;
    }
        .get-off-btn-hol li{
        flex-direction: column;
    }
}
.monthly-yearly-hol {
    position: relative;
    width: 300px;
    display: flex;
    justify-content: center;
}

.monthly-yearly-hol input[type=checkbox] {
    display: none;
}

.monthly-yearly-hol label {
    position: relative;
    width: 57px;
    height: 30px;
    border-radius: 15px;
    background: #d9d9d9;

}

.monthly-yearly-hol label:after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    left: 4px;
    top: 3px;
    border-radius: 50%;
    background: #3b6ef8;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    transition: all .35s ease;

}

.monthly-yearly-hol input[type=checkbox]:checked+label:after {
    left: 29px !important;
}

.monthly-yearly-hol input[type=checkbox]:checked+label span:last-child {
    color: #3B6EF8 !important;
}

.monthly-yearly-hol input[type=checkbox]:checked+label span:first-child {
    color: #000 !important;
}

.monthly-yearly-hol input[type=checkbox]+label span:first-child {
    left: -20px;
    top: 50%;
    position: absolute;
    transform: translate(-100%, -50%);
    font-family: Arial;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #3B6EF8;

}

.monthly-yearly-hol input[type=checkbox]+label span:last-child {
    right: -20px;
    top: 50%;
    position: absolute;
    transform: translate(100%, -50%);
    font-family: Arial;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #000;

}

.monthly-yearly-hol span.yearly-tooltip {
    position: absolute;
    width: 98px;
    height: 24px;
    border-radius: 5px;
    background: #fd5c5c;
    font-family: Poppins;
    font-weight: 600;
    font-size: 11px;
    color: #fff;
    line-height: 24px !important;
	text-align: center;
    top: 40px;
}

.monthly-yearly-hol span.yearly-tooltip:after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    background: #fd5c5c;
    left: 44%;
    transform: rotate(45deg);
    top: -5px;
}

.domain-range-list ul {
    margin: 0 -10px;
}
.domain-range-list .more-btn-link {
    position: absolute;
    right: -45px;
}
</style>