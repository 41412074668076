<template>
	<div>
		<div>
			<h3 class="text-blue mb-3">Cookie Consent Banner Script</h3>
			<p class="mb-2">Copy this script into your website's HTML header</p>
			<textarea
				class="scriptcode"
				placeholder="Describe yourself here..."
				id="joomlaStep1"
				readonly><script data-key="{{ script && script.key }}" data-name="CookieXray" src="{{script_url}}" type="text/javascript"></script></textarea>
			<p class="font-italic mb-0">
				Note: Paste this before any other script in the HTML head tag
			</p>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step1')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<h3 class="text-blue mb-3">
				Cookie Consent Banner Integrations / Plugins Script
			</h3>
			<span class="d-block mb-3"
				>Input this ID in web builders plugins (WordPress, Joomla,
				Presta shop etc.)</span
			>
			<p class="mb-2">Domain Group ID:</p>
			<textarea
				class="scriptcode mb-3"
				placeholder="Describe yourself here..."
				id="joomlaStep2"
				readonly>{{script && script.key}}</textarea>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step2')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<h3 class="text-blue mb-3">Cookie Policy Script</h3>
			<p class="mb-2">
				Copy code into your cookie policy URL to show default Cookie
				Policy.
			</p>
			<textarea
				class="scriptcode mb-3"
				placeholder="Describe yourself here..."
				id="joomlaStep3"
				readonly><div id="seers-cookie-consent"></div></textarea>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step3')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<hr />
			<h3 class="text-blue my-5">
				Installation instructions for Joomla:
			</h3>

			<details open>
				<summary>Step 1</summary>
				<div>
					<p>Log in as an administrator on your Joomla Website.</p>
					<img
						class="img-fluid"
						src="@/assets/images/Joomla-images/1.png"
					/>
				</div>
			</details>
			<div id="accordion" role="tablist">
				<details>
					<summary>Step 2</summary>
					<div>
						<p>
							Go to
							<strong class="d-inline-block text-dark"
								>"Extensions"</strong
							>
							tab in the top ribbon and select
							<strong class="d-inline-block text-dark"
								>"Manage"</strong
							>
							&gt; Install".
						</p>

						<img
							class="img-fluid"
							src="@/assets/images/Joomla-images/2.jpg"
						/>
					</div>
				</details>

				<details>
					<summary>Step 3</summary>
					<div>
						<p>
							Select the tab
							<strong class="d-inline-block text-dark"
								>“Upload Package File”</strong
							>
							Select the tab
							<strong class="d-inline-block text-dark"
								>“Seers Cookie Consent Banner and Privacy
								Policy”</strong
							>
							Seers Cookie Consent Banner and Privacy Policy
							<strong
								>“Installation of the plugin was
								successful”</strong
							>
							on the top of the page.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/Joomla-images/3.jpg"
						/>
					</div>
				</details>

				<details>
					<summary>Step 4</summary>
					<div>
						<p>
							Go to
							<strong class="d-inline-block text-dark"
								>“Manage”</strong
							>
							in the left-side menu.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/Joomla-images/4.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 5</summary>
					<div>
						<p>
							Search and select
							<strong class="d-inline-block text-dark"
								>“Seers Cookie Consent”</strong
							>
							in the plugins list.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/Joomla-images/5.png"
						/>
					</div>
				</details>
				<details>
					<summary>Step 6</summary>
					<div>
						<p>
							Click on the
							<strong class="d-inline-block text-dark"
								>“Enable”</strong
							>
							button on the top-left corner of the page.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/Joomla-images/6.png"
						/>
					</div>
				</details>
				<details>
					<summary>Step 7</summary>
					<div>
						<p>
							After the plugin is enabled, click on the
							<strong class="d-inline-block text-dark"
								>“Edit”</strong
							>
							button on the top-left corner of the page.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/Joomla-images/7.png"
						/>
					</div>
				</details>
				<details>
					<summary>Step 8</summary>
					<div>
						<p>
							Check your web URL and Email and click on SAVE
							button to get your Domain Group ID.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/Joomla-images/8.png"
						/>
					</div>
				</details>
				<details class="mb-5">
					<summary>Step 9</summary>
					<div>
						<p>
							Message received Plugin saved. Visit your and site
							banner will appear.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/Joomla-images/9.png"
						/>
					</div>
				</details>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
	name: "Joomla",
	computed:{
		cmp_url(){
			return process.env.VUE_APP_BANNER_URL
		},
		script: function() {
			return this.$store.getters['getCode/getScript'];
		},
		script_url: function() {
			// return process.env.MIX_CB_HOST +'/'+ this.$store.getters.getUser.id +'/'+ this.$store.getters.getCurrentDomainId + "/cb.js";
			return this.cmp_url + '/banners/' + this.$store.state.domain.userId +'/'+ this.$store.getters['domain/getCurrentDomainId'] + "/cb.js";
		},
	},
	methods: {
		copyScript(script){
			if (script === "step1") {
				$("#joomlaStep1").select();
			}

			if (script === "step2") {
				$("#joomlaStep2").select();
			}

			if (script === "step3") {
				$("#joomlaStep3").select();
			}
			
			Swal.fire({
				icon: "success",
				title: 'Code Copied',
				text: "Code copied successfully",
				timer: 4000,
				confirmButtonText: "Ok"
			});
			document.execCommand("copy");
		}
	},
};
</script>

<style scoped>


textarea:focus {
	outline: none !important;
}
::-webkit-scrollbar {
	display: none;
}
p span {
	font-size: 16px !important;
	font-weight: 500;
}
p {
	font-size: 15px !important;
	font-weight: 400;
}
.scriptcode {
	padding-inline-start: 10px;	
	padding-block-start: 0%;
	padding: auto;
}
#joomlaStep2 {
	height: 70px;
	padding-left: 15%;
	padding-top: 2%;
}
#joomlaStep3 {
	height: 70px;
	padding-left: 15%;
	padding-top: 2%;
}
</style>