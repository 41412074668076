export const getScript = (state) => {
    return state.script;
}

export const getFacebookConsentModeButton = (state) => {
    return state.apply_facebook_consent;
}

export const getGoogleConsentModeButton = (state) => {
    return state.apply_google_consent;
}