<template>
  <div v-if="this.inLoader" class="report-hol" style="margin-top: 50px;">
    <div class="before scanning-block text-center py-5">
      <PulseLoader style="display:inline-block" />
    </div>
  </div>
  <form v-else>
    <div class="row mx-0 mb-3">
          <div
            class="col-12 p-0 d-flex justify-content-center align-align-items-center"
          >
          <button class="btn btn-green-bg text-capitalize fixed-preview" @click.prevent="bannerShow">
            <i class="fa fa-eye mr-2 text-white" aria-hidden="true"></i>
              {{ preview_btn }}
          </button>
          </div>
        </div>
         
    <div class="row pb-3 border-bottom add-tag-language-hol mx-0">
      <div class="col-12 pl-0 d-flex flex-wrap align-items-center justify-content-start">
        <div v-for="language in this.dialogue_languages" :key="language">
          <a href="javascript:void(0)" :class="setActiveClassToLang(language)"
            @click="setCurrentLanguage($event, language);" :key="language.id"
            class="btn cursor-pointer mr-3 mb-2 px-3 new-btn">
            {{ language.name }}
            <span v-if="dialogue_languages_length > 1" :class="shouldBeDisabled ? 'disabled' : ''" @click="removeLanguage(language)"
              class="font-weight-bold ml-3 text-white" style="font-size: 10px">&#9587;</span>
          </a>
        </div>
        <a class="add-language-btn mb-2" :class="shouldBeDisabled ? 'disabled' : ''" @click="addNewLanguage">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" fill="white" stroke="#6CC04A" />
            <path d="M13.8656 20V10H16.1344V20H13.8656ZM10 16.1344V13.8656H20V16.1344H10Z" fill="#6CC04A" />
          </svg>
        </a>
        <!-- new -->
        <div class="radio-group">
        <div
          class="radio-item control-label"
        >
          <label class=" control-label pl-4 pr-0">GDPR</label>

          <i
            aria-hidden="true"
            class="fa fa-info-circle ml-2 info-tooltip-color"
          >
            <span class="pre-tooltip-text" style="text-align: center !important;">
              GDPR/ Opt-in banner content
            </span></i
          >
        </div>
        <div
          class=" d-flex align-items-center"
        >
          <label class="toggle-box"
            > <input
      id="badge"
      type="checkbox"
      class="toggle-checkbox"
      v-model="isChecked"
      @change="handleChange"
    />
            <div class="toggle-switch"></div
          ></label>
          <!---->
          <div
          class="radio-item control-label"
        >
          <label class=" control-label pl-4 pr-0">Do Not Sell</label>

          <i
            aria-hidden="true"
            class="fa fa-info-circle ml-2 info-tooltip-color"
          >
            <span class="pre-tooltip-text" style="text-align: center !important;">
              Do Not Sell banner content
            </span></i
          >
        </div>
        </div>
      </div>
     
        <!-- end -->
      </div>
    </div>

    <div v-if="banner_type_content === 'default' || banner_type_content === 'universal'" class="form-group row mb-3 mx-0 pt-4">
      <div
        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 control-label pl-0 mb-0 d-flex justify-content-start align-items-center">
        <label class="mb-2 control-label">"I disagree" button title</label>
        <i aria-hidden="true" class="fa fa-info-circle ml-2 text-gray-dark mb-2">
          <span class="pre-tooltip-text">
            This text will appear on the button that the user can click to
            “decline” the storage of cookies on their terminal equipment
            (computer or mobile)
          </span></i>
      </div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-2 pl-0 d-flex align-items-center">
  <input type="text" autocomplete="off" name="btn_disagree_title" placeholder="Disagree" class="form-control"
    v-model="current_language.btn_disagree_title" :class="shouldBeDisabled ? 'disabled' : ''" :readonly="banner_content === 0 ? true : false"
    @click="signalChange" @blur="signalChange" @keypress="languageSetting" @keyup="languageSetting"
    @keydown="languageSetting" />

  <!-- <input v-else type="text" autocomplete="off" name="btn_disagree_title" placeholder="Disagree" class="form-control"
    v-model="current_language.cpra_btn_disagree_title" :readonly="banner_content === 0 ? true : false"
    @click="signalChange" @blur="signalChange" @keypress="languageSetting" @keyup="languageSetting"
    @keydown="languageSetting" /> -->
</div>
    </div>
    <div class="form-group row mb-3 mx-0 mt-3">
      <div v-if="banner_type_content === 'default' || banner_type_content === 'universal'"
        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 control-label pl-0 mb-0 d-flex justify-content-start align-items-center">
        <label class="mb-2 control-label">"I agree" button title*</label>

        <i aria-hidden="true" class="fa fa-info-circle ml-2 text-gray-dark mb-2">
          <span class="pre-tooltip-text">
            This text will appear on the button that the user can click to
            “accept” the storage of their terminal equipment (computer or
            mobile).
          </span></i>
      </div>
      <div v-else
        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 control-label pl-0 mb-0 d-flex justify-content-start align-items-center">
        <label class="mb-2 control-label">"Confirm My Choices" button title*</label>

        <i aria-hidden="true" class="fa fa-info-circle ml-2 text-gray-dark mb-2">
          <span class="pre-tooltip-text">
            This text will appear on the button that the user can click to
            “Confirm My Choices” the storage of their terminal equipment (computer or
            mobile).
          </span></i>
      </div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-2 pl-0 d-flex align-items-center">
        <input v-if="banner_type_content === 'default' || banner_type_content === 'universal'" type="text" name="btn_agree_title" placeholder="Agree" class="form-control" autocomplete="off"
          v-model="current_language.btn_agree_title" :class="shouldBeDisabled ? 'disabled' : ''" :readonly="banner_content === 0 ? true : false" @click="signalChange"
          @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting" @keydown="languageSetting" />
        <input v-else type="text" name="btn_agree_title" placeholder="Confirm My Choices" class="form-control" autocomplete="off"
          v-model="current_language.cpra_btn_agree_title" :class="shouldBeDisabled ? 'disabled' : ''" :readonly="banner_content === 0 ? true : false" @click="signalChange"
          @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting" @keydown="languageSetting" />
        <!---->
      </div>
    </div>
    <div>
    <div v-if="banner_type_content === 'default' || banner_type_content === 'universal'" class="form-group row mb-3 mx-0">
      <div
        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 control-label pl-0 mb-0 d-flex justify-content-start align-items-center">
        <label class="mb-2 control-label">"Preferences" button title *</label>

        <i aria-hidden="true" class="fa fa-info-circle ml-2 text-gray-dark mb-2">
          <span class="pre-tooltip-text">
            This text will expand the cookie banner downward, showing the
            categories of cookies and details of each cookie within the
            respective category.
          </span></i>
      </div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-2 pl-0 d-flex align-items-center">
        <input type="text" name="btn_preference_title" placeholder="Preferences" class="form-control" autocomplete="off"
          v-model="current_language.btn_preference_title" :class="shouldBeDisabled ? 'disabled' : ''" :readonly="banner_content === 0 ? true : false"
          @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
          @keydown="languageSetting" />
        <!-- <input v-else type="text" name="btn_preference_title" placeholder="Preferences" class="form-control" autocomplete="off"
          v-model="current_language.cpra_btn_preference_title" :readonly="banner_content === 0 ? true : false"
          @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
          @keydown="languageSetting" /> -->
        <!---->
      </div>
    </div>
    <div v-else class="form-group row mb-3 mx-0">
      <div
        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 control-label pl-0 mb-0 d-flex justify-content-start align-items-center">
        <label class="mb-2 control-label">"Email Submit" button title *</label>

        <i aria-hidden="true" class="fa fa-info-circle ml-2 text-gray-dark mb-2">
          <span class="pre-tooltip-text">
            This text will appear on the Email Submit button
          </span></i>
      </div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-2 pl-0 d-flex align-items-center">
        <input type="text" name="btn_preference_title" placeholder="Submit" class="form-control" autocomplete="off"
          v-model="current_language.cpra_btn_preference_title" :class="shouldBeDisabled ? 'disabled' : ''" :readonly="banner_content === 0 ? true : false"
          @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
          @keydown="languageSetting" />
      </div>
    </div>
  </div>
  <div>
    <div v-if="banner_type_content === 'exerto'" class="form-group row mb-3 mx-0">
      <div
        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 control-label pl-0 mb-0 d-flex justify-content-start align-items-center">
        <label class="mb-2 control-label">"Policy URL" button title *</label>

        <i aria-hidden="true" class="fa fa-info-circle ml-2 text-gray-dark mb-2">
          <span class="pre-tooltip-text">
            This text will appear on the Policy URL button
          </span></i>
      </div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-2 pl-0 d-flex align-items-center">
        <input type="text" name="btn_preference_title" placeholder="Open" class="form-control" autocomplete="off"
          v-model="current_language.cpra_btn_disagree_title" :class="shouldBeDisabled ? 'disabled' : ''" :readonly="banner_content === 0 ? true : false"
          @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
          @keydown="languageSetting" />
      </div>
    </div>
  </div>
    <div class="form-group row mb-3 mx-0">
      <div
        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 control-label pl-0 mb-0 d-flex justify-content-start align-items-center">
        <label class="mb-2 control-label">Title*</label>

        <i aria-hidden="true" class="fa fa-info-circle ml-2 text-gray-dark mb-2">
          <span class="pre-tooltip-text"> Banner title </span></i>
      </div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-2 pl-0 d-flex align-items-center">
        <input v-if="banner_type_content === 'default' || banner_type_content === 'universal'" placeholder="We use cookies" type="text" name="title" class="form-control" autocomplete="off"
          v-model="current_language.title" :readonly="banner_content === 0 ? true : false" @click="signalChange"
          @focus="signalChange" :class="shouldBeDisabled ? 'disabled' : ''" v-on:keypress="languageSetting" @keyup="languageSetting" @keydown="languageSetting" />
        <input v-else placeholder="Do Not Share My Personal Information" type="text" name="title" class="form-control" autocomplete="off"
          v-model="current_language.cpra_title" :class="shouldBeDisabled ? 'disabled' : ''" :readonly="banner_content === 0 ? true : false" @click="signalChange"
          @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting" @keydown="languageSetting" />
        <!---->
      </div>
    </div>
    <div>
    <div v-if="banner_type_content === 'default' || banner_type_content === 'universal'" class="form-group row mb-3 mx-0">
      <div
        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 control-label pl-0 mb-0 d-flex justify-content-start align-items-center">
        <label class="mb-2 control-label">First Layout Description *</label>

        <i aria-hidden="true" class="fa fa-info-circle ml-2 text-gray-dark mb-2">
          <span class="pre-tooltip-text"> Banner content </span></i>
      </div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-2 pl-0 d-flex align-items-center">
        <div class="editor-container">
          <editor api-key="8ikn5meo0203s1f31v93iuq5rdig1t0yyekb2jkssi521xfo" :init="initOptions"
            v-model="current_language.body" :class="shouldBeDisabled ? 'disabled' : ''" :readonly="shouldBeDisabled ||banner_content === 0 ? true : false" @click="signalChange"
            @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting" @keydown="languageSetting"
            autocomplete="off" />
        </div>

        <!-- <textarea
          v-model="current_language.body" :readonly="banner_content === 0 ? true : false" @click="signalChange"
          @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
          @keydown="languageSetting" 
          autocomplete="off"
          name="body"
          maxlength="2000"
          placeholder="Cookies are to ensure website user gets best experience. Necessary cookies can be stored in the users devices. We need your permission for non essential cookies. Learn more about how we process personal data in our Privacy Policy?"
          class="form-control"
          style="min-height: 100px"
        ></textarea> -->
      </div>
    </div>
    <div v-else class="form-group row mb-3 mx-0">
      <div
        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 control-label pl-0 mb-0 d-flex justify-content-start align-items-center">
        <label class="mb-2 control-label">Description *</label>

        <i aria-hidden="true" class="fa fa-info-circle ml-2 text-gray-dark mb-2">
          <span class="pre-tooltip-text"> Banner content </span></i>
      </div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-2 pl-0 d-flex align-items-center">
        <div class="editor-container">
          <editor api-key="8ikn5meo0203s1f31v93iuq5rdig1t0yyekb2jkssi521xfo" :init="initOptions"
            v-model="current_language.cpra_body" :class="shouldBeDisabled ? 'disabled' : ''" :readonly="banner_content === 0 ? true : false" @click="signalChange"
            @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting" @keydown="languageSetting"
            autocomplete="off" />
        </div>

        <!-- <textarea
          v-model="current_language.body" :readonly="banner_content === 0 ? true : false" @click="signalChange"
          @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
          @keydown="languageSetting" 
          autocomplete="off"
          name="body"
          maxlength="2000"
          placeholder="Cookies are to ensure website user gets best experience. Necessary cookies can be stored in the users devices. We need your permission for non essential cookies. Learn more about how we process personal data in our Privacy Policy?"
          class="form-control"
          style="min-height: 100px"
        ></textarea> -->
      </div>
    </div>
  </div>
    <div v-if="banner_type_content === 'default' || banner_type_content === 'universal'" class="form-group row mb-3 mx-0">
      <div
        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 control-label pl-0 mb-0 d-flex justify-content-start align-items-center">
        <label class="mb-2 control-label">Second Layout Description</label>

        <i aria-hidden="true" class="fa fa-info-circle ml-2 text-gray-dark mb-2">
          <span class="pre-tooltip-text"> Second Layout Description </span></i>
      </div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-2 pl-0 d-flex align-items-center">
        <textarea  v-model="current_language.second_layout_description" :readonly="banner_content === 0 ? true : false"
          @click="signalChange" :class="shouldBeDisabled ? 'disabled' : ''" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
          @keydown="languageSetting" autocomplete="off" name="body" maxlength="2000" class="form-control"
          style="min-height: 100px" placeholder="Enter Second Layout Description"></textarea>
        <!-- <textarea v-else v-model="current_language.cpra_second_layout_description" :readonly="banner_content === 0 ? true : false"
          @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
          @keydown="languageSetting" autocomplete="off" name="body" maxlength="2000" class="form-control"
          style="min-height: 100px" placeholder="Enter Second Layout Description"></textarea> -->
      </div>
    </div>
    <div  v-if="banner_type_content === 'exerto'" class="form-group row mb-3 mx-0">
      <div
        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 control-label pl-0 mb-0 d-flex justify-content-start align-items-center">
        <label class="mb-2 control-label">Email Section Content</label>

        <i aria-hidden="true" class="fa fa-info-circle ml-2 text-gray-dark mb-2">
          <span class="pre-tooltip-text"> Email Section Content </span></i>
      </div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-2 pl-0 d-flex align-items-center">
        <!-- <textarea v-if="banner_type_content === 'default'" v-model="current_language.email_section_title" :readonly="banner_content === 0 ? true : false"
          @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
          @keydown="languageSetting" autocomplete="off" name="body" maxlength="2000" class="form-control"
          style="min-height: 100px"></textarea> -->
        <textarea v-model="current_language.cpra_email_section_title" :readonly="banner_content === 0 ? true : false"
          @click="signalChange" :class="shouldBeDisabled ? 'disabled' : ''" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
          @keydown="languageSetting" autocomplete="off" name="body" maxlength="2000" class="form-control"
          style="min-height: 100px"></textarea>
      </div>
    </div>
    <div  v-if="banner_type_content === 'exerto'" class="form-group row mb-3 mx-0">
      <div
        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 control-label pl-0 mb-0 d-flex justify-content-start align-items-center">
        <label class="mb-2 control-label">Policy URL Content</label>

        <i aria-hidden="true" class="fa fa-info-circle ml-2 text-gray-dark mb-2">
          <span class="pre-tooltip-text">Policy URL Content</span></i>
      </div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-2 pl-0 d-flex align-items-center">
        <!-- <textarea v-if="banner_type_content === 'default'" v-model="current_language.email_section_title" :readonly="banner_content === 0 ? true : false"
          @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
          @keydown="languageSetting" autocomplete="off" name="body" maxlength="2000" class="form-control"
          style="min-height: 100px"></textarea> -->
        <textarea v-model="current_language.cpra_second_layout_description" :readonly="banner_content === 0 ? true : false"
          @click="signalChange" :class="shouldBeDisabled ? 'disabled' : ''" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
          @keydown="languageSetting" placeholder="Enter Policy URL Text" autocomplete="off" name="body" maxlength="2000" class="form-control"
          style="min-height: 100px"></textarea>
      </div>
    </div>
    <div  v-if="banner_type_content === 'default' || banner_type_content === 'universal'" class="row">
      <div class="col-12">
        <details>
          <summary>Cookies*</summary>
          <div>
            <textarea name="cookies_body" v-model="current_language.cookies_body"
              :readonly="banner_content === 0 ? true : false" @click="signalChange" @focus="signalChange"
              v-on:keypress="languageSetting" :class="shouldBeDisabled ? 'disabled' : ''" @keyup="languageSetting" @keydown="languageSetting" autocomplete="off"
              maxlength="2000"
              placeholder="Cookies are to ensure website user gets best experience. Necessary cookies can be stored in the users devices. We need your permission for non essential cookies. Learn more about how we process personal data in our Privacy Policy?"
              class="w-100 custom-textarea form-control"></textarea>
            <!-- <textarea v-else name="cookies_body" v-model="current_language.cpra_cookies_body"
              :readonly="banner_content === 0 ? true : false" @click="signalChange" @focus="signalChange"
              v-on:keypress="languageSetting" @keyup="languageSetting" @keydown="languageSetting" autocomplete="off"
              maxlength="2000"
              placeholder="Cookies are to ensure website user gets best experience. Necessary cookies can be stored in the users devices. We need your permission for non essential cookies. Learn more about how we process personal data in our Privacy Policy?"
              class="w-100 custom-textarea form-control"></textarea> -->
          </div>
        </details>
      </div>
    </div>
    <div  v-if="banner_type_content === 'default' || banner_type_content === 'universal'" class="row">
      <div class="col-12">
        <details>
          <summary>Necessary*</summary>

          <div>
            <input type="text" name="preference_title" placeholder="Preferences"
              class="form-control w-100 mb-3 custom-textarea" autocomplete="off"
              :readonly="banner_content === 0 ? true : false" @click="signalChange"
              v-model="current_language.necessory_title" :class="shouldBeDisabled ? 'disabled' : ''" @keyup="languageSetting" @keydown="languageSetting"
              @focus="signalChange" v-on:keypress="languageSetting" />
            <!-- <input v-else type="text" name="preference_title" placeholder="Preferences"
              class="form-control w-100 mb-3 custom-textarea" autocomplete="off"
              :readonly="banner_content === 0 ? true : false" @click="signalChange"
              v-model="current_language.cpra_necessory_title" @keyup="languageSetting" @keydown="languageSetting"
              @focus="signalChange" v-on:keypress="languageSetting" /> -->
            <textarea name="necessory_body" maxlength="2000" v-model="current_language.necessory_body"
              @keyup="languageSetting" :class="shouldBeDisabled ? 'disabled' : ''" @keydown="languageSetting" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting"
              placeholder="Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies."
              class="form-control w-100 custom-textarea"></textarea>
            <!-- <textarea v-else name="necessory_body" maxlength="2000" v-model="current_language.cpra_necessory_body"
              @keyup="languageSetting" @keydown="languageSetting" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting"
              placeholder="Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies."
              class="form-control w-100 custom-textarea"></textarea> -->
          </div>
        </details>
      </div>
    </div>
    <div  v-if="banner_type_content === 'default' || banner_type_content === 'universal'" class="row">
      <div class="col-12">
        <details>
          <summary>Preference*</summary>

          <div>
            <input type="text" name="preference_title" placeholder="Preferences"
              class="form-control w-100 mb-3 custom-textarea" autocomplete="off"
              v-model="current_language.preference_title" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" :class="shouldBeDisabled ? 'disabled' : ''" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
              @keydown="languageSetting" />
            <!-- <input v-else type="text" name="preference_title" placeholder="Preferences"
              class="form-control w-100 mb-3 custom-textarea" autocomplete="off"
              v-model="current_language.cpra_preference_title" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
              @keydown="languageSetting" /> -->
            <textarea name="preference_body" v-model="current_language.preference_body"
              :readonly="banner_content === 0 ? true : false" @click="signalChange" @focus="signalChange"
              v-on:keypress="languageSetting" :class="shouldBeDisabled ? 'disabled' : ''" @keyup="languageSetting" @keydown="languageSetting" autocomplete="off"
              maxlength="2000"
              placeholder="Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in."
              class="form-control w-100 custom-textarea"></textarea>
            <!-- <textarea v-else name="preference_body" v-model="current_language.cpra_preference_body"
              :readonly="banner_content === 0 ? true : false" @click="signalChange" @focus="signalChange"
              v-on:keypress="languageSetting" @keyup="languageSetting" @keydown="languageSetting" autocomplete="off"
              maxlength="2000"
              placeholder="Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in."
              class="form-control w-100 custom-textarea"></textarea> -->
          </div>
        </details>
      </div>
    </div>
    <div  v-if="banner_type_content === 'default' || banner_type_content === 'universal'" class="row">
      <div class="col-12">
        <details>
          <summary>Statistics*</summary>
          <div>
            <input v-model="current_language.statistics_title" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" :class="shouldBeDisabled ? 'disabled' : ''" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
              @keydown="languageSetting" autocomplete="off" type="text" name="statistics_title" placeholder="Statistics"
              class="form-control mb-3 custom-textarea" />
            <!-- <input v-else v-model="current_language.cpra_statistics_title" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
              @keydown="languageSetting" autocomplete="off" type="text" name="statistics_title" placeholder="Statistics"
              class="form-control mb-3 custom-textarea" /> -->
            <textarea v-model="current_language.statistics_body" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" :class="shouldBeDisabled ? 'disabled' : ''" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
              @keydown="languageSetting" name="statistics_body" autocomplete="off" maxlength="2000"
              placeholder="Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously."
              class="form-control w-100 custom-textarea"></textarea>
            <!-- <textarea v-else v-model="current_language.cpra_statistics_body" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
              @keydown="languageSetting" name="statistics_body" autocomplete="off" maxlength="2000"
              placeholder="Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously."
              class="form-control w-100 custom-textarea"></textarea> -->
          </div>
        </details>
      </div>
    </div>
    <div  v-if="banner_type_content === 'default' || banner_type_content === 'universal'" class="row">
      <div class="col-12">
        <details>
          <summary>Marketing*</summary>
          <div>
            <input v-model="current_language.marketing_title" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" :class="shouldBeDisabled ? 'disabled' : ''" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
              @keydown="languageSetting"  autocomplete="off" type="text" name="marketing_title" placeholder="Marketing"
              class="form-control mb-3 custom-textarea" />
            <!-- <input v-else v-model="current_language.cpra_marketing_title" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
              @keydown="languageSetting" autocomplete="off" type="text" name="marketing_title" placeholder="Marketing"
              class="form-control mb-3 custom-textarea" /> -->
            <textarea v-model="current_language.marketing_body" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" :class="shouldBeDisabled ? 'disabled' : ''" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
              @keydown="languageSetting" autocomplete="off" name="marketing_body" maxlength="2000"
              placeholder="t('Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third-party advertisers.')"
              class="form-control w-100 custom-textarea"></textarea>
            <!-- <textarea v-else v-model="current_language.cpra_marketing_body" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
              @keydown="languageSetting" autocomplete="off" name="marketing_body" maxlength="2000"
              placeholder="t('Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third-party advertisers.')"
              class="form-control w-100 custom-textarea"></textarea> -->
          </div>
        </details>
      </div>
    </div>
    <div  v-if="banner_type_content === 'default' || banner_type_content === 'universal'" class="row">
      <div class="col-12">
        <details>
          <summary>Unclassified*</summary>
          <div>
            <input v-model="current_language.unclassified_title" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" :class="shouldBeDisabled ? 'disabled' : ''" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
              @keydown="languageSetting" autocomplete="off" type="text" name="unclassified_title"
              placeholder="Unclassified" class="form-control mb-3 custom-textarea" />
            <!-- <input v-else v-model="current_language.cpra_unclassified_title" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
              @keydown="languageSetting" autocomplete="off" type="text" name="unclassified_title"
              placeholder="Unclassified" class="form-control mb-3 custom-textarea" /> -->
            <textarea v-model="current_language.unclassified_body" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" :class="shouldBeDisabled ? 'disabled' : ''" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
              @keydown="languageSetting" autocomplete="off" name="unclassified_body" maxlength="2000"
              placeholder="Unclassified cookies are cookies that we are in the process of classifying, together with the providers of individual cookies."
              class="form-control w-100 custom-textarea"></textarea>
            <!-- <textarea v-else v-model="current_language.cpra_unclassified_body" :readonly="banner_content === 0 ? true : false"
              @click="signalChange" @focus="signalChange" v-on:keypress="languageSetting" @keyup="languageSetting"
              @keydown="languageSetting" autocomplete="off" name="unclassified_body" maxlength="2000"
              placeholder="Unclassified cookies are cookies that we are in the process of classifying, together with the providers of individual cookies."
              class="form-control w-100 custom-textarea"></textarea> -->
          </div>
        </details>

      </div>
    </div>

    <div v-if="banner_type_content === 'default'" class="row" v-for="(category, index) in filteredCategories" :key="index">
  <div class="col-12">
    <details>
      <summary @click="toggleCategoryVisibility(index)">
        <span
              style="font-weight: 500;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; font-size: 15px; vertical-align: middle; color: #7E7E7E;">
          {{ category.customize_title }}
        </span>
        <!-- <button
    style="font-size: 24px; color: #7E7E7E; background-color: transparent; border: none; float: right; padding-right: 30px; line-height: 24px;"
    @click.prevent="deleteCategory(index, category.id)">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button> -->
        <button
    style="font-size: 24px; color: #7E7E7E; background-color: transparent; border: none; float: right; margin-right: 30px; line-height: 24px;"
    @click.prevent="confirmDelete(index, category.id)">
    <i class="fa fa-trash" aria-hidden="true"></i>
</button>

      </summary>
      <div v-show="category.visible">
        <input v-model="category.customize_title" v-on:keypress="languageSetting" :class="shouldBeDisabled ? 'disabled' : ''" @keyup="languageSetting"
              @keydown="languageSetting" type="text" :name="`${category.customize_title}_title`"
              :placeholder="category.customize_title" class="form-control mb-3 custom-textarea" />
        <textarea v-model="category.customize_body" v-on:keypress="languageSetting" :class="shouldBeDisabled ? 'disabled' : ''" @keyup="languageSetting"
              @keydown="languageSetting" :name="category.customize_title" maxlength="2000"
              :placeholder="category.customize_body" class="form-control w-100 custom-textarea"></textarea>
              </div>
    </details>
  </div>
</div>
          <DefaultbBanner v-if="show_dialogue_preview === true && banner_type_content == 'default'"></DefaultbBanner>
          <exertoBanner v-if="show_dialogue_preview === true && banner_type_content == 'exerto'"></exertoBanner>
          <universalBanner v-if="show_dialogue_preview === true && banner_type_content == 'universal'"></universalBanner>

    <div class="row m-0 mt-4">
      <div class="col-12 pt-4 border-top px-0">
        <ul class="list-unstyled d-flex align-items-center p-0 domain-hol mb-0">
          <li class="mr-3 mb-3"><router-link to="get-code" class="btn btn-white-bg">Previous</router-link></li>


          <li  v-if="banner_type_content === 'default'" class="ml-auto mr-3 mb-3">
            <button class="btn btn-blue-bg" @click.prevent="addNewCategory" :class="shouldBeDisabled ? 'disabled' : ''">Add New Category</button>
          </li>

          <li class="mr-3 mb-3">
            <a class="btn btn-green-bg" :class="button_is_disabled == false  || shouldBeDisabled ? 'disabled' : ''"
              @click.prevent="saveDialogueProperties()">Save Changes</a>
          </li>
        </ul>
      </div>
    </div>

  </form>
</template>

<script>
import { emitter } from "@/eventbus";
import Editor from '@tinymce/tinymce-vue'
import Swal from "sweetalert2";
import { mapState } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import DefaultbBanner from "@/components/CustoMiZationComponent/DefaultBanner.vue"
import ccpaBanner from "@/components/CustoMiZationComponent/ccpaBanner.vue"
import exertoBanner from "@/components/CustoMiZationComponent/exertoBanner.vue"
import universalBanner from "@/components/CustoMiZationComponent/UniversalBanner.vue"

export default {
  name: "BannerContent",
  components: {
    PulseLoader,
    DefaultbBanner,
    ccpaBanner,
    exertoBanner,
    universalBanner,
    'editor': Editor
  },
  data() {
    return {
      content: '',
      banner_type_content: 'default',
      isChecked: false,
        initOptions: {
        deprecation_warnings: false,
        forced_root_block: false,
        valid_elements: 'br,a[href|target=_blank]',
        branding: false,
        plugins: 'link',
        toolbar: 'link',
        menubar: false,

        // branding: false,
        // fontsize_formats: '',
        // font_formats: '',

      },
    };
  },
  mounted() {
    this.banner_type_content=this.current_banner.name;
    if (this.banner_type === 'exerto') {
      this.isChecked = true; 
    } else {
      this.isChecked = false;
    }
  },
  computed: {
    ...mapState('domain', ['inLoader','current_domain_id','banner_type','user','assign_role']
    ),
    preview_btn: function() {
      return this.$store.getters["domain/getPreviewBtn"];
    },
    shouldBeDisabled() {
      return !this.user.isSuperAdmin && this.user.superAdminId !== null && this.assign_role === 'reader';
    },
    show_dialogue_preview: function () {
      return this.$store.getters["domain/getShowDialoguePreview"];
    },
    banner_type: function () {
      return this.$store.state.domain.banner_type;
    },
    //Add New Categories
    categories: function () {
      return this.$store.getters["domain/getCategories"];
    },
    dialogue: function () {
      return this.$store.getters["domain/getDialogueProperties"];
    },
    filteredCategories: function () {
      return this.categories.filter((cat) => cat.dialogueId === this.cookie_xray_dialogues_id);
    },
    //End Add New Category

    button_is_disabled: function () {
      return this.$store.getters["domain/getButtonIsDisabled"];
    },

    form: function () {
      return this.$store.state.dialogue.form;
    },
    // domain_id: function() {
    //   return this.$store.state.current_domain_id;
    // },
    current_language: function () {
      return this.$store.getters["domain/getCurrentLanguage"];
    },
    current_banner: function () {
      return this.$store.getters["domain/getCurrentBanner"];
    },
    dialogue_languages: {
      set: function (languages) {
        this.$store.commit("domain/setDialogueLanguages", languages);
      },
      get: function () {
        return this.$store.getters["domain/getDialogueLanguages"];
      },
    },
    dialogue_languages_length: function () {
      return this.dialogue_languages.length;
    },
    preDefinedLanguages: function () {
      return this.$store.getters["domain/getPreDefinedLanguages"];
    },
    multilingual_banner: function () {
      return parseInt(
        this.$store.getters["domain/getFeatures"].multilingual_banner
      );
    },
    allowed_languages: function () {
      switch (this.multilingual_banner) {
        case 0:
          return ["GB"];
          break;
        case 1:
          return ["GB", "US"];
          break;
        case 2:
          return ["GB", "US", "FR"];
          break;
        case 3:
          return ["GB", "US", "FR", "DE"];
          break;
        case 4:
          return ["GB", "US", "FR", "DE", "PT"];
          break;
        case 5:
          return ["GB", "US", "FR", "DE", "PT", "IT"];
          break;
        case 6:
          return ["GB", "US", "FR", "DE", "PT", "IT", "ES"];
          break;
        case 7:
          return ["GB", "US", "FR", "DE", "PT", "IT", "ES", "SA"];
          break;
        case 8:
          return [
            "US",
            "ES",
            "AL",
            "SA",
            "EN",
            "CN",
            "FR",
            "DE",
            "IT",
            "PT",
            "GB",
            "BG",
            "HR",
            "EL",
            "TR",
            "CZ",
            "DK",
            "EE",
            "FI",
            "SE",
            "HU",
            "IE",
            "LV",
            "LT",
            "MT",
            "PL",
            "RO",
            "SK",
            "SI",
            "NL",
          ];
          break;

        default:
          return ["GB"];
      }
    },
    banner_content: function () {
      return parseInt(this.$store.getters['domain/getFeatures'].banner_content);
    },
  },
  //   watch:{
  // 		current_language: {
  // 			deep: true,
  // 			handler(){
  // 				console.log('first')
  // 				if(this.$store.state.domain.button_is_disabled == false){
  // 					this.$store.commit("domain/setButtonIsDisabled", true);
  // 				}
  // 			}
  // 		}
  // 	},
  methods: {
    //Add New Categories
    addNewCategory() {
      this.$store.dispatch('domain/addNewCategory', [this.dialogue.id,this.current_domain_id]).then(() => {
        console.log(this.current_domain_id);
      });
    },
    handleChange() {
      this.banner_type_content = this.isChecked ? 'exerto' : this.current_banner.name == 'universal' ? 'universal' : 'default';
    },

    toggleCategoryVisibility(index) {
      this.$store.commit('domain/toggleCategoryVisibility', index);
    },

    //End Add New Category
    saveDialogueProperties() {
      emitter.emit('saveDialogueProperties');
      console.log("hello",this.banner_type);
    },
    
    saveChanges() {
      try {
        this.$store.dispatch('domain/saveCategories');
        emitter.emit('saveDialogueProperties');
        console.log('Changes saved successfully!');
      } catch (error) {
        console.error('Failed to save changes.', error);
      }
    },
    confirmDelete(index, categoryId) {
    Swal.fire({
      title: "Alert!",
      text: "You Want To Delete This Category ? ",
      icon: "warning", 
      confirmButtonColor: "#41a2db",
      cancelButtonColor: "#666666",
      confirmButtonText: "Yes",
      cancelButtonText: "No", 
      showCancelButton: true 
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteCategory(index, categoryId);
      }
    });
  },
    deleteCategory(index, categoryId) {
  this.$store.dispatch('domain/deleteCategory', { index, categoryId })
    .then(successMessage => {
      console.log(successMessage);
          })
    .catch(error => {
      console.error(error);
    });
},

    setActiveClassToLang(lang) {
      // console.log('current lang',this.current_language);

      return lang.country_code === this.form.default_language ? "active" : "inactive";

    },
    setCurrentLanguage: function (event, language) {
      if (event.target.tagName == 'SPAN') {
        return;
      }
      if (language.country_code === this.current_language.country_code) return;
      this.dialogue_languages.forEach((lang) => {
        if (lang.country_code === this.current_language.country_code) {
          Object.assign(lang, this.current_language);
        }
        if (lang.country_code === language.country_code) {
          this.$store.commit("domain/setCurrentLanguage", language);
          this.form.default_language = language.country_code;
        }
      });
      // console.log('country code',this.current_language.country_code)
      this.languageSetting();
      // this.enableSaveButton();
    },
    removeLanguage: function (language) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete the language?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3B6EF8",
        cancelButtonColor: "#6cc04a",
        confirmButtonText: "Yes, Delete It!",
        cancelButtonText: "Cancel",
      }).then(async (result) => {
        if (result.value) {
          this.dialogue_languages = this.dialogue_languages.filter(
            (lang) => lang.name !== language.name
          );
          if (this.dialogue_languages.length > 0 && this.current_language.name == language.name) {
            // const lang = this.dialogue_languages.length - this.dialogue_languages.length;`
            this.$store.commit("domain/setCurrentLanguage", this.dialogue_languages[0]);
            this.form.default_language = this.dialogue_languages[0].country_code;
          }
          this.languageSetting();

        }
        // console.log('total languages',this.dialogue_languages);
      });
    },
    addNewLanguage() {
      if (this.preDefinedLanguages.length > 0) {
        let selectOptions = "";
        selectOptions =
          '<option value="null" selected disabled>Select...</option>';
        this.preDefinedLanguages.forEach((lang) => {
          selectOptions +=
            '<option value="' +
            lang.country_code +
            '"> ' +
            lang.name +
            "</option>";
        });
        Swal.fire({
          title: "Select Language",
          html:
            "<div>" +
            '<select class="form-control" name="language" id="lang">' +
            selectOptions +
            "</select>" +
            "</div>",
          confirmButtonText: "Add Language",
          confirmButtonColor: "#6cc04a",
          showCloseButton: true,
          focusConfirm: false,

          preConfirm: () => {
            let country_code = $("#lang option:selected").val();
            // .text()
            // .trim();
            let lang = this.dialogue_languages.find(
              (lang) => lang.country_code === country_code
            );
            if (lang === undefined) {
              // if (!this.allowed_languages.includes(country_code)) {
              //   // this.displayAlert();
              //   return;
              // }
              this.addLanguage(country_code);
            } else {
              setTimeout(() => {
                Swal.fire({
                  type: "warning",
                  title: "Warning",
                  text: "Language already exists",
                  confirmButtonText: "Ok",
                });
              }, 1000);
            }
            return;
          },
        });
      } else {
        setTimeout(() => {
          Swal.fire({
            title: "Warning",
            type: "warning",
            text: "No Languages are given",
            confirmButtonText: "Ok",
          });
        }, 1000);
      }
    },
    addLanguage: function (country_code) {
      let lang = this.preDefinedLanguages.find(
        (language) => language.country_code === country_code
      );
      if (lang !== undefined) {
        this.dialogue_languages.push(lang);
        setTimeout(() => {
          Swal.fire({
            title: "Great!",
            type: "success",
            text: "Language Added Successfully",
            confirmButtonText: "Ok",
          });
        }, 500);
        emitter.emit("saveDialogueProperties");
      }
    },
    displayAlert: function () {
      this.$store.commit("domain/setShowPricingPopup", true);
    },
    signalChange: function (e) {
      if (this.banner_content === 0) {
        e.preventDefault();
        this.displayAlert();
      }
    },
    languageSetting() {
      this.$store.commit("domain/setButtonIsDisabled", true);
    },
    bannerShow(){
      if(this.show_dialogue_preview == true){
        this.$store.commit('domain/setShowDialoguePreview', false)
        this.$store.commit("domain/setPreviewBtn", 'Preview');
      }
      else{
        this.$store.commit("domain/setPreviewBtn", 'Exit Preview');
        this.$store.commit('domain/setShowDialoguePreview', true)
      }
    },
  },
};
</script>

<style scoped>
.disabled {
  opacity: 1;
  cursor: not-allowed;
  pointer-events: none;
}

.fixed-preview{
  position: fixed;
  top: 50%;
  transform: rotate(-90deg) translateY(-50%);
right: -65px;
    width: 132px;
}
.editor-container {
  width: 100%;
  /* set your desired width here */
}

.add-language-tag {
  cursor: pointer;
}

.add-language-btn {
  cursor: pointer;
}

.swal2-close:focus {
  outline: none !important;
  box-shadow: none !important;
}

.new-btn {
  border-color: #6cc04a;
}

.new-btn:hover {
  background-color: #6cc04a;
  color: #fff;
}

.new-btn.active {
  color: #fff;
  background-color: #6cc04a;
}

.radio-group {
  display: flex;
  justify-content: center;
  margin-left: auto;
}

.radio-item {
  display: flex;
  align-items: center;
}

.radio-item input[type="radio"] {
  width: 20px; 
  height: 20px; 
  margin-bottom: 5px;
  -webkit-appearance: none; 
  -moz-appearance: none; 
  appearance: none; 
  border: 2px solid #3b6ef8; 
  border-radius: 50%; 
  outline: none;
}

.radio-item input[type="radio"]:checked {
  background-color: #3b6ef8; 
}

.toggle-box .toggle-switch:before {
    background: #126FEC !important;
    
}

</style>
