<template>
    <div>
        <div id="seers-cx-side-bar" class="seers-cx-show cookie_seers_popup">
            <div class="cookie_seers_popup_content">
                <div id="popup-close" class="popupCloseButton">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" v-bind:style="{'fill': current_banner.body_text_color}"><title>
                        Close</title> <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></div>
                <!-- header -->
                <div id="cookie_seers_popup_header" class="cookie_seers_popup_header" v-bind:style="{background: current_banner.banner_bg_color}">
                    <div class="cookie_client_img" v-if="dialogueProperties.logo_status !== 'none'" v-bind:style="{background :current_banner.logo_bg_color}"><img :src="this.dialogueProperties.logo_link"></div>
                    <div class="cookie_client_heading"><h2 v-bind:style="{color :current_banner.title_text_color, fontSize :(parseInt(current_banner.font_size)+2).toString() + 'px !important'}"> {{this.language.cpra_title ? this.language.cpra_title : this.language.title}}</h2></div>
                    <div class="popup_discription"><p id="seers-cookie-body-2" v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}" v-html="desanitizedBody ? desanitizedBody : cpra_sanitizedBody ? cpra_sanitizedBody : sanitizedBody"></p></div>
                    <div class="seers_header_toggle">
                        <div class="seers_main_switch seers_header_switch">
                            <input id="do-not-sell-1" class="cmn-toggle cmn-toggle-round do-not-sell" type="checkbox">
                            <label for="do-not-sell-1" v-bind:style="{'--switch-color': current_banner.banner_bg_color}" />
                        </div>
                        <span class="header_toggle_label" v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">Targeting / Advertising</span>
                    </div>
                    <!-- language -->
                    <div id="seers-cx-header-menu" class="seers_language hide">
                        <button id="seers-cx-back-btn" type="button" class="cookie_btn cookie_btn_open cookie_seers_open_btn seers-cx-show-details" v-bind:class="current_banner.button_type" v-bind:style="{background :current_banner.agree_btn_color + ' !important',color :current_banner.agree_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">
                            Back
                        </button>
                        <div class="language_right">
                            <!--                        <div class="seers_circle_language sees_en">EN</div>-->
                            <!--                        <div class="seers_circle_language sees_count">16+</div>-->
                        </div>
                    </div>
                    <!-- language end-->
                </div>
                <!-- header end -->
                <!-- cookie content -->
                <div v-bind:style="{height: 'calc(100vh - ' + (container_height + footer_height) + 'px)'}" id="seers-cx-cookies" class="seers_cookie_content seers-cx-show">
                    <div class="seers_cookie_header">
                        <h3 v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important', fontWeight: 'bold'}">{{language.about_cookies}}</h3>
                        <p v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important'}">{{language.cpra_cookies_body ? language.cpra_cookies_body : language.cookies_body}}</p>
                    </div>
                    <!-- box1 -->
                    <div class="seers_text_box">
                        <div class="seers_text_top_section">
                            <h5 v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important', fontWeight: 'bold'}">{{language.cpra_necessory_title ? language.cpra_necessory_title : language.necessory_title}}</h5>
                            <div class="seers_main_switch disabled_switch">
                                <input id="necc" class="cmn-toggle cmn-toggle-round"  type="checkbox" value="" :checked="true" :disabled="true">
                                <label for="necc" v-bind:style="{'--switch-color': current_banner.banner_bg_color}" />
                            </div>
                        </div>
                        <div class="seers_text_box_description">
                            <p v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important'}">{{language.cpra_necessory_body ? language.cpra_necessory_body : language.necessory_body}}</p>
                        </div>
                        <div class="display_cookie" v-if="this.dialogueProperties.drill_down">
                            <span v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important'}"> 3 Cookies </span> | <a  class="show-cookie-menu" id="show-cookie-menu_necess" data-category="necess">View</a>
                        </div>
                    </div>
                    <!-- box1 -->

                    <!-- box1 -->
                    <div class="seers_text_box">
                        <div class="seers_text_top_section">
                            <h5 v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important', fontWeight: 'bold'}">{{language.cpra_preference_title ? language.cpra_preference_title : language.preference_title}}</h5>
                            <div class="seers_main_switch">
                                <input id="pref" class="cmn-toggle cmn-toggle-round"  type="checkbox" value="" :checked="dialogueProperties.preferences_checked" >
                                <label for="pref" v-bind:style="{'--switch-color': current_banner.banner_bg_color}" />
                            </div>
                        </div>
                        <div class="seers_text_box_description">
                            <p v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important'}">{{language.cpra_preference_body ? language.cpra_preference_body : language.preference_body}}</p>
                        </div>
                        <div class="display_cookie" v-if="this.dialogueProperties.drill_down">
                            <span v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important'}"> 3 Cookies </span> | <a class="show-cookie-menu" id="show-cookie-menu_pref" data-category="pref">View</a>
                        </div>
                    </div>
                    <!-- box1 -->

                    <!-- box1 -->
                    <div class="seers_text_box">
                        <div class="seers_text_top_section">
                            <h5 v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important', fontWeight: 'bold'}">{{language.cpra_statistics_title ? language.cpra_statistics_title : language.statistics_title}}</h5>
                            <div class="seers_main_switch">
                                <input id="stat" class="cmn-toggle cmn-toggle-round"  type="checkbox" value="" :checked="dialogueProperties.statistics_checked">
                                <label for="stat" v-bind:style="{'--switch-color': current_banner.banner_bg_color}" />
                            </div>
                        </div>
                        <div class="seers_text_box_description">
                            <p v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important'}">{{language.cpra_statistics_body ? language.cpra_statistics_body : language.statistics_body}}</p>
                        </div>
                        <div class="display_cookie" v-if="this.dialogueProperties.drill_down">
                            <span v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important'}"> 3 Cookies </span> | <a class="show-cookie-menu" id="show-cookie-menu_stat" data-category="stat">View</a>
                        </div>
                    </div>
                    <!-- box1 -->


                    <!-- box1 -->
                    <div class="seers_text_box">
                        <div class="seers_text_top_section">
                            <h5 v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important', fontWeight: 'bold'}">{{language.cpra_marketing_title ? language.cpra_marketing_title : language.marketing_title}}</h5>
                            <div class="seers_main_switch">
                                <input id="market" class="cmn-toggle cmn-toggle-round" type="checkbox" value=""
                                        :checked="dialogueProperties.targeting_checked">
                                <label for="market" v-bind:style="{'--switch-color': current_banner.banner_bg_color}" />
                            </div>
                        </div>
                        <div class="seers_text_box_description">
                            <p v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important'}">{{language.cpra_marketing_body ? language.cpra_marketing_body : language.marketing_body}}</p>
                        </div>
                        <div class="display_cookie" v-if="this.dialogueProperties.drill_down">
                            <span v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important'}"> 3 Cookies </span> | <a class="show-cookie-menu" id="show-cookie-menu_market" data-category="market">View</a>
                        </div>
                    </div>
                    <!-- box1 -->


                    <!-- box1 -->
                    <div class="seers_text_box">
                        <div class="seers_text_top_section">
                            <h5 v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important', fontWeight: 'bold'}" >{{language.cpra_unclassified_title ? language.cpra_unclassified_title : language.unclassified_title}}</h5>
                            <div class="seers_main_switch disabled_switch">
                                <input id="unclass" class="cmn-toggle cmn-toggle-round"  type="checkbox" value="" :checked="true" :disabled="true">
                                <label for="unclass" v-bind:style="{'--switch-color': current_banner.banner_bg_color}" />
                            </div>
                        </div>
                        <div class="seers_text_box_description">
                            <p v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important'}">{{language.cpra_unclassified_body ? language.cpra_unclassified_body : language.unclassified_body}}</p>
                        </div>
                        <div class="display_cookie" v-if="this.dialogueProperties.drill_down">
                            <span v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important'}"> 3 Cookies </span> | <a class="show-cookie-menu" id="show-cookie-menu_unclass" data-category="unclass">View</a>
                        </div>
                    </div>
                    <!-- box1 -->

                </div>
                <!-- cookie content -->

                <!-- cookie management -->
                <div :style="{height: 'calc(100vh - '+(container_height+footer_height)+'px)'}" id="cookie-management" class="seers_cookie_content hide">
                    <div class="seers_cookie_header">
                        <h3 id="category_title" v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important', fontWeight: 'bold'}"></h3>
                        <p id="category_body" v-bind:style="{color :'#333 i!mportant', fontSize :current_banner.font_size + 'px !important'}"></p>
                    </div>
                    <!-- box1 -->
                    <div class="seers_text_box">
                        <div class="seers_text_top_section">
                            <h5 v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important', fontWeight: 'bold'}">
                                Seers_Cookie_1
                            </h5>
                            <div class="seers_main_switch"></div>
                        </div>
                        <div class="seers_text_box_description">
                            <p v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important'}">
                                This cookie is set by Seers to suggest to the client, that how the cookie will appear on their banner.
                            </p>
                        </div>

                        <div class="seers_cookie_details_list">
                            <div class="seers_details_entry">Secure | HTTP Only | 7 days | consents.dev </div>
                        </div>

                        <div class="display_cookie">
                            <!--                            <span> 3 Cookies </span> | <a class="show-cookie-menu" id="show-cookie-menu" data-category="pref">Manage</a>-->
                        </div>
                    </div>
                    <!-- box1 -->
                    <!-- box1 -->
                    <div class="seers_text_box">
                        <div class="seers_text_top_section">
                            <h5 v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important', fontWeight: 'bold'}">
                                Seers_Cookie_2
                            </h5>
                            <div class="seers_main_switch"></div>
                        </div>
                        <div class="seers_text_box_description">
                            <p v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important'}">
                                This cookie is set by Seers to suggest to the client, that how the cookie will appear on their banner.
                            </p>
                        </div>

                        <div class="seers_cookie_details_list">
                            <div class="seers_details_entry">Not Secure | HTTP Only | 1 month | consents.dev </div>
                        </div>
                        <div class="display_cookie">
                            <!--                            <span> 3 Cookies </span> | <a class="show-cookie-menu" id="show-cookie-menu" data-category="pref">Manage</a>-->
                        </div>
                    </div>
                    <!-- box1 -->
                    <!-- box1 -->
                    <div class="seers_text_box">
                        <div class="seers_text_top_section">
                            <h5 v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important', fontWeight: 'bold'}">
                                Seers_Cookie_3
                            </h5>
                            <div class="seers_main_switch"></div>
                        </div>
                        <div class="seers_text_box_description">
                            <p v-bind:style="{color :'#333 !important', fontSize :current_banner.font_size + 'px !important'}">
                                This cookie is set by Seers to suggest to the client, that how the cookie will appear on their banner.
                            </p>
                        </div>
                        <div class="seers_cookie_details_list">
                            <div class="seers_details_entry">Secure | HTTP Only | 3 days | consents.dev </div>
                        </div>
                        <div class="display_cookie">
                            <!--                            <span> 3 Cookies </span> | <a class="show-cookie-menu" id="show-cookie-menu" data-category="pref">Manage</a>-->
                        </div>
                    </div>
                    <!-- box1 -->
                </div>
                <!-- cookie management -->

                <div class="seers_footer" id="seers_cookie_side_one_ccpa_footer" v-bind:style="{background: current_banner.banner_bg_color}">
                    <div class="seers_powered"><span v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">{{this.language.powered_by}} <a :href="mix_cmp_url+'/cookie-consent-popup-generator.html'" v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}" target="_blank">Seers</a></span></div>
                    <div class="seers_footer_btn">
                        <button id="save-my-choices" type="button" v-bind:style="{background :current_banner.agree_btn_color + ' !important',color :current_banner.agree_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}" v-bind:class="current_banner.button_type">{{this.language.btn_save_my_choices}}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Dialogue Preview Start -->
        <!-- <div class="popcenter" v-bind:style="{fontFamily :current_banner.font_style  + ' !important;', background: current_banner.banner_bg_color}"> -->
        <div id="seers-cx-banner" v-bind:style="{fontFamily :current_banner.font_style  + ' !important', background: current_banner.banner_bg_color}" class="cookie_box seers-cx-show" v-bind:class="current_banner.position">
            <div class="cookie_text_box">
                <div class="d-flex align-items-center mb-2">
                <div class="seers_logo" v-if="dialogueProperties.logo_status !== 'none'" v-bind:style="{background :current_banner.logo_bg_color}"><img :src="this.dialogueProperties.logo_link"></div>
                <span class="seers_sub_heading" v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">{{this.language.cpra_title ? this.language.cpra_title : this.language.title}}</span>
                </div>
                <p id="seers-cookie-body-1" class="cookie_text" v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}" v-html="desanitizedBody ? desanitizedBody : cpra_sanitizedBody ? cpra_sanitizedBody : sanitizedBody"></p>
            <div class="d-flex align-items-center justify-content-between mb-3">
                            <h2 class="cookie_title" v-bind:style="{color :current_banner.title_text_color, fontSize :(parseInt(current_banner.font_size)+2).toString() + 'px !important'}"> Targeting / Advertising</h2>
                <div class="seers_main_switch seers_switch mt-0">
                    <input id="do-not-sell-2" class="cmn-toggle cmn-toggle-round" type="checkbox">
                    <label for="do-not-sell-2"></label>
                </div>
            </div>
            </div>
            <div class="button_box">
                <div class="deital_button">
                    <a  v-if="dialogueProperties.cookie_policy_url !== null && dialogueProperties.cookie_policy_url !== ''" :href="dialogueProperties.cookie_policy_url ? dialogueProperties.cookie_policy_url : `${mix_cmp_url}/cookie-policy`" target="_blank" v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">{{language.policy}}</a> <span v-if="dialogueProperties.cookie_policy_url !== null && dialogueProperties.cookie_policy_url !== ''" v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">|</span> <a v-on:click="this.fin" v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}" id="seers-cx-show-details">{{language.cpra_btn_preference_title ? language.cpra_btn_preference_title : language.btn_preference_title}}</a>
                </div>
                <div class="button_postion">
                    <button id="seers-cx-agree" @click="bannerShow()" type="button" class="cookie_btn cookie_btn_open cookie_seers_open_btn seers-cx-show-details" v-bind:class="[this.agreeBtnSpecific, current_banner.button_type]" v-bind:style="{background :current_banner.agree_btn_color + ' !important',color :current_banner.agree_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">
                        {{language.cpra_btn_agree_title ? language.cpra_btn_agree_title : language.btn_agree_title}}
                    </button>
                </div>
            </div>
                    <div class="cookie_text_box mt-3 pt-3 border-top" v-if="form.email_section">
                <p id="seers-cookie-body-1" class="cookie_text mb-3" v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">{{this.language.cpra_email_section_title ? this.language.cpra_email_section_title : this.language.email_section_title}}</p>
                    <div class="d-flex align-items-center">
                        <label class="email_heading mb-3 mr-3">Email:</label>
                        <input id="email_user" type="email" class="form-control mb-3">
                    </div>
                    <div class="d-flex align-items-center justify-content-end"> 
                            <button id="seers-cx-agree" @click="bannerShow()" type="button" class="cookie_btn cookie_btn_open cookie_seers_open_btn seers-cx-show-details" v-bind:class="[this.agreeBtnSpecific, current_banner.button_type]" v-bind:style="{background :current_banner.agree_btn_color + ' !important',color :current_banner.agree_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">
                        Submit
                    </button>
                    </div>
            </div>
            
        </div>
        <!-- </div> -->
        <!-- Dialogue Preview End -->

        <!--Tag setting-->
        <div class="seers_cc_tag_seeting seers_cc_bottom " v-if="dialogueProperties.has_badge" style="background-image: url(/images/design-new/seersco-thumb-scan.svg);
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 15px;">
            
        </div>
        <!--Tag Setting End-->
    </div>

</template>

<script>
import DOMPurify from 'dompurify';
    // import store from '../cookieXray/store';
    export default {
        // store,
        components: {

        },
        props:[],
        data(){
            return {
                readMoreBtnSpecific: '',
                agreeBtnSpecific: '',
                disAgreeBtnSpecific: '',
                arrow_icon: '&#8897;',
                toggle_one: false,
                toggle_two: false,
                anchor_tag_one : '',
                cookie_body_el_one : '',
                anchor_tag_two : '',
                cookie_body_el_two : '',
                container_height: 0,
                footer_height: 0,
            }

        },
        // updated() {
        //     alert('11')
        //     let cookie_body_2 = document.getElementById('cookie_seers_popup_header');
        //     let cookie_banner_footer = document.getElementById('seers_cookie_side_one_ccpa_footer');
        //     this.container_height = cookie_body_2.offsetHeight;
        //     this.footer_height = cookie_banner_footer.offsetHeight;
        // },
        mounted(){
            // alert('222')
            this.setStyleForSpecificConsent();
            let anchorNodeOne = this.createAnchorNode(true);
            this.anchor_tag_one = anchorNodeOne;
            let anchorNodeTwo = this.createAnchorNode(false);
            this.anchor_tag_two = anchorNodeTwo;
            let cookie_body_1 = document.getElementById('seers-cookie-body-1');
            this.cookie_body_el_one = cookie_body_1;
            let cookie_body_2 = document.getElementById('seers-cookie-body-2');
            this.cookie_body_el_two = cookie_body_2;
            if(cookie_body_1.textContent.split(' ').length >= 20){
                cookie_body_1.appendChild(anchorNodeOne);
            }
            if(cookie_body_2.textContent.split(' ').length >= 20){
                cookie_body_2.appendChild(anchorNodeTwo);
            }

            this.initEvents();
        },
        computed: {
            sanitizedBody() {
              return DOMPurify.sanitize(this.language.body);
            },
            cpra_sanitizedBody() {
              return DOMPurify.sanitize(this.language.cpra_body);
            },
            desanitizedBody() {
              return DOMPurify.sanitize(this.language.cpra_second_layout_description);
            },
            // dialogueProperties: function () {
            //     return this.$store.getters.getDialogueProperties;
            // },
            form: function () {
              return this.$store.state.dialogue.form;
            },
            dialogueProperties: function () {
              return this.$store.getters["domain/getDialogueProperties"];
            },
            logo: function () {
                return this.dialogueProperties.logo;
            },
            mix_cmp_url: function () {
                return process.env.VUE_APP_ENV_VARIABLE
             },
            logo_url: function () {
                return `${this.mix_cmp_url}/local/images/logo/${this.logo}`;
            },
            language: function() {
                return this.$store.getters["domain/getCurrentLanguage"];
            },
            current_banner: function(){
                return this.$store.getters["domain/getCurrentBanner"];
            },
            language_body_1: {
                get: function() {
                    let self = this;
                    let lang = this.sanitizedBody;

                    if(!self.toggle_one && self.cookie_body_el_one && lang.split(' ').length > 20){
                        lang = self.truncate(lang.trim(), 20, null);
                        if(!self.cookie_body_el_one.contains(self.anchor_tag_one)){
                            self.cookie_body_el_one.appendChild(self.anchor_tag_one);
                        }
                    }
                    if(lang.split(' ').length < 20 && self.cookie_body_el_one && self.anchor_tag_one && self.cookie_body_el_one.contains(self.anchor_tag_one)){
                        self.cookie_body_el_one.removeChild(self.anchor_tag_one);
                    }

                    return lang;
                },
                set: function(lang) {
                    this.language_body = lang;
                }
            },
            language_body_2: {
                get: function() {
                    let self = this;
                    let lang = this.sanitizedBody;

                    if(!self.toggle_two && self.cookie_body_el_two && lang.split(' ').length > 20){
                        lang = self.truncate(lang.trim(), 20, null);
                        if(!self.cookie_body_el_two.contains(self.anchor_tag_two)){
                            self.cookie_body_el_two.appendChild(self.anchor_tag_two);
                        }
                    }
                    if(lang.split(' ').length < 20 && self.cookie_body_el_two && self.anchor_tag_two && self.cookie_body_el_two.contains(self.anchor_tag_two)){
                        self.cookie_body_el_two.removeChild(self.anchor_tag_two);
                    }

                    return lang;
                },
                set: function(lang) {
                    this.language_body = lang;
                }
            },
            show_dialogue_preview: function () {
                return this.$store.getters["domain/getShowDialoguePreview"];
            },
        },
        methods:{
            toggleArrow(){
                this.arrow_icon =  (this.arrow_icon == "&#8897;") ? '&#8743;' : '&#8897;'
                return;
            },
            showMenu(){
                $('.menu').slideToggle("slow");
                // Get the element with id="defaultOpen" and click on it
                document.getElementById("defaultOpen").click();
                // console.log('this.showMenu called');

            },
            showTab(cityName, $event){
                var i, tabcontent, tablinks;
                tabcontent = document.getElementsByClassName("seers-cx-tab-content");
                for (i = 0; i < tabcontent.length; i++) {
                    tabcontent[i].style.display = "none";
                }
                tablinks = document.getElementsByClassName("seers-cx-tab-links");
                for (i = 0; i < tablinks.length; i++) {
                    tablinks[i].className = tablinks[i].className.replace(" seers-cx-active", "");
                }
                document.getElementById(cityName).style.display = "block";
                $event.target.className += " seers-cx-active";
            },
            setStyleForSpecificConsent(){
                if(this.dialogueProperties.consent_type == 'specific') {
                    this.readMoreBtnSpecific = 'readMoreBtnSpecific';
                    this.agreeBtnSpecific = 'agreeBtnSpecific';
                    this.disAgreeBtnSpecific = 'hide';
                }

                return;
            },
            fin()
            {
                self = this;
                for (var i = 0; i <= 1; i += 0.01) {
                    i = Math.round(i * 100) / 100;
                    setTimeout("self.seto(" + i + ")", i * 1000);
                }
                document.getElementById('seers-cx-side-bar').classList.replace('hide','seers-cx-show');
                setTimeout(() => {
                    let element = document.getElementById('cookie_seers_popup_header');
                    self.container_height = element.offsetHeight;
                }, 500);
            },
            seto(opa)
            {
                self = this;
                document.getElementsByClassName('cookie_seers_popup')[0].style.opacity = opa;
                document.getElementsByClassName('cookie_seers_popup')[0].style.display = 'block';
            },
            truncate(text, limit, after) {

                var self = this;
                // Make sure an element and number of items to truncate is provided
                if (!text || !limit) return;

                // Get the inner content of the element
                var content = text;
                content = content.trim();

                // Convert the content into an array of words
                // Remove any words above the limit
                content = content.split(' ').slice(0, limit);

                // Convert the array of words back into a string
                // If there's content to add after it, add it
                content = content.join(' ');

                return content;

            },
            bannerShow() {
                if (this.show_dialogue_preview == true) {
                this.$store.commit("domain/setShowDialoguePreview", false);
                this.$store.commit("domain/setPreviewBtn", "Preview");
                } else {
                this.$store.commit("domain/setPreviewBtn", "Exit Preview");
                this.$store.commit("domain/setShowDialoguePreview", true);
                }
            },
            initEvents() {
                self = this;
                // var show_details = document.getElementById('seers-cx-show-details');
                // show_details.addEventListener("click", function(){
                //     self.fin();
                // });

                // var agree = document.getElementById('seers-cx-agree');
                // agree.addEventListener("click", function(){
                //     // cx.proceedConsent('seers-cx-agree');
                //     // self.bannerShow();
                //     // document.getElementById('seers-cx-side-bar').classList.replace('seers-cx-show','hide');
                //     document.getElementById('seers-cx-banner').classList.replace('seers-cx-show','hide');
                    
                // });

                var popup_close_btn = document.getElementById('popup-close');
                popup_close_btn.addEventListener("click", function(){
                    document.getElementById('seers-cx-side-bar').classList.replace('seers-cx-show','hide');
                    
                });

                var save_my_choices_btn = document.getElementById('save-my-choices');
                save_my_choices_btn.addEventListener("click", function(){
                    document.getElementById('seers-cx-side-bar').classList.replace('seers-cx-show','hide');
                    
                });

                var back_btn = document.getElementById('seers-cx-back-btn');
                back_btn.addEventListener("click", function(){
                    document.getElementById('seers-cx-header-menu').classList.replace('seers-cx-show','hide');
                    document.getElementById('cookie-management').classList.replace('seers-cx-show','hide');
                    document.getElementById('seers-cx-cookies').classList.replace('hide','seers-cx-show');
                    let element = document.getElementById('cookie_seers_popup_header');
                    self.container_height = element.offsetHeight;
                });

                var show_cookie_menu = document.getElementsByClassName('show-cookie-menu');
                var self = this;
                for (var i = 0; i < show_cookie_menu.length; i++) {
                    show_cookie_menu[i].addEventListener("click", function () {
                        self.category = this.dataset.category;
                        // self.category = self.category + '_title'; //cat_names[self.category]
                        var cat_names = {
                            'necess_title': 'necessory_title',
                            'necess_body': 'necessory_body',
                            'pref_title': 'preference_title',
                            'pref_body': 'preference_body',
                            'stat_title': 'statistics_title',
                            'stat_body': 'statistics_body',
                            'market_title': 'marketing_title',
                            'market_body': 'marketing_body',
                            'unclass_title': 'unclassified_title',
                            'unclass_body': 'unclassified_body',
                        }
                        var category_title = document.getElementById('category_title');
                        var category_body = document.getElementById('category_body');
                        category_title.innerText = self.language[cat_names[self.category+'_title']];
                        category_body.innerText = self.language[cat_names[self.category+'_body']];
                        document.getElementById('seers-cx-header-menu').classList.replace('hide', 'seers-cx-show');
                        document.getElementById('cookie-management').classList.replace('hide', 'seers-cx-show');
                        document.getElementById('seers-cx-cookies').classList.replace('seers-cx-show', 'hide');
                        let element = document.getElementById('cookie_seers_popup_header');
                        self.container_height = element.offsetHeight;
                    });
                }

                const do_not_sell_1 = document.querySelector('#do-not-sell-1');
                const do_not_sell_2 = document.querySelector('#do-not-sell-2');
                var pref = document.getElementById('pref');
                var stat = document.getElementById('stat');
                var market = document.getElementById('market');
                do_not_sell_1.addEventListener('change', function () {
                    if (this.checked) {
                        if (pref.checked) {
                            pref.checked = !pref.checked;
                        }
                        if (stat.checked) {
                            stat.checked = !stat.checked;
                        }
                        if (market.checked) {
                            market.checked = !market.checked;
                        }
                        if (!do_not_sell_2.checked) {
                            do_not_sell_2.checked = !do_not_sell_2.checked;
                        }
                    } else {
                        if (!pref.checked) {
                            pref.checked = !pref.checked;
                        }
                        if (!stat.checked) {
                            stat.checked = !stat.checked;
                        }
                        if (!market.checked) {
                            market.checked = !market.checked;
                        }
                        if (do_not_sell_2.checked) {
                            do_not_sell_2.checked = !do_not_sell_2.checked;
                        }
                    }
                });

                do_not_sell_2.addEventListener('change', function () {
                    if (this.checked) {
                        if (pref.checked) {
                            pref.checked = !pref.checked;
                        }
                        if (stat.checked) {
                            stat.checked = !stat.checked;
                        }
                        if (market.checked) {
                            market.checked = !market.checked;
                        }
                        if (!do_not_sell_1.checked) {
                            do_not_sell_1.checked = !do_not_sell_1.checked;
                        }
                    } else {
                        if (!pref.checked) {
                            pref.checked = !pref.checked;
                        }
                        if (!stat.checked) {
                            stat.checked = !stat.checked;
                        }
                        if (!market.checked) {
                            market.checked = !market.checked;
                        }
                        if (do_not_sell_1.checked) {
                            do_not_sell_1.checked = !do_not_sell_1.checked;
                        }
                    }
                });

                pref.addEventListener('change', function () {
                    if (this.checked) {
                        if (do_not_sell_1.checked) {
                            do_not_sell_1.checked = !do_not_sell_1.checked;
                        }
                        if (do_not_sell_2.checked) {
                            do_not_sell_2.checked = !do_not_sell_2.checked;
                        }
                    } else {
                        if(!stat.checked && !market.checked){
                            if (!do_not_sell_1.checked) {
                                do_not_sell_1.checked = !do_not_sell_1.checked;
                            }
                            if (!do_not_sell_2.checked) {
                                do_not_sell_2.checked = !do_not_sell_2.checked;
                            }
                        }

                    }
                });

                stat.addEventListener('change', function () {
                    if (this.checked) {
                        if (do_not_sell_1.checked) {
                            do_not_sell_1.checked = !do_not_sell_1.checked;
                        }
                        if (do_not_sell_2.checked) {
                            do_not_sell_2.checked = !do_not_sell_2.checked;
                        }
                    } else {
                        if(!pref.checked && !market.checked){
                            if (!do_not_sell_1.checked) {
                                do_not_sell_1.checked = !do_not_sell_1.checked;
                            }
                            if (!do_not_sell_2.checked) {
                                do_not_sell_2.checked = !do_not_sell_2.checked;
                            }
                        }

                    }
                });

                market.addEventListener('change', function () {
                    if (this.checked) {
                        if (do_not_sell_1.checked) {
                            do_not_sell_1.checked = !do_not_sell_1.checked;
                        }
                        if (do_not_sell_2.checked) {
                            do_not_sell_2.checked = !do_not_sell_2.checked;
                        }
                    } else {
                        if(!pref.checked && !stat.checked){
                            if (!do_not_sell_1.checked) {
                                do_not_sell_1.checked = !do_not_sell_1.checked;
                            }
                            if (!do_not_sell_2.checked) {
                                do_not_sell_2.checked = !do_not_sell_2.checked;
                            }
                        }

                    }
                });

                if(self.cookie_body_el_one && self.cookie_body_el_one.contains(self.anchor_tag_one)){
                    self.anchor_tag_one.addEventListener("click", function () {
                        if (!self.toggle_one) {
                            self.sanitizedBody = self.sanitizedBody;
                            self.anchor_tag_one.textContent = self.language.link_less;
                            self.anchor_tag_one.style = 'font-size: inherit; color: #269abc !important; padding-left: 2px; text-decoration: none; font-weight: inherit;';
                            self.toggle_one = true;
                        } else {
                            self.toggle_one = false;
                            self.anchor_tag_one.textContent = self.language.link_more;
                            self.anchor_tag_one.style = 'font-size: inherit; color: #269abc !important; padding-left: 0px; text-decoration: none; font-weight: inherit;';
                        }
                    });
                }

                if(self.cookie_body_el_two && self.cookie_body_el_two.contains(self.anchor_tag_two)){
                    self.anchor_tag_two.addEventListener("click", function () {
                        if (!self.toggle_two) {
                            self.sanitizedBody = self.sanitizedBody;
                            self.anchor_tag_two.textContent = self.language.link_less;
                            self.anchor_tag_two.style = 'font-size: inherit; color: #269abc !important; padding-left: 2px; text-decoration: none; font-weight: inherit;';
                            self.toggle_two = true;
                        } else {
                            self.toggle_two = false;
                            self.anchor_tag_two.textContent = self.language.link_more;
                            self.anchor_tag_two.style = 'font-size: inherit; color: #269abc !important; padding-left: 0px; text-decoration: none; font-weight: inherit;';
                        }
                    });
                }

            },
            previewToggle(status){
                this.$store.commit('setShowDialoguePreview', status);
            },
            createAnchorNode(value) {
                let self = this;
                let anchor = document.createElement('a');
                anchor.id = value ? 'seers_read_more_id_one' : 'seers_read_more_id_two';
                anchor.href = 'javascript:void(0)';
                anchor.textContent = self.language.link_more;
                anchor.setAttribute("style", "font-size: inherit;\n" +
                    "    color: #269abc !important;\n" +
                    "    text-decoration: none;\n" +
                    "    padding-left: 0px;\n" +
                    "    font-weight: inherit;");

                return anchor;
            }
        }
    }


</script>

<style scoped>

    * {box-sizing: border-box}

    /*body {*/
    /*    margin: 0;*/
    /*    font-family: 'Open Sans', sans-serif;*/
    /*    background-color: #fefefe;*/
    /*}*/


    /**********************/
    /*setting tag */
    /**********************/

    .seers_cc_tag_seeting{
        position: fixed;
        overflow: hidden;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font-family: Helvetica,Calibri,Arial,sans-serif;
        font-size: 16px;
        line-height: 1.5em;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        z-index: 9999;
        cursor: pointer;
        padding: .5em .8em;
        color: rgb(255, 255, 255);
    }

 .seers_cc_tag_seeting.seers_cc_bottom{
    bottom: 16px;
    left: 16px;
    border-top-left-radius: .5em;
    border-top-right-radius: .5em;
}

    .seers_cc_animate.seers_cc_tag_seeting.seers_cc_bottom{
        -webkit-transform: translateY(2em);
        transform: translateY(2em);
        -webkit-transition: transform 1s ease;
        -webkit-transition: -webkit-transform 1s ease;
        transition: -webkit-transform 1s ease;
        transition: transform 1s ease;
        transition: transform 1s ease,-webkit-transform 1s ease;
    }

    .seers_cc_animate:hover{
        -webkit-transform: translateY(0em) !important;
        transform: translateY(0em) !important;
    }

    /**********************/
    /*setting tag end */
    /**********************/


    .hide{
        display: none !important;
    }

    .cookie_seers_popup{
        display:none;
        position:fixed;
        top:0;
        width: 450px;
        z-index:10000;
        left: -5px;
        right: auto;
        box-sizing: border-box;
    }

    .cookie_seers_popup_right{
        display:none;
        position:fixed;
        top:0;
        width: 400px;
        z-index:10000;
        right: -30px;
        left: auto;
    }

    .cookie_seers_popup .helper{
        display:inline-block;
        height:100%;
        vertical-align:middle;
    }
    .cookie_seers_popup > .cookie_seers_popup_content, .cookie_seers_popup_right > .cookie_seers_popup_content {
        background-color: #fff;
        box-shadow: 9px 0px 11px #7b7b7b;
        display: inline-block;
        height: auto;
        vertical-align: middle;
        max-width: 450px;
        position: relative;
        border-radius: 0px;
        z-index: 99999;
        height: 100vh;

        /*   padding: 15px 5%; */
    }
    .popupCloseButton {
        background-color: #fff0;
        border-radius: 50px;
        cursor: pointer;
        display: inline-block;
        font-family: arial;
        font-weight: bold;
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 15px;
        line-height: 22px;
        width: 20px;
        height: 20px;
        text-align: center;
    }
    .popupCloseButton:hover {
        color: blue;
    }
    .cookie_seers_open_btn {
        cursor: pointer;
    }


    /* Popup box BEGIN */

    /*bar for back*/
    .seers_language{
        display: inline-block;
        width: 100%;
        padding: 5px 20px;
        box-shadow: 0px 6px 9px #cacaca;
        position: relative;
        right: 0px;
        left: 0px;
        background: white;
    }

    .viewconsent{
        float: left;
    }

    .viewconsent a{
        text-decoration: none;
        color: #fff;
        font-size: 13px;
        padding: 5px 10px 5px 10px;
        border-radius: 40px;
        border: 1px solid #fff;
    }

    .viewconsent a:hover{
        color: #fff;
        background: linear-gradient(to left, #3bd08c, #3bd08c) !important;
    }


    .language_right{
        float: right;
    }
    .seers_circle_language{
        width: 24px;
        height: 24px;
        line-height: 24px;
        float: left;
        cursor: pointer;
        border-radius: 50px;
        font-size: 10px;
        text-align: center;
        margin-left: 5px;
    }

    .sees_en{
        color: #383737;
        background-color: white;
    }

    .sees_count{
        background-color: #525055;
        color: #fff;
    }

    .seers_cookie_content{
        overflow-x: hidden;
        overflow-y: auto;
        padding: 20px;
        /*min-height: calc(100vh - 200px)*/
    }

    /*bar end for back*/

    /* new banner css */

    .cookie_box{
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,e5e5e5+100;White+3D */
         box-shadow: 2px 1px 10px 0px rgba(119, 119, 119, 0.75);
        color: #fff;
        border-radius: 8px;
        position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
        max-width: 100%;
        width:350px;
        padding:15px !important;
        z-index: 9999;
    }

    /*left*/
    .seers_cc_bottom{
        bottom: 3% !important;
        top: auto;
        right: auto !important;
        left: 1% !important;
        transform: translate(-0%, -0%) !important;
    }
    /*left end*/
        /*top left mz*/
    .seers_cc_top_left{
    left: 1% !important;
    right: auto !important;
    top: 3% !important;
        bottom: auto;
            transform: translate(-0%, -0%) !important;
    }
    /*top left end*/
    /*right*/
    .seers_cc_bottom_right{
        bottom: 3% !important;
        top: auto;
        left: auto !important;
        right: 1% !important;
            transform: translate(-0%, -0%) !important;
    }
    /*right end*/
    /*top right mz*/
    .seers_cc_top_right{
    right: 1% !important;
    top: 3% !important;
    left: auto !important;
        bottom: auto;
            transform: translate(-0%, -0%) !important;
    }
    /* top right end*/

    /*center vertical*/
    .seers_cc_center{
        top: auto;
        margin: 0 auto;
        z-index: 99999;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -45%);
        box-shadow: 2px 1px 28px 0px rgba(60, 58, 58, 0.85);
    }
    /*center vertical end*/

    /* ------------------------------------------------------------------------------------------- */

    /* content area */

    h2.cookie_title{
        font-size: 16px;
        margin: 0px 0px 0px 0px !important;
        color: #000;
        padding: 0 !important;
        float: unset !important;
        position: unset !important;
        display: block !important;
        letter-spacing: normal !important;
        font-weight: 600 !important;
        line-height: 20px !important;

    }

    span.seers_sub_heading{
        color: #383737;
        font-size: 12px;
        line-height: 20px !important;
        margin: 0!important;
        padding: 0!important;
        vertical-align: middle !important;
        float: left !important;
        letter-spacing: normal !important;
        font-weight: 600 !important;
        position: unset !important;
        display: unset !important;
    }

    .seers_logo{
        float: left;
        display: flex;
        flex-direction: column;
        margin-right: 15px !important;
    }

    .seers_logo img{
        width: 50px !important;
        vertical-align: middle !important;
        display: block !important;
        height: auto !important;
        margin: 0 !important;
        padding: 0 !important;
        position: unset !important;
        border: none !important;
    }

    .cookie_text_box{
        float: left;
        width: 100%;
        margin-bottom: 0px !important;
    }
    p.cookie_text{
        color: #383737;
        font-size: 13px;
        line-height: 20px !important;
        margin: 0 0 15px 0 !important;
        display: block  !important;
        padding: 0 !important;
        clear: both !important;
        float: unset    !important;
        position: unset !important;
        letter-spacing: normal  !important;
        font-weight: normal !important;
    }

    .seers_switch{
        float: left !important;
    }

    .seers_switch > input.cmn-toggle-round + label::before{
        background-color: #adadad;
    }


    /* end content area */
    /* ------------------------------------------------------------------------------------------- */



    /* --------------------------------------------------------------------------------------------- */
    /* button area */

    .deital_button{
        float: left;
        color: #41a2db;
        font-size: 13px;
        margin-top: 10px;
    }

    .deital_button a{
        color: #41a2db;
        text-decoration: none !important;
        font-size: 13px;
        float: none !important;
        position: unset !important;
        display: unset !important;
        cursor: pointer !important;
        outline: 0 !important;
        background: none !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .deital_button a:hover{
        color: #333;
        text-decoration: underline !important;
        background: none !important;
    }

    .button_box{
        width: 100%;
        float: left;
        margin-top: 0px !important;
    }
    .button_postion{
        float: right;
    }
    button.cookie_btn:focus{
        outline: -webkit-focus-ring-color auto 0px !important;
    }
    .cookie_btn{
        padding: 8px 15px !important;
        border-radius: 20px;
        font-size: 13px;
        background: #fff;
        border:none !important;
        cursor: pointer !important;
        min-width: 100px !important;
        letter-spacing: .5px !important;
        margin: 0 !important;
        line-height: 20px !important;
        text-transform: none !important;
        text-indent: 0px !important;
        text-shadow: none !important;
        display: inline-block !important;
        text-align: center !important;
        align-items: flex-start !important;
        word-spacing: normal !important;
        -webkit-writing-mode: horizontal-tb !important;
        text-rendering: auto !important;
        position: unset !important;

    }
    .cookie_btn_open{
        background: #3bd08c;
        transition: all 0.3s ease 0s;
        color: #fff;
    }

    .cookie_btn_open:hover{
        background: #41a2db;
        color: #fff;
        transform: translateY(-2px);
    }

    /* button area end -------------------------------------------------------------*/



    /* popup text */


    .cookie_seers_popup_header{
        padding: 20px 0px 0px 0px;
        display: block;
        border-bottom: 1px solid #f7f7f7;
        margin-bottom:5px;
    }

    .cookie_client_img{
        display: block;
        float: left;
        margin-left: 20px;
    }
    .cookie_client_img img{
        width: 40px !important;
        vertical-align: middle !important;
        position: unset !important;
        display: unset !important;
        height: auto !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    .cookie_client_heading{
        float: left;
        margin-left: 20px;
    }
    .cookie_client_heading h2{
        font-size: 15px;
        font-weight: 600 !important;
        line-height: inherit !important;
        margin: 8px 0px 0px 0px !important;
        padding: 0 !important;

    }

    .popup_discription{
        display: inline-block;
        position: relative;
        padding: 0px 20px 0px 20px;
    }

    .popup_discription p{
        font-size: 13px;
        color: #383737;
        margin: 10px 0px 10px 0px !important;
        padding: 0 !important;
        float: unset !important;
        display: block !important;
        position: unset !important;
        font-weight: normal !important;
    }

    .seers_cookie_content{
        overflow-x: hidden;
        overflow-y: auto;
        padding: 20px;
        /*min-height: calc(100vh - 230px)*/
    }

    .seers_header_toggle{
        display: flex;
        clear: both;
        justify-content:left;
        padding: 0px 0px 20px 20px;
    }

    .seers_header_switch {
        float: left !important;
    }

    .seers_header_switch > input.cmn-toggle-round + label::before{
        background-color: #5a5353;
    }

    .header_toggle_label{
        float: left !important;
        font-size: 13px;
        font-weight: 600 !important;
        vertical-align: middle !important;
        margin: 10px 0px 0px 10px !important;
        padding: 0px !important;
        line-height: 18px !important;
        color: #4b4b4d !important;
        position: unset !important;
        display: unset !important;
    }

    /*    scrol bar design*/
    /* width */
    .seers_cookie_content::-webkit-scrollbar {
        width: 15px;
    }

    /* Track */
    .seers_cookie_content::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #c1c1c1;
        border-radius: 30px;
    }

    /* Handle */
    .seers_cookie_content::-webkit-scrollbar-thumb {
        background: linear-gradient(to left, #c0c0c0, #c0c0c0) !important;
        border-radius: 30px;
    }

    /* Handle on hover */
    .seers_cookie_content::-webkit-scrollbar-thumb:hover {
        background: #b30000;
    }
    /*    scrol bar design end */


    .seers_cookie_header{
        margin-bottom: 10px;
        border-bottom: 1px solid #c0c0c0;
        padding-bottom: 20px;
    }
    .seers_cookie_header h3{
        font-size: 16px;
        color: #383737;
        padding: 0px !important;
        margin: 10px 0px 10px 0px !important;
        line-height: 20px !important;
        font-weight: 600 !important;
        box-sizing: border-box !important;
        display: block !important;
        position: unset !important;
    }

    .seers_cookie_header p{
        font-size: 13px;
        color: #383737;
        margin: 5px 0px 5px 0px !important;
        padding: 0 !important;
        float: unset !important;
        display: block !important;
        position: unset !important;
        font-weight: normal !important;
    }

    /* box content */
    .seers_text_box{
        border-bottom: 1px solid #c0c0c0;
        display: inline-block;
        width: 100%;
        padding: 0px 0px 15px 0px;
    }
    .seers_text_top_section{
        display: flex;
        justify-content: space-between;
    }
    .seers_text_box h5{
        float: left !important;
        padding: 0px !important;
        margin: 10px 0px 10px 0px !important;
        line-height: 20px !important;
        font-weight: 600 !important;
        box-sizing: border-box !important;
        display: block !important;
        position: unset !important;
    }
    .seers_text_box_description{
        display: block;
        margin-top: 0px;
    }

    .seers_text_box_description p{
        font-size: 13px;
        margin: 5px 0px 10px 0px !important;
        padding: 0 !important;
        float: unset !important;
        display: block !important;
        position: unset !important;
        font-weight: normal !important;
    }

    .display_cookie{
        display: inline-block;
        float: left;
        font-size: 10px;
        color:#383737;
    }

    .display_cookie span{
        margin: 0px !important;
        padding: 0px !important;
        display: unset!important;
        position: unset !important;
        letter-spacing: normal !important;
        float: unset !important;
        font-weight: normal !important;
    }

    .display_cookie a{
        color: #3b6ef8;
        text-decoration: none;
        cursor: pointer;
        outline: 0;
        background: none;
        float: none;
        position: unset;
        display: unset;
        letter-spacing: normal;
        margin: 0;
        padding: 0;
    }

    .display_cookie a:hover{
        color:#383737;
        text-decoration: underline !important;
    }

    .seers_footer{
        background: #f2f2f2;
        padding: 10px 20px;
        position: absolute;
        bottom: 0;
        top: auto;
        width: 100%;
    }
    .seers_powered{
        float: left;
        margin-top: 10px;
    }
    .seers_powered span{
        font-size: 12px;
        vertical-align: middle !important;
        padding: 0 !important;
        float: unset !important;
        display: block !important;
        position: unset !important;
        font-weight: normal !important;
        letter-spacing: normal !important;
    }


    .seers_powered span img{
        width: 20px !important;
        height: auto !important;
        vertical-align: middle !important;
        position: unset !important;
        display: unset !important;
        height: auto !important;
        margin: 0 0 0 3px !important;
        padding: 0 !important;
        float: unset !important;
    }

    .seers_footer button{
        border: none !important;
        background-color: #41a2db;
        min-width: 100px !important;
        float: right !important;
        vertical-align: middle !important;
        padding: 10px !important;
        margin: 0  !important;
        color: #fff;
        font-size: 12px;
        border-radius: 4px;
        cursor: pointer !important;
        text-indent: 0px !important;
        text-shadow: none !important;
        display: inline-block !important;
        text-align: center !important;
        align-items: flex-start !important;
        text-transform: none !important;
        letter-spacing: normal !important;
        word-spacing: normal !important;
        line-height: 15px !important;
        -webkit-writing-mode: horizontal-tb !important;
        text-rendering: auto !important;
    }




    /* box content end*/


    /* popup text end */


    /* ----------------------------------------------------- */

    /*switch button*/

    input.cmn-toggle-round + label {
        padding: 2px !important;
        width: 40px !important;
        height: 23px !important;
        background-color: #dddddd !important;
        -webkit-border-radius: 30px !important;
        -moz-border-radius: 30px !important;
        -ms-border-radius: 30px !important;
        -o-border-radius: 30px !important;
        border-radius: 30px !important;
    }
    input.cmn-toggle-round + label::before, input.cmn-toggle-round + label::after {
        display: block !important;
        position: absolute !important;
        top: 1px !important;
        left: 1px !important;
        bottom: 1px !important;
        content: "" !important;
    }
    input.cmn-toggle-round + label::before {
        right: 1px !important;
        background-color: #b1b1b1 !important;
        -webkit-border-radius: 30px !important;
        -moz-border-radius: 30px !important;
        -ms-border-radius: 30px !important;
        -o-border-radius: 30px !important;
        border-radius: 30px !important;
        -webkit-transition: background 0.4s !important;
        -moz-transition: background 0.4s !important;
        -o-transition: background 0.4s !important;
        transition: background 0.4s !important;
    }
    input.cmn-toggle-round + label::after {
        width: 20px !important;
        background-color: #fff  !important;
        -webkit-border-radius: 100% !important;
        -moz-border-radius: 100%    !important;
        -ms-border-radius: 100% !important;
        -o-border-radius: 100%  !important;
        border-radius: 100% !important;
        -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3)    !important;
        -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3)   !important;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3)    !important;
        -webkit-transition: margin 0.4s !important;
        -moz-transition: margin 0.4s    !important;
        -o-transition: margin 0.4s  !important;
        transition: margin 0.4s !important;
    }
    input.cmn-toggle-round:checked + label::before {
        background-color: var(--switch-color) !important;
    }
    input.cmn-toggle-round:checked + label::after {
        margin-left: 18px !important;
    }

    .cmn-toggle {
        position: absolute !important;
        margin-left: -9999px !important;
        visibility: hidden !important;
    }
    .cmn-toggle + label {
        display: block !important;
        position: relative !important;
        cursor: pointer !important;
        outline: none !important;
        -webkit-user-select: none !important;
        -moz-user-select: none !important;
        -ms-user-select: none !important;
        user-select: none !important;
    }

    .disabled_switch input.cmn-toggle-round:checked + label::before{
        background-color: var(--switch-color) !important;
    }

    /* switch button end*/
    /* popup end */
    /* ----------------------------------------------------- */

    button.cbtn_default{
        background: #98acb7 !important;
        border-radius: 4px !important;
    }
    button.cbtn_rounded{
        background: #98acb7 !important;
        color: #fff !important;
        border-radius: 30px !important;
    }

    button.cbtn_flat{
        background: #98acb7 !important;
        border-radius:0px !important;
    }

    button.cbtn_stroke{
        border: 1px solid #b3bfb8 !important;
        border-radius: 4px !important;
        color: #333 !important;
        background: transparent !important;
    }

    button.cbtn_stroke:hover{
        color: #333 !important;
    }


    /*mobile responsive  */

    @media only screen and (max-width: 550px) {
 .cookie_box{
            width: 96% !important;
            max-width: 100% !important;
        left: 2% !important;
    right: 2% !important;
    bottom: 2% !important;
            top: auto;
            z-index: 999999999;
            transform: translate(0%, 0%) !important;
        }

        .cookie_seers_popup_header{
            background: #7cbee31a;
            box-shadow: none;
            border: none;
        }

        .cookie_seers_popup{
            width: 100%;
            left: 0;
            z-index: 99999999999;
        }

        .seers_cookie_content {
            /*min-height: calc(100vh - 280px);*/
        }


        .popup_discritpion p{
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .seers_sub_heading{
            font-size: 10px;
        }

    }

    /*mobile responsive  */


    /*=================*/
    /*extra css*/
    /*==================*/
    .seers_cookie_details_list{
        display: inline-block;
        clear: both;
    }
    .seers_cookie_details_list strong{
        font-size:12px;
        color:#8a8787;
        font-weight: 700;
        letter-spacing: 0;
        margin:0;
        padding: 0;
    }
    .seers_details_entry{
        font-size:12px;
        color:#8a8787;
    }
    /*=================*/
    /*extra end*/
    /*==================*/
</style>