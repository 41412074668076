<template>
	<!-- <div v-show="this.inLoader" class="report-hol" style="margin-top: 50px;">
        <div  class="before scanning-block text-center py-5">
            <PulseLoader  style="display:inline-block" />
        </div>
      </div> -->
	<div class="row">
		<div class="col-12">
			<h3 class="text-dark mb-4">Manually block vendors</h3>
			<p class="d-block mb-4 pb-4 border-bottom text-gray-dark">
				Seers bot detects vendor and their cookies and technologies on a
				website that you may manually add or remove. Select the vendors
				you want to block until website visitors consent.
			</p>
			<button title="Add new script" class="
						float-right
						btn btn-blue-bg
						d-flex
						align-items-center
						px-3
						mb-4
					" style="margin-top: -5px" :class="shouldBeDisabled ? 'disabled' : ''" @click="addModal">
				<i class="fa fa-plus-circle mr-2"></i> Add New
			</button>
			<div v-if="show_add_manually_script">
				<div class="overlay"></div>
				<addManuallyScript />
			</div>
		</div>
		<div class="col-md-12">
			<div  class="report-hol" style="margin-top: 50px;">
				<div class="before scanning-block text-center py-5"  style="margin-top: 10px;" v-show="this.trackingLoader" >
					<PulseLoader style="display:inline-block" />
				</div>
			</div>
			<div class="table-responsive" v-show="!this.trackingLoader">
				<table style="border-collapse: separate; border-spacing: 0 10px;" id="datatable-responsive" cellpadding="0" cellspacing="0" width="100%" class="table cookie-m-table cookie_consent_log_table p-2" v-if="scripts_loaded">
					<thead>
						<tr>
							<th title="Serial Number" class="border-top-0 border-bottom-0">
								No.
							</th>
							<th title="Name of Script" class="border-top-0 border-bottom-0">
								Vendor
							</th>
							<th title="Block the cookies until your visitors accept." class="border-top-0 border-bottom-0">
								Block vendor
							</th>
							<th title="Type of Cookie" class="border-top-0 border-bottom-0">
								Category
							</th>
							<th title="Options for script" class="border-top-0 border-bottom-0">
								Actions
							</th>
						</tr>
					</thead>
					<tbody class="notranslate">
						<tr class="bg-tr" v-for="(script, index) in this.scripts.data" :key="index">
							<td>{{ start + index }}</td>
							<td class="textlimit">
								{{ script.title }}
							</td>
							<td>
								<label class="toggle">
									<input :checked="script.enabled === 1" :disabled="shouldBeDisabled"
									@click="toggleScript($event, script.id)"
										type="checkbox" class="toggle-checkbox" />
									<div class="toggle-switch"></div>
									<span class="toggle-label"></span>
								</label>
							</td>
							<!-- <td>
								<select class="
											form-control
											double-arrow
											marketing-bg
										" :class="script.script_category_id == 1 ? 'necessary-bg' : script.script_category_id == 2 ? 'unclassified-bg' : script.script_category_id == 3 ? 'statistics-bg' : script.script_category_id == 4 ? 'marketing-bg' : script.script_category_id == 5 ? 'preference-bg' : ''"
									v-on:change="changeCategory($event, script)" style="width:132px !important;">

									<option v-for="(category, index) in script_categories" :key="index" :value="category.id"
										:selected="category.id == script.script_category_id">
										{{ category.name }}
									</option>
								</select>
							</td> -->
							<td>
    <select class="
                form-control
                double-arrow
                marketing-bg
            " :class="script.script_category_id == 1 ? 'necessary-bg' : script.script_category_id == 2 ? 'unclassified-bg' : script.script_category_id == 3 ? 'statistics-bg' : script.script_category_id == 4 ? 'marketing-bg' : script.script_category_id == 5 ? 'preference-bg' : ''"
        v-on:change="changeCategory($event, script)" style="width:132px !important;">

        <option v-for="(category, index) in [...script_categories, ...script_custom_categories]" :disabled="shouldBeDisabled"
		:key="index" :value="category.id"
            :selected="category.id == script.script_category_id">
            {{ category.customize_title ? category.customize_title : category.name }}
        </option>
    </select>
</td>


							<td class="script-icon">
								<i class="fa fa-edit text-blue mr-2" style="font-size: 22px; cursor: pointer;"
									title="Edit Script" :class="shouldBeDisabled ? 'disabled' : ''" @click="editModal(script.id)" />
								<i class="fa fa-trash text-blue" style="font-size: 22px; cursor: pointer;"
									title="Delete Script" :class="shouldBeDisabled ? 'disabled' : ''" @click="removeScript(script.id)" />
							</td>
							<div v-if="show_edit_manually_script">
								<div class="edit_overlay"></div>
								<editManuallyScript v-if="current_script_id === script.id" :script="script"
									@onHideScript="hideEditScript" />
							</div>
						</tr>

					</tbody>
				</table>
				<div class="col-12 d-flex justify-content-center align-items-center" v-if="scripts_loaded">
					<ul class="pagination">
						<li class="page-item pagination-prev-nav" :class="new_script.current_page === 1 ? 'disabled' : ''"
							@click="new_script.current_page === 1 ? '' : filterScripts(new_script.current_page - 1)">
							<a href="#" aria-label="Previous" tabindex="-1" class="page-link"><span><img
										src="@/assets/images/cl-paginatin-left.svg" /></span></a>
						</li>
						<li v-for="(item) in total_script_on_page" :key="item" class="page-item pagination-page-nav"
							:class="this.new_script.current_page === item ? 'active' : ''" @click="filterScripts(item)">
							<a href="#" class="page-link">
								{{ item }}
								<span v-if="this.new_script.current_page === item" class="sr-only">(current)</span></a>
						</li>
						<li class="page-item pagination-next-nav"
							:class="new_script.current_page === total_script_on_page ? 'disabled' : ''"
							@click="new_script.current_page === total_script_on_page ? '' : filterScripts(new_script.current_page + 1)">
							<a href="#" aria-label="Next" class="page-link"><span class="red"><img
										src="@/assets/images/cl-paginatin-right.svg" /></span></a>
						</li>

					</ul>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import addManuallyScript from "@/components/CustoMiZationComponent/TrackingManagerParts/modal/addManuallyScript.vue"
import editManuallyScript from "@/components/CustoMiZationComponent/TrackingManagerParts/modal/editManuallyScript.vue"
import { emitter } from "@/eventbus";
import Swal from 'sweetalert2';
import { mapState } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
	name: "ManuallyBlock",
	components: {
		addManuallyScript,
		PulseLoader,
		editManuallyScript
	},
	data() {
		return {
			current_script_id: '',
			new_script: {
				total: 0,
				current_page: 1,
				per_page: 2,

			},
			total_script_on_page: 1,

			// show_add_manually_script:false
		}
	},
	watch: {
		domain_id: {
			// deep: true,
			handler() {
				this.filterScripts()
			}
		},
		scripts: {
			handler() {
				this.new_script = this.scripts;
				this.total_script_on_page = Math.ceil(this.scripts.total / this.scripts.per_page)
			}
		}
	},
	computed: {
		...mapState('domain', ['trackingLoader' , 'user' , 'assign_role']
		),
		show_add_manually_script: function () {
			return this.$store.state.domain.show_add_manually_script;
		},
		show_edit_manually_script: function () {
			return this.$store.state.domain.show_edit_manually_script;
		},
		domain_id: function () {
			return this.$store.getters['domain/getCurrentDomainId'];
		},
		scripts: function () {
			return this.$store.getters['domain/getManuallyBlockScripts'];
		},
		start: function () {
			return this.$store.getters['domain/getStart'];
		},
		script_categories: function () {
			return this.$store.getters['domain/getScriptCategories'];
		},
		scripts_loaded: function () {
			return (this.scripts.hasOwnProperty('data') ? this.scripts.data.length > 0 : false);
		},
		script_custom_categories: function () {
			return this.$store.getters['domain/getScriptCustomizeCategories'];
		},
		shouldBeDisabled() {
		return !this.user.isSuperAdmin && this.user.superAdminId !== null && this.assign_role === 'reader';
		},

	},
	mounted() {
		this.filterScripts()
	},
	methods: {
		filterScripts: function (page = 1) {
			this.$store.commit('domain/trackingLoader', true)
			let payload = {
				domain_id: this.domain_id,
				page: page
			};
			this.$store.dispatch('domain/filterScripts', payload)
				.then(() => {
					this.$store.commit('domain/trackingLoader', false)
					this.mounted = true
				})
		},
		addModal() {
			this.$store.commit('domain/showAddManuallyScriptModal', true)
		},
		editModal(id) {
			this.current_script_id = id;
			if (this.current_script_id == id) {
				this.$store.commit('domain/showEditManuallyScriptModal', true)
			}
		},
		hideEditScript: function () {
			this.$store.commit('domain/showEditManuallyScriptModal', false)
		},
		toggleScript(event, id) {
			let enabled = event.target.checked;
			let text = 'Are you sure you want to' + ' ' + (enabled ? 'enabled' : 'disable') + '' + ' this script?';
			Swal.fire({
				icon: 'warning',
				title: 'Are you sure?',
				text: text,
				showCancelButton: true,
				confirmButtonColor: '#3B6EF8',
				cancelButtonColor: '#6cc04a',
			})
				.then(async result => {
					if (result.value) {
						let payload = {
							id: id, enabled: enabled, domain_id: this.domain_id
						};
						this.$store.dispatch('domain/toggleScript', payload)
						emitter.emit('saveDialogueProperties');
					} else {
						event.target.checked = !enabled;
					}
				});
		},
		changeCategory: function (event, script) {
			let category_id = event.target.value;
			Swal.fire({
				icon: 'warning',
				title: 'Are you sure?',
				text: 'Are you sure you want to change the script category?',
				showCancelButton: true,
				confirmButtonColor: '#3B6EF8',
				cancelButtonColor: '#6cc04a',
			})
				.then(async result => {
					if (result.value) {
						let payload = {
							id: script.id, category_id: category_id, src: script.src, domain_id: this.domain_id
						};
						this.$store.dispatch('domain/changeCategory', payload)
						
					} else {
						event.target.value = script.script_category_id;
					}
				});
		},
		removeScript(id) {
			Swal.fire({
				icon: 'warning',
				title: 'Are you sure?',
				text: 'Are you sure you want to delete this record?',
				showCancelButton: true,
				confirmButtonColor: '#3B6EF8',
				cancelButtonColor: '#6cc04a',
			})
				.then(async result => {
					if (result.value) {
						this.$store.dispatch('domain/removeScript', id);
						emitter.emit('saveDialogueProperties');
					}
				});
		}
	},
};
</script>

<style scoped>
.disabled {
  opacity: 0.8;
  cursor: not-allowed; 
  pointer-events: none; 
}

.edit_overlay {
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .1);
}

.domain-modal {
	padding: 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: fixed;
	width: 564px;
	height: max-content;
	top: 50%;
	left: 50%;
	background: #ffffff;
	box-shadow: 0px 0px 6px rgb(0 0 0 / 15%);
	border-radius: 8px;
	z-index: 99999;
	overflow: hidden;
	outline: 0;
	transform: translate(-50%, -50%);
}

@media (max-width: 575.99px) {
	.block-style {
		flex-direction: column;
		justify-content: flex-start !important;
		align-items: flex-start !important;
	}

	.block-style ul:nth-child(1) {
		margin-bottom: 15px !important;
	}

	.domain-modal {
		/* height: 77vh; */
		width: 90vw;
		overflow-y: scroll;
		padding: 0 !important;
	}

	domain-modal .form-group {
		flex-direction: column !important;
	}

	.domain-modal .form-group .limit-class {
		width: 100% !important;
	}
}

.cookie-m-table>thead>tr>th {
	color: #3b6ef8;
	font-size: 15px;
	font-weight: 500;
	padding: 7px 10px;
	white-space: nowrap;
}

.cookie-m-table>tbody>tr>td {
	border: 0 !important;
	padding: 7px 12px !important;
	vertical-align: middle;
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	max-width: 195px;
	background: #fff;
}

.bg-tr {
	overflow: hidden;
	-webkit-box-shadow: 0 1px 7px #bfbfbfba;
	-moz-box-shadow: 0 1px 7px #bfbfbfba;
	-ms-box-shadow: 0 1px 7px #bfbfbfba;
	-o-box-shadow: 0 1px 7px #bfbfbfba;
	box-shadow: 0 1px 7px #bfbfbfba;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	outline: 1px solid#fff !important;
}

.toggle {
	cursor: pointer;
	display: inline-block;
	margin-bottom: 0 !important;
}

.toggle-checkbox {
	position: absolute;
	visibility: hidden;
}

.toggle-checkbox:checked+.toggle-switch {
	background: #6cc04a;
}

.toggle-switch:after,
.toggle-switch:before {
	content: "";
}

.toggle-checkbox:checked+.toggle-switch:before {
	left: 24px;
}

.toggle-switch:before {
	display: block;
	background: linear-gradient(180deg, #fff 0, #eee);
	border-radius: 50%;
	box-shadow: 0 0 0 1px rgb(0 0 0 / 25%);
	width: 17.5px;
	height: 17.5px;
	position: absolute;
	top: 2.5px;
	left: 4px;
	transition: left .25s;
}

.toggle-switch {
	display: inline-block;
	background: #ccc;
	border-radius: 16px;
	width: 45px;
	height: 23px;
	position: relative;
	vertical-align: middle;
	transition: background .25s;
	top: -5px;
	left: 10px;
}

.table-responsive table tbody tr td select.form-control.double-arrow {
	border: 0 !important;
	border-right: 10px solid #fff !important;
	-webkit-box-shadow: 0 1px 7px #bfbfbfba;
	-moz-box-shadow: 0 1px 7px #bfbfbfba;
	-ms-box-shadow: 0 1px 7px #bfbfbfba;
	-o-box-shadow: 0 1px 7px #bfbfbfba;
	box-shadow: 0 1px 7px #bfbfbfba;
	border-radius: 0 !important;
}

.table-responsive table tbody tr td select.form-control.double-arrow:focus {
	border-color: transparent !important;
	outline: 0;
	width: 130px;
	-webkit-box-shadow: 0 1px 7px #bfbfbfba;
	-moz-box-shadow: 0 1px 7px #bfbfbfba;
	-ms-box-shadow: 0 1px 7px #bfbfbfba;
	-o-box-shadow: 0 1px 7px #bfbfbfba;
	box-shadow: 0 1px 7px #bfbfbfba;
}

.Necessary {
	outline: 0 !important;
	display: block;
	width: 130px;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	border: 0;
	-webkit-box-shadow: 0 1px 7px #bfbfbfba;
	-moz-box-shadow: 0 1px 7px #bfbfbfba;
	-ms-box-shadow: 0 1px 7px #bfbfbfba;
	-o-box-shadow: 0 1px 7px #bfbfbfba;
	box-shadow: 0 1px 7px #bfbfbfba;
	background: #6cc04a !important;
	border-right: 10px solid #6cc04a !important;
	color: #fff !important;
}

.Unclassified {
	outline: 0 !important;
	display: block;
	width: 130px;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	border: 0;
	-webkit-box-shadow: 0 1px 7px #bfbfbfba;
	-moz-box-shadow: 0 1px 7px #bfbfbfba;
	-ms-box-shadow: 0 1px 7px #bfbfbfba;
	-o-box-shadow: 0 1px 7px #bfbfbfba;
	box-shadow: 0 1px 7px #bfbfbfba;
	background: #4fbdb9 !important;
	border-right: 10px solid #4fbdb9 !important;
	color: #fff !important;
}

.Statistics {
	outline: 0 !important;
	display: block;
	width: 130px;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	border: 0;
	-webkit-box-shadow: 0 1px 7px #bfbfbfba;
	-moz-box-shadow: 0 1px 7px #bfbfbfba;
	-ms-box-shadow: 0 1px 7px #bfbfbfba;
	-o-box-shadow: 0 1px 7px #bfbfbfba;
	box-shadow: 0 1px 7px #bfbfbfba;
	background: #b34ada !important;
	border-right: 10px solid #b34ada !important;
	color: #fff !important;
}

.Marketing {
	outline: 0 !important;
	display: block;
	width: 130px;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	border: 0;
	-webkit-box-shadow: 0 1px 7px #bfbfbfba;
	-moz-box-shadow: 0 1px 7px #bfbfbfba;
	-ms-box-shadow: 0 1px 7px #bfbfbfba;
	-o-box-shadow: 0 1px 7px #bfbfbfba;
	box-shadow: 0 1px 7px #bfbfbfba;
	background: #4f5cbd !important;
	border-right: 10px solid #4f5cbd !important;
	color: #fff !important;
}

.Preference {
	outline: 0 !important;
	display: block;
	width: 130px;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	border: 0;
	-webkit-box-shadow: 0 1px 7px #bfbfbfba;
	-moz-box-shadow: 0 1px 7px #bfbfbfba;
	-ms-box-shadow: 0 1px 7px #bfbfbfba;
	-o-box-shadow: 0 1px 7px #bfbfbfba;
	box-shadow: 0 1px 7px #bfbfbfba;
	background: #e68521 !important;
	border-right: 10px solid #e68521 !important;
	color: #fff !important;
}
.toggle {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0 !important;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-checkbox:checked + .toggle-switch {
  background: #6cc04a;
}

.toggle-switch:before {
  content: " ";
  display: block;
  background: linear-gradient(180deg, #fff 0, #eee);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 25%);
  width: 17.5px;
  height: 17.5px;
  position: absolute;
  top: 2.5px;
  left: 4px;
  transition: left 0.25s;
}

.toggle-checkbox:checked + .toggle-switch:before {
	left: 24px;
    content: "🚫";
    font-size: 20px;
    top: 2px;
    display: inline-grid;
    justify-content: center;
}

.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 45px;
  height: 23px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
  top: -1px;
  margin-left: 10px; /* Adjusted margin-left for spacing */
}

</style>