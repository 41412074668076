import { createRouter, createWebHistory } from 'vue-router'
import AddDomain from '@/views/AddDomain.vue';
import GetCode from '@/views/GetCode.vue';
import CustoMiZation from '@/views/CustoMiZation.vue';
import store from '@/store'
import HomeComponent from "@/components/Layout/HomeComponent.vue"
import LoginComponent from "@/views/LoginComponent.vue";
import NotFound from "@/views/Page404.vue"
const routes = [
  {
    path: '/token',
    name: 'LoginComponent',
    component: LoginComponent,
    meta:{
      visit : true
    }
  },
  {
    path: '/',
    name: 'add-domain',
    component: AddDomain,
    meta:{
      layout: HomeComponent,
      isAu:true
    }
  },
  {
    path: '/get-code',
    name: 'get-code',
    component: GetCode,
    meta:{
      layout: HomeComponent,
      requiresPath: true,
    }
    // component: function () {
    //   if(store.getters['domain/getCurrentDomainId']){
    //     return GetCode
    //   }else{
    //     return '/:id'
    //   }
    // }  ,
  },
  {
    path: '/customize',
    name: 'customization',
    component: CustoMiZation,
    meta: {
      layout: HomeComponent,
      requiresPath: true,
      isAu:true
    }
  },
  // ... other paths ...
  {
    path: "/:NotFound(.*)*",
    name: "NotFound",
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes

})

// router.beforeEach( (to, from, next) => {
//   const isAu = to.matched.some(record => record.meta.isAu);
//   const visit = to.matched.some(record => record.meta.visit);
//   const token = store.state.domain.userToken;
//   console.log(token)
//   if(isAu){
//     if(token){
//       next();
//       return
//     }
//     next()
//     return
//     // else{
//     //   console.log('222')
//     //   // next({
//     //   //   path : '/:id'
//     //   // })
//     // }
//   }
//   if(visit){
//     if(visit && token){
//       next({
//         path: '/'
//       })
//       return
//     }
//     next();
//     return
//     // else{
//     //   console.log('44')
//     //   next({
//     //     path: '/'
//     //   })
//     // }
//   }
//   if(!isAu && !visit){
//     next()
//   }
// });
router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresPath)) {
      const sessionStorage = store.getters['domain/getCurrentDomainId']
      if (sessionStorage != false) {
        // console.log('from1',from)
        // console.log('to1',to)
        next();
      } else {
        next({
          path : '/'
        });
      }
    } else {
      next();
    }
});



export default router
