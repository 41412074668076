<template>
  <!-- Dialogue Preview Start -->
  <div
    v-bind:style="{ fontFamily: current_banner.font_style + ' !important',  background: current_banner.banner_bg_color }"
    class="cookie_box seers-cx-show" v-bind:class="current_banner.position"
    id="seers-cx-banner"
  >
    <div
      
      v-bind:style="{ background: current_banner.banner_bg_color }"
    >
      <div class="cookie_text_box">
        <span class="popClose" @click="bannerShow()" id="popClose" v-bind:style="{
            color: current_banner.title_text_color}">✕</span>
        <h2
          class="cookie_title m-0"
          v-bind:style="{
            color: current_banner.title_text_color,
            fontSize:
              (parseInt(current_banner.font_size) + 2).toString() +
              'px !important',
              fontFamily: current_banner.font_style + '!important',
          }"
        >
          {{this.language.cpra_title ? this.language.cpra_title : this.language.title}}
        </h2>
        <hr class="my-3" />
        <p
          id="seers-cookie-body-1"
          class="cookie_text"
          v-bind:style="{
            color: current_banner.body_text_color + ' !important',
            fontSize: current_banner.font_size + 'px !important',
            fontFamily: current_banner.font_style + '!important',
          }" style="font-size:11px !important; text-overflow: ellipsis;white-space: nowrap;display: block;overflow: hidden; margin-bottom:7px !important;" v-html="cpra_sanitizedBody ? cpra_sanitizedBody : sanitizedBody"
        >
        </p>
        <!-- <hr class="my-3" /> -->
        <div class="d-flex justify-content-between align-items-center">
          <span
            class="seers_sub_heading"
            v-bind:style="{
              color: current_banner.body_text_color + ' !important',
              fontSize: current_banner.font_size + 'px !important',
              fontFamily: current_banner.font_style + '!important',
            }"
          >
            Targeting / Advertising
          </span>
          <!-- <hr class="my-3" /> -->
          <div class="seers_main_switch seers_switch">
            <input
              id="do-not-sell-2"
              class="cmn-toggle cmn-toggle-round"
              type="checkbox"
            />
            <label for="do-not-sell-2"></label>
          </div>
        </div>
        <!-- <hr class="my-3" /> -->
        <div class="row">
          <div class="col-12 d-flex justify-content-end mt-4">
            <button id="confirm_my_choice" class="pl-1 pr-1" type="button" @click="bannerShow()" v-bind:style="{ color: current_banner.agree_text_color + ' !important', background: current_banner.agree_btn_color + '!important', fontFamily: current_banner.font_style + '!important', }" style="min-width: 90px !important; width: fit-content; ">
              {{language.cpra_btn_agree_title ? language.cpra_btn_agree_title : language.btn_agree_title}}
            </button>
          </div>
        </div>
        <div v-if="form.email_section">
          <hr class="my-3" />
          <p
            id="seers-cookie-body-1"
            class="cookie_text" 
            v-bind:style="{
              color: current_banner.body_text_color + ' !important',
              fontSize: current_banner.font_size + 'px !important',
              fontFamily: current_banner.font_style + '!important',
            }" style="font-size:11px !important; text-overflow: ellipsis;white-space: nowrap;display: block;overflow: hidden; margin-bottom:7px !important;"
          >
            {{this.language.cpra_email_section_title ? this.language.cpra_email_section_title : this.language.email_section_title}}
          </p>
          <!-- <hr class="my-3" /> -->
        </div>

        <div v-if="form.policy_url_section">
          <hr class="my-3" />
          <p
            id="seers-cookie-body-1"
            class="cookie_text" 
            v-bind:style="{
              color: current_banner.body_text_color + ' !important',
              fontSize: current_banner.font_size + 'px !important',
              fontFamily: current_banner.font_style + '!important',
            }" style="font-size:11px !important; text-overflow: ellipsis;white-space: nowrap;display: block;overflow: hidden; margin-bottom:7px !important;"
          >
            {{this.language.cpra_email_section_title ? this.language.cpra_email_section_title : this.language.email_section_title}}
          </p>
          <!-- <hr class="my-3" /> -->
        </div>
      </div>
      <div class="row email_box align-items-center" v-if="form.email_section">
        <div class="col-2 pr-0">
          <h5 class="email_heading m-0" v-bind:style="{
            color: current_banner.body_text_color + ' !important',
            fontSize: current_banner.font_size + 'px !important',
            fontFamily: current_banner.font_style + '!important',
          }">Email:</h5>
        </div>
        <div class="col-7 pl-0">
          <input type="email" class="form-control" />
        </div>
        <div class="col-3 pl-0">
          <button type="submit" class="email_btn px-3" @click="bannerShow()" style="width: max-content !important;" v-bind:style="{ color: current_banner.agree_text_color + ' !important', background: current_banner.agree_btn_color + '!important', fontFamily: current_banner.font_style + '!important', }">{{ language.cpra_btn_preference_title }}</button>
        </div>
      </div>
      <div class="row email_box align-items-center mr-1" v-if="form.policy_url_section">
        
          <button type="submit" class="policy_btn px-3" @click="bannerShow()" style="margin-left: auto;" v-bind:style="{ color: current_banner.agree_text_color + ' !important', background: current_banner.agree_btn_color + '!important', fontFamily: current_banner.font_style + '!important', }">{{ language.cpra_btn_disagree_title ? language.cpra_btn_disagree_title : 'Open' }}</button>
      </div>

      <!-- <div class="button_box">
                    <div class="deital_button">
                        <a  v-if="dialogueProperties.cookie_policy_url !== null && dialogueProperties.cookie_policy_url !== ''" :href="dialogueProperties.cookie_policy_url ? dialogueProperties.cookie_policy_url : `${mix_cmp_url}/cookie-policy`" target="_blank" v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">{{language.policy}}</a> <span v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">|</span> <a v-on:click="this.fin" v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}" id="seers-cx-show-details">{{language.btn_preference_title}}</a>
                    </div>
                    <div class="button_postion">
                        <button id="seers-cx-agree" type="button" class="cookie_btn cookie_btn_open cookie_seers_open_btn seers-cx-show-details" v-bind:class="[this.agreeBtnSpecific, current_banner.button_type]" v-bind:style="{background :current_banner.agree_btn_color + ' !important',color :current_banner.agree_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">
                            {{language.btn_agree_title}}
                        </button>
                    </div>
                </div> -->
    </div>
    <!--Tag setting-->
    <div
      class="seers_cc_tag_seeting seers_cc_bottom"
      v-if="dialogueProperties.has_badge"
      style="
        background-image: url(/images/design-new/seersco-thumb-scan.svg);
        width: 60px;
        height: 60px;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 15px;
      "
    ></div>
    <!--Tag Setting End-->
  </div>
</template>

<script>
import DOMPurify from 'dompurify';
// import store from "../cookieXray/store";
export default {
  // store,
  components: {},
  props: [],
  data() {
    return {
      readMoreBtnSpecific: "",
      agreeBtnSpecific: "",
      disAgreeBtnSpecific: "",
      arrow_icon: "&#8897;",
      toggle_one: false,
      toggle_two: false,
      anchor_tag_one: "",
      cookie_body_el_one: "",
      anchor_tag_two: "",
      cookie_body_el_two: "",
      container_height: 0,
      footer_height: 0,
    };
  },
  // updated() {
  //   let cookie_body_2 = document.getElementById("cookie_seers_popup_header");
  //   let cookie_banner_footer = document.getElementById(
  //     "seers_cookie_side_one_ccpa_footer"
  //   );
  //   this.container_height = cookie_body_2.offsetHeight;
  //   this.footer_height = cookie_banner_footer.offsetHeight;
  // },
  mounted() {
    this.setStyleForSpecificConsent();
    let anchorNodeOne = this.createAnchorNode(true);
    this.anchor_tag_one = anchorNodeOne;
    let anchorNodeTwo = this.createAnchorNode(false);
    this.anchor_tag_two = anchorNodeTwo;
    let cookie_body_1 = document.getElementById("seers-cookie-body-1");
    this.cookie_body_el_one = cookie_body_1;
    let cookie_body_2 = document.getElementById("seers-cookie-body-2");
    this.cookie_body_el_two = cookie_body_2;
    // if (cookie_body_1.textContent.split(" ").length >= 20) {
    //   cookie_body_1.appendChild(anchorNodeOne);
    // }
    // if (cookie_body_2.textContent.split(" ").length >= 20) {
    //   cookie_body_2.appendChild(anchorNodeTwo);
    // }

    // this.initEvents();
  },
  computed: {
        sanitizedBody() {
          return DOMPurify.sanitize(this.language.body);
        },
        cpra_sanitizedBody() {
          return DOMPurify.sanitize(this.language.cpra_body);
        },
      // dialogueProperties: function () {
      //     return this.$store.getters.getDialogueProperties;
      // },
      form: function () {
        return this.$store.state.dialogue.form;
      },
      dialogueProperties: function () {
        return this.$store.getters["domain/getDialogueProperties"];
      },
      logo: function () {
          return this.dialogueProperties.logo;
      },
      mix_cmp_url: function () {
          return process.env.VUE_APP_ENV_VARIABLE
        },
      logo_url: function () {
          return `${this.mix_cmp_url}/local/images/logo/${this.logo}`;
      },
      language: function() {
          return this.$store.getters["domain/getCurrentLanguage"];
      },
      current_banner: function(){
          return this.$store.getters["domain/getExertoBanner"];
          // return this.$store.getters["domain/getCurrentBanner"];
      },
      // current_banner1: function(){
      //   return this.$store.getters["domain/getCurrentBanner"];
      // },
      // defaultBanner: function(){
      //   return this.$store.getters["domain/getDefaultBanner"];
      // },
      // xertoBanner: function(){
      //   return this.$store.getters["domain/getExertoBanner"];
      // },
      language_body_1: {
          get: function() {
              let self = this;
              let lang = this.sanitizedBody;

              if(!self.toggle_one && self.cookie_body_el_one && lang.split(' ').length > 20){
                  lang = self.truncate(lang.trim(), 20, null);
                  if(!self.cookie_body_el_one.contains(self.anchor_tag_one)){
                      self.cookie_body_el_one.appendChild(self.anchor_tag_one);
                  }
              }
              if(lang.split(' ').length < 20 && self.cookie_body_el_one && self.anchor_tag_one && self.cookie_body_el_one.contains(self.anchor_tag_one)){
                  self.cookie_body_el_one.removeChild(self.anchor_tag_one);
              }

              return lang;
          },
          set: function(lang) {
              this.language_body = lang;
          }
      },
      language_body_2: {
          get: function() {
              let self = this;
              let lang = this.sanitizedBody;

              if(!self.toggle_two && self.cookie_body_el_two && lang.split(' ').length > 20){
                  lang = self.truncate(lang.trim(), 20, null);
                  if(!self.cookie_body_el_two.contains(self.anchor_tag_two)){
                      self.cookie_body_el_two.appendChild(self.anchor_tag_two);
                  }
              }
              if(lang.split(' ').length < 20 && self.cookie_body_el_two && self.anchor_tag_two && self.cookie_body_el_two.contains(self.anchor_tag_two)){
                  self.cookie_body_el_two.removeChild(self.anchor_tag_two);
              }

              return lang;
          },
          set: function(lang) {
              this.language_body = lang;
          }
      },
      show_dialogue_preview: function () {
          return this.$store.getters["domain/getShowDialoguePreview"];
      },
  },
  methods: {
    toggleArrow() {
      this.arrow_icon = this.arrow_icon == "&#8897;" ? "&#8743;" : "&#8897;";
      return;
    },
    showMenu() {
      $(".menu").slideToggle("slow");
      // Get the element with id="defaultOpen" and click on it
      document.getElementById("defaultOpen").click();
      // console.log('this.showMenu called');
    },
    showTab(cityName, $event) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("seers-cx-tab-content");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("seers-cx-tab-links");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(
          " seers-cx-active",
          ""
        );
      }
      document.getElementById(cityName).style.display = "block";
      $event.target.className += " seers-cx-active";
    },
    setStyleForSpecificConsent() {
      if (this.dialogueProperties.consent_type == "specific") {
        this.readMoreBtnSpecific = "readMoreBtnSpecific";
        this.agreeBtnSpecific = "agreeBtnSpecific";
        this.disAgreeBtnSpecific = "hide";
      }

      return;
    },
    fin() {
      self = this;
      for (var i = 0; i <= 1; i += 0.01) {
        i = Math.round(i * 100) / 100;
        setTimeout("self.seto(" + i + ")", i * 1000);
      }
      document
        .getElementById("seers-cx-side-bar")
        .classList.replace("hide", "seers-cx-show");
      setTimeout(() => {
        let element = document.getElementById("cookie_seers_popup_header");
        self.container_height = element.offsetHeight;
      }, 500);
    },
    seto(opa) {
      self = this;
      document.getElementsByClassName("cookie_seers_popup")[0].style.opacity =
        opa;
      document.getElementsByClassName("cookie_seers_popup")[0].style.display =
        "block";
    },
    truncate(text, limit, after) {
      var self = this;
      // Make sure an element and number of items to truncate is provided
      if (!text || !limit) return;

      // Get the inner content of the element
      var content = text;
      content = content.trim();

      // Convert the content into an array of words
      // Remove any words above the limit
      content = content.split(" ").slice(0, limit);

      // Convert the array of words back into a string
      // If there's content to add after it, add it
      content = content.join(" ");

      return content;
    },
    bannerShow() {
        if (this.show_dialogue_preview == true) {
        this.$store.commit("domain/setShowDialoguePreview", false);
        this.$store.commit("domain/setPreviewBtn", "Preview");
        } else {
        this.$store.commit("domain/setPreviewBtn", "Exit Preview");
        this.$store.commit("domain/setShowDialoguePreview", true);
        }
    },
    
    previewToggle(status) {
      this.$store.commit("setShowDialoguePreview", status);
    },
    createAnchorNode(value) {
      let self = this;
      let anchor = document.createElement("a");
      anchor.id = value ? "seers_read_more_id_one" : "seers_read_more_id_two";
      anchor.href = "javascript:void(0)";
      anchor.textContent = self.language.link_more;
      anchor.setAttribute(
        "style",
        "font-size: inherit;\n" +
          "    color: #269abc !important;\n" +
          "    text-decoration: none;\n" +
          "    padding-left: 0px;\n" +
          "    font-weight: inherit;"
      );

      return anchor;
    },
  },
};
</script>

<style scoped>
/* new banner css */

.cookie_box {
  background: #ffffff; /* Old browsers */
  max-width: 100%;
  width: 396px;
  padding: 14px !important;
  box-shadow: 2px 1px 10px 0px rgba(119, 119, 119, 0.75);
  color: #fff;
  border-radius: 8px;
  z-index: 999999999 !important;
  position: fixed;
}

/*left*/
.seers_cc_bottom {
  bottom: 20px;
  top: auto;
  left: 20px;
  transition: all 0.6s ease 0s;
}
/*right*/
.seers_cc_bottom_right {
  top: auto;
  bottom: 20px !important;
  right: 20px !important;
  transition: all 0.6s ease 0s;
}

/*center bottom*/
.seers_cc_bottom_center {
  bottom: 20px;
  transition: all 0.6s ease 0s;
  left: 50%;
  transform: translate(-50%, 0%);
}
/*left*/
.seers_cc_middle_left {
  left: 20px;
  top: 50%;
  transform: translate(0%, -50%);
  transition: all 0.6s ease 0s;
}
/*right*/
.seers_cc_middle_right {
  right: 20px;
  top: 50%;
  transform: translate(0%, -50%);
  transition: all 0.6s ease 0s;
}

/*center bottom*/
.seers_cc_middle_center {
  transition: all 0.6s ease 0s;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
/* Genral stle */
.email_btn {
  color: rgb(40, 40, 40);
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: #fff;
  height: 37.3px;
  border: 1px solid #ced4da;
  border-radius: 4px !important;
  width: 85px;
}
.policy_btn {
  color: rgb(40, 40, 40);
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: #fff;
  height: 37.3px;
  border: 1px solid #ced4da;
  border-radius: 4px !important;
  min-width: 100px;
  width: fit-content;
}
#confirm_my_choice {
  /* width: 165px; */
  height: 37.3px;
  color: rgb(40, 40, 40);
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px !important;
}
.popClose {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 0;
  color: #000;
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
}
.cookie_box h2.cookie_title {
  font-size: 16px;
  margin: 10px !important;
  color: #000;
  padding: 0 !important;
  float: unset !important;
  position: unset !important;
  display: block !important;
  letter-spacing: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.cookie_box span.seers_sub_heading {
  color: #383737;
  font-size: 14px;
  line-height: 20px !important;
  margin: 10px 0px 0px 5px;
  padding: 0px 10px 0px 0px;
  vertical-align: middle !important;
  float: left !important;
  letter-spacing: normal !important;
  font-weight: 600 !important;
  position: unset !important;
  display: unset !important;
}

.cookie_box .cookie_text_box {
  width: 100%;
  margin-bottom: 10px;
}
.cookie_box p.cookie_text {
  color: #383737;
  font-size: 14px;
  line-height: 24px !important;
  margin-bottom: 10px !important;
  display: block !important;
  clear: both !important;
  float: unset !important;
  position: unset !important;
  letter-spacing: normal !important;
  font-weight: normal !important;
}

/*switch button*/

.seers_main_switch {
  float: right;
  margin-top: 10px;
}

.cookie_box input.cmn-toggle-round + label {
  padding: 2px !important;
  width: 40px !important;
  height: 23px !important;
  background-color: #dddddd !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
  border-radius: 30px !important;
}
.cookie_box input.cmn-toggle-round + label::before,
.cookie_box input.cmn-toggle-round + label::after {
  display: block !important;
  position: absolute !important;
  top: 1px !important;
  left: 1px !important;
  bottom: 1px !important;
  content: "" !important;
}
.cookie_box input.cmn-toggle-round + label::before {
  right: 1px !important;
  content: 'OFF' !important;
    color: #3B6Ef8 !important;
    margin-left: 21px;
    font-size: 8px;
    background-color: #DDDDDD !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
  border-radius: 30px !important;
  -webkit-transition: background 0.4s !important;
  -moz-transition: background 0.4s !important;
  -o-transition: background 0.4s !important;
  transition: background 0.4s !important;
}
.cookie_box input.cmn-toggle-round + label::after {
  width: 20px !important;
  background-color: #dddddd !important;
  -webkit-border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -ms-border-radius: 100% !important;
  -o-border-radius: 100% !important;
  border-radius: 100% !important;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3) !important;
  -webkit-transition: margin 0.4s !important;
  -moz-transition: margin 0.4s !important;
  -o-transition: margin 0.4s !important;
  transition: margin 0.4s !important;
}
.cookie_box input.cmn-toggle-round:checked + label::before {
  background: #1e8e3e !important;
}
.cookie_box input.cmn-toggle-round:checked + label::after {
  margin-left: 18px !important;
}

.cmn-toggle {
  position: absolute !important;
  margin-left: -9999px !important;
  visibility: hidden !important;
}
.cmn-toggle + label {
  display: block !important;
  position: relative !important;
  cursor: pointer !important;
  outline: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.disabled_switch input.cmn-toggle-round:checked + label::before {
  background-color: var(--switch-color) !important;
}

/* media Queries */
@media only screen and (max-width: 767px) {
  .seers_cc_bottom_left,
  .seers_cc_bottom_right,
  .seers_cc_bottom_center,
  .seers_cc_middle_left,
  .seers_cc_middle_right,
  .seers_cc_middle_center {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
 /*top right mz*/
    .seers_cc_top_right{
    right: 1% !important;
    top: 3% !important;
    left: auto !important;
        bottom: auto;
            transform: translate(-0%, -0%) !important;
    }
    /* top right end*/
          /*top left mz*/
    .seers_cc_top_left{
    left: 1% !important;
    right: auto !important;
    top: 3% !important;
    bottom: auto;
    transform: translate(-0%, -0%) !important;
    }
    /*top left end*/
    .seers_cc_center{
        top: auto;
        margin: 0 auto;
        z-index: 99999;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -45%);
        box-shadow: 2px 1px 28px 0px rgba(60, 58, 58, 0.85);
        border-radius: 20px !important;
        max-height: 80vh !important;
        width: 430px !important;

    }
</style>
