import Api from "@/apis/Api";
import axios from "axios";

export const getCurrentUser = async({ rootState,state, commit }, routeParam) => {
    try {             
       const response = await Api.get('userFetch',
       {
           headers:{
               'Authorization' : 'Bearer ' + routeParam
           },   
       });
       const {status} = response;
       if(status===200){
           commit('setUser',response.data.user)
           console.log('checking',response.data.user.superAdminId)
           if(!response.data.user.isSuperAdmin && response.data.user.superAdminId != null){
               commit('setUserId',response.data.user.superAdminId)
               commit('setAssign_role',response.data.permission.role.name)
           }else{
           commit('setUserId',response.data.user.id)
           }
           commit('setUserEmail',response.data.user.email)
            commit("userToken", routeParam)
            localStorage.setItem('user_token',routeParam)
            // dispatch('getAuthUser')
       }
       else {
        
        commit("theLoader", true)
            window.location.href = `${process.env.VUE_APP_FRONT}/business/dashboard`;
        }
    } catch (error) {
    
        commit("theLoader", true)
             window.location.href = `${process.env.VUE_APP_FRONT}/business/dashboard`;
    }
}

export  const  getAuthUser =async ({rootState, state, commit , dispatch }) => {
    // commit("theLoader", true)
    try {
     
        const response = await Api.get("getFeatures", {
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            },
 
          })
          if (response.data.features !== null) {
            commit("setFeatures", response.data.features);
            dispatch('setCurrencyForCountry',response.data.country)
            dispatch('pricingPopupData')
            }
    } catch (error) {
   
             window.location.href = `${process.env.VUE_APP_FRONT}/business/dashboard`;
    }
}

export const setCurrencyForCountry = ({commit},country) => {
    if (country === null) {
        // console.log('LKLSASJAS')
       //   commit("setCurrency", "GBP");
      return;
    }
    let code = country.countryCode;
    if (code === "US") {
      commit("setCurrency", "USD");
      return;
    }
    if (["IT", "ES"].includes(code)) {
      commit("setCurrency", "EUR");
    }
}

export const pricingPopupData = async ({ rootState,state , commit }) => {
    try {
        
        const response = await Api.get('get-cookies-plans',{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            },
         
          })
        if(response){
             
            commit("setProduct", response.data.product);
            commit(
                "setPopupFeatures",
                response.data.product.plans[
                  response.data.product.plans.length - 1
                ].features
            );
            commit("setActivatedPlan", response.data.purchasedPlan);
        }
    } catch (error) {
        console.log(error)
    }
}

export const getAllDomains = async ({ rootState,state , commit },userId) => {
   commit("inLoader", true)
    try {
        
        const response = await Api.get(`getAllDomains/${userId}`,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            },
          
          })
         commit("inLoader", false)
        if(response){
            commit('setDomains',response.data.domains)
        }
    } catch (error) {
        console.log(error)
    }
}

export const deleteDomain = async ({ rootState,state , commit },payload) => {
    try {
       
        commit('removeDomain',payload.domainId)
        const response = await Api.delete(`deleteDomain/${payload.domainId}/${payload.user_id}`,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            },
        
          })
        if(response.status === 200){
            let responseError = {
                status: response.status,
                message: response.data.message
            }
       
            commit('toastAlert',responseError)
        }
        else{
            let responseError = {
                status: response.status,
                error: response.data.error
            }
            
            commit('toastAlert',responseError)
        }
    } catch (error) {
        console.log('2',error)
    }
}

export const scanNow = async ({ rootState , state , commit },payload) => {
    try {

        const response = await Api.get(`domain_scan_now/${payload}`,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            },
            
          })
        // if(response.status === 200){
        //     let responseError = {
        //         status: response.status,
        //         message: response.data.message
        //     }
        //     commit('toastAlert',responseError)
        // }
        // else{
        //     let responseError = {
        //         status: response.status,
        //         error: response.data.error
        //     }
        //     commit('toastAlert',responseError)
        // }
        return response;
    } catch (error) {
        
        console.log('2',error)
    }
}

export const sendEmail = async ({ rootState , state , commit },payload) => {
    try {
        
        const response = await Api.post(`sendReportEmail/` + payload.dom_id,payload,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            },
            
          })
        if(response.status === 200){
            let responseError = {
                status: response.status,
                message: 'Sent email Successful!'
            }
           
            // console.log('responseresponse',response)
            commit('toastAlert',responseError)
        }
        else{
            let responseError = {
                status: response.status,
                error: response.data.error
            }
            commit('toastAlert',responseError)
        }
        return response;
    } catch (error) {
        console.log('2',error)
    }
}

export const editDomain = async ({ rootState , state , commit },payload) => {
    try {
      
        const response = await Api.post('editDomain',payload,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            },
            
          })
        if(response.status === 200){
            commit('updateDomain',payload)
            let responseMessage = {
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                status: response.status,
                message: response.data.message
            }
           
            commit('swalAlert',responseMessage)
        }
    } catch (error) {
        console.log('2',error)
        throw error;
    }
}

export const addDomain = async ({ state , commit },payload) => {
    try {
          const  response = await Api.post('storeDomain',payload,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            }
          })
        
        return response;
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const coookieDialogue = async ({ rootState, state, commit }, payload) => {
    try {
        payload.categories = state.categories;
        const response = await Api.post('cookieXray-store-dialogue', payload, {
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            },
        });

        if (response.status === 200) {
            commit("setDefaultLanguage", response.data.default_language);
            commit("setCurrentLanguage", response.data.default_language);
            commit("setDialogueLanguages", response.data.dialogue_languages);
            commit("setDialogueProperties", response.data.cookie_xray_dialogue);
            
            if (response.data.categories) {
                commit('addCategory', response.data.categories);
            }
            commit("setShowSearchBar", true);
            commit("setIsOneStepBanner", true);

            payload.banners.forEach((banner) => {
                if (banner.is_active === 1) {
                    commit("setCurrentBanner", banner);
                }
            });
        }

        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
};


// export const loadDialogueProperties = async ({rootState, state , commit },payload) => {
  
//     // if(rootState.policy.tab != 'TrackingManager') 
//     // {
//     //     commit("inLoader", true)
//     // }
  
//     try {
//         // if (rootState.abortAxiosRequest.cancelLoadDialoguePropertiesToken) {
//         //     rootState.abortAxiosRequest.cancelLoadDialoguePropertiesToken.cancel('Request canceled');
//         //     commit("abortAxiosRequest/setDestroyCancelLoadDialoguePropertiesToken", {}, { root: true })
//         //   }
//         //   const cancelToken = axios.CancelToken.source();
//         //   commit("abortAxiosRequest/setCancelLoadDialoguePropertiesToken", cancelToken, { root: true })
//           const  response = await Api.get(`cookieXray-dialogue/${payload.domain_id}/${payload.user_id}`,{
//             headers: {
//                 Authorization: 'Bearer ' + state.userToken,
//             },
            
//             // cancelToken: cancelToken.token,
//           });
//         //    commit("inLoader", false)
//         if(response.status === 200){
//             // commit('abortAxiosRequest/setDestroyCancelLoadDialoguePropertiesToken', {}, { root: true })
//             // console.log('responseresponse',response)
//             // console.log('bannersbbbb',state.banners)
//             // commit("domain/setCurrentLanguage", response.data.default_language)
//             // commit("domain/hasPolicy", response.data.policy)
//             // commit("dialogue/consentlog_limit_reached", response.data.consentlog_limit_reached, { root: true })
//             if (response.data.dialogue) {
//                 commit('dialogue/resetState',{}, { root: true })
//                 // commit('abortAxiosRequest/setDestroyCancelLoadDialoguePropertiesToken',{},{root: true});
//                 let banners = response.data.dialogue.banners;
//                 let banner = banners.find((banner) => banner.is_active === 1);
                
//                 // console.log('gggg',banner_type)
//                 // banners.forEach((banner) => {
//                 //     if (banner.name == 'google_banner' && banner.is_active == 1) {
//                 //         // console.log('1')
//                 //         response.data.dialogue.banners = []
//                 //         response.data.dialogue.banners.push(state.default)
//                 //       commit("setCurrentBanner", state.default);
//                 //     //   console.log('firstcurrent_banner', state.current_banner)
//                 //     }
//                 //     else if(banner.name == 'default' && banner.is_active == 1){
//                 //         // console.log('12')
//                 //         commit("setCurrentBanner", banner);
//                 //         banner.customizeColors = state.default.customizeColors;
//                 //         banner.mainPositions = state.default.mainPositions;
//                 //     }
//                 //     else if(banner.name == 'side_one_ccpa' && banner.is_active == 1){
//                 //         banner.customizeColors = state.banners.side_one_ccpa.customizeColors;
//                 //         banner.mainPositions = state.banners.side_one_ccpa.mainPositions;
//                 //         commit("setCurrentBanner", banner);
//                 //     }
//                 //     else if(banner.name == 'exerto' && banner.is_active == 1){
//                 //         // console.log('13')
//                 //         banner.customizeColors = state.banners.side_one_ccpa.customizeColors;
//                 //         banner.mainPositions = state.banners.side_one_ccpa.mainPositions;
//                 //         commit("setCurrentBanner", banner);
//                 //     }
//                 // });
//                 // let banner = banners.find((banner) => banner.is_active === 1);
//                 if (banner !== undefined) {
//                     let banner_type = banner.name;
//                 commit("banner_type", banner_type);
//                     banner.mainPositions = state.banners[banner.name] ? state.banners[banner.name].mainPositions : '';
//                     banner.customizeColors = state.banners[banner.name] ? state.banners[banner.name].customizeColors : '';
//                     commit("setCurrentBanner", banner);
//                     commit("dialogue/setForm",response.data.dialogue, { root: true });
//                   } else {
//                     commit("dialogue/setForm",response.data.dialogue, { root: true });
//                     // rootState.dialogue.form.banners.push(state.banners.default);
//                     commit("dialogue/setFormBanner",state.banners.default, { root: true });
//                     commit("banner_type", "default");
//                     commit("setCurrentBanner", state.banners.default);
//                   }
//                 // commit('dialogue/resetState',{}, { root: true })
//                 // console.log('for bbbb',response)
//                 // console.log('setform',response.data.dialogue)
//                 commit('setPreDefinedLanguages',response.data.preDefinedLanguages)
                
//                 commit("setCurrentLanguage", response.data.default_language);
//                 commit("setDialogueLanguages",response.data.dialogue_languages);
//                 commit("setDialogueProperties", response.data.dialogue);
//                 commit("getCode/setGoogleConsentModeButton", response.data.dialogue.apply_google_consent,{ root: true });
//                 commit("getCode/setFacebookConsentModeButton", response.data.dialogue.apply_facebook_consent,{ root: true });
                
//             }
//             else {
//                 commit("setCurrentLanguage", response.data.default_language);
//                 commit("setDialogueLanguages",response.data.dialogue_languages);
//                 commit("setCurrentBanner", state.banners.default);
//                 commit("setDialogueProperties", rootState.dialogue.form);
//             }
//             // console.log('dialogue/resetState',rootState.dialogue.form)
//             // console.log('res',response)
//         }
//         return response;
//     } 
//     catch (error) {
//         // if (axios.isCancel(error)) {
//         //     console.log('Request canceled:', error.message);
//         //     commit("abortAxiosRequest/setDestroyCancelLoadDialoguePropertiesToken", {}, { root: true })
//         // }
//         // commit("abortAxiosRequest/setDestroyCancelLoadDialoguePropertiesToken", {}, { root: true })
//         console.log(error)
//         throw error
//     }
 
      
// }




export const loadDialogueProperties = async ({ rootState, state, commit, getters }, payload) => {
    try {
        commit('clearCategories');
      const response = await Api.get(`cookieXray-dialogue/${payload.domain_id}/${payload.user_id}`, {
        headers: {
          Authorization: 'Bearer ' + state.userToken,
        },
      });
  
      if (response.status === 200) {
        const dialogueProperties = response.data.dialogue;
        // Update dialogue properties
        commit('dialogue/resetState', {}, { root: true });
        let banners = dialogueProperties.banners;
        let banner = banners.find((banner) => banner.is_active === 1);
        let defaultbanner = banners.find((banner) => banner.name === 'default');
        let exertobanner = banners.find((banner) => banner.name === 'exerto');
        if (banner !== undefined) {
          let banner_type = banner.name;
          commit('banner_type', banner_type);
          banner.mainPositions = state.banners[banner.name] ? state.banners[banner.name].mainPositions : '';
          banner.customizeColors = state.banners[banner.name] ? state.banners[banner.name].customizeColors : '';
          commit('setCurrentBanner', banner);
          commit('setDefaultBanner', defaultbanner);
          if(exertobanner !== undefined){
          commit('setExertoBanner', exertobanner);
          }else{
            commit('setExertoBanner', banner);
          }
          commit('dialogue/setForm', dialogueProperties, { root: true });
        } else {
          commit('dialogue/setForm', dialogueProperties, { root: true });
          commit('dialogue/setFormBanner', state.banners.default, { root: true });
          commit('banner_type', 'default');
          commit('setCurrentBanner', state.banners.default);
        }
        const existingCategoryTitles = response.data.categories;
        existingCategoryTitles.forEach(category => {
            commit('addCategory', category);
        });
        // console.log(state.categories);
  
        commit('setPreDefinedLanguages', response.data.preDefinedLanguages);
        commit('setCurrentLanguage', response.data.default_language);
        commit('setDialogueLanguages', response.data.dialogue_languages);
        commit('setDialogueProperties', dialogueProperties);
        commit('getCode/setGoogleConsentModeButton', dialogueProperties.apply_google_consent, { root: true });
        commit('getCode/setFacebookConsentModeButton', dialogueProperties.apply_facebook_consent, { root: true });
  
        // await fetchExistingCategoryTitles({ commit, state }, dialogueProperties.id);
      }
  
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  

export const filterScripts = async ({rootState, state , commit },payload) => {
    //commit("inLoader", true)
    try {
      
          const  response = await Api.get('cookie-consent.scripts.load.all?dom_id=' + payload.domain_id + '&page=' + payload.page,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            },
           
          })
          //commit("inLoader", false)
        if(response.status === 200){
            commit('setManuallyBlockScripts',response.data.scripts)
            commit('setStart',response.data.scripts.from)
            commit('setScriptCategories',response.data.script_categories)
            commit('setScriptCustomizeCategories',response.data.script_categories_customize)

           
        }
        return response;
    } catch (error) {
       
        console.log(error)
        
        throw error
    }
}

export const toggleScript = async ({ state , commit },payload) => {
    try {
          const  response = await Api.post('cookie-consent.script.toggle',payload,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            }
          })
        if(response.status === 200){
            commit('updateToggleScript',payload)
            let responseError = {
                status: response.status,
                message: response.data.message
            }
            commit('toastAlert',responseError)
        }
        return response;
    } catch (error) {
        console.log(error)
        throw error
    }
}
import Swal from 'sweetalert2';

export const changeCategory = async ({ state, commit }, payload) => {
    try {
        const response = await Api.post('cookie-consent.script.toggle-category', payload, {
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            }
        });
        if (response.status === 200) {
            commit('updateChangeCategory', payload);
            let responseError = {
                status: response.status,
                message: response.data.message
            };
            commit('toastAlert', responseError);
        }
        return response;
    } catch (error) {
        if (error.response && error.response.status === 409 && error.response.data && error.response.data.message) {
            let message = error.response.data.message;
            let responseMessage = {
                icon: "error",
                title: message,
                timer: 4000,
                showConfirmButton: false,
                confirmButtonText: "OK"
            };
            Swal.fire(responseMessage);
        } else {
            console.error("An unexpected error occurred:", error);
        }

        throw error;
    }
};


export const saveScript = async ({ state , commit },payload) => {
    try {
          const  response = await Api.post('cookie-consent.script-manager.save',payload,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            }
          })
        if(response.status === 200){
            commit('setNewScript',response.data.script)
            let responseMessage = {
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                status: response.status,
                message: response.data.message
            }
            commit('swalAlert',responseMessage)
        }
        return response;
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const removeScript = async ({ state , commit },id) => {
    try {
        commit('removeScript',id)
        const response = await Api.delete(`cookie-consent.script.remove?id=${id}`,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            }
          })
        if(response.status === 200){
            let responseError = {
                status: response.status,
                message: response.data.message
            }
            commit('toastAlert',responseError)
        }
        else{
            let responseError = {
                status: response.status,
                error: response.data.error
            }
            commit('toastAlert',responseError)
        }
    } catch (error) {
        console.log('2',error)
    }
}

export const UpdateScript = async ({ state , commit },payload) => {
    try {
        const response = await Api.post('cookie-consent.script-manager.update',payload,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            }
          })
        if(response.status === 200){
            commit('updateScript',payload)
            let responseMessage = {
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                status: response.status,
                message: response.data.message
            }
            commit('swalAlert',responseMessage)
        }
        return response;
    } catch (error) {
        console.log('2',error)
        throw error;
    }
}

export const loadAutoBlockScript = async ({ state , commit }) => {
    try {
        const response = await Api.get('auto-block-list',{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            }
          })
        if(response){
            commit('setAutoBlockScript',response.data.list)
            return response;
        }
    } catch (error) {
        console.log(error)
    }
}

export const loadCookies = async ({ rootState , state , commit },payload) => {
    commit('trackingLoader' , true)
    try {
        // if (rootState.abortAxiosRequest.cancelLoadCookiesToken) {
        //     rootState.abortAxiosRequest.cancelLoadCookiesToken.cancel('Request canceled');
        //     commit('abortAxiosRequest/setDestroyCancelLoadCookiesToken',{},{root: true});
        //   }
        //   const cancelToken = axios.CancelToken.source();
        //   commit("abortAxiosRequest/setCancelLoadCookiesToken", cancelToken, { root: true })
          const  response = await Api.post('cookieXray-get-cookies',payload,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            },
            // cancelToken: cancelToken.token,
          })
          commit('trackingLoader' , false)
         
        if(response.status === 200){
            commit('setDiscoveredCookies',response.data.discovered_cookies)
            commit('setSelfDeclaredCookies',response.data.self_declared_cookies)
            // commit('abortAxiosRequest/setDestroyCancelLoadCookiesToken',{},{root: true});
        }
        return response;
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const createCookie = async ({ rootState, state , commit },payload) => {
    try {
        // if (rootState.abortAxiosRequest.cancelCreateCookieToken) {
        //     rootState.abortAxiosRequest.cancelCreateCookieToken.cancel('Request canceled');
        //     commit('abortAxiosRequest/setDestroyCancelCreateCookieToken',{},{root: true});
        //   }
        //   const cancelToken = axios.CancelToken.source();
        //   commit("abortAxiosRequest/setCancelCreateCookieToken", cancelToken, { root: true })
          const  response = await Api.post('cookieXray-create-cookie',payload,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            },
            // cancelToken: cancelToken.token,
          })
        if(response.status === 200){
            commit('setNewSelfDeclaredCookies',response.data.cookie)
            let responseMessage = {
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                status: response.status,
                message: 'Cookie created successfully!'
            }
            // commit('abortAxiosRequest/setDestroyCancelCreateCookieToken',{},{root: true});
            commit('swalAlert',responseMessage)
        }
        return response;
    } catch (error) {
        let responseError = {
            status: error.response.status,
            error: error.response.data.message         
        }
        commit('toastAlert',responseError)  
        throw error
    }
}

export const updateCookie = async ({ state , commit },payload) => {
    try {
        
          const  response = await Api.post('cookieXray-update-cookie',payload,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            }
          })
        if(response.status === 200){
            commit('setUpdateSelfDeclaredCookie',response.data.cookie)
            let responseMessage = {
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                status: response.status,
                message: 'Cookie Updated successfully!'
            }
            commit('swalAlert',responseMessage)
        }
        return response;
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const removeCookie = async ({ state , commit },payload) => {
    try {
        commit('removeSelfDeclaredCookie',payload.id)
        const response = await Api.post('remove-cookie',payload,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            }
          })
        if(response.status === 200){
            let responseError = {
                status: response.status,
                message: 'Cookie deleted successfully'
            }
            commit('toastAlert',responseError)
        }
        else{
            let responseError = {
                status: response.status,
                error: response.data.error
            }
            commit('toastAlert',responseError)
        }
    } catch (error) {
        console.log('2',error)
    }
}

export const getReport = async ({ rootState , state ,commit },payload) => {
    try {
        // if (rootState.abortAxiosRequest.cancelGetReportToken) {
        //     rootState.abortAxiosRequest.cancelGetReportToken.cancel('Request canceled');
        //     commit('abortAxiosRequest/setDestroyCancelGetReportToken',{},{root: true});
        //   }
        //   const cancelToken = axios.CancelToken.source();
        //   commit("abortAxiosRequest/setCancelGetReportToken", cancelToken, { root: true })
        const response = await Api.get(`reports/${payload}`,{
            headers: {
                Authorization: 'Bearer ' + state.userToken,
            },
            // cancelToken: cancelToken.token,
          })
        if(response){
            // commit('abortAxiosRequest/setDestroyCancelGetReportToken',{},{root: true});
            return response;
        }
    } catch (error) {
        console.log(error)
    }
}

export const addNewCategory = ({ commit, state }, [dialogueId,current_domain_id]) => {
    const newCategory = {
        // id:'',
        customize_title: `New Category`,
        customize_body: `Description Of New Category`,
        customize_checked: true,
        cookie_xray_dialogue_id:dialogueId,
        domain_id:current_domain_id
    };
    commit('setButtonIsDisabled', true);
    commit('addCategory', newCategory);
};


// export const fetchExistingCategoryTitles = async ({ commit, state }, dialogueId) => {
//     try {
//     commit('clearCategories');
//       const response = await Api.get(`customize-category/show/${dialogueId}`, {
//         headers: {
//           Authorization: `Bearer ${state.userToken}`,
//         },
//       });
  
//       const existingCategoryTitles = response.data;
//         existingCategoryTitles.forEach(category => {
//             commit('addCategory', category);
//         });
//       console.log(state.categories);
//     } catch (error) {
//       console.error('Error in Fetching', error);
//       throw error;
//     }
//   };
  
  //   await fetchExistingCategoryTitles({ commit, state }, dialogueProperties.id);

  //   const existingCategoryTitles = state.existingCategoryTitles;
//   export const saveCategories = async ({ state, commit, getters }) => {
//     try {
//       const dialogueProperties = getters['getDialogueProperties'];
//       const existingCategoryTitles = state.categories;
//       console.log(existingCategoryTitles, 'Existing');
  
//       if (state.categories.length === 0) {
//         console.log('Categories array is empty. API call skipped.');
//         return 'Categories array is empty. No API call made.';
//       }

//       const categoriesPayload = state.categories.map((category) => ({
//         id:category.id,
//         dialogue_id: dialogueProperties.id,
//         title: category.customize_title,
//         body: category.customize_body,
//         checked: false,
//       }));
  
//       const response = await Api.post('customize-category/add', { categories: categoriesPayload }, {
//         headers: {
//           Authorization: `Bearer ${state.userToken}`,
//         },
//       });
  
//       if (response.status === 200) {
//         let responseError = {
//           status: response.status,
//           error: response.data.message,
//         };
//         commit('toastAlert', responseError);
//         return response;
//       } else {
//         return 'Categories is not saved';
//       }
//     } catch (error) {
//       console.error('Failed to save categories:', error);
//       commit('setCategoriesError', error.message);
//       throw error;
//     }
//   };

  export const deleteCategory = async ({ commit, state }, { index, categoryId }) => {
    if(!categoryId){
        commit('deleteCategory', index); 
        commit('setButtonIsDisabled', true);
    }
    else{
    try {
      const response = await Api.delete(`customize-category/delete/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${state.userToken}`,
        },
      });
      if (response.status === 200) {
        commit('deleteCategory', index);
        commit('setButtonIsDisabled', true);
        // console.log('index:',index)
        // console.log(state.categories);
        return 'Category deleted successfully';
      } else {
        throw new Error('Failed to delete category');
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
}
};
// export const emailConsentLogs = async (payload) => {
//     try {
      
//         const response = await Api.get(`emailConsectLogs/${payload}`,{
//             headers: {
//                 Authorization: 'Bearer ' + state.userToken,
//             },
//           })
//         if(response){
//             return response;
//         }
//     } catch (error) {
//         console.log(error)
//     }
// }
