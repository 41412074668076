<template>
	<div class="domain-modal">
		<div class="modal-hol">
			<form>
				<div class="modal-header border-0 pb-0">
					<h2>Scanning Status</h2>
					<button type="button" class="close" @click="handleHideScan">+</button>
				</div>
				<div class="modal-body pb-0">
					<div class="table-responsive">
						<table id="datatable-responsive" cellpadding="0" cellspacing="0" width="100%" class="table m-0"
							style="border-spacing: 0 15px;
                             border-collapse: separate;">
							<tbody>
								<tr>
									<td class="td-bold pl-0 border-0 white-space w-25">Domain name:</td>
									<td class="border-0 td-color white-space w-75">{{ domain.name }}</td>
								</tr>
								<tr>
									<td class="td-bold pl-0 border-0 white-space w-25">Email:</td>
									<td class="td-color border-0 white-space w-75">
										{{
											domain && domain.cb_reports_receivers &&
											domain.cb_reports_receivers.receiver_email
										}}
									</td>
								</tr>

								<tr v-if="domain.last_scan_at != null">
									<td class="td-bold pl-0 border-0 white-space w-25"
										style="vertical-align: middle !important">
										Date:
									</td>
									<td class="border-0 td-color white-space w-75">
										{{
											date(domain.last_scan_at) + " " + time(domain.last_scan_at)
										}}&nbsp;
									</td>
								</tr>
								<tr v-if="domain.last_scan_at != null">
									<td class="td-bold pl-0 border-0 white-space w-25"
										style="vertical-align: middle !important">
										Next due date:
									</td>
									<td class="border-0 td-color white-space w-75">
										{{
											dateWithFrequency(
												domain.last_scan_at,
												domain.scan_frequency
											)
										}}
										<span>
											<button v-if="this.progress == 100"
												class="btn btn-md btn-primary box-shadow-none" type="button"
												ref="scan_now" @click="handleRole(domain.id)">Scan now
											</button>
											<button v-else disabled class="btn btn-md btn-primary box-shadow-none"
												type="button" ref="scan_now" @click="scanNow(domain.id)">Scan now
											</button>
										</span>
									</td>
								</tr>
								<tr>
									<td class="td-bold pl-0 border-0 white-space w-25">Status:</td>
									<td class="border-0 td-color w-75">
										<div class="progress" style="
												margin: 0px;
												height: 20px;
												background: rgb(208, 211, 214);
											">
											<div role="progressbar" aria-valuenow="75" aria-valuemin="0"
												aria-valuemax="100" class="
													progress-bar progress-bar-animated
												" :style="scan_progress" style="
													background-color: rgb(108, 192, 74);
													padding: 4px 0px;
												">{{ this.progress }}%</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import { mapState } from 'vuex';
export default {
	name: "ScanDomain",
	props: ['domain'],
	data() {
		return {
			progress: 0,
		};
	},
	computed: {
		...mapState('domain', ["scan_now_response", "activated_plan", "user", "assign_role"]),
		scan_progress: function () {
			this.progress =
				this.domain.last_scan_at != null &&
					this.domain.scan_done === 1 &&
					this.domain.generate_report === 0
					? 100
					: 70;
			return { width: this.progress + "%" };
		},
	},
	methods: {
		async handleRole(domain_id) {
			if (!this.user.isSuperAdmin && this.user.superAdminId !== null && this.assign_role === 'reader') {
				await this.handleHideScan();

				const result = await Swal.fire({
					title: "Unauthenticated",
					html: "Please Contact Your Admin",
					icon: "info",
					confirmButtonColor: "#3b6ef8",
					confirmButtonText: "Confirm",

				});
			} else {
				this.scanNow(domain_id);
			}
		},

		handleHideScan: function () {
			this.$emit("onHideScan");
		},
		date(value) {
			return moment(value).format("DD-MM-YYYY ");
		},
		time(value) {
			return moment(value).format("HH:mm:ss");
		},
		dateWithFrequency(value, frequency) {
			let date = new Date(value);
			switch (frequency) {
				case "only_once":
					date = "Only one time scan was allowed";
					break;
				case "monthly":
					date.setMonth(date.getMonth() + 1);
					break;
				case "3 months":
					date.setMonth(date.getMonth() + 3);
					break;
				case "6 months":
					date.setMonth(date.getMonth() + 6);
					break;
				case "yearly":
					date.setMonth(date.getMonth() + 12);
					break;
				default:
					break;
			}
			if (frequency !== "only_once") {
				date = moment(date).format("DD-MM-YYYY HH:mm:ss");
			}
			return date;
		},
		scanNow(domain_id) {
			if (this.activated_plan != 'pro' &&
				this.activated_plan != 'premium') {
				this.$store.commit("domain/setShowPricingPopup", true);
				return false;
			}
			this.$store.dispatch("domain/scanNow", domain_id).then((response) => {
				this.handleHideScan();
				Swal.fire({
					icon: response.data.type,
					html: response.data.message,
					heightAuto: false,
					showConfirmButton: true,
					width: 600,
					confirmButtonText: "OK",
					confirmButtonColor: "#6cc04a",
				});
			});
		},
	},
};
</script>

<style scoped>
.table td,
.table th {
	font-size: 14px !important;
	margin-bottom: 15px !important;
}

.table td.td-bold {
	font-weight: 600 !important;
}

.table td.td-color {
	background: #f7f7ff !important;
}
</style>