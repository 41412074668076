<template>
	<div>
		<div>
			<h3 class="text-blue mb-3">Cookie Consent Banner Script</h3>
			<p class="mb-2">Copy this script into your website's HTML header</p>
			<textarea
				class="scriptcode mb-0"
				placeholder="Describe yourself here..."
				id="WeeblyStep1"
				readonly><script data-key="{{ script && script.key }}" data-name="CookieXray" src="{{script_url}}" type="text/javascript"></script></textarea
			>
			<p class="font-italic mb-0">
				Note: Paste this before any other script in the HTML head tag
			</p>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step1')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<h3 class="text-blue mb-3">Cookie Policy Script</h3>
			<p class="mb-2">
				Copy code into your cookie policy URL to show default Cookie
				Policy.
			</p>
			<textarea
				class="scriptcode mb-3"
				placeholder="Describe yourself here..."
				id="WeeblyStep2"
				readonly><div id="seers-cookie-consent"></div></textarea>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step2')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<hr />
			<h3 class="text-blue my-5">
				Implementing the Seers Cookie consent dialogue (cookie consent banner) in Weebly:
			</h3>

			<details open>
				<summary>Step 1</summary>
				<div>
					<p>In the Admin card, select Settings then click on SEO at the left-hand side menu.</p>
				</div>
			</details>
			<div id="accordion" role="tablist">
				<details>
					<summary>Step 2</summary>
					<div>
	<ul class="pl-3"><li class="mb-1">
                  In the Header Code field, paste the Seers Cookie Consent dialogue script:
                </li>
				 <div class="script-hol" style="margin: 20px 0px; border: 1px solid rgb(59, 110, 248); padding: 18px; border-radius: 7px;"><p 
				  class="m-0" style="word-break: break-all; table-layout: fixed;">
                    &lt; script
                    data-key="xxxxxxxx-xxxxxxx-xxxxxxxxx-xxxxxxxxxx-xxxxxxxx"
                    data-name="CookieXray"
                    src="https://seersco.com/script/cb.js"
                    type="text/javascript"&gt;&lt; /script&gt;
                  </p></div></ul>
					</div>
				</details>

				<details>
					<summary>Step 3</summary>
					<div>
					<ul class="pl-3"><li class="mb-1"><p class="mb-0">
                    3. Replace the <span>"X"</span> with the key from the domain group where the domain is added. It is available from the
                    <a target="blank" href="/business/seers-cookie-consent" class="text-blue">
                      'Your scripts'
                    </a>
                    tab on your Cookie Consent account.
                  </p></li></ul>
					</div>
				</details>

				<details class="mb-5">
					<summary>Step 4</summary>
					<div>
				<ul class="pl-3"><li class="mb-1">When complete, click the Save and Publish buttons.</li></ul>
						<img
							class="img-fluid"
							src="@/assets/images/weebly-images/weebly-plugin.png"
						/>
					</div>
				</details>

			</div>
		</div>
		<div class="mt-5">
			<hr />
			<h3 class="text-blue my-5">
				Prior consent:
			</h3>
<p>
        To enable prior consent (block the cookies until the user has consented), you need to go to the
        <a target="blank" href="/cookie-consent-banner.html" class="text-blue">Seers Cookie Consent</a>
        on
        <a target="blank" href="http://www.consents.dev" class="text-blue">http://www.seersco.com.</a></p>
		<p class="text-dark">The steps are as follows:</p>
			<details open>
				<summary>Step 1</summary>
				<div>
					<ul class="pl-3"><li>
              Go to
              <a target="blank" href="/" class="text-blue">http://www.seersco.com</a></li></ul>
			  <img src="@/assets/images/weebly-images/fig-2-1.jpg" class="img-fluid">
				</div>
			</details>
			<div id="accordion" role="tablist">
				<details>
					<summary>Step 2</summary>
					<div>
<ul class="pl-3"><li><a target="blank" href="/login" class="text-blue">Log in </a> to your Seers Business Account
            </li></ul>
			 <img src="@/assets/images/weebly-images/fig-2-2.jpg" class="img-fluid">
					</div>
				</details>

				<details>
					<summary>Step 3</summary>
					<div>
			<ul class="pl-3"><li>
              Go Select Cookie Consent from the products icons or left navigation menu
            </li></ul>
			 <img src="@/assets/images/weebly-images/fig-2-3.png" class="img-fluid">
					</div>
				</details>

				<details class="mb-5">
					<summary>Step 4</summary>
					<div>
				<ul class="pl-3"><li class="mb-1">
              You will land into the cookie consent product page. Jump to "3.
              <a target="blank" href="/business/seers-cookie-consent" class="text-blue">
                Dialogue"</a>
              section from the left navigation and select <strong>“Consent Mode”</strong> tab. Select <strong>“Yes”</strong> in the
               <br>
              dropdown for <strong>“Apply Prior Consent”</strong>.
            </li></ul>
							 <img src="@/assets/images/weebly-images/fig-2-4.png" class="img-fluid">
					</div>
				</details>

			</div>
		</div>
		<div class="mt-5">
			<hr />
			<h3 class="text-blue my-5">
				Implementing the Cookie Declaration in Weebly:
			</h3>
<p>To show the Cookie Declaration on your website (on a new page or, e.g. as part of your existing privacy policy), do the following:</p>
			<details open>
				<summary>Step 1</summary>
				<div>
<ul class="pl-3"><li>
              Select the page where you would like to add the Cookie Declaration.
            </li></ul>
				</div>
			</details>
				<details>
					<summary>Step 2</summary>
					<div>
	<ul class="pl-3"><li>
              In the Admin card, select Build from the top menu, choose the
              Embed Code and drag it to where you want the Cookie Declaration to
              appear on the page.
            </li></ul>
					</div>
				</details>
	<details>
					<summary>Step 3</summary>
					<div>
	<ul class="pl-3"><li class="mb-1">
                 Click on the Embed Code and Edit Custom HTML, then paste the script tag into the field.
                </li>
				 <div class="script-hol" style="margin: 20px 0px; border: 1px solid rgb(59, 110, 248); padding: 18px; border-radius: 7px;"><p 
				  class="m-0" style="word-break: break-all; table-layout: fixed;">
            <pre>    <div id="seers-cookie-consent"> </div></pre>
                  </p></div></ul>
					</div>
				</details>
				<details>
					<summary>Step 4</summary>
					<div>
		<ul class="pl-3"><li>
              Replace the zeros with the key from the domain group where the
              domain is added. It is available from the 'Your scripts' tab on
              your Seers Cookie Consent account.
              
            </li></ul>
					</div>
				</details>

				<details class="mb-5">
					<summary>Step 5</summary>
					<div>
				<ul class="pl-3"><li class="mb-1">When the script tag is added, click Publish.</li></ul>

					</div>
				</details>

		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
	name: "Weebly",
	computed:{
		cmp_url(){
			return process.env.VUE_APP_BANNER_URL
		},
		script: function() {
			return this.$store.getters['getCode/getScript'];
		},
		script_url: function() {
			// return process.env.MIX_CB_HOST +'/'+ this.$store.getters.getUser.id +'/'+ this.$store.getters.getCurrentDomainId + "/cb.js";
			return this.cmp_url + '/banners/' + this.$store.state.domain.userId +'/'+ this.$store.getters['domain/getCurrentDomainId'] + "/cb.js";
		},
	},
	methods: {
		copyScript(script){
			if (script === "step1") {
				$("#WeeblyStep1").select();
			}

			if (script === "step2") {
				$("#WeeblyStep2").select();
			}
			
			Swal.fire({
				icon: "success",
				title: 'Code Copied',
				text: "Code copied successfully",
				timer: 4000,
				confirmButtonText: "Ok"
			});
			document.execCommand("copy");
		}
	},
};
</script>

<style scoped>
textarea:focus {
	outline: none !important;
}
::-webkit-scrollbar {
	display: none;
}
p span {
	font-size: 16px !important;
	font-weight: 500;
}
p {
	font-size: 15px !important;
	font-weight: 400;
}
.scriptcode {
	padding-inline-start: 10px;	
	padding-block-start: 0%;
	padding: auto;
}

#WeeblyStep2 {
	height: 50px;
	padding-left: 15%;
	padding-top: 1.3%;
}

</style>