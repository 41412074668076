export default {
  domains:[],
  totalDomains:0,
  show_add_domain : '',
  showAddDomainModel : false,
  currentActiveModal: false,
  user:null,
  userId:null,
  userEmail:null,
  userToken: localStorage.getItem('user_token') || null,
  current_domain_id: 0,
  current_domain: {},
  activetab:'',
  banner_type:'default',
  default: {
    name: "default",
    display_name: "Default",
    position: "seers-cmp-banner-bar",
    title_text_color: "#000000",
    body_text_color: "#000000",
    agree_text_color: "#FFFFFF",
    disagree_text_color: "#FFFFFF",
    preferences_text_color: "#3B6EF8",
    agree_btn_color: "#3B6EF8",
    disagree_btn_color: "#3B6EF8",
    preferences_btn_color: "#FFFFFF",
    logo_bg_color: "#FFFFFF",
    banner_bg_color: "#FFFFFF",
    font_style: "arial",
    font_size: "12",
    button_type: "cbtn_default",
    layout:"defaultLayout",
    is_active: 1,
    modes:1,
    dialogue_id: null,
    image: "default.svg",
    mainPositions: ["seers-cmp-banner-bar", "seers-cmp-middle-bar", "seers-cmp-center-bar",
    "seers-cmp-right-bar", "seers-cmp-left-bar", "seers-cmp-top-bar","seers-cmp-top-right-bar","seers-cmp-top-left-bar",
    ],
    settingPositions: ["seers-cmp-left-settings-bar", "seers-cmp-right-settings-bar"],
    customizeColors: [
    {"title_text_color": ["Title text color", "This text will be heading appearing in banner"]}, 
    {"body_text_color": ["This text will appear in body of banner", "This text will be heading appearing in banner"]}, 
    {"agree_text_color": ["\"I agree\" text color", ""]}, 
    {"disagree_text_color": ["\"I disagree\" text color", ""]}, 
    {"preferences_text_color": ["\"Preferences\" text color", ""]}, 
    {"agree_btn_color": ["\"I agree\" button color", ""]}, 
    {"disagree_btn_color": ["\"I disagree\" button color", ""]}, 
    {"preferences_btn_color": ["\"Preferences\" button color", ""]}, 
    {"logo_bg_color": ["\"Logo\" background color", ""]}, 
    {"banner_bg_color": ["\"Banner\" background color", ""]}
    ],
  },
  DefaultMainPosition: [
    {
      postionCategory: "Banner",
      activeImage: require('@/assets/images/Banner-styles/BannerActive.svg'),
      notActiveImage: require('@/assets/images/Banner-styles/BannerNotActive.svg'),
      id: 1,
      children: [
        {
          id: 1,
          name: "top",
          activeImage: "BannerTopActiveBlue.svg",
          notActiveImage:
            "BannerTopNotActive.svg",
        },
        {
          id: 2,
          name: "bottom",
          activeImage:
            "BannerBottomActiveBlue.svg",
          notActiveImage:
            "BannerBottomNotActive.svg",
        },
      ],
    },
    {
      postionCategory: "Modal",
      activeImage: require('@/assets/images/Banner-styles/ModalActive.svg'),
      notActiveImage: require('@/assets/images/Banner-styles/ModalNotActive.svg'),
      id: 2,
      children: [
        {
          id: 1,
          name: "modal",
          activeImage: "ModalActive.svg",
          notActiveImage: "ModalNotActive.svg",
        },
      ],
    },
    {
      postionCategory: "Tooltip",
      activeImage: require('@/assets/images/Banner-styles/tooltipActive.svg'),
      notActiveImage: require('@/assets/images/Banner-styles/tooltipNotActive.svg'),
      id: 3,
      children: [
        {
          id: 1,
          name: "top left",
          activeImage: "TooltipTopLeftActive.svg",
          notActiveImage:
            "TooltipTopLeftNotActive.svg",
        },
        {
          id: 2,
          name: "bottom left",
          activeImage:
            "TooltipBottomLeftActive.svg",
          notActiveImage:
            "TooltipBottomLeftNotActive.svg",
        },
        {
          id: 3,
          name: "top right",
          activeImage:
            "TooltipTopRightActive.svg",
          notActiveImage:
            "TooltipTopRightNotActive.svg",
        },
        {
          id: 4,
          name: "bottom right",
          activeImage:
            "TooltipBottomRightActive.svg",
          notActiveImage:
            "TooltipBottomRightNotActive.svg",
        },
      ],
    },
  ],
  banners: {
    default: {
      name: "default",
      display_name: "Default",
      position: "seers-cmp-banner-bar",
      title_text_color: "#000000",
      body_text_color: "#000000",
      agree_text_color: "#FFFFFF",
      disagree_text_color: "#FFFFFF",
      preferences_text_color: "#3B6EF8",
      agree_btn_color: "#3B6EF8",
      disagree_btn_color: "#3B6EF8",
      preferences_btn_color: "#FFFFFF",
      logo_bg_color: "#FFFFFF",
      banner_bg_color: "#FFFFFF",
      font_style: "arial",
      font_size: "12",
      button_type: "cbtn_default",
      layout:"defaultLayout",
      is_active: 1,
      modes:1,
      dialogue_id: null,
      image: "default.svg",
      mainPositions: [
        "seers-cmp-banner-bar",
        "seers-cmp-middle-bar",
        // "seers-cmp-center-bar",
        "seers-cmp-right-bar",
        "seers-cmp-left-bar",
        "seers-cmp-top-right-bar",
        "seers-cmp-top-left-bar",
        "seers-cmp-top-bar",
      ],
      settingPositions: [
        "seers-cmp-left-settings-bar",
        "seers-cmp-right-settings-bar",
      ],
      customizeColors: [
        {
          title_text_color: [
            "Title text color",
           "This text will be heading appearing in banner",
          ],
        },
        {
          body_text_color: [
            "This text will appear in body of banner",
            "This text will be heading appearing in banner",
          ],
        },
        { agree_text_color: ["\"I agree\" text color", ""] },
        { disagree_text_color: ["\"I disagree\" text color", ""] },
        { preferences_text_color: ["\"Preferences\" text color", ""] },
        { agree_btn_color: ["\"I agree\" button color", ""] },
        { disagree_btn_color: ["\"I disagree\" button color", ""] },
        { preferences_btn_color: ["\"Preferences\" button color", ""] },
        { logo_bg_color: ["\"Logo\" background color", ""] },
        { banner_bg_color: ["\"Banner\" background color", ""] },
      ],
    },
    universal: {
      name: "universal",
      display_name: "Universal",
      position: "seers-cmp-banner-center",
      title_text_color: "#000000",
      body_text_color: "#000000",
      agree_text_color: "#FFFFFF",
      disagree_text_color: "#FFFFFF",
      preferences_text_color: "#3B6EF8",
      agree_btn_color: "#3B6EF8",
      disagree_btn_color: "#3B6EF8",
      preferences_btn_color: "#FFFFFF",
      logo_bg_color: "#FFFFFF",
      banner_bg_color: "#FFFFFF",
      font_style: "arial",
      font_size: "12",
      button_type: "cbtn_default",
      layout:"defaultLayout",
      is_active: 1,
      modes:1,
      dialogue_id: null,
      image: "default.svg",
      mainPositions: [
        "seers-cmp-banner-center",
      ],
      settingPositions: [
        "seers-cmp-left-settings-bar",
        "seers-cmp-right-settings-bar",
      ],
      customizeColors: [
        {
          title_text_color: [
            "Title text color",
           "This text will be heading appearing in banner",
          ],
        },
        {
          body_text_color: [
            "This text will appear in body of banner",
            "This text will be heading appearing in banner",
          ],
        },
        { agree_text_color: ["\"I agree\" text color", ""] },
        { disagree_text_color: ["\"I disagree\" text color", ""] },
        { preferences_text_color: ["\"Preferences\" text color", ""] },
        { agree_btn_color: ["\"I agree\" button color", ""] },
        { disagree_btn_color: ["\"I disagree\" button color", ""] },
        { preferences_btn_color: ["\"Preferences\" button color", ""] },
        { logo_bg_color: ["\"Logo\" background color", ""] },
        { banner_bg_color: ["\"Banner\" background color", ""] },
      ],
    },
    google_banner: {
      name: "google_banner",
      display_name: "Centralised Banner",
      position: "seers-cx-center",
      title_text_color: "#000000",
      body_text_color: "#000000",
      agree_text_color: "#FFFFFF",
      disagree_text_color: "#FFFFFF",
      preferences_text_color: "#000000",
      agree_btn_color: "#3B6EF8",
      disagree_btn_color: "#3B6EF8",
      preferences_btn_color: "#3B6EF8",
      logo_bg_color: "#FFFFFF",
      banner_bg_color: "#FFFFFF",
      font_style: "arial",
      font_size: "12",
      button_type: "cbtn_default",
      layout:"googleLayout",
      is_active: 1,
      dialogue_id: null,
      image: "google_banner.svg",
      mainPositions: ["seers-cx-center"],
      settingPositions: [
        "seers-cmp-left-settings-bar",
        "seers-cmp-right-settings-bar",
      ],
      customizeColors: [
        {
          title_text_color: [
            "Title text color",
            "This text will be heading appearing in banner",
          ],
        },
        {
          body_text_color: [
            "This text will appear in body of banner",
            "This text will be heading appearing in banner",
          ],
        },
        { agree_text_color: ["\"I agree\" text color", ""] },
        { disagree_text_color: ["\"I disagree\" text color", ""] },
        { preferences_text_color: ["\"Preferences\" text color", ""] },
        { agree_btn_color: ["\"I agree\" button color", ""] },
        { disagree_btn_color: ["\"I disagree\" button color", ""] },
        { preferences_btn_color: ["\"Preferences\" button color", ""] },
        { logo_bg_color: ["\"Logo\" background color", ""] },
        { banner_bg_color: ["\"Banner\" background color", ""] },
      ],
    },
    iab: {
      name: "iab",
      display_name: "IAB Banner",
      position: "seers-cx-iab-center",
      title_text_color: "#000000",
      body_text_color: "#000000",
      agree_text_color: "#FFFFFF",
      disagree_text_color: "#FFFFFF",
      preferences_text_color: "#000000",
      agree_btn_color: "#3B6EF8",
      disagree_btn_color: "#3B6EF8",
      preferences_btn_color: "#3B6EF8",
      logo_bg_color: "#FFFFFF",
      banner_bg_color: "#FFFFFF",
      font_style: "arial",
      font_size: "12",
      button_type: "cbtn_default",
      is_active: 1,
      dialogue_id: null,
      image: "iab.svg",
      mainPositions: ["seers-cx-iab-center"],
      settingPositions: [],
      customizeColors: [
        {
          title_text_color: [
            "Title text color",
            "This text will be heading appearing in banner",
          ],
        },
        {
          body_text_color: [
            "This text will appear in body of banner",
            "This text will be heading appearing in banner",
          ],
        },
        { agree_text_color: ["\"I agree\" text color", ""] },
        { disagree_text_color: ["\"I disagree\" text color", ""] },
        { preferences_text_color: ["\"Preferences\" text color", ""] },
        { agree_btn_color: ["\"I agree\" button color", ""] },
        { disagree_btn_color: ["\"I disagree\" button color", ""] },
        // {"preferences_btn_color": ["\"Preferences\" button color", ""]},
        // {"logo_bg_color": ["\"Logo\" background color", ""]},
        // {"banner_bg_color": ["\"Banner\" background color", ""]}
      ],
    },
    side_one_ccpa: {
      name: "side_one_ccpa",
      display_name: "Left Open",
      position: "seers_cc_center",
      title_text_color: "#000000",
      body_text_color: "#000000",
      agree_text_color: "#FFFFFF",
      disagree_text_color: "#FFFFFF",
      preferences_text_color: "#FFFFFF",
      agree_btn_color: "#3b6ef8",
      disagree_btn_color: "#009900",
      preferences_btn_color: "#41A2DB",
      logo_bg_color: "#FFFFFF",
      banner_bg_color: "#FFFFFF",
      font_style: "arial",
      font_size: "14",
      button_type: "cbtn_default",
      layout:"ccpaLayout",
      is_active: 1,
      dialogue_id: null,
      image: "side_one.svg",
      mainPositions: [
        "seers_cc_center",
        "seers_cc_top_left",
        "seers_cc_bottom",
        "seers_cc_top_right",
        "seers_cc_bottom_right",
      ],
      settingPositions: [],
      customizeColors: [
        {
          title_text_color: [
            "Title text color",
            "This text will be heading appearing in banner",
          ],
        },
        {
          body_text_color: [
            "This text will appear in body of banner",
            "This text will be heading appearing in banner",
          ],
        },
        { agree_text_color: ["\"I agree\" text color", ""] },
        // {"disagree_text_color": ["\"I disagree\" text color", ""]},
        { preferences_text_color: ["\"Preferences\" text color", ""] },
        { agree_btn_color: ["\"I agree\" button color", ""] },
        // {"disagree_btn_color": ["\"I disagree\" button color", ""]},
        // {"preferences_btn_color": ["\"Preferences\" button color", ""]},
        { logo_bg_color: ["\"Logo\" background color", ""] },
        { banner_bg_color: ["\"Banner\" background color", ""] },
      ],
    },
    exerto: {
      name: "exerto",
      display_name: "Exerto",
      position: "seers_cc_center",
      title_text_color: "#000000",
      body_text_color: "#000000",
      agree_text_color: "#FFFFFF",
      disagree_text_color: "#FFFFFF",
      preferences_text_color: "#FFFFFF",
      agree_btn_color: "#3b6ef8",
      disagree_btn_color: "#009900",
      preferences_btn_color: "#41A2DB",
      logo_bg_color: "#FFFFFF",
      banner_bg_color: "#FFFFFF",
      font_style: "arial",
      font_size: "14",
      button_type: "cbtn_default",
      layout:"exertoLayout",
      is_active: 1,
      modes: 1,
      dialogue_id: null,
      image: "side_one.svg",
      mainPositions: [
        "seers_cc_center",
        "seers_cc_top_left",
        "seers_cc_bottom",
        "seers_cc_top_right",
        "seers_cc_bottom_right",
      ],
      // mainPositions: [
      //   "seers_cc_bottom",
      //   "seers_cc_bottom_right",
      //   "seers_cc_bottom_center",
      // ],
      settingPositions: [],
      customizeColors: [
        {
          title_text_color: [
            "Title text color",
            "This text will be heading appearing in banner",
          ],
        },
        {
          body_text_color: [
            "This text will appear in body of banner",
            "This text will be heading appearing in banner",
          ],
        },
        { agree_text_color: ["\"I agree\" text color", ""] },
        // {"disagree_text_color": ["\"I disagree\" text color", ""]},
        { preferences_text_color: ["\"Preferences\" text color", ""] },
        { agree_btn_color: ["\"I agree\" button color", ""] },
        // {"disagree_btn_color": ["\"I disagree\" button color", ""]},
        // {"preferences_btn_color": ["\"Preferences\" button color", ""]},
        { logo_bg_color: ["\"Logo\" background color", ""] },
        { banner_bg_color: ["\"Banner\" background color", ""] },
      ],
    },
  },
  language: {
    name: "English UK",
    btn_disagree_title: "Disable All",
    btn_agree_title: "Allow All",
    btn_preference_title: "Settings",
    title: "We use cookies",
    body: "We use cookies to personalize content and ads, to provide social media features and to analyze our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you have provided to them or that they have collected from your use of their services. You consent to our cookies if you continue to use our website.",
    cookies_body:
    "Cookies are to ensure website user gets best experience. Necessary cookies can be stored in the users devices. We need your permission for non essential cookies. Learn more about how we process personal data in our Privacy Policy?",
    preference_title: "Preferences",
    preference_body:
    "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.",
    statistics_title: "Statistics",
    statistics_body:
    "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.",
    marketing_title: "Marketing",
    marketing_body:
    "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third-party advertisers.",
    unclassified_title: "Unclassified",
    unclassified_body:
    "Unclassified cookies are cookies that we are in the process of classifying, together with the providers of individual cookies.",
    cookies_declaration: "Cookie Declaration",
    necessory_title: "Necessary",
    necessory_body:
    "Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.",
    col_name: "Name",
    col_provider: "Provider",
    col_purpose: "Purpose",
    col_expiry: "Expiry",
    col_type: "Type",
    year: "year",
    years: "years",
    day: "day",
    days: "days",
    left: "left",
    expired: "Expired",
    link_more: "...",
    you_can_read_our_cookie_policy_here:
    "You can read our cookie policy here",
    about_cookies: "About Cookies",
    email_section_title: "To request that your personal information is not shared for these purposes outside of the cookie context, enter your email address below. Additionally, you will not need to opt out of targeted advertising again if you visit from a different device or browser and are logged in.",
    cookie_declaration_powered_by: "cookie declaration powered by",
    country_code: "GB",
  },
  features: {
      domain_limit: null,
      scan_report_varient: null,
      banner_visibility_duration: null,
      logo_branding: null,
      scan_frequency: null,
      scan_pages_limit: null,
      banner_design_presets_limit: null,
      banner_layouts_limit: null,
      cookie_declaration_table: null,
      territory_preferences: null,
      live_support: null,
      multilingual_banner: null,
      banner_content: null,
      banner_customization: null,
      cookie_policy: null,
      cookie_policy_design: null,
      prior_consent: null,
      consent_log: null,
      script_manger: null,
      consent_log_limit: null,
      ccpa_banner: null,
      subdomain: null,
      integrations: null,
      cookie_bank: null,
      plan_name: "",
      allow_reject: null,
      iab: null,
      powered_by: null,
      save_consent_logs: null,
      child_privacy: null,
      wcag: null,
      auto_blocking: null,
      drill_down:null
  },
  currency: "GBP",
  product:null,
  pricing_features: [],
  activated_plan: "",
  theLoader:false,
  inLoader:false,
  trackingLoader:false,
  reportLoader:false,
  toastAlert:false,
  swalAlert:false,
  show_pricing_popup: false,
  scan_now_response: "",
  show_dialogue_preview: false,
  preview_btn: "Preview",
  show_searchBar: false,
  is_one_step_banner: false,
  current_banner: {},
  
  custom_banner: {},
  default_banner: {},
  exerto_banner: {},
  universal_banner: {},
  dialogue_properties: {},
  // language: {},
  dialogue_languages: [],
  current_language: {
      name: "English UK",
      btn_disagree_title: "Disable All",
      btn_agree_title: "Allow All",
      btn_preference_title: "Settings",
      title: "This website uses cookies",
      body: "We use cookies to enhance your browsing experience, analyse site traffic, and some targeted advertisements. By \"Accepting all\", you consent to all cookies in accordance with our cookie policy.",
      second_layout_description: "We Use cookies",
      // body: 'We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you have provided to them or that they have collected from your use of their services. You consent to our cookies if you continue to use our website.',
      // cookies_body: 'Cookies are small text files that can be used by websites to make the experience of the user more efficient. The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies, we need your permission. This site uses different types of cookies. Some cookies are placed by third party services that appear on our pages. You can at any time, change or withdraw your consent from the Cookie Declaration on our website. Learn more about who we are, how you can contact us, and how we process personal data in our Privacy Policy?',
      cookies_body:
        "Cookies are to ensure website user gets best experience. Necessary cookies can be stored in the users devices. We need your permission for non essential cookies. Learn more about how we process personal data in our Privacy Policy?",
      preference_title: "Preferences",
      preference_body:
        "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.",
      statistics_title: "Statistics",
      statistics_body:
        "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.",
      marketing_title: "Marketing",
      marketing_body:
        "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third-party advertisers.",
      unclassified_title: "Unclassified",
      unclassified_body:
        "Unclassified cookies are cookies that we are in the process of classifying, together with the providers of individual cookies.",
      cookies_declaration: "Cookie Declaration",
      necessory_title: "Necessary",
      necessory_body: "",
      col_name: "Name",
      col_provider: "Provider",
      col_purpose: "Purpose",
      col_expiry: "Expiry",
      col_type: "Type",
      year: "year",
      years: "years",
      day: "day",
      days: "days",
      left: "left",
      expired: "Expired",
      you_can_read_our_cookie_policy_here:
        "You can read our cookie policy here",
      about_cookies: "About Cookies",
      cookie_declaration_powered_by: "cookie declaration powered by",
      country_code: "GB",
  },
  ManuallyBlockScripts: {
    total:0,
    data:[],
    current_page: 1,
    per_page: 2,
  },
  scriptCategories: {},
  scriptStart: 1,
  show_add_manually_script:false,
  show_edit_manually_script:false,
  Auto_block_script:'',
  discovered_cookies:[],
  self_declared_cookies:[],
  //dial

  consentlog_limit_reached:1,
  apply_google_consent: false,
  apply_facebook_consent:false,
  preDefinedLanguages:{},
  button_is_disabled:false,
  all_toggle_btn:true,
  apply_wcag_banner: false,
  totalCookies : 0,
 uservar : 39892,
 categories:[],
 script_customize_categories:[],
 mode:'',
 hide_font:false,
 assign_role:''
} 