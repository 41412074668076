
<template>
    <div class="report-hol" style="margin-top: 50px;">
        <div v-if="this.loading" class="before scanning-block text-center py-5">
            <PulseLoader :loading="true" style="display:inline-block" />
        </div>
        <div class="before scanning-block text-center py-5" v-if="!loaded && !this.loading">
            <h4 class="mb-3">
                Your website scanning is in process
            </h4>
            <p>
                When the scan report is done, you will be informed by email.
            </p>
        </div>
        <div class="container forfont" v-if="user && loaded">
        <div class="row">
            <div class="col-md-12"> 
                <h4 style="margin:50px 0px 20px 0px;color: #3B6EF8;"><strong>Website compliance audit report for
                        {{ user.company ? user.company : domainReports[0].title }}
                    </strong>
                </h4>
            </div>
        </div>
        <a
            class="btn btn-primary ml-auto my-3" style="width:10%;"
            @click="downloadCSV"
            ><i  class="fa fa-download mr-2"></i>Cookie Table
          </a>
        <div class="row" style="margin: 0 0 20px 0; background:#FBFBFB; padding: 15px 10px 5px 10px; border-radius:
                7px; display: -webkit-box;
                                                display: -moz-box;
                                                display: -ms-flexbox;
                                                display: -webkit-flex;
                                                display: flex;
                                                flex-flow: row wrap;
                                                -ms-align-items: center;
                                                align-items: center;
                                                justify-content: flex-start;border: 1px solid #E6E6E2;">
            <div class=" col-sm-12 col-md-9">
                <p style="font-size: 14px; margin: 0 0 10px 0;">This report is based on a scan of your website
                    as at  {{ formatDate(domainReports[0].created_at) }}
                    <br> It shows your level of Compliance Risk based on the types of cookies found on your
                    website during the scan.
                </p>
            </div>
            <div class=" col-sm-12 col-md-12">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <strong
                    style="padding: 10px 10px 10px 0px; border-bottom: 1px solid #C1C1C1; color: #292929; font-size: 17px; width: 100%; display: block;margin-bottom: 20px;">Summary
                </strong>
            </div>
        </div>
        <div class="row" style=" display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -webkit-flex;
                                        display: flex;
                                        flex-flow: row wrap;
                                        -ms-align-items: flex-start;
                                        align-items: flex-start;
                                        justify-content: flex-start;">

            <div class="col-xs-12 col-sm-12 col-md-6">
                <div
                    style="border-radius: 7px; border:1px solid #C1C1C1; background: #FBFBFB; margin: 7.5px 0;padding: 10px 0px 20px;">
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr>
                                <td width="50%" align="left" valign="middle" nowrap="nowrap"
                                    style="padding:2px  2px 2px 20px; font-size: 14px; color: #7E7E7E;">
                                    <span style="font-size: 14px !important; color: #7E7E7E !important;">Company name:
                                    </span>
                                </td>
                                <td align="left" valign="middle" width="50%"
                                    style="padding:2px 2px 2px 20px; font-size: 14px; color: #292929;">
                                    <span style="font-size: 14px !important; color: #292929 !important;"><strong>{{ user.company ? user.company : '______________' }}</strong>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td width="50%" align="left" valign="middle" nowrap="nowrap"
                                    style="padding:2px  2px 2px 20px; font-size: 14px; color: #7E7E7E;">
                                    <span style="font-size: 14px !important; color: #7E7E7E !important;">URL:
                                    </span>
                                </td>
                                <td align="left" valign="middle" width="50%"
                                    style="padding:2px 2px 2px 20px; font-size: 14px; color: #292929;">
                                    <span style="font-size: 14px !important; color: #292929 !important;"><strong>{{ domainReports[0].title }}</strong>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td width="50%" align="left" valign="middle" nowrap="nowrap"
                                    style="padding:2px  2px 2px 20px; font-size: 14px; color: #7E7E7E;">
                                    <span style="font-size: 14px !important; color: #7E7E7E !important;">Date and Time:
                                    </span>
                                </td>
                                <td align="left" valign="middle" width="50%"
                                    style="padding:2px 2px 2px 20px; font-size: 14px; color: #292929;">
                                    <span style="font-size: 14px !important; color: #292929 !important;"><strong>{{ formatDate(domainReports[0].created_at) }}</strong>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td width="50%" align="left" valign="middle" nowrap="nowrap"
                                    style="padding:2px  2px 2px 20px; font-size: 14px; color: #7E7E7E;">
                                    <span style="font-size: 14px !important; color: #7E7E7E !important;">Server Location:
                                    </span>
                                </td>
                                <td align="left" valign="middle" width="50%"
                                    style="padding:2px 2px 2px 20px; font-size: 14px; color: #292929;">
                                    <span style="font-size: 14px !important; color: #292929 !important;"><strong>{{ domain_country ?? 'Unknown Location' }}</strong>
                                    </span>
                                </td>
                            </tr>
                            <!-- <tr>
                                            <td width="50%" align="left" valign="middle" nowrap="nowrap" style="padding:2px  2px 2px 20px; font-size: 14px; color: #7E7E7E;">
                                        <span style="font-size: 14px !important; color: #7E7E7E !important;">No. of pages scanned:
                                        </span>
                                            </td>
                                            <td align="left" valign="middle" width="50%" style="padding:2px 2px 2px 20px; font-size: 14px; color: #292929;">
                                        <span><strong> 62</strong>
                                        </span>
                                            </td>
                                        </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
                <div
                    style="border-radius: 7px; border:1px solid #C1C1C1; background: #FBFBFB;  margin: 7.5px 0px 40px 0px;padding: 10px 0px;">
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                        <tr>
                            <td width="50%" align="left" valign="middle" nowrap="nowrap"
                                style="padding:2px  2px 2px 20px; font-size: 14px; color: #7E7E7E;">
                                <span style="font-size: 14px !important; color: #7E7E7E !important;">Total Partners found:
                                </span>
                            </td>
                            <td align="left" valign="middle" width="50%"
                                style="padding:2px 2px 2px 20px; font-size: 14px; color: #292929;">
                                <span style="font-size: 14px !important; color: #292929 !important;"><strong>{{ totalPartnerFound }}</strong></span>
                            </td>
                        </tr>
                        <tr>
                            <td width="50%" align="left" valign="middle" nowrap="nowrap"
                                style="padding:2px  2px 2px 20px; font-size: 14px; color: #7E7E7E;">
                                <span style="font-size: 14px !important; color: #7E7E7E !important;">Total cookies found:
                                </span>
                            </td>
                            <td align="left" valign="middle" width="50%"
                                style="padding:2px 2px 2px 20px; font-size: 14px; color: #292929;">
                                <span style="font-size: 14px !important; color: #292929 !important;"><strong>{{ totalCookies }}</strong></span>
                            </td>
                        </tr>
                        <tr>
                            <td width="50%" align="left" valign="middle" nowrap="nowrap"
                                style="padding:2px  2px 2px 20px; font-size: 14px; color: #7E7E7E;">
                                <span style="font-size: 14px !important; color: #7E7E7E !important;">New Cookies Found:
                                </span>
                            </td>
                            <td align="left" valign="middle" width="50%"
                                style="padding:2px 2px 2px 20px; font-size: 14px; color: #292929;">
                                <span style="font-size: 14px !important; color: #292929 !important;"><strong>{{ missingCookieCount }}</strong></span>
                            </td>
                        </tr>
                        <tr>
                            <td width="50%" align="left" valign="middle" nowrap="nowrap"
                                style="padding:2px  2px 2px 20px; font-size: 14px; color: #7E7E7E;">
                                <span style="font-size: 14px !important; color: #7E7E7E !important;">First party cookies:
                                </span>
                            </td>
                            <td align="left" valign="middle" width="50%"
                                style="padding:2px 2px 2px 20px; font-size: 14px; color: #292929;">
                                <span style="font-size: 14px !important; color: #292929 !important;"><strong>{{ firstParty }} </strong></span>
                            </td>
                        </tr>
                        <tr>
                            <td width="50%" align="left" valign="middle" nowrap="nowrap"
                                style="padding:2px  2px 2px 20px; font-size: 14px; color: #7E7E7E;">
                                <span style="font-size: 14px !important; color: #7E7E7E !important;">Third-party cookies:
                                </span>
                            </td>
                            <td align="left" valign="middle" width="50%"
                                style="padding:2px 2px 2px 20px; font-size: 14px; color: #292929;">
                                <span style="font-size: 14px !important; color: #292929 !important;"><strong>{{ thirdParty }}</strong>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
        
        
        <div class="row" style=" display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -webkit-flex;
                                        display: flex;
                                        flex-flow: row wrap;
                                        -ms-align-items: flex-start;
                                        align-items: flex-start;
                                        justify-content: flex-start;margin-bottom:30px;">
            <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <strong
                            style="padding: 10px 10px 10px 0px; border-bottom: 1px solid #C1C1C1; color: #292929; font-size: 17px; width: 100%; display: block; margin: 0 0 30px 0;">Sensitivity
                            Analysis
                        </strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="risk-hol"
                            style="display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -webkit-flex;
                                        display: flex;
                                        -ms-align-items: center;
                                        align-items: center; margin: 0 0 10px 0; border:1px solid #CBCBCB; border-radius: 7px;  padding: 5px;">
                            <div
                                style="padding:0px 5px; margin: 0 10px 0 0; width: 40px; height: 40px; font-size:14px; line-height:40px; border-radius: 7px; background: #FF736B; color: #292929; text-align: center;">
                                {{ highRisk }}%</div>
                            <div style="padding:2px;">
                                <p style="margin:0; font-size: 14px; color: #292929;"><strong>Highly Sensitive
                                        Cookies</strong>
                                </p>
                            </div>
                        </div>
                        <div class="risk-desc-hol">
                            <p style="margin:0 0 20px 0; font-size: 12px; color: #7E7E7E;">Highly sensitive
                                cookies: These consist of both first-party and third-party cookies that track
                                and collect the
                                visitor's information, including sensitive personal information.
                            </p>
                        </div>
                        <div class="risk-hol"
                            style="display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -webkit-flex;
                                        display: flex;
                                        -ms-align-items: center;
                                        align-items: center; margin: 0 0 10px 0; border:1px solid #CBCBCB; border-radius: 7px;  padding: 5px;">
                            <div
                                style="padding:0px 5px; margin: 0 10px 0 0; width: 40px; height: 40px; font-size:14px; line-height:40px; border-radius: 7px; background: #FFC107; color: #292929; text-align:center;">
                                {{ mediumRisk }}%</div>
                            <div style="padding:2px;">
                                <p style="margin:0; font-size: 14px; color: #292929;"><strong>Moderately
                                        Sensitive Cookies</strong>
                                </p>
                            </div>
                        </div>
                        <div class="risk-desc-hol">
                            <p style="margin:0 0 20px 0; font-size: 12px; color: #7E7E7E;">Moderately sensitive
                                cookies: These consist of both first-party and third-party cookies that track
                                and
                                collect the visitors information but does track sensitive personal information.
                            </p>
                        </div>
                        <div class="risk-hol"
                            style="display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -webkit-flex;
                                        display: flex;
                                        -ms-align-items: center;
                                        align-items: center; margin: 0 0 10px 0; border:1px solid #CBCBCB; border-radius: 7px;  padding: 5px;">
                            <div
                                style="padding:0px 5px; margin: 0 10px 0 0; width: 40px; height: 40px; font-size:14px; line-height:40px; border-radius: 7px; background: #8CEC8C; color: #292929; text-align:center;">
                                {{ lowRisk }}%</div>
                            <div style="padding:2px;">
                                <p style="margin:0; font-size: 14px; color: #292929;"><strong>Least Sensitive
                                        Cookies</strong>
                                </p>
                            </div>
                        </div>
                        <div class="risk-desc-hol">
                            <p style="margin:0 0 20px 0; font-size: 12px; color: #7E7E7E;">Least sensitive
                                cookies: These cookies are only used for the basic functionality of the website
                                and third-party integrations, and do not track the web activity and personal
                                information of website visitors.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <strong
                            style="padding: 10px 10px 10px 0px; border-bottom: 1px solid #C1C1C1; color: #292929; font-size: 17px; width: 100%; display: block; margin: 0 0 30px 0;">Cookie
                            Breakdown Based on Source
                        </strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="risk-hol"
                            style="display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -webkit-flex;
                                        display: flex;
                                        -ms-align-items: center;
                                        align-items: center; margin: 0 0 15px 0; border:1px solid #CBCBCB; border-radius: 7px;  padding: 5px;">
                            <div
                                style="padding:0px 5px; margin: 0 10px 0 0; width: 70px; height: 40px; font-size:14px; line-height:40px; border-radius: 7px; background: #8CEC8C; color: #292929; text-align: center;">
                                {{ firstPartyCookies.toFixed(2) }}%</div>
                            <div style="padding:2px;">
                                <p style="margin:0; font-size: 14px; color: #292929;"><strong>First Party
                                        Cookies</strong>
                                </p>
                            </div>
                        </div>
                        <div class="risk-hol"
                            style="display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -webkit-flex;
                                        display: flex;
                                        -ms-align-items: center;
                                        align-items: center; margin: 0 0 10px 0; border:1px solid #CBCBCB; border-radius: 7px;  padding: 5px;">
                            <div
                                style="padding:0px 5px; margin: 0 10px 0 0; width: 70px; height: 40px; font-size:14px; line-height:40px; border-radius: 7px; background: #FF736B; color: #292929; text-align: center;">
                                {{ thirdPartyCookies.toFixed(2) }}%</div>
                            <div style="padding:2px;">
                                <p style="margin:0; font-size: 14px; color: #292929;"><strong>Third-Party
                                        Cookies</strong>
                                </p>
                            </div>
                        </div>
                        <div class="risk-desc-hol">
                            <p style="margin:0 0 20px 0; font-size: 12px; color: #7E7E7E;">Third-party cookies
                                can put you at risk as they result in unauthorised tracking of your website's
                                visitors without for various purposes without consent which is illegal under
                                GDPR and ePrivacy Directive. Not all these purposes are illicit, but you need to
                                detect and block unauthorised third-party cookies, and even for authorised
                                third-parties you need to obtain the consent of visitors under GDPR and provide
                                an opt-out button under CCPA.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <strong
                            style="padding: 10px 10px 10px 0px; border-bottom: 1px solid #C1C1C1; color: #292929; font-size: 17px; width: 100%; display: block; margin: 0 0 25px 0;">Cookie
                            breakdown based on functionality
                        </strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" style="display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -webkit-flex;
                                        display: flex;
                                        flex-flow: row wrap;
                                        -ms-align-items: flex-start;
                                        align-items: flex-start;
                                        justify-content: flex-start; justify-content: flex-start;
                                            margin: 0 -15px 40px;">
                        <div class="risk-hol"
                            style="display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -webkit-flex;
                                        display: flex;
                                        -ms-align-items: center;
                                        align-items: center; flex: auto; margin: 7.5px 15px; border:1px solid #CBCBCB; border-radius: 7px;  padding: 5px;">
                            <div
                                style="padding:0px 5px; margin: 0 10px 0 0; width: 40px; height: 40px; font-size:14px; line-height:40px; border-radius: 7px; background: #8CEC8C; color: #fff; text-align: center;">
                                {{ report.necessary.length }}</div>
                            <div style="padding:2px;">
                                <p style="margin:0; font-size: 14px; color: #292929;"><strong>Necessary</strong>
                                </p>
                            </div>
                        </div>
                        <div class="risk-hol"
                            style="display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -webkit-flex;
                                        display: flex;
                                        -ms-align-items: center;
                                        align-items: center; flex: auto; margin: 7.5px 15px; border:1px solid #CBCBCB; border-radius: 7px;  padding: 5px;">
                            <div
                                style="padding:0px 5px; margin: 0 10px 0 0; width: 40px; height: 40px; font-size:14px; line-height:40px; border-radius: 7px; background: #5D5D5D; color: #fff; text-align: center;">
                                {{ report.preferences.length }}</div>
                            <div style="padding:2px;">
                                <p style="margin:0; font-size: 14px; color: #292929;">
                                    <strong>Preferences</strong>
                                </p>
                            </div>
                        </div>
                        <div class="risk-hol"
                            style="display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -webkit-flex;
                                        display: flex;
                                        -ms-align-items: center;
                                        align-items: center; flex: auto; margin: 7.5px 15px; border:1px solid #CBCBCB; border-radius: 7px;  padding: 5px;">
                            <div
                                style="padding:0px 5px; margin: 0 10px 0 0; width: 40px; height: 40px; font-size:14px; line-height:40px; border-radius: 7px; background: #5D5D5D; color: #fff; text-align: center;">
                                {{ report.statistics.length }}</div>
                            <div style="padding:2px;">
                                <p style="margin:0; font-size: 14px; color: #292929;">
                                    <strong>Statistics</strong>
                                </p>
                            </div>
                        </div>
                        <div class="risk-hol"
                            style="display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -webkit-flex;
                                        display: flex;
                                        -ms-align-items: center;
                                        align-items: center; flex: auto; margin: 7.5px 15px; border:1px solid #CBCBCB; border-radius: 7px;  padding: 5px;">
                            <div
                                style="padding:0px 5px; margin: 0 10px 0 0; width: 40px; height: 40px; font-size:14px; line-height:40px; border-radius: 7px; background: #5D5D5D; color: #fff; text-align: center;">
                                {{ report.marketing.length }} </div>
                            <div style="padding:2px;">
                                <p style="margin:0; font-size: 14px; color: #292929;"><strong>Marketing</strong>
                                </p>
                            </div>
                        </div>
                        <div class="risk-hol"
                            style="display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -webkit-flex;
                                        display: flex;
                                        -ms-align-items: center;
                                        align-items: center; flex: auto; margin: 7.5px 15px; border:1px solid #CBCBCB; border-radius: 7px;  padding: 5px;">
                            <div
                                style="padding:0px 5px; margin: 0 10px 0 0; width: 40px; height: 40px; font-size:14px; line-height:40px; border-radius: 7px; background: #FFC107; color: #fff; text-align: center;">
                                {{ report.non_categorize.length }}</div>
                            <div style="padding:2px;">
                                <p style="margin:0; font-size: 14px; color: #292929;">
                                    <strong>Unclassified</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row" style="margin: 0 0 20px 0; background:#EEF2FE; padding: 15px 15px 0px 15px; border-radius:
                7px; display: -webkit-box;
                                                display: -moz-box;
                                                display: -ms-flexbox;
                                                display: -webkit-flex;
                                                display: flex;
                                                -webkit-flex-flow: row wrap;
                                                -ms-align-items: center;
                                                align-items: center;
                                                justify-content: flex-start;border:1px solid #CBCBCB">
        
                            <div class=" col-sm-12 col-md-12">
                
                
                            </div>
        </div> -->
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12" style="margin: 0 0 40px 0;">
                <div class="row">
                    <div class="col-md-12">
                        <strong class="mb-3 d-block"
                            style="padding: 10px 10px 10px 0px; border-bottom: 1px solid #C1C1C1; color: #292929; font-size: 17px; width: 100%; display: block; margin: 0 0 30px 0;">Compliant
                            vs Non Compliant
                        </strong>
                        <img src="../../../assets/images/complaint_noncomplaint.svg" alt="compliant vs non compliant"
                            width="100%">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12" style="margin: 0 0 40px 0;">
                <div class="row">
                    <div class="col-md-12">
                        <strong
                            style="padding: 10px 10px 10px 0px; border-bottom: 1px solid #C1C1C1; color: #292929; font-size: 17px; width: 100%; display: block; margin: 0 0 30px 0;">Trends
                            over time
                        </strong>
                    </div>
                </div>
                <div class="row">
                    
                    <div class="col-12 col-sm-12 col-md-4">
                       
                            <div class="graph-block">
                                <!-- <div id="graph-hol-1"
                                    style="-webkit-box-shadow: 6px 6px 40px 0 rgba(29, 70, 94, .1);
                -moz-box-shadow: 6px 6px 40px 0 rgba(29, 70, 94, .1);
                box-shadow: 6px 6px 40px 0 rgba(29, 70, 94, .1); border-radius: 7px; padding: 15px 10px; margin: 0 0 20px 0;">
                                </div> -->
                                <vue-highcharts
                                type="chart"
                                :options="chartOptions1"
                                :redrawOnUpdate="true"
                                :animateOnUpdate="true" @rendered="chart1"/>
                                <div class="risk-desc-hol">
                                    <p style="margin:0 0 20px 0; font-size: 12px; color: #7E7E7E;">This presents
                                        the number of total cookies detected on
                                        your website, both first-party and third-party
                                        cookies. Monitoring this trend may help you detect warning signs before
                                        its too late.

                                    </p>
                                </div>

                            </div>
                       
                    </div>
                    <div class="col-12 col-sm-12 col-md-4">
                        
                     
                            <div class="graph-block">
                                <vue-highcharts
                                type="chart"
                                :options="chartOptions2"
                                :redrawOnUpdate="true"
                                :oneToOneUpdate="false"
                                :animateOnUpdate="true" @rendered="chart2"/>
                                <!-- <div id="graph-hol-2"
                                    style="-webkit-box-shadow: 6px 6px 40px 0 rgba(29, 70, 94, .1);
                -moz-box-shadow: 6px 6px 40px 0 rgba(29, 70, 94, .1);
                box-shadow: 6px 6px 40px 0 rgba(29, 70, 94, .1); border-radius: 7px; padding: 15px 10px; margin: 0 0 20px 0;">
                                </div> -->
                                <div class="risk-desc-hol">
                                    <p style="margin:0 0 20px 0; font-size: 12px; color: #7E7E7E;">Third-party
                                        cookies are the ones that are placed on
                                        your website by an entity other than you. Asa
                                        general rule, more third-party cookies are present on
                                        your website; the more is the risk for the personal data
                                        breach.
                                    </p>
                                </div>
                            </div>
                        
                    </div>
                    <div class="col-12 col-sm-12 col-md-4">
                       
                            <div class="graph-block">
                                <vue-highcharts
                                type="chart"
                                :options="chartOptions3"
                                :redrawOnUpdate="true"
                                :oneToOneUpdate="false"
                                :animateOnUpdate="true" @rendered="chart3"
                                />
                                <!-- <div id="graph-hol-3"
                                    style="-webkit-box-shadow: 6px 6px 40px 0 rgba(29, 70, 94, .1);
                -moz-box-shadow: 6px 6px 40px 0 rgba(29, 70, 94, .1);
                box-shadow: 6px 6px 40px 0 rgba(29, 70, 94, .1); border-radius: 7px; padding: 15px 10px; margin: 0 0 20px 0;">
                                </div> -->
                                <div class="risk-desc-hol">
                                    <p style="margin:0 0 20px 0; font-size: 12px; color: #7E7E7E;">An increasing
                                        risk trend is an alarming sign. It may
                                        indicate a failure to control/block unauthorised
                                        third-party cookies on your website.
                                    </p>
                                </div>
                            </div>
                       
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <table width="100%" border="0" cellspacing="0" cellpadding="0" class="cookie-table">
                    <thead>
                        <tr>
                            <td width="100%" align="left" height="40" valign="middle" style=" border-bottom: 1px solid
                                #C1C1C1; color: #1A8D58;display: flex;
                margin-bottom: 20px;" colspan="4">
                                <strong
                                    style="padding: 10px 10px 10px 0px; color: #292929; font-size: 17px; width: 100%; display: block;">Cookie
                                    Table
                                </strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="100%" align="center" valign="middle">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <thead>
                                        <tr class="none">
                                            <td height="40" align="left" valign="middle" width="20%"
                                                style="padding:10px 2px 10px 20px; text-transform: uppercase; font-size: 14px;">
                                                <strong>Name</strong>
                                            </td>
                                            <td align="left" valign="middle" width="35%" height="40"
                                                style="padding:10px 25px 10px 20px; text-transform: uppercase; font-size: 14px;">
                                                <strong>Purpose</strong>
                                            </td>
                                            <td height="40" align="left" valign="middle" width="15%"
                                                style="padding:10px 2px 10px 20px; text-transform: uppercase; font-size: 14px;">
                                                <strong>Duration/Exp</strong>
                                            </td>
                                            <td align="left" valign="middle" width="10%" height="40"
                                                style="padding:10px 2px 10px 20px;text-transform: uppercase; font-size: 14px;">
                                                <strong>Secure</strong>
                                            </td>
                                            <td align="left" valign="middle" width="20%" height="40"
                                                style="padding:10px 2px 10px 20px;text-transform: uppercase; font-size: 14px;">
                                                <strong>Category</strong>
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                            </td>
                        </tr>
                        <!-- <tr v-for="(cookie,index) in domainCbCookies.cb_cookies" :key="index">
                            <td width="100%" align="center" valign="middle">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        
                                        <tr style="border-bottom: 1px solid #E6E6E2;">
                                            <td data-column="Name" height="25" align="left" valign="middle"
                                                width="20%" style="padding:10px 2px 10px 20px; font-size: 13px;">
                                                {{cookie.name}}
                                            </td>
                                            <td data-column="Purpose" height="25" align="left" valign="middle"
                                                width="35%" style="padding:10px 25px 10px 20px; font-size: 13px;">
                                                {{cookie.purpose_desc ?? 'Not Provided'}}
                                            </td>
                                            <td data-column="Duration/Exp" height="25" align="left" valign="middle"
                                                width="15%" style="padding:10px 2px 10px 20px; font-size: 13px;">
                                                
                                                {{ formatDate(cookie.expiry) }}
                                            </td>
                                            <td data-column="Secure" height="25" align="left" valign="middle"
                                                width="10%" style="padding:10px 2px 10px 20px; font-size: 13px;">
                                                {{ cookie.secure == 0 ? 'No' : 'Yes' }}
                                            </td>
                                            <td data-column="Purpose" height="25" align="left" valign="middle"
                                                width="20%" style="padding:10px 25px 10px 20px; font-size: 13px;">
                                                {{cookie.cb_cookie_categories.name ?? 'Not Provided'}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr> -->
                         <tr v-for="(cookie, index) in domainCbCookies.cb_cookies" :key="index">
        <td width="100%" align="center" valign="middle">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody>
                    <tr style="border-bottom: 1px solid #E6E6E2;">
                        <td data-column="Name" height="25" align="left" valign="middle"
                            width="20%" style="padding:10px 2px 10px 20px; font-size: 13px;">
                            {{ cookie.name }}
                        </td>
                        <td data-column="Purpose" height="25" align="left" valign="middle"
                            width="35%" style="padding:10px 25px 10px 20px; font-size: 13px;">
                            {{ cookie.purpose_desc ?? 'Not Provided' }}
                        </td>
                        <td data-column="Duration/Exp" height="25" align="left" valign="middle"
                            width="15%" style="padding:10px 2px 10px 20px; font-size: 13px;">
                            {{ formatDate(cookie.expiry) }}
                        </td>
                        <td data-column="Secure" height="25" align="left" valign="middle"
                            width="10%" style="padding:10px 2px 10px 20px; font-size: 13px;">
                            {{ cookie.secure == 0 ? 'No' : 'Yes' }}
                        </td>
                        <!-- <td data-column="Purpose" height="25" align="left" valign="middle"
                            width="20%" style="padding:10px 25px 10px 20px; font-size: 13px;">
                            {{ cookie.cb_cookie_categories.name ?? 'Not Provided' }}
                        </td> -->
                        <td data-column="Name" height="25" align="left" valign="middle"
    width="20%" style="padding:10px 2px 10px 20px; font-size: 13px;">
    <span style="font-size: 13px; color: #212529;" v-if="cookieExistsInMissingCookies(cookie.name)">
    {{ cookie.cb_cookie_categories.name ?? 'Not Provided' }}
    <span style="float: right; font-size: 13px; color: blue; font-weight: bold; margin-right: 30px">(New)</span>
</span>
    <span style="font-size: 13px; color: #212529;" v-else>
        {{ cookie.cb_cookie_categories.name ?? 'Not Provided' }}
    </span>
</td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <div class="row" style="margin: 30px 0 30px 0; background:#EEF2FE; padding: 15px 15px 0px 15px; border-radius:
                7px; display: -webkit-box;
                                                display: -moz-box;
                                                display: -ms-flexbox;
                                                display: -webkit-flex;
                                                display: flex;
                                                flex-flow: row wrap;
                                                -ms-align-items: center;
                                                align-items: center;
                                                justify-content: flex-start;">

            <div class=" col-sm-12 col-md-9">
                <h5><strong>The quickest and simplest way to become fully cookie compliant</strong></h5>
                <p style="font-size: 13px; margin: 0 0 15px 0;">Easily create a compliant Cookie Consent Banner,
                    Cookie Policy with automatic cookie updates to ensure that you comply with GDPR, ePrivacy
                    Directive, CCPA and LGPD.
                </p>
            </div>
        </div>
        <div class="row" style="
                            margin: 10px 0 10px 0;
                            background: #EEF2FE;
                            padding: 30px 15px 30px 15px;
                            display: -webkit-box;
                            display: -moz-box;
                            display: -ms-flexbox;
                            display: -webkit-flex;
                            display: flex;
                            flex-flow: row wrap;
                            -ms-align-items: center;
                            align-items: center;
                            justify-content: flex-start;
                            border-radius: 7px;
                            ">

            <div class=" col-12 col-sm-12 col-md-12">
                <h5 >

            <strong>Disclaimer</strong></h5>
                                    <p style="font-size: 13px;">
                                    As stipulated in the <a href="https://seersco.com/term-and-condition.html" target="_blank">Terms &
                    Conditions</a>, by agreeing to them you accept any further disclaimers and limitations of liability
                on the part of Seers. The Cookie Audit is an automated scanning tool designed to find cookies and
                tracking technologies on a website, Seers makes no representations or guarantees regarding this report
                effectiveness or accuracy. If there are any queries or concerns arising from an audit, we advise that
                you seek independent professional advice.
                </p>
            </div>
        </div>

        </div>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import moment from "moment";
import VueHighcharts from 'vue3-highcharts';
import Papa from 'papaparse';
export default {
	name: "ScanReport",
    components: {
        PulseLoader,
        VueHighcharts,
  },
  data: function() {
    return {
        chartOptions1: {
                chart: {
            type: 'line',
            },
            title: {
            text: 'Total Cookies Detected',
            },
            yAxis: {
            title: {
                text: 'Number of Cookies',
            },
            },
             xAxis: {
                tickmarkPlacement: 'on',
            categories: [],
            labels: {
                        rotation: 0
                    }
            },
            credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        events: {
                            legendItemClick: function() {
                                return false;
                            }
                        },
                        pointPlacement: 'on',
                        label: {
                            connectorAllowed: false
                        },
                        pointStart: 0
                    }
                },
            series: [{
              
            name: 'Date - Month',
            data: [0],
            }],
        },
        chartOptions2: {
            chart: {
            type: 'line',
            },
            title: {
            text: 'Total Cookies Detected',
            },
            xAxis: {
                tickmarkPlacement: 'on',
            categories: [],
            labels: {
                        rotation: 0
                    }
            },
            yAxis: {
            title: {
                text: 'Number of Cookies',
            },
        },
            credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        events: {
                            legendItemClick: function() {
                                return false;
                            }
                        },
                        pointPlacement: 'on',
                        label: {
                            connectorAllowed: false
                        },
                        pointStart: 0
                    }
                },
            series: [{
                
            name: 'Date - Month',
            data: [0],
            }],
        },
        chartOptions3: {
                chart: {
            type: 'line',
            },
            title: {
            text: 'Total Cookies Detected',
            },
            xAxis: {
                tickmarkPlacement: 'on',
            categories: [],
            labels: {
                        rotation: 0
                    }
            },
            yAxis: {
            title: {
                text: 'Number of Cookies',
            },
            },
            credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        events: {
                            legendItemClick: function() {
                                return false;
                            }
                        },
                        pointPlacement: 'on',
                        label: {
                            connectorAllowed: false
                        },
                        pointStart: 0
                    }
                },
            series: [{
            name: 'Date - Month',
            data: [0],
            }],
            
        },
        loading : true,
       loaded : false,
       
      cookieDetectedLabels : [0],
      cookiesBadLabels : [0],
      cookiesRiskLabels : [0],
      cookieDetectedValues : [0],
      cookiesBadValues : [0],
      cookiesRiskValues : [0],
      vendors : [],
      thirdParty :0,
      firstParty :0,
      thirdPartyCookies :0,
      firstPartyCookies : 0,
      totalPartnerFound : 0,
      totalRisk :0,
      highRisk :0,
      mediumRisk : 0,
      lowRisk : 0, 
      badRisk : 0,
      cbCookies:[],
      isRegistered : false,
      orderedReports:[],
      totalCookies:0,
      domainReports:[],
      user:null,
      domain_country:'',
      report:[],
      domainCbCookies:[],
      new_cookies:0,
      missingCookies:[],
      missingCookieCount:0,
    };
  },
  watch:{
        domain_id: {
            // deep: true,
        // immediate:true,
            handler(){
                this.getReport();
            }
        }
    },
  async mounted() {
    await this.getReport();

    // await this.newCookiesfound();
    
    // this.chart1();
    // this.chart2();
    // this.chart3();
  },
  computed:{
      chart1(){
          this.chartOptions1.xAxis.categories  =  this.cookieDetectedLabels;
          this.chartOptions1.series[0].data = [];
          let cookieDetectedValues = this.cookieDetectedValues.length != 0 ? this.cookieDetectedValues : [0];
          this.chartOptions1.series[0].data = cookieDetectedValues;
          
      },
      chart2(){
          this.chartOptions2.xAxis.categories  =  this.cookiesBadLabels;
          let cookiesBadValues = this.cookiesBadValues.length != 0 ? this.cookiesBadValues : [0];
          this.chartOptions2.series[0].data = [];
          this.chartOptions2.series[0].data = cookiesBadValues;
      },
      chart3(){
          this.chartOptions3.xAxis.categories  =  this.cookiesRiskLabels;
          this.chartOptions3.series[0].data = [];
          let cookiesRiskValues = this.cookiesRiskValues.length != 0 ? this.cookiesRiskValues : [0];
          this.chartOptions3.series[0].data = cookiesRiskValues; 
      },
      domain_id: function () {
        return this.$store.getters["domain/getCurrentDomainId"];
      },
      current_domain: function () {
      return this.$store.getters['domain/getCurrentDomain'];
    },
      current_role: function () {
      return this.$store.getters['domain/getAssign_role'];
    },
  },
  methods: {
    cookieExistsInMissingCookies(cookieName) {
        return this.missingCookies.some(cookie => cookie.name === cookieName);
    },
    downloadCSV() {
    const allcookies=this.domainCbCookies.cb_cookies;
    const selectedColumns = ['name', 'slug' , 'purpose_desc','secure', "cb_cat_id"];
      const csvContent = this.convertToCSV(allcookies, selectedColumns);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      
      link.setAttribute('href', url);
      link.setAttribute('download', 'cookie_table.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    convertToCSV(data, columns) {
  const categoryMap = {
    1: 'Statistics',
    2: 'Marketing',
    3: 'Necessary',
    4: 'Preferences',
    5: 'Unclassified',
  };
  const getCategory = (id) => categoryMap[id] || '';

  const header = columns.join(',') + ',secure\n'; 
  const rows = data.map(obj => {
    const rowData = columns.map(col => {
      if (col === 'cb_cat_id') {
        return getCategory(obj[col]);
      } else if (col === 'secure') {
        return obj[col] === 1 ? 'Yes' : 'NO';
      } else {
        return obj[col];
      }
    }).join(',');
    return rowData;
  }).join('\n');
  return header + rows;
},

    
      formatDate(date) {
        // return moment(date).format("Do MMM YYYY")
        return moment(date).format("DD/MM/YYYY ");
      },
      getReport(){
          if (this.current_domain.last_scan_at == null) {
                this.loaded = false;
                this.loading = false;
            } else if (this.current_domain.report_generated == 0) {
                this.loaded = false;
                this.loading = false;
            }
            else{
                this.cookieDetectedLabels = [0];
                this.cookiesBadLabels = [0];
                this.cookiesRiskLabels = [0];
                this.cookieDetectedValues = [0];
                this.cookiesBadValues = [0];
                this.cookiesRiskValues = [0];
                this.vendors = [];
                this.thirdParty =0;
                this.firstParty =0;
                this.thirdPartyCookies =0;
                this.firstPartyCookies = 0;
                this.totalPartnerFound = 0;
                this.totalRisk =0;
                this.highRisk=0;
                this.mediumRisk= 0;
                this.lowRisk= 0;
                this.badRisk = 0;
                this.cbCookies=[];
                this.isRegistered = false;
                this.orderedReports=[];
                this.totalCookies=0;
                this.domainReports=[];
                this.user=null;
                this.domain_country='';
                this.report=[];
                this.domainCbCookies=[];
                this.$store.dispatch('domain/getReport',this.current_domain.web_url)
        //   axios.get(`https://upgradedcmp.here/api/auth/reports/${this.current_domain.web_url}`)
          .then(response => {
               if(response){
                    this.loaded = true;
                    this.loading = false;
                    this.domainCbCookies = response.data.domainCbCookies;
                    this.domainReports = response.data.domainReports;
                    this.user = response.data.user;
                    this.domain_country = response.data.domain_country;
                    this.report = response.data.report;
                    let report = response.data.report;

                    if(report.length != 0){
                    this.totalRisk = ((report.mundane.length) * 1) + ((report.medium.length) * 2) + ((report.high.length) * 3);
                    }
                    if(this.totalRisk > 0){
                        this.high = Math.round(((report.high.length * 3) / this.totalRisk) * 100, 2);
                        this.medium = Math.round(((report.medium.length * 2) / this.totalRisk) * 100, 2);
                        this.low = Math.round(((report.mundane.length * 1) / this.totalRisk) * 100, 2);
                        this.highRisk = this.high - parseInt(this.high) > 0.5 ? Math.ceil(this.high) : Math.floor(this.high);
                        this.mediumRisk = this.medium - parseInt(this.medium) > 0.5 ? Math.ceil(this.medium) : Math.floor(this.medium);
                        this.lowRisk = 100 - parseInt(this.highRisk + this.mediumRisk);
                        this.badRisk = this.highRisk + this.mediumRisk;
                    }
                    // this.cbCookies.concat([report.mundane, report.medium, report.high]);
                    this.cbCookies.push(...[...report.mundane, ...report.medium, ...report.high, ...report.not_classified]);
                    this.totalCookies = this.cbCookies.length;
                    this.$store.commit("domain/setTotalCookies" , this.totalCookies);
                    if(this.totalCookies > 0){
                        for (let i = 0; i < this.totalCookies; i++) {
                            
                            if (this.cbCookies[i].provider.indexOf(".") == false) {
                                
                                if (report.domain.name != this.cbCookies[i].provider.replace(".","")) {
                                    this.thirdParty++;
                                    
                                    if (!this.vendors.includes(this.cbCookies[i].provider)) {
                                        this.vendors.push(this.cbCookies[i].provider)
                                    }
                                }
                            }
                            else if(report.domain.name != this.cbCookies[i].provider) {
                                this.thirdParty++;
                                if (!this.vendors.includes(this.cbCookies[i].provider)) {
                                    this.vendors.push(this.cbCookies[i].provider)
                                }
                            }
                        }
                        // console.log('Total Cookies Found ',this.totalCookies);
                        //             console.log('Third Party Cookies ',this.thirdParty);
                        //             console.log('Domain Name ',report.domain.name);
                        //             console.log('cb-cookies ',this.cbCookies);
                        this.totalPartnerFound = this.vendors.length;
                        this.firstParty = this.totalCookies - this.thirdParty;
                        // this.thirdPartyCookies = this.thirdPartyCookies - parseInt(this.thirdPartyCookies) >= 0.5 ? Math.ceil(this.thirdPartyCookies) : parseInt(this.thirdPartyCookies);
                        this.thirdPartyCookies = (this.thirdParty / this.totalCookies) * 100 ;
                        this.thirdPartyCookies = Math.ceil(this.thirdPartyCookies);
                        this.firstPartyCookies = 100 - this.thirdPartyCookies;
                        this.isRegistered = response.data.user.is_register == 1 ? true : false;
                        // this.orderedReports = this.domainReports.reverse();
                        // this.new_cookies = this.domainReports[0].content.domain.cb_cookies.length - this.domainReports[1].content.domain.cb_cookies.length;
                        if (
                            this.domainReports.length > 1 && 
                            this.domainReports[0]?.content?.domain?.cb_cookies?.length !== undefined && 
                            this.domainReports[1]?.content?.domain?.cb_cookies?.length !== undefined
                            ) {
                                this.new_cookies = this.domainReports[0].content.domain.cb_cookies.length - this.domainReports[1].content.domain.cb_cookies.length;
                            } else {
                                this.new_cookies = 0; 
                            }

                        this.orderedReports = this.domainReports;
                        let total;
                        this.orderedReports.forEach((domainReport) => {
                            let content = domainReport.content;
                            this.cookieDetectedLabels.push(moment.utc(domainReport.created_at).format("DD-MM"));
                            this.cookiesBadLabels.push(moment.utc(domainReport.created_at).format("DD-MM"));
                            this.cookiesRiskLabels.push(moment.utc(domainReport.created_at).format("DD-MM"));
                            if(domainReport.content !== '0'){
                            total = content.domain.cb_cookies.length;
                            this.cookieDetectedValues.push(total)
                            let badValue = content.high.length + content.medium.length;
                            this.cookiesBadValues.push(badValue);
                            if(total > 0){
                                this.cookiesRiskValues.push(Math.ceil((badValue / total) * 100,0));
                            }else{
                                this.cookiesRiskValues.push(0)
                            }
                          
                        }
                        
                        });
                    }
                    // let missingCookieCount = 0;
                    // const missingCookies = [];
                    const cookies1 = this.domainReports[0]?.content?.domain?.cb_cookies || {};
                    const cookies2 = this.domainReports[1]?.content?.domain?.cb_cookies || {};
                    const cookieNames2 = new Set(Object.values(cookies2).map(cookie => cookie.name));
                    for (const i in cookies1) {
                    if (!cookieNames2.has(cookies1[i].name)) {
                    this.missingCookieCount++;
                    this.missingCookies.push(cookies1[i]); 
                        }
                    }
                    console.log("Missing Cookie Count:", this.missingCookieCount);
                    console.log("Missing Cookies:", this.missingCookies);
                }
            })
        }
      }
  },
};
</script>

<style scoped>
body {
	background-image: url("@/assets/images/seers_watermark.png");
	background-position: center;
}

table.cookie-table thead tr th,
table.cookie-table tbody tr td {
	text-align: left !important;
}

table.cookie-table tbody tr:nth-child(even) {
	background-color: #f1f8f4;
}
.graph-block{
-webkit-box-shadow: 0px 0px 6px rgb(0 0 0 / 15%);
    -moz-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    -ms-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    -o-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 6px rgb(0 0 0 / 15%);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    padding: 15px;
        align-items: stretch;
    display: flex;
    flex-direction: column;
    height: 100%;
}
</style>