<template>
	<div>
		<div>
			<h3 class="text-blue mb-3">Cookie Consent Banner Script</h3>
			<p class="mb-2">Copy this script into your website's HTML header</p>
			<textarea
				class="scriptcode mb-0"
				placeholder="Describe yourself here..."
				id="BigCommerceStep1"
				readonly><script data-key="{{ script && script.key }}" data-name="CookieXray" src="{{script_url}}" type="text/javascript"></script></textarea
			>
			<p class="font-italic mb-0">
				Note: Paste this before any other script in the HTML head tag
			</p>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step1')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<h3 class="text-blue mb-3">
				Cookie Consent Banner Integrations / Plugins Script
			</h3>
			<span class="d-block mb-3"
				>Input this ID in web builders plugins (WordPress, Joomla,
				Presta shop etc.)</span
			>
			<p class="mb-2">Domain Group ID:</p>
			<textarea
				class="scriptcode mb-3"
				placeholder="Describe yourself here..."
				id="BigCommerceStep2"
				readonly>{{script && script.key}}</textarea>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step2')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<h3 class="text-blue mb-3">Cookie Policy Script</h3>
			<p class="mb-2">
				Copy code into your cookie policy URL to show default Cookie
				Policy.
			</p>
			<textarea
				class="scriptcode mb-3"
				placeholder="Describe yourself here..."
				id="BigCommerceStep3"
				readonly><div id="seers-cookie-consent"></div></textarea>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step3')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<hr />
			<h3 class="text-blue my-5">
				Installation instructions for BigCommerce:
			</h3>

			<details open>
				<summary>Step 1</summary>
				<div>
					<p>Go to homepage of your BigCommerce website</p>
					<img
						class="img-fluid"
						src="@/assets/images/bigcommerce-images/1.png"
					/>
				</div>
			</details>
			<div id="accordion" role="tablist">
				<details>
					<summary>Step 2</summary>
					<div>
<ul class="pl-3"><li class="mb-1">Give your <strong>login</strong> credentials.</li> <li class="mb-1">Press <strong class="d-inline-block text-dark">"Enter"</strong> on keyboard.</li></ul>
						<img
							class="img-fluid"
							src="@/assets/images/bigcommerce-images/2.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 3</summary>
					<div>
				<ul class="pl-3"><li class="mb-1">
                  Click <strong class="d-inline-block text-dark">"Storefront"</strong> button in ribbon appearing on left side of website.
                </li></ul>
						<img
							class="img-fluid"
							src="@/assets/images/bigcommerce-images/3.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 4</summary>
					<div>
						<p class="mb-1">New menu will open:</p>
		<ul class="pl-3"><li class="mb-1">
                  Click on <strong class="d-inline-block text-dark">"Script Manager"</strong> in sub menu of Storefront.
                </li> <li class="mb-1">Click on <strong class="d-inline-block text-dark">"Create a Script"</strong> button.</li></ul>
						<img
							class="img-fluid"
							src="@/assets/images/bigcommerce-images/4.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 5</summary>
					<div>
			<p class="mb-1">Next window <strong class="d-inline-block text-dark">"Create Script"</strong> will open</p>
						<ul class="pl-3"><li class="mb-1">
                  Write <strong class="d-inline-block text-dark">"Name of Script"</strong> in field below as 
                  <strong class="d-inline-block text-dark">Seers Cookie Consent Banner and Policy</strong>.
                </li> <li class="mb-1">
                  Write <strong class="d-inline-block text-dark">"Description"</strong> in field below as <strong data-v-facd3f1c="" class="d-inline-block text-dark">
                    GDPR and ePrivacy Compliance with Cookie Policy</strong>.
                </li> <li class="mb-1">
                  Select <strong class="d-inline-block text-dark">"Location on Page"</strong> where you want Banner to appear in either <strong data-v-facd3f1c="" class="d-inline-block text-dark">"Head"</strong> or
                  <strong class="d-inline-block text-dark">"Footer"</strong>.
                </li> <li class="mb-1">
                  Select
                  <strong class="d-inline-block text-dark">"Select pages where script will be added"</strong>
                  from available options.
                </li></ul>
						<img
							class="img-fluid"
							src="@/assets/images/bigcommerce-images/5.png"
						/>
					</div>
				</details>
				<details>
					<summary>Step 6</summary>
					<div>
						<p class="mb-1">
					Scroll down for next step
						</p>
						<ul class="pl-3"><li class="mb-1">
                  Select <strong class="d-inline-block text-dark">"Script type"</strong> from options
                  <strong class="d-inline-block text-dark">"Script"</strong>.
                </li> <li class="mb-1">
                  Paste your script which you will get from <a href="/business/seers-cookie-consent">
                    seersco</a>
                  in this window.
                </li> <li class="mb-1">Click <strong  class="d-inline-block text-dark">"Save"</strong> button.</li></ul>
						<img
							class="img-fluid"
							src="@/assets/images/bigcommerce-images/6.png"
						/>
					</div>
				</details>
				<details>
					<summary>Step 7</summary>
					<div>
		<p>
                Next window will appear. You will see message
                <strong class="d-inline-block text-dark">"Script created"</strong> .
              </p>
						<img
							class="img-fluid"
							src="@/assets/images/bigcommerce-images/7.png"
						/>
					</div>
				</details>
				<details class="mb-5">
					<summary>Step 8</summary>
					<div>
						<p>
						Go to your website, Banner will appear
						</p>

						<img
							class="img-fluid"
							src="@/assets/images/bigcommerce-images/8.png"
						/>
					</div>
				</details>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
	name: "BigCommerce",
	computed:{
		cmp_url(){
			return process.env.VUE_APP_BANNER_URL
		},
		script: function() {
			return this.$store.getters['getCode/getScript'];
		},
		script_url: function() {
			// return process.env.MIX_CB_HOST +'/'+ this.$store.getters.getUser.id +'/'+ this.$store.getters.getCurrentDomainId + "/cb.js";
			return this.cmp_url + '/banners/' + this.$store.state.domain.userId +'/'+ this.$store.getters['domain/getCurrentDomainId'] + "/cb.js";
		},
	},
	methods: {
		copyScript(script){
			if (script === "step1") {
				$("#BigCommerceStep1").select();
			}

			if (script === "step2") {
				$("#BigCommerceStep2").select();
			}

			if (script === "step3") {
				$("#BigCommerceStep3").select();
			}
			
			Swal.fire({
				icon: "success",
				title: 'Code Copied',
				text: "Code copied successfully",
				timer: 4000,
				confirmButtonText: "Ok"
			});
			document.execCommand("copy");
		}
	},
};
</script>

<style scoped>
textarea:focus {
	outline: none !important;
}
::-webkit-scrollbar {
	display: none;
}
p span {
	font-size: 16px !important;
	font-weight: 500;
}
p {
	font-size: 15px !important;
	font-weight: 400;
}
.scriptcode {
	padding-inline-start: 10px;	
	padding-block-start: 0%;
	padding: auto;
}
#BigCommerceStep3 {
	height: 50px;
	padding-left: 15%;
	padding-top: 1.3%;
}
#BigCommerceStep2 {
	height: 50px;
	padding-left: 15%;
	padding-top: 1.3%;
}
</style>