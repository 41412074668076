<template>
  <!-- Dialogue Preview Start -->
  <div
    style="width: 315px !important;" v-bind:style="{ fontFamily: current_banner.font_style + ' !important', background: current_banner.banner_bg_color,
    borderRadius: current_banner.position === 'seers_cc_center' ? '15px !important' : '10px !important'}"
    class="cookie_box seers-cx-show" v-bind:class="current_banner.position"
    id="seers-cx-banner" 
  >
    <div
      
      v-bind:style="{ background: current_banner.banner_bg_color }"
    >
      <div class="cookie_text_box" style="margin: 0 !important;">
        <span class="popClose mr-1"  id="popClose" style="font-size:11px !important;" v-bind:style="{
            color: current_banner.title_text_color}">✕</span>
        <h2
          class="cookie_title m-0"
          v-bind:style="{
            color: current_banner.title_text_color,
            fontFamily: current_banner.font_style + '!important',
            fontSize:
              (parseInt(current_banner.font_size) + 2).toString() +
              'px !important',
          }" style="font-size:11px !important; margin:0 !important"
        >
          {{this.language.cpra_title ? this.language.cpra_title : this.language.title}}
        </h2>
        <hr class="my-3" style="margin:5px 0 !important;" />
        <p
          id="seers-cookie-body-1"
          class="cookie_text"
          v-bind:style="{
            color: current_banner.body_text_color + ' !important',fontFamily: current_banner.font_style + '!important',
          }" style="font-size:8px !important; text-overflow: ellipsis;white-space: nowrap;display: block;overflow: hidden; margin:0px !important;" v-html="cpra_sanitizedBody ? cpra_sanitizedBody : sanitizedBody"
        >
        </p>
        <!-- <hr class="my-3" style="margin:5px 0 !important;"/> -->
        <div class="d-flex justify-content-between align-items-center">
          <span
            class="seers_sub_heading"
            v-bind:style="{
              color: current_banner.body_text_color + ' !important',
              fontFamily: current_banner.font_style + '!important',
            }" style="font-size:11px !important; margin:0 !important;"
          >
            Targeting / Advertising
          </span>
          <!-- <hr class="my-3" style="margin:5px 0 !important;"/> -->
          <div class="seers_main_switch seers_switch">
            <input
              id="do-not-sell-2"
              class="cmn-toggle cmn-toggle-round"
              type="checkbox" style="font-size:11px !important;" disabled
            />
            <label for="do-not-sell-2"></label>
          </div>
        </div>
        <!-- <hr class="my-3" style="margin:5px 0 !important;"/> -->
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <button id="confirm_my_choice" type="button"  style="font-size:9px !important; height: 35px !important; margin-top: 10px !important; min-width: 10px !important; padding: 8px !important;
            min-width: 80px !important; width: fit-content;" v-bind:style="{ color: current_banner.agree_text_color + ' !important', background: current_banner.agree_btn_color + '!important',fontFamily: current_banner.font_style + '!important', }">
              {{language.cpra_btn_agree_title ? language.cpra_btn_agree_title : language.btn_agree_title}}
            </button>
          </div>
        </div>
        <div v-if="form.email_section">
          <hr class="my-3" style="margin:5px 0 !important;"/>
          <p
            id="seers-cookie-body-1"
            class="cookie_text"
            v-bind:style="{
              color: current_banner.body_text_color + ' !important',
              fontFamily: current_banner.font_style + '!important',
            }" style="font-size:8px !important; text-overflow: ellipsis;white-space: nowrap;display: block;overflow: hidden; margin:0px !important;"
          >
            {{this.language.cpra_email_section_title ? this.language.cpra_email_section_title : this.language.email_section_title}}
          </p>
          <!-- <hr class="my-3" style="margin:5px 0 !important;"/> -->
        </div>
        <div v-if="form.policy_url_section">
          <hr class="my-3" style="margin:5px 0 !important;"/>
          <p
            id="seers-cookie-body-1"
            class="cookie_text"
            v-bind:style="{
              color: current_banner.body_text_color + ' !important',
              fontFamily: current_banner.font_style + '!important',
            }" style="font-size:8px !important; text-overflow: ellipsis;white-space: nowrap;display: block;overflow: hidden; margin:0px !important;"
          >
            {{this.language.cpra_second_layout_description ? this.language.cpra_second_layout_description : ''}}
          </p>
          <!-- <hr class="my-3" style="margin:5px 0 !important;"/> -->
        </div>
      </div>
      <div class="row email_box align-items-center" v-if="form.email_section">
        <div class="col-2 pr-0">
          <h5 class="email_heading m-0" style="font-size:11px !important;" v-bind:style="{
            color: current_banner.body_text_color + ' !important',
            fontSize: current_banner.font_size + 'px !important',
            fontFamily: current_banner.font_style + '!important',
          }">Email:</h5>
        </div>
        <div class="col-6 pl-0 ml-3">
          <input type="email" class="form-control" style="font-size:9px !important; height: 25px !important;" />
        </div>
        <div class="col-3 pl-0">
          <button type="submit" class="email_btn"  style="font-size:9px !important; height: 35px !important; width: 80px !important;" v-bind:style="{ color: current_banner.agree_text_color + ' !important', background: current_banner.agree_btn_color + '!important',fontFamily: current_banner.font_style + '!important', }">{{ language.cpra_btn_preference_title }}</button>
        </div>
      </div>
      <div class="row email_box align-items-center" v-if="form.policy_url_section">
        <div class="col-2 pr-0">
          
        </div>
        <div class="col-6 pl-0 ml-3">
          
        </div>
        <div class="col-3 pl-0">
          <button type="submit" class="email_btn"  style="font-size:9px !important; height: 35px !important; width: 80px !important;" v-bind:style="{ color: current_banner.agree_text_color + ' !important', background: current_banner.agree_btn_color + '!important',fontFamily: current_banner.font_style + '!important', }">{{ language.cpra_btn_disagree_title ? language.cpra_btn_disagree_title : 'Open' }}</button>
        </div>
      </div>

      <!-- <div class="button_box">
                    <div class="deital_button">
                        <a  v-if="dialogueProperties.cookie_policy_url !== null && dialogueProperties.cookie_policy_url !== ''" :href="dialogueProperties.cookie_policy_url ? dialogueProperties.cookie_policy_url : `${mix_cmp_url}/cookie-policy`" target="_blank" v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">{{language.policy}}</a> <span v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">|</span> <a v-on:click="this.fin" v-bind:style="{color :current_banner.body_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}" id="seers-cx-show-details">{{language.btn_preference_title}}</a>
                    </div>
                    <div class="button_postion">
                        <button id="seers-cx-agree" type="button" class="cookie_btn cookie_btn_open cookie_seers_open_btn seers-cx-show-details" v-bind:class="[this.agreeBtnSpecific, current_banner.button_type]" v-bind:style="{background :current_banner.agree_btn_color + ' !important',color :current_banner.agree_text_color + ' !important', fontSize :current_banner.font_size + 'px !important'}">
                            {{language.btn_agree_title}}
                        </button>
                    </div>
                </div> -->
    </div>
    <!--Tag setting-->
    <!--Tag Setting End-->
  </div>
</template>
<script>
import DOMPurify from 'dompurify';
// import store from "../cookieXray/store";
export default {
  // store,
  components: {},
  props: [],
  data() {
    return {
      readMoreBtnSpecific: "",
      agreeBtnSpecific: "",
      disAgreeBtnSpecific: "",
      arrow_icon: "&#8897;",
      toggle_one: false,
      toggle_two: false,
      anchor_tag_one: "",
      cookie_body_el_one: "",
      anchor_tag_two: "",
      cookie_body_el_two: "",
      container_height: 0,
      footer_height: 0,
    };
  },
  // updated() {
  //   let cookie_body_2 = document.getElementById("cookie_seers_popup_header");
  //   let cookie_banner_footer = document.getElementById(
  //     "seers_cookie_side_one_ccpa_footer"
  //   );
  //   this.container_height = cookie_body_2.offsetHeight;
  //   this.footer_height = cookie_banner_footer.offsetHeight;
  // },
  mounted() {
    this.setStyleForSpecificConsent();
    let anchorNodeOne = this.createAnchorNode(true);
    this.anchor_tag_one = anchorNodeOne;
    let anchorNodeTwo = this.createAnchorNode(false);
    this.anchor_tag_two = anchorNodeTwo;
    let cookie_body_1 = document.getElementById("seers-cookie-body-1");
    this.cookie_body_el_one = cookie_body_1;
    let cookie_body_2 = document.getElementById("seers-cookie-body-2");
    this.cookie_body_el_two = cookie_body_2;
    // if (cookie_body_1.textContent.split(" ").length >= 20) {
    //   cookie_body_1.appendChild(anchorNodeOne);
    // }
    // if (cookie_body_2.textContent.split(" ").length >= 20) {
    //   cookie_body_2.appendChild(anchorNodeTwo);
    // }

    // this.initEvents();
  },
  computed: {
      sanitizedBody() {
        return DOMPurify.sanitize(this.language.body);
      },
      cpra_sanitizedBody() {
        return DOMPurify.sanitize(this.language.cpra_body);
      },
      // dialogueProperties: function () {
      //     return this.$store.getters.getDialogueProperties;
      // },
      form: function () {
        return this.$store.state.dialogue.form;
      },
      dialogueProperties: function () {
        return this.$store.getters["domain/getDialogueProperties"];
      },
      logo: function () {
          return this.dialogueProperties.logo;
      },
      mix_cmp_url: function () {
          return process.env.VUE_APP_ENV_VARIABLE
        },
      logo_url: function () {
          return `${this.mix_cmp_url}/local/images/logo/${this.logo}`;
      },
      language: function() {
          return this.$store.getters["domain/getCurrentLanguage"];
      },
      current_banner: function(){
          return this.$store.getters["domain/getCurrentBanner"];
      },
      language_body_1: {
          get: function() {
              let self = this;
              let lang = this.sanitizedBody;

              if(!self.toggle_one && self.cookie_body_el_one && lang.split(' ').length > 20){
                  lang = self.truncate(lang.trim(), 20, null);
                  if(!self.cookie_body_el_one.contains(self.anchor_tag_one)){
                      self.cookie_body_el_one.appendChild(self.anchor_tag_one);
                  }
              }
              if(lang.split(' ').length < 20 && self.cookie_body_el_one && self.anchor_tag_one && self.cookie_body_el_one.contains(self.anchor_tag_one)){
                  self.cookie_body_el_one.removeChild(self.anchor_tag_one);
              }

              return lang;
          },
          set: function(lang) {
              this.language_body = lang;
          }
      },
      language_body_2: {
          get: function() {
              let self = this;
              let lang = this.sanitizedBody;

              if(!self.toggle_two && self.cookie_body_el_two && lang.split(' ').length > 20){
                  lang = self.truncate(lang.trim(), 20, null);
                  if(!self.cookie_body_el_two.contains(self.anchor_tag_two)){
                      self.cookie_body_el_two.appendChild(self.anchor_tag_two);
                  }
              }
              if(lang.split(' ').length < 20 && self.cookie_body_el_two && self.anchor_tag_two && self.cookie_body_el_two.contains(self.anchor_tag_two)){
                  self.cookie_body_el_two.removeChild(self.anchor_tag_two);
              }

              return lang;
          },
          set: function(lang) {
              this.language_body = lang;
          }
      },
      show_dialogue_preview: function () {
          return this.$store.getters["domain/getShowDialoguePreview"];
      },
  },
  methods: {
    toggleArrow() {
      this.arrow_icon = this.arrow_icon == "&#8897;" ? "&#8743;" : "&#8897;";
      return;
    },
    showMenu() {
      $(".menu").slideToggle("slow");
      // Get the element with id="defaultOpen" and click on it
      document.getElementById("defaultOpen").click();
      // console.log('this.showMenu called');
    },
    showTab(cityName, $event) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("seers-cx-tab-content");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("seers-cx-tab-links");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(
          " seers-cx-active",
          ""
        );
      }
      document.getElementById(cityName).style.display = "block";
      $event.target.className += " seers-cx-active";
    },
    setStyleForSpecificConsent() {
      if (this.dialogueProperties.consent_type == "specific") {
        this.readMoreBtnSpecific = "readMoreBtnSpecific";
        this.agreeBtnSpecific = "agreeBtnSpecific";
        this.disAgreeBtnSpecific = "hide";
      }

      return;
    },
    fin() {
      self = this;
      for (var i = 0; i <= 1; i += 0.01) {
        i = Math.round(i * 100) / 100;
        setTimeout("self.seto(" + i + ")", i * 1000);
      }
      document
        .getElementById("seers-cx-side-bar")
        .classList.replace("hide", "seers-cx-show");
      setTimeout(() => {
        let element = document.getElementById("cookie_seers_popup_header");
        self.container_height = element.offsetHeight;
      }, 500);
    },
    seto(opa) {
      self = this;
      document.getElementsByClassName("cookie_seers_popup")[0].style.opacity =
        opa;
      document.getElementsByClassName("cookie_seers_popup")[0].style.display =
        "block";
    },
    truncate(text, limit, after) {
      var self = this;
      // Make sure an element and number of items to truncate is provided
      if (!text || !limit) return;

      // Get the inner content of the element
      var content = text;
      content = content.trim();

      // Convert the content into an array of words
      // Remove any words above the limit
      content = content.split(" ").slice(0, limit);

      // Convert the array of words back into a string
      // If there's content to add after it, add it
      content = content.join(" ");

      return content;
    },
    
    previewToggle(status) {
      this.$store.commit("setShowDialoguePreview", status);
    },
    createAnchorNode(value) {
      let self = this;
      let anchor = document.createElement("a");
      anchor.id = value ? "seers_read_more_id_one" : "seers_read_more_id_two";
      anchor.href = "javascript:void(0)";
      anchor.textContent = self.language.link_more;
      anchor.setAttribute(
        "style",
        "font-size: inherit;\n" +
          "    color: #269abc !important;\n" +
          "    text-decoration: none;\n" +
          "    padding-left: 0px;\n" +
          "    font-weight: inherit;"
      );

      return anchor;
    },
  },
};
</script>

<style scoped>
.cookie_box{
    position: absolute !important;
    z-index: 99 !important;
    padding: 8px !important;
}
    @import '@/assets/css/BannerCss/ExtroBannerBox.css';
</style>