export const tabComponent = (state, tabComponent) => {
  state.tab = tabComponent;
}

export const setShowQuestionnaire = (state, show_questionnaire) => {
    state.show_questionnaire = show_questionnaire;
}

export const setShowPolicyReport = (state, show_policy_report) => {
    state.show_policy_report = show_policy_report;
}


export const setCurrentCookieXrayId = (state, id) => {
    state.current_cookieXray_id = id;
}

export const setCurrentCookieXray = (state, { cookieXray }) => {
    state.current_cookieXray = cookieXray;
}

export const setCurrentQuestionIndex = (state, index) => {
    state.current_question_index = index;
}

export const setQuestionCounter = (state, index) => {
    state.question_counter = index;
}

export const setQuestions = (state) => {
    state.questions = state.current_cookieXray.questions.pages[0].elements.filter(
      (question) => state.visible_types.includes(question.type)
    );
}

export const setTotalQuestions = (state) => {
    state.total_questions = state.questions.length;
}

export const setPrevQuestionIndex = (state, index) => {
  state.prev_question_index = index;
}

export const initializeAnswers = (state) => {
    if (state.current_cookieXray.answer == null) {
      state.questions.forEach((question, question_index) => {
        state.answers[0][question_index] = {
          question_name: "",
          answer: question.type == "checkbox" ? [] : "",
        };
      });
    } else {
        
      state.answers = state.current_cookieXray.answer.answers;
      state.current_question_index = state.answers[1].current_question_index;
      state.question_counter = state.answers[1].question_counter;
      state.next_question_index = state.answers[1].next_question_index;
      state.prev_question_index = state.answers[1].prev_question_index;
    }
}

export const findNextQuestion = (state) => {
    var questions = state.questions;
    var temp = state.current_question_index;
    var current_question_condition;
    var next_question_visiable;

    var progress = (temp / questions.length) * 100;

    state.progress = progress;
    state.is_last_question = false;
    state.answers[0].reverse();
    for (temp; temp < state.total_questions; temp++) {
      if (temp != state.current_question_index) {
        if (
          typeof questions[temp] !== "undefined" &&
          questions[temp].hasOwnProperty("visibleIf")
        ) {
          current_question_condition = questions[temp].visibleIf;
          String.prototype.replaceAll = function(search, replacement) {
              var target = this;
              return target.replace(new RegExp(search, 'g'), replacement);
          }
          var result = current_question_condition
            .replaceAll("\n", "")
            .replaceAll(/AND/, "&&")
            .replaceAll(/OR/, "||")
            .replaceAll("!=", "NOTEQUAL")
            .replace("=", "==")
            .replaceAll("NOTEQUAL", "!=")
            .replaceAll("notempty", "!= 'null'")
            .replaceAll("empty", "== 'null'")
            .replaceAll("notcontains", "!==")
            .replaceAll("contains", "===");

          state.answers[0].forEach(({ question_name, answer }) => {
            if (question_name !== "" && question_name != null) {
              result = findVariableValue(result, question_name, answer);
            }
          });
          var str = result.match(/{.*?}/i)[0];

          var inReferenceAnswers = true;
          str = str.replaceAll("{", "").replaceAll("}", "");
          if (str != "true") {
            state.answers[0].forEach(({ question_name, answer }) => {
              if (question_name !== "" && question_name != null) {
                if (question_name == str) {
                  if (inReferenceAnswers == true) {
                    inReferenceAnswers = false;
                  }
                }
              }
            });
          } else {
            inReferenceAnswers = false;
          }
          result = result.replaceAll("{", "'").replaceAll("}", "'");
          next_question_visiable = eval(result);

          if (next_question_visiable == true && inReferenceAnswers == false) {
            //if(next_question_visiable == true){
            state.next_question_index = temp;
            break;
          } else if (state.total_questions - 1 == temp) {
            state.is_last_question = true;
          }
        } else {
          if (state.total_questions - 1 >= temp) {
            state.next_question_index = temp;
            break;
          } else if (state.total_questions == temp) {
            state.is_last_question = true;
            state.next_question_index = temp;
            break;
          }
        }
      } else if (state.total_questions - 1 == temp) {
        state.is_last_question = true;
        state.next_question_index = temp;
        break;
      }
    }
    state.answers[0].reverse();
}

export const findPrevQuestion = (state) => {
  var questions = state.questions;
  var prevTemp = state.current_question_index;
  var current_question_condition;
  var prev_question_visiable;
  if (prevTemp > 0) {
    for (prevTemp; prevTemp >= 0; prevTemp--) {
      if (prevTemp != state.current_question_index) {
        if (
          typeof questions[prevTemp] !== "undefined" &&
          questions[prevTemp].hasOwnProperty("visibleIf")
        ) {
          current_question_condition = questions[prevTemp].visibleIf;
          String.prototype.replaceAll = function(search, replacement) {
              var target = this;
              return target.replace(new RegExp(search, 'g'), replacement);
          }
          var result = current_question_condition
            .replaceAll("\n", "")
            .replaceAll(/AND/, "&&")
            .replaceAll(/OR/, "||")
            .replaceAll("!=", "NOTEQUAL")
            .replaceAll("=", "==")
            .replaceAll("NOTEQUAL", "!=")
            .replaceAll("notempty", "!= 'null'")
            .replaceAll("empty", "== 'null'")
            .replaceAll("notcontains", "!==")
            .replaceAll("contains", "===");
          state.answers[0].forEach(({ question_name, answer }) => {
            if (question_name != null) {
              result = findVariableValue(result, question_name, answer);
            }
          });

          var str = result.match(/{.*?}/i)[0];

          var inReferenceAnswers = true;
          str = str.replaceAll("{", "").replaceAll("}", "");
          if (str != "true") {
            state.answers[0].forEach(({ question_name, answer }) => {
              if (question_name !== "" && question_name != null) {
                if (question_name == str) {
                  if (inReferenceAnswers == true) {
                    inReferenceAnswers = false;
                  }
                }
              }
            });
          } else {
            inReferenceAnswers = false;
          }

          result = result.replaceAll("{", "'").replaceAll("}", "'");
          prev_question_visiable = eval(result);
          if (prev_question_visiable == true && inReferenceAnswers == false) {
            state.prev_question_index = prevTemp;
            break;
          }
        } else {
          state.prev_question_index = prevTemp;
          break;
        }
      }
    }
  } else {
    state.prev_question_index = 0;
  }
}

export const setShowError = (state, show_error) => {
  state.show_error = show_error;
}

export const setCurrentAnswer = (state, { question_name, answer }) => {
  state.answers[0][state.current_question_index] = {
    question_name: question_name,
    answer: answer,
  };
  state.answers[1].current_question_index = state.current_question_index;
  state.answers[1].question_counter = state.question_counter;
  state.answers[1].next_question_index = state.next_question_index;
  state.answers[1].prev_question_index = state.prev_question_index;
}

export const setErrorMessage = (state, message) => {
  state.error_message = message;
}



export const setQuestionIndex = (state, inc = 1) => {
  state.current_question_index = inc;
}

export const decrementQuestionCounter = (state, inc = 1) => {
  state.question_counter -= inc;
}

export const setLastQuestion = (state, is_last_question) => {
  state.is_last_question = is_last_question;
}

export const setIsDone = (state, is_done) => {
  state.is_done = is_done;
}

export const incrementQuestionCounter = (state, inc = 1) => {
  state.question_counter += inc;
}

export const replaceVariableValues = (source,question,val,what) =>{
    var broken = [
        source.substr(0,val.var_start),
        'true',
        source.substr(val.var_end,val.value_start - val.var_end),
        what,
        source.substr(val.value_end,source.length),
    ];
    return broken.join('');
}

export const findVariableValue = (source,search,values) =>{

    var found = 0;
    var vals = [];
    var  i = 0;

    while((found = source.indexOf(search,0)) !== -1){
        var val = {
            var_start: found,
            var_end: found + search.length,
            value_start: source.indexOf("'",found+ search.length)+1,
            value_end:0,
            value:''
        };
        val.value_end = source.indexOf("'",val.value_start+1);
        val.value = source.substr(val.value_start,val.value_end - val.value_start);


        if(Array.isArray(values)){
            if(values.includes(val.value)){
                source = replaceVariableValues(source,search,val,'true')
            } else {
                source = replaceVariableValues(source,search,val,'false')
            }
        }else{
            if(values == val.value){
                source = replaceVariableValues(source,search,val,true);
            } else {
                source = replaceVariableValues(source,search,val,false);
            }
        }
        vals.push(val)
    }
    return source;
}

export const setPolicy = (state, policy) => {
  state.policy = policy;
}