<template>
	<div>
		<div>
			<h3 class="text-blue mb-3">Cookie Consent Banner Script</h3>
			<p class="mb-2">Copy this script into your website's HTML header</p>
			<textarea
				class="scriptcode mb-0"
				placeholder="Describe yourself here..."
				id="PrestashopStep1"
				readonly><script data-key="{{ script && script.key }}" data-name="CookieXray" src="{{script_url}}" type="text/javascript"></script></textarea
			>
			<p class="font-italic mb-0">
				Note: Paste this before any other script in the HTML head tag
			</p>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step1')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<h3 class="text-blue mb-3">Cookie Policy Script</h3>
			<p class="mb-2">
				Copy code into your cookie policy URL to show default Cookie
				Policy.
			</p>
			<textarea
				class="scriptcode mb-3"
				placeholder="Describe yourself here..."
				id="PrestashopStep2"
				readonly><div id="seers-cookie-consent"></div></textarea>
			<button class="btn btn-blue-bg float-right mb-3" @click="copyScript('step2')">
				Copy Script
			</button>
		</div>
		<div class="mt-5">
			<hr />
			<h3 class="text-blue my-5">
				Installation instructions for Prestashop:
			</h3>

			<details open>
				<summary>Step 1</summary>
				<div>
					<p>Log in as administrator on your PrestaShop Website.</p>

					<img
						class="img-fluid"
						src="@/assets/images/prestashop-images/1.png"
					/>
				</div>
			</details>
			<div id="accordion" role="tablist">
				<details>
					<summary>Step 2</summary>
					<div>
						<p class="mb-1">
							1: From dashboard go to
							<strong class="d-inline-block text-dark"
								>"Module Manager"</strong
							>
							tab in Left menu.
						</p>
						<p>
							2: Click on
							<strong class="d-inline-block text-dark"
								>"Module Manager"</strong
							>
						</p>

						<img
							class="img-fluid"
							src="@/assets/images/prestashop-images/2.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 3</summary>
					<div>
						<p>
							New window will open. Click
							<strong class="d-inline-block text-dark"
								>"Upload a module"</strong
							>
							tab.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/prestashop-images/3.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 4</summary>
					<div>
						<p class="mb-1">New window Dialog box will open.</p>
						<ul class="pl-3">
							<li class="mb-1">
								Drag and drop file of module downloaded from
								Seers website in Highlighted area.
							</li>
							<li class="mb-1">
								Click
								<strong class="d-inline-block text-dark"
									>"Select File"</strong
								>
								to upload files using
								<strong class="d-inline-block text-dark"
									>"File Explorer"</strong
								>
								from computer
							</li>
						</ul>
						<img
							class="img-fluid"
							src="@/assets/images/prestashop-images/4.png"
						/>
					</div>
				</details>

				<details>
					<summary>Step 5</summary>
					<div>
						<p>
							1: As files are dropped or Selected Prestashop will
							start installing module automatically.
						</p>
						<img
							class="img-fluid"
							src="@/assets/images/prestashop-images/5.png"
						/>
					</div>
				</details>
				<details>
					<summary>Step 6</summary>
					<div>
						<ul class="pl-3">
							<li class="mb-1">
								1: As upload is completed it will show message
								of
								<strong class="d-inline-block text-dark"
									>"Module Installed"</strong
								>.
							</li>
							<li class="mb-1">
								2: Click on
								<strong class="d-inline-block text-dark"
									>"Configure"</strong
								>
								button under message.
							</li>
						</ul>
						<img
							class="img-fluid"
							src="@/assets/images/prestashop-images/6.png"
						/>
					</div>
				</details>
				<details>
					<summary>Step 7</summary>
					<div>
						<ul class="pl-3">
							<li class="mb-1">
								1: It will take you to configuration page.
							</li>
							<li class="mb-1">
								2: Paste
								<strong class="d-inline-block text-dark"
									>"ID"</strong
								>
								given by Seers.
							</li>
							<li class="mb-1">
								3: Click on
								<strong class="d-inline-block text-dark"
									>"Save"</strong
								>
								to save setting.
							</li>
						</ul>
						<img
							class="img-fluid"
							src="@/assets/images/prestashop-images/7.png"
						/>
					</div>
				</details>
				<details class="mb-5">
					<summary>Step 8</summary>
					<div>
						<p class="mb-1 font-weight-bold">Verify:</p>
						<p>
							Go to your PrestaShop Site plugin will be working.
						</p>
					</div>
				</details>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
	name: "Prestashop",
	computed:{
		cmp_url(){
			return process.env.VUE_APP_BANNER_URL
		},
		script: function() {
			return this.$store.getters['getCode/getScript'];
		},
		script_url: function() {
			// return process.env.MIX_CB_HOST +'/'+ this.$store.getters.getUser.id +'/'+ this.$store.getters.getCurrentDomainId + "/cb.js";
			return this.cmp_url + '/banners/' + this.$store.state.domain.userId +'/'+ this.$store.getters['domain/getCurrentDomainId'] + "/cb.js";
		},
	},
	methods: {
		copyScript(script){
			if (script === "step1") {
				$("#PrestashopStep1").select();
			}

			if (script === "step2") {
				$("#PrestashopStep2").select();
			}
			
			Swal.fire({
				icon: "success",
				title: 'Code Copied',
				text: "Code copied successfully",
				timer: 4000,
				confirmButtonText: "Ok"
			});
			document.execCommand("copy");
		}
	},
};
</script>

<style scoped>


textarea:focus {
	outline: none !important;
}
::-webkit-scrollbar {
	display: none;
}
p span {
	font-size: 16px !important;
	font-weight: 500;
}
p {
	font-size: 15px !important;
	font-weight: 400;
}
.scriptcode {
	padding-inline-start: 10px;	
	padding-block-start: 0%;
	padding: auto;
}
#PrestashopStep2 {
	height: 70px;
	padding-left: 15%;
	padding-top: 2%;
}
</style>