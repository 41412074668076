<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h2 class="text-dark mb-4 pb-4 border-bottom">
          Consent Management Platform
        </h2>
        <h4 class="text-dark mb-0">Cookie Policy</h4>
        <p class="last-scanned-policy">
          Last Scanned on {{ this.domain.last_scan_at }}
        </p>
        <hr class="seprater-line mx-auto w-100 mt-0 mb-3" />
        <!-- <h3 class="text-dark mb-2 font-weight-bold">{{ product_display_name }}</h3> -->
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <p class="mb-1">
          Below is your cookie policy, including a table of all current cookies
          & their purpose.
        </p>
        <p class="mb-1">Copy & paste into your Cookie Policy web page.</p>
        <p class="mb-1">
          Alternatively (if preferred) just add the automatically updating
          script with a table of cookies of step 4 to your own pre-existing
          policy document.
        </p>
      </div>
    </div>

    <button
      class="btn btn-blue-bg m-1 mt-3 mr-2 mb-3 float-right"
      @click="downloadCookiePolicy"
    >
      Download Policy
    </button>
    <div
      style=""
      class="cooki-m-policy no-border box-shadow-same radius-4"
      v-html="this.policy"
    ></div>
    <br />
    <!-- <div class="policy-btn-cooki hidden-xs hidden-sm">
            <div class="col-md-2 col-sm-12 col-xs-12">
                <a  type="button" class="btn btn-info box-shadow-none margin-tb-15 margin-bottom-none" @click="resetPolicy">Retry</a>
            </div>
        </div> -->

    <div class="policy-btn-cooki hidden-md hidden-lg">
      <!-- <div class="row"><div class="col-md-12 col-sm-12 col-xs-12 text-center"><p class="next-step-popover">Next Step <span> >> </span> Create your Cookie Consent Dialogue Pop Over</p></div>
            </div> -->

      <div class="row">
        <div class="col-sm-2 col-xs-2">
          <a type="button" class="btn btn-blue-bg" @click="resetPolicy"
            >Retry</a
          >
        </div>

        <div class="col-md-8 col-sm-8 col-xs-8 text-center pt-2">
          <p class="next-step-popover"></p>
        </div>

        <div class="col-sm-2 col-xs-2 notranslate">
          <a
            style="float: right"
            type="button"
            @click="showDialogue()"
            data-toggle="tooltip"
            data-placement="top"
            class="btn btn-white-bg"
            >Dashboard</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- /-- Report -->
</template>

<script>
//next task is show next question method

import Swal from "sweetalert2";
import moment from "moment";

export default {
  components: {},
  props: ["current_id"],
  filters: {
    date(value) {
      return moment(String(value)).format("Do MMMM YYYY");
    },
  },
  data() {
    return {};
  },
  async mounted() {
    await this.loadPolicy();
  },
  computed: {
    cmp_url(){
			return process.env.VUE_APP_ENV_VARIABLE
		},
    show_questionnaire: function () {
      return this.$store.getters["policy/getShowQuestionnaire"];
    },
    show_policy_report: function () {
      return this.$store.getters["policy/getShowPolicyReport"];
    },
    policy: function () {
      return this.$store.getters["policy/getPolicy"].policy;
    },
    domain_id: function () {
      return this.$store.getters["domain/getCurrentDomainId"];
    },
    domain: function () {
      return this.$store.getters["domain/getCurrentDomain"];
    },
    userId: function () {
      return this.$store.state.domain.userId;
    },
  },
  watch: {},
  methods: {
    async loadPolicy() {
      var id = this.domain_id;
      var user_id = this.userId;
      let payload = {
        id: id,
        user_id: user_id,
      };
      this.$store.dispatch("policy/loadPolicy", payload);
    },
    resetPolicy() {
        const self = this;
      

      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3b6ef8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, reset it!",
      }).then(async (result) => {
        if (result.value) {
            let payload = {
                domain_id: this.domain_id,
                user_id: this.userId,
            };
          this.$store.dispatch('policy/resetPolicy',payload)
            .then(function (response) {
              self.$store.commit("policy/setShowQuestionnaire", true);
              self.$store.commit("policy/setShowPolicyReport", false);
            });
        }
      });
      return;
    },
    showDialogue(){
      this.$store.commit('policy/tabComponent','Reports')
      // $("#dialogue-nav").addClass("step-success");
      // this.$store.dispatch("loadComponent", "dialogue");
    },
    downloadCookiePolicy: function () {
      // let id = this.domain_id;
      let payload = {
        id: this.domain_id,
      };
      window.location.href = `${this.cmp_url}/api/auth/cookieXray-download-policy/${payload.id}`;
    },
  },
};
</script>
<style scoped>

.cooki-m-policy ::v-deep ul {
  padding-left: 20px !important;
}

.cooki-m-policy ::v-deep span{
  font-size: 12px !important;
}

.cooki-m-policy ::v-deep ul ::v-deep li{
  display: inline-block;
  font-size: 12px !important;
  font-weight: 100;
  font-family: "Poppins", sans-serif;
}
.cooki-m-policy ::v-deep p {
  margin: 0 0 15px;
  font-size: 12px !important;
  font-weight: 100;
  line-height: inherit;
}
.cooki-m-policy ::v-deep p ::v-deep span{
   font-size: 12px !important;
  font-weight: 100;
  line-height: inherit;
}
</style>
