<template>
    <div>
        <div class="form-check" v-for="choice,key in question.choices" :key="key">
            <label class="control-label">
                <input type="radio" @keyup.enter="showNextQuestion" :style="getStyles" :name="question.name" :value="choice.value" v-model="selected"> &nbsp; <span class="control-label"> {{ choice.text }}</span>
            </label>
          
        </div>
    </div>
</template>

<script>
    export default {
        props:['question','question_index'],
        data(){
            return {
            }
        },
        watch:{
        },
        computed: {
            userId: function(){
                return this.$store.state.domain.userId;
            },
            domain_id: function () {
                return this.$store.getters["domain/getCurrentDomainId"];
            },
            selected: {
                set: function (value){
                    this.$store.dispatch('policy/saveCurrentAnswer',{user_id:this.userId,domain_id:this.domain_id,question_name:this.question.name,answer:value});
                },
                get: function(value){
                    return this.$store.getters['policy/getCurrentAnswer'];
                }
            }
        },
        mounted() {
        },
        methods:{
            getStyles: function (){
                return '';
            },
            showNextQuestion(){
                document.getElementById('next-question').click();
                return;
            }
        }
    }
</script>
<style scoped>
.control-label{
    font-size: 15px !important;
    display: flex;
}
</style>